import React from "react";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

import Select from "react-select";
import { UserDropdownContainer } from "./UserDropdown.styles";

interface ISectionProps {
  isMultiple?: boolean;
  options: { label: string; value: string | number }[];
  onChange(value: string | number): void;
  value: string | number;
  className?: string;
  placeholder?: string;
}

type OptionType = {
  value: string | number;
  label: string;
};

const UserDropdown: React.FC<ISectionProps> = ({
  options,
  onChange,
  value,
  className = "",
  placeholder = "Select...",
  isMultiple = false,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<OptionType>();

  React.useEffect(() => {
    if (value) {
      const findOption = find(options, { value });
      if (!isEmpty(findOption)) setSelectedOption(findOption);
    } else setSelectedOption(undefined);
  }, [value, options]);

  const handleChange = (option: OptionType) => {
    onChange(option.value);
  };

  return (
    <UserDropdownContainer className={className}>
      <Select
        isMulti={isMultiple}
        placeholder={placeholder}
        options={options}
        onChange={(newOption: any) => handleChange(newOption)}
        value={selectedOption || ""}
        className="select-container"
        classNamePrefix="select-prefix"
      />
    </UserDropdownContainer>
  );
};

export default UserDropdown;
