/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  CropImage,
  CarrotSolidButton,
  GeyserSolidButton,
  FlexHorizontal,
  DefaultInput,
  Textarea,
  PreviewImage,
  AdminLabel,
  ContentContainer,
  ContentForm,
  DisabledInputField,
  UpdatedLabel,
} from "../../../components/Common";
import { ROUTES } from "../../../constants";
import { SelectMedia } from "../../../components/Layout/Popups";
import { MembersActions, PageSettingsActions } from "../../../actions";
import { statusAlert } from "../../../libs";
import history from "../../../history";
import { TAB_BAR } from "../../../constants/Constants";

const {
  createMember,
  updateMember,
  setMembersDetails,
  getMemberById,
  clearMembersDetails,
} = MembersActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const ClientDetails = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const thumbnailRef = useRef<HTMLInputElement>(null);
  const isRequest = useSelector((state) => _.get(state, "Members.isLoading"));
  const isUploadingMedia = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const details: any = useSelector((state) => _.get(state, "Members.details"));

  const location = useLocation();
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const queryStringValue = queryString.parse(location.search);
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );

  useEffect(() => {
    const id = _.get(queryStringValue, "id");
    if (id && _.includes(id, "-") && _.isString(id))
      dispatch(getMemberById(id));

    return () => {
      dispatch(setStatusEdited(false));
      dispatch(clearMembersDetails());
    };
  }, []);

  useEffect(() => {}, [details]);

  const onChangeHandler = (value: any, key: string, defaultValue?: any) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const resolveDetails = {
      ...details,
      [key]: value,
    };
    dispatch(setMembersDetails(resolveDetails));
  };

  const handleSubmit = async () => {
    const dataCheck = [
      {
        value: "management",
        label: t("alert.team"),
      },
      {
        value: _.trim(_.get(details, "fullname")),
        label: t("alert.fullname"),
      },
      {
        value: _.trim(_.get(details, "position")),
        label: `${t("alert.position")} (English)`,
      },
      {
        value: _.trim(_.get(details, "positionAL")),
        label: `${t("alert.position")} (Albanian)`,
      },
      {
        value: _.trim(_.get(details, "description")),
        label: "Bio (English)",
      },
      {
        value: _.trim(_.get(details, "descriptionAL")),
        label: "Bio (Albanian)",
      },
      {
        value: _.trim(_.get(details, "photo")),
        label: t("alert.profilePhoto"),
      },
    ];
    let emptyFields = "";
    let flag = true;
    _.map(dataCheck, (item) => {
      const value = _.get(item, "value");
      if (!value) {
        flag = false;
        emptyFields = emptyFields + `\n${item.label}`;
      }
    });
    if (!flag)
      return await statusAlert(
        "warning",
        `${t("alert.foundEmptyFields")} : ${emptyFields}`
      );
    else {
      const isHasId = _.has(details, "id");
      if (isHasId)
        await dispatch(updateMember({ ...details, team: "idraDiGIS" }));
      else await dispatch(createMember({ ...details, team: "idraDiGIS" }));
      await history.push(ROUTES.ADMIN.TEAM_MEMBERS);
    }
  };

  const handleHidePopup = (value: string) => {
    if (value) onChangeHandler(value, "photo");
    setIsShowPopup(false);
  };

  const onBack = async () => {
    if (isEditing) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningConfirmLeaveWhenEditing")
      );
      if (isAgree) history.goBack();
    } else history.goBack();
  };

  const renderMain = () => {
    const lastUpdated = _.get(details, "updatedAt");
    return (
      <ContentContainer>
        {isShowPopup && (
          <SelectMedia
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(value: string) => handleHidePopup(value)}
            onClose={() => {
              setIsShowPopup(false);
            }}
            tabBar={TAB_BAR.MEMBER_POPUP}
            selectedTab="image"
          />
        )}
        <ContentForm>
          {lastUpdated && (
            <UpdatedLabel className="mb-1">
              {t("label.lastUpdated")}: {lastUpdated}
            </UpdatedLabel>
          )}
          <FlexHorizontal className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AdminLabel width="100px">{t("label.fullname")}*</AdminLabel>
              <DefaultInput
                flex="1"
                value={_.get(details, "fullname")}
                placeholder={t("label.fullname")}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "fullname")
                }
                onFocus={(event: any) =>
                  dispatch(setContentEdited(event.target.value))
                }
              />
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AdminLabel width="100px">
                {t("label.position")} (English)*
              </AdminLabel>
              <DefaultInput
                flex="1"
                value={_.get(details, "position")}
                placeholder={`${t("label.position")} (English)`}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "position")
                }
                onFocus={(event: any) =>
                  dispatch(setContentEdited(event.target.value))
                }
              />
            </FlexHorizontal>
            <FlexHorizontal alignCenter className="ml-2" flex="1">
              <AdminLabel width="100px">
                {t("label.position")} (Albanian)*
              </AdminLabel>
              <DefaultInput
                flex="1"
                value={_.get(details, "positionAL")}
                placeholder={`${t("label.position")} (Albanian)`}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "positionAL")
                }
                onFocus={(event: any) =>
                  dispatch(setContentEdited(event.target.value))
                }
              />
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="100px">Bio (English)*</AdminLabel>
            <Textarea
              flex="1"
              placeholder={t("label.description")}
              value={_.get(details, "description")}
              onChange={(event: any) =>
                onChangeHandler(event.target.value, "description")
              }
              onFocus={(event: any) =>
                dispatch(setContentEdited(event.target.value))
              }
            ></Textarea>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="100px">Bio (Albanian)*</AdminLabel>
            <Textarea
              flex="1"
              placeholder={t("label.description")}
              value={_.get(details, "descriptionAL")}
              onChange={(event: any) =>
                onChangeHandler(event.target.value, "descriptionAL")
              }
              onFocus={(event: any) =>
                dispatch(setContentEdited(event.target.value))
              }
            ></Textarea>
          </FlexHorizontal>

          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="100px">{t("label.profilePhoto")}*</AdminLabel>
            <FlexHorizontal flex="1">
              <DisabledInputField>
                <input
                  value={_.get(details, "photo") || "N/I"}
                  ref={thumbnailRef}
                  readOnly={true}
                />
              </DisabledInputField>
              <CarrotSolidButton onClick={() => setIsShowPopup(true)}>
                {t("button.selectMedia")}
              </CarrotSolidButton>
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="flex-align-center mt-1">
            <AdminLabel width="100px">{t("label.preview")}</AdminLabel>
            <PreviewImage>
              <CropImage
                isMembers
                imageUrl={_.get(details, "photo")}
                handleChange={(cropData: any) =>
                  onChangeHandler(cropData, "croppedData")
                }
                data={_.get(details, "croppedData")}
              />
            </PreviewImage>
          </FlexHorizontal>
          <FlexHorizontal justifySpaceBetween className="mt-3">
            <GeyserSolidButton className="mr-1" onClick={() => onBack()}>
              {t("button.back")}
            </GeyserSolidButton>
            <FlexHorizontal justifyFlexEnd>
              <GeyserSolidButton
                className="mr-1"
                onClick={() => dispatch(clearMembersDetails())}
              >
                {t("button.clear")}
              </GeyserSolidButton>
              <CarrotSolidButton onClick={() => handleSubmit()}>
                {t("button.save")}
              </CarrotSolidButton>
            </FlexHorizontal>
          </FlexHorizontal>
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.TEAM_MEMBERS_DETAILS}
      loading={isRequest || isUploadingMedia}
    />
  );
};

export default ClientDetails;
