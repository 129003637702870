/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { Icons } from "../../../themes";
import { ColorName } from "../../Variables";
interface SectionProps {
  label: string;
  onChange(value: string | number): unknown;
  checked: boolean;
  className?: string;
  isLeftLabel?: boolean;
  disabled?: boolean;
  value: string | number;
  style?: object;
}

const Label = styled.p`
  font-size: 15px;
  color: ${ColorName.black};
`;

const CheckboxSquare = styled.div`
  position: relative;
  width: 16px;
  height: 15px;
  margin: 0 5px;

  &:hover {
    cursor: pointer;
    & .tick-icon {
      opacity: 0.8;
    }
  }
  &.disabled {
    opacity: 0.8;
    &:hover {
      cursor: not-allowed;
      & .tick-icon {
        opacity: 0;
      }
    }
  }

  & .tick-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 4px;
    left: 3px;
    opacity: 0;
    /* transition: all ease 0.25s; */

    &.checked {
      opacity: 1;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  object-fit: cover;
`;

const UserCheckbox: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    onChange,
    checked,
    className,
    label,
    isLeftLabel,
    disabled,
    value,
    style = {},
  } = props;

  const handleCheck = () => {
    if (disabled) return;
    if (onChange) onChange(value);
  };

  return (
    <CheckboxContainer className={`${className}`} style={style}>
      {isLeftLabel && <Label>{label}</Label>}
      <CheckboxSquare
        className={`${disabled ? "disabled" : ""}`}
        onClick={() => handleCheck()}
      >
        <img src={Icons.checkboxSquare.default} alt="square" />
        <img
          src={Icons.userTick.default}
          alt="tick"
          className={`tick-icon ${checked ? "checked" : ""}`}
        />
      </CheckboxSquare>
      {!isLeftLabel && <Label>{label}</Label>}
    </CheckboxContainer>
  );
};

export default UserCheckbox;
