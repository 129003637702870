import styled, { keyframes } from 'styled-components';
import { PageContainer, FlexHorizontal, FlexVertical } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const SearchContainer = styled(PageContainer)`
  & .result-search-content {
    @media screen and (max-width: 426px) {
      padding: 0 20px;
    }
  }
`;

const SearchContent = styled(FlexVertical)`
  position: relative;
  max-width: 910px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
`;

const FilterBar = styled(FlexHorizontal)`
  width: 100%;
  height: 40px;
  position: sticky;
  top: 160px;
  left: 0;
  border-bottom: 1px solid ${ColorName.black};
  background: ${ColorName.white};
  z-index: 10;
  div {
    display: flex;
    align-items: center;
    width: max-content;
    height: 100%;
    transition: all ease 0.15s;
    font-size: 16px;
    font-weight: 600;
    color: ${ColorName.grannySmith};
    padding: 0 15px;
    user-select: none;

    &:hover,
    &.active {
      color: ${ColorName.burntSienna};
      cursor: pointer;
    }
  }

  @media screen and (max-width: 426px) {
    top: 110px;
  }
`;

const NewsItem = styled(FlexHorizontal)`
  padding-bottom: 2rem;
  margin-top: 2rem;

  &.border-bottom {
    border-bottom: 1px solid ${ColorName.black};
  }
  & .preview {
    max-height: 150px;
    overflow: hidden;
  }
  & .preview span,
  & .preview p {
    color: ${ColorName.black} !important;
  }
  img {
    width: 100%;
    max-width: 295px;
    height: 420px;
    object-fit: cover;
  }

  &:hover {
    img,
    h3 {
      cursor: pointer;
    }
  }

  & .content {
    flex: 1;
    span {
      color: ${ColorName.burntSienna};
      font-size: 16px;
      font-weight: 500;
    }
    h3 {
      color: ${ColorName.shark};
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      margin: 10px 0;
    }
    p {
      margin: 0;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /* after 3 line show ... */
      -webkit-box-orient: vertical;
    }
    & .link {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
    a {
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 2px;
      padding-bottom: 3px;
      width: max-content;
      justify-self: flex-end;
    }
  }
  @media screen and (max-width: 426px) {
    flex-direction: column;
    & .content {
      margin-left: 0;
      h3 {
        font-size: 18px;
        line-height: 25px;
      }
      p {
        font-size: 13px;
        line-height: 19px;
      }
      span {
        margin-top: 10px;
        font-size: 12px;
      }
    }

    img {
      width: 100%;
      max-width: unset;
    }
  }
`;

const LoadingLayer = styled(FlexHorizontal)`
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  border-bottom: 1px solid ${ColorName.grannySmith};
  margin-top: 40px;

  & .image {
    width: 315px;
    height: 420px;
    margin-right: 20px;
  }

  & .time {
    width: 30%;
    height: 30px;
    margin: 10px 0;
  }

  & .title {
    width: 90%;
    height: 100px;
    margin-top: 15px;
  }

  & .description {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
  }

  & .button {
    width: 150px;
    height: 50px;
  }

  & .description,
  & .button,
  & .title,
  & .time,
  & .image {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  @media screen and (max-width: 436px) {
    flex-direction: column;
    & .image {
      width: 100%;
      max-height: 200px;
      padding-top: 50%;
    }
  }
`;

const OutputImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 295px;
  height: 420px;
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
  @media screen and (max-width: 426px) {
    width: 100%;
    max-width: unset;
  }
`;

const SVGImage = styled.img`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

const SearchInput = styled.div`
  height: 80px;
  position: sticky;
  top: 80px;
  display: flex;
  align-items: center;
  background: ${ColorName.white};
  z-index: 2;

  & .content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid ${ColorName.black};
    border-radius: 3px;
    padding: 0 15px;
    height: 43px;

    & .remove-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      &:hover {
        cursor: pointer;
      }
    }
    & .search-icon {
      width: 30px;
      img {
        width: 20px;
      }
    }

    input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-size: 1.6rem;
    }
  }
  @media screen and (max-width: 426px) {
    top: 60px;
    height: 50px;
  }
`;

export { SearchContainer, SearchContent, FilterBar, NewsItem, LoadingLayer, OutputImage, SVGImage, SearchInput };
