import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../Variables';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const LoadingLayer = styled.div`
  width: 100%;
  margin-top: 40px;

  &:nth-child(1) {
    margin-top: 0px;
  }

  & .timestamp {
    width: 100px;
    position: relative;
    height: 30px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .thumbnail {
    width: 151px;
    position: relative;
    height: 200px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 10px;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .title {
    width: 90%;
    position: relative;
    height: 120px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 10px;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .description {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .button {
    position: relative;
    width: 150px;
    height: 50px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }
`;

const CardLoading = styled.div`
  border: 1px solid #ddd;
  background: ${ColorName.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.55s;
  transition-delay: 0.1s;
  position: relative;
  will-change: background;
  min-height: 237px;
  padding: 40px 25px;
  & .title,
  & .description {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }
  & .title {
    width: 60%;
    height: 32px;
  }
  & .description {
    margin-top: 10px;
    width: 100%;
    height: 80px;
  }
`;

export { LoadingLayer, CardLoading };
