import styled from "styled-components";
import { ColorName } from "../../Variables";

interface SectionProps {
  textAlignRight?: boolean;
  textAlignLeft?: boolean;
  textAlignCenter?: boolean;
  textAlignJustify?: boolean;
  textAlignLastCenter?: boolean;
  textAlignLastLeft?: boolean;
  textAlignLastRight?: boolean;
  fontSize?: string;
  maxWidth?: string;
  fontWeight?: string;
  minHeight?: string;
}

const handleTextAlign = (props: SectionProps) => {
  const { textAlignRight, textAlignLeft, textAlignCenter, textAlignJustify } =
    props;
  if (textAlignRight) return "right";
  if (textAlignLeft) return "left";
  if (textAlignCenter) return "center";
  if (textAlignJustify) return "justify";
  return "unset";
};

const handleTextAlignLast = (props: SectionProps) => {
  const { textAlignLastCenter, textAlignLastLeft, textAlignLastRight } = props;
  if (textAlignLastCenter) return "center";
  if (textAlignLastLeft) return "left";
  if (textAlignLastRight) return "right";
  return "unset";
};

const Paragraph = styled.p`
  white-space: pre-line;
  text-align: ${(props: SectionProps) => handleTextAlign(props)};
  text-align-last: ${(props: SectionProps) => handleTextAlignLast(props)};
  font-size: ${(props: SectionProps) => props.fontSize};
  max-width: ${(props: SectionProps) => props.maxWidth || "100%"};
  font-weight: ${(props: SectionProps) => props.fontWeight || "unset"};
  min-height: ${(props: SectionProps) => props.minHeight || "unset"};

  li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 436px) {
    > *,
    & {
      font-size: 14px;
      line-height: 20px !important;
    }
  }
`;

const RegularParagraph = styled(Paragraph)`
  font-weight: 400;
  line-height: 2.7rem;
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: 700;
`;

const BlackBoldParagraph = styled(BoldParagraph)`
  color: ${ColorName.black};
`;

const GrannyBoldParagraph = styled(BoldParagraph)`
  color: ${ColorName.grannySmith};
`;

const WhiteBoldParagraph = styled(BoldParagraph)`
  color: ${ColorName.white};
`;

const BlackRegularParagraph = styled(RegularParagraph)`
  color: ${ColorName.black};
`;

const GrannyRegularParagraph = styled(RegularParagraph)`
  color: ${ColorName.grannySmith};
`;

const WhiteRegularParagraph = styled(RegularParagraph)`
  color: ${ColorName.white};
`;

const QuoteWhoWeAre = styled.p`
  font-size: 1.9rem;
  line-height: 2.8rem;
  font-weight: 500;
  white-space: pre-line;

  @media screen and (max-width: 426px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export {
  RegularParagraph,
  BlackBoldParagraph,
  GrannyBoldParagraph,
  WhiteBoldParagraph,
  GrannyRegularParagraph,
  WhiteRegularParagraph,
  BlackRegularParagraph,
  QuoteWhoWeAre,
};
