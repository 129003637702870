import Service from './Base';
import API from '../constants/API';

const { TEAM_MEMBERS } = API;

export function getAllMembers(params: any) {
  return Service.get(TEAM_MEMBERS.BASIC, params);
}

export function getAllMembersForUser() {
  return Service.get(TEAM_MEMBERS.GET_FOR_USER);
}

export function getMemberById(id: string) {
  return Service.get(`${TEAM_MEMBERS.BASIC}/${id}`);
}

export function createMember(data: any) {
  return Service.post(TEAM_MEMBERS.BASIC, data);
}

export function updateMember(data: any) {
  const { id } = data;
  return Service.put(`${TEAM_MEMBERS.BASIC}/${id}`, data);
}

export function removeSingleMember(id: string) {
  return Service.del(`${TEAM_MEMBERS.BASIC}/${id}`);
}

export function removeMultipleMembers(ids: string[]) {
  return Service.del(TEAM_MEMBERS.REMOVE_MULTIPLE, { ids });
}
