import React, { useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import history from "../../../history";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES, ANIMATION } from "../../../constants/";
import {
  UserCarrotButton,
  Content,
  SectionTitle,
  PageContainer,
  RegularParagraph,
  FlexHorizontal,
  FlexVertical,
} from "../../../components/Common";
import { useMediaQuery } from "../../../libs";
import { Icons } from "../../../themes";
import { AlumniParagraph, AlumniIcon } from "./AlumniNetwork.styles";

const AlumniaNetwork = () => {
  // Declare translation
  const { t } = useTranslation("user");
  const payload = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.ALUMNI_NETWORK}`)
  );
  const locale = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const isMobile = useMediaQuery("(max-width: 768px)");
  const requestIsLoading = useSelector((state) =>
    _.get(state, "AlumniNetwork.requestIsLoading")
  );

  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const newsAndInformationRef = useRef<HTMLParagraphElement>(null);
  const alumniStoriesRef = useRef<HTMLParagraphElement>(null);
  const careerAdvancementRef = useRef<HTMLParagraphElement>(null);
  const consultingServicesRef = useRef<HTMLParagraphElement>(null);
  const expertiseEngagementRef = useRef<HTMLParagraphElement>(null);
  const informationExchangeRef = useRef<HTMLParagraphElement>(null);
  const networkingOpportunitiesRef = useRef<HTMLParagraphElement>(null);
  const virtualEventsRef = useRef<HTMLParagraphElement>(null);

  const refsToUpdate = useMemo(
    () => [
      { ref: descriptionRef, key: "description" },
      { ref: newsAndInformationRef, key: "newsAndInformation" },
      { ref: alumniStoriesRef, key: "alumniStories" },
      { ref: careerAdvancementRef, key: "careerAdvancement" },
      { ref: consultingServicesRef, key: "consultingServices" },
      { ref: expertiseEngagementRef, key: "expertiseEngagement" },
      { ref: informationExchangeRef, key: "informationExchange" },
      { ref: networkingOpportunitiesRef, key: "networkingOpportunities" },
      { ref: virtualEventsRef, key: "virtualEvents" },
    ],
    []
  );

  const leftKey = {
    newsAndInformation: newsAndInformationRef,
    careerAdvancement: careerAdvancementRef,
    consultingServices: consultingServicesRef,
    expertiseEngagement: expertiseEngagementRef,
  };

  const rightKey = {
    networkingOpportunities: networkingOpportunitiesRef,
    informationExchange: informationExchangeRef,
    virtualEvents: virtualEventsRef,
    alumniStories: alumniStoriesRef,
  };

  useEffect(() => {
    _.forEach(refsToUpdate, ({ ref, key }) => {
      if (ref && ref.current) {
        ref.current.innerHTML = payload?.[key]?.[`${locale}`] ?? "";
      }
    });
  }, [locale, payload, refsToUpdate]);

  const _renderContent = () => (
    <motion.div
      variants={ANIMATION.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={ANIMATION.item}>
        <SectionTitle
          fontSize="2.6rem"
          className={`${
            !requestIsLoading && _.isEmpty(payload) ? "mb-0" : "mb-3"
          }`}
        >
          {payload?.title?.[`${locale}`]}
        </SectionTitle>
      </motion.div>
      <motion.div variants={ANIMATION.item}>
        <RegularParagraph textAlignLeft fontSize="15px" ref={descriptionRef} />
      </motion.div>
      <motion.div variants={ANIMATION.item} className="mb-3">
        <FlexHorizontal
          style={{
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <FlexVertical flex="1">
            {_.map(_.keys(leftKey), (key, index) => {
              return (
                <FlexHorizontal key={index} alignCenter className="mb-2">
                  <AlumniIcon
                    src={Icons[`${key as keyof typeof leftKey}`].default}
                    alt={Icons[`${key as keyof typeof leftKey}`].default}
                  />
                  <AlumniParagraph
                    textAlignLeft
                    fontSize="15px"
                    ref={leftKey[key as keyof typeof leftKey]}
                  />
                </FlexHorizontal>
              );
            })}
          </FlexVertical>
          <FlexVertical flex="1">
            {_.map(_.keys(rightKey), (key, index) => {
              return (
                <FlexHorizontal key={index} alignCenter className="mb-2">
                  <AlumniIcon
                    src={Icons[`${key as keyof typeof rightKey}`].default}
                    alt={Icons[`${key as keyof typeof rightKey}`].default}
                  />
                  <AlumniParagraph
                    textAlignLeft
                    fontSize="15px"
                    ref={rightKey[key as keyof typeof rightKey]}
                  />
                </FlexHorizontal>
              );
            })}
          </FlexVertical>
        </FlexHorizontal>
      </motion.div>
      <motion.div
        variants={ANIMATION.item}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <UserCarrotButton
          onClick={() => {
            history.push(ROUTES.USER.ALUMNI_NETWORK_REGISTER);
          }}
        >
          {t("button.registerHere")}
        </UserCarrotButton>
      </motion.div>
    </motion.div>
  );

  const renderMain = () => {
    return (
      <PageContainer style={{ minHeight: "500px" }}>
        <Content className="pb-5 pt-5" direction="column">
          {_renderContent()}
        </Content>
      </PageContainer>
    );
  };
  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.ALUMNI_NETWORK}
      headerTitle={t("titlePage.alumniNetwork")}
      loading={requestIsLoading}
      metaDescription="IDRA has started it’s first Alumni Network to bring together all past and present alumni"
    />
  );
};

export default AlumniaNetwork;
