/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';
import { MEDIAS } from '../constants/Constants';

const defaultFilter = {
  keyword: '',
  type: MEDIAS.MEDIA_TYPES.IMAGE,
  videoType: 'aws',
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payload: [],
  videos: [],
  filter: defaultFilter,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_MEDIA_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_MEDIA_FILTER:
      return {
        ...state,
        filter: {
          ...defaultFilter,
          type: state.filter.type,
        },
      };

    case ActionTypes.SET_MEDIA_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_MEDIA_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.GET_MEDIA_BY_TYPE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
        mediaPayload: [],
      };
    case ActionTypes.GET_MEDIA_BY_TYPE_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        mediaPayload: [],
      };
    case ActionTypes.GET_MEDIA_BY_TYPE_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        mediaPayload: payload,
      };

    case ActionTypes.UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_VIDEOS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
        videos: [],
      };
    case ActionTypes.GET_VIDEOS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        videos: [],
      };
    case ActionTypes.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        videos: payload,
      };

    case ActionTypes.REMOVE_MEDIA_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MEDIA_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MEDIA_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
