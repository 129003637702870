import styled, { keyframes } from 'styled-components';
import { FlexVertical, PageContainer } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

interface SectionProps {
  marginLeft?: number;
}

const NewsContainer = styled(PageContainer)`
  padding-bottom: 80px;

  @media screen and (max-width: 836px) {
    padding: 40px 0 40px 0;
  }

  @media screen and (max-width: 436px) {
    padding: 20px 0 40px 0;
  }
`;

const ContentCol = styled(FlexVertical)`
  flex: 1;
  margin-left: ${(props: SectionProps) => (props.marginLeft ? `${props.marginLeft}px` : '0px')};
  @media screen and (max-width: 1366px) {
    margin-left: 80px;
  }

  @media screen and (max-width: 1025px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 836px) {
    margin-left: 0;
  }
  @media screen and (max-width: 426px) {
    margin-left: 0px;
  }
`;

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const LoadingLayer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;

  &:nth-child(1) {
    margin-top: 0px;
  }

  & .flex-column {
    flex: 1;
    box-sizing: border-box;
  }

  & .image {
    width: 315px;
    height: 420px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .title {
    width: calc(90%);
    position: relative;
    margin-left: 20px;
    height: 100px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .description {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    position: relative;
    width: calc(100% - 20px);
    height: 180px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .button {
    position: relative;
    margin-left: 20px;
    width: 150px;
    height: 50px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    margin-top: 60px;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & .image {
      max-width: unset;
      width: 100%;
      padding-top: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    & .title,
    & .description,
    & .timestamp,
    & .button {
      margin-left: 0;
      margin-bottom: 10px;
      margin-top: 0;
    }

    & .title {
      height: 65px;
    }
    & .description {
      height: 140px;
    }
    & .button {
      height: 40px;
    }
  }
`;

const SVGImage = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export { NewsContainer, ContentCol, LoadingLayer, SVGImage };
