import { ROUTES } from "../../../constants";

const PAGES_DATA = [
  {
    label: "homepage",
    value: ROUTES.USER.HOMEPAGE,
  },
  {
    label: "contact",
    value: ROUTES.USER.CONTACT,
  },
  {
    label: "whoWeAre",
    value: ROUTES.USER.WHO_WE_ARE,
  },
  {
    label: "areasOfInterest",
    value: ROUTES.USER.AREAS_OF_INTEREST,
  },
  // {
  //   label: "IDRAResearch",
  //   value: ROUTES.USER.IDRA_RESEARCH,
  // },
  // {
  //   label: "IDRADiGIS",
  //   value: ROUTES.USER.IDRA_DIGIS,
  // },
  // {
  //   label: "IDRAAdvance",
  //   value: ROUTES.USER.IDRA_ADVANCE,
  // },
  // {
  //   label: "researchServices",
  //   value: ROUTES.USER.RESEARCH_SERVICES,
  // },
  // {
  //   label: "developmentSolutions",
  //   value: ROUTES.USER.DEVELOPMENT_SOLUTIONS,
  // },
];

export { PAGES_DATA };
