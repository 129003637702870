/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Wrapper, Container, IconWrapper, Title, ButtonWrapper, Button } from './Alert.styles';
import { ColorName } from '../../../Variables';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  type?: string;
  message?: string;
  buttons?: any;
  callback?(value: string): unknown;
  isMessageServer?: boolean;
  subMessage?: string;
  duration?: any;
}

const hideAlert = () => {
  return new Promise<void>(resolve => {
    const rootElement = document.getElementById('alert');
    const emptyElemnt = React.createElement('div', {}, []);
    ReactDOM.render(emptyElemnt, rootElement);
    resolve();
  });
};

const AlertPopup: React.FC<SectionProps> = (props: SectionProps) => {
  // Delacre translation
  // Declare Props
  const { t } = useTranslation('admin');
  const { type, message, buttons, callback, isMessageServer, subMessage } = props;
  const [listSubMessage, setListSubMessage] = useState<string[]>([]);

  useEffect(() => {
    if (subMessage) {
      const splitSubMessage = _.split(subMessage, '\n');
      const resolvedSubMessage = _.map(splitSubMessage, item => {
        return item;
      });
      setListSubMessage(resolvedSubMessage);
    }
  }, []);

  // EVENT CLICK ON BUTTONS
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (_.get(event.target, 'classList').contains('wrapper')) {
      if (callback) return callback('cancel');
      return hideAlert();
    }
  };

  const renderIcons = () => {
    if (type === 'success')
      return (
        <IconWrapper>
          <div className="screenAlert-icon screenAlert-success animate">
            <span className="screenAlert-line screenAlert-tip animateSuccessTip" />
            <span className="screenAlert-line screenAlert-long animateSuccessLong" />
            <div className="screenAlert-placeholder" />
            <div className="screenAlert-fix" />
          </div>
        </IconWrapper>
      );
    if (type === 'warning')
      return (
        <IconWrapper>
          <div className="screenAlert-icon screenAlert-warning scaleWarning">
            <span className="screenAlert-body pulseWarningIns" />
            <span className="screenAlert-dot pulseWarningIns" />
          </div>
        </IconWrapper>
      );
    if (type === 'error')
      return (
        <IconWrapper>
          <div className="screenAlert-icon screenAlert-error animate">
            <span className="screenAlert-x-mark">
              <span className="screenAlert-line screenAlert-left animateXLeft" />
              <span className="screenAlert-line screenAlert-right animateXRight" />
            </span>
            <div className="screenAlert-placeholder" />
            <div className="screenAlert-fix" />
          </div>
        </IconWrapper>
      );
  };

  const renderTitle = () => {
    if (type === 'success') return <Title className="title_success">{t('alert.success')}</Title>;
    if (type === 'error') return <Title className="title_error">{t('alert.error')}</Title>;
    if (type === 'warning') return <Title className="title_warning">{t('alert.warning')}</Title>;
    return null;
  };

  const renderDefaultButtons = () => {
    if (type === 'success' || type === 'error') {
      return (
        <ButtonWrapper>
          <Button value="ok" onClick={() => callback && callback('ok')}>
            {t('alert.ok')}
          </Button>
        </ButtonWrapper>
      );
    }
    return (
      <ButtonWrapper>
        {!isMessageServer && (
          <Button
            color={ColorName.darkElectricBlue}
            background={ColorName.geyser}
            value="cancel"
            onClick={() => callback && callback('cancel')}
          >
            {t('alert.cancel')}
          </Button>
        )}
        <Button
          color={ColorName.white}
          background={ColorName.burntSienna}
          value="cancel"
          onClick={() => callback && callback('ok')}
        >
          {t('alert.ok')}
        </Button>
      </ButtonWrapper>
    );
  };

  const renderCustomButtons = () => {
    return (
      <ButtonWrapper>
        {_.map(buttons, (button, index) => {
          return (
            <Button
              key={index}
              color={button.color}
              background={button.background}
              value={button.value}
              onClick={() => callback && callback(button.value)}
            >
              {button.title}
            </Button>
          );
        })}
      </ButtonWrapper>
    );
  };

  return (
    <Wrapper onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClick(event)} className="wrapper">
      <Container>
        {renderIcons()}
        {renderTitle()}
        {isMessageServer && <p className="alert__message">{message}</p>}
        {!isMessageServer && (
          <p className="alert__message">
            {message}
            {listSubMessage}
          </p>
        )}
        {!buttons && renderDefaultButtons()}
        {_.isArray(buttons) && !_.isEmpty(buttons) && renderCustomButtons()}
      </Container>
    </Wrapper>
  );
};

const showAlert = (attribute?: any) => {
  const { type, message, buttons, isMessageServer, subMessage } = attribute;
  return new Promise(resolve => {
    const rootElement = document.getElementById('alert');
    ReactDOM.render(
      <AlertPopup
        type={type}
        message={message}
        buttons={buttons}
        callback={(value: string) => resolve(value)}
        isMessageServer={isMessageServer}
        subMessage={subMessage}
      />,
      rootElement
    );
  });
};

const Alert = (attribute?: SectionProps) => {
  return new Promise(resolve => {
    showAlert(attribute).then(value => {
      hideAlert().then(() => resolve(value));
    });
  });
};

export default Alert;
