import React from "react";
import { UserCarrotButton } from "../../components/Common";
import history from "../../history";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation("user");

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h2 style={{ fontSize: "80px" }}>404</h2>
      <h3 style={{ fontSize: "28px", marginTop: "14px" }}>
        {t("label.pageNotFound")}
      </h3>
      <p style={{ marginTop: "14px", marginBottom: "28px" }}>
        {t("label.sorryAboutPageNotFound")}
      </p>
      <UserCarrotButton
        isUppercase
        onClick={() => history.push(ROUTES.USER.HOMEPAGE)}
      >
        {t("button.backToHome")}
      </UserCarrotButton>
    </div>
  );
};

export default NotFound;
