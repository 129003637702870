import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import _ from 'lodash';
import styled from 'styled-components';

interface SectionProps {
  data?: any;
  onChange?(value: any): unknown;
  onFocus?(value: any): unknown;
  isHasHeading?: boolean;
  placeholder?: string;
  fontSize?: string;
  toolbar?: string[];
  className?: string;
}

const RichtextBoxContainer = styled.div`
  &.description {
    * {
      text-align: justify;
      text-align-last: left;
    }
  }

  & .ck-editor__editable.ck-content {
    max-width: 100%;
  }
  p,
  span,
  div {
    font-size: ${(props: SectionProps) => props.fontSize || '16px'};
    line-height: 24px;
  }
  li {
    font-size: 16px;
    line-height: 25px;
  }
  h2 {
    font-size: 30px;
    line-height: 38px;
    text-align: justify;
  }
  ul {
    margin: 0;
  }
`;

const Richtextbox = (props: SectionProps) => {
  const { data, onChange, placeholder, onFocus, fontSize, toolbar, className } = props;
  // Create a configuration object
  const editorConfiguration = {
    toolbar: toolbar || ['bold', 'italic', 'link', '|', 'numberedList', 'bulletedList', '|', 'undo', 'redo'],
    placeholder,
    allowContent: 'b s u i em strong; span{text-decoration,font-weight}',
    spellCheck: false,
  };

  const handleChange = (value: any, editor: any) => {
    const newData = editor.getData();
    if (onChange && !_.isEmpty(newData)) {
      const resolveNewData = _.replace(newData, new RegExp("<a href", "g"), "<a target=\"_blank\" rel=\"noopener noreferrer\" href");
      onChange(resolveNewData);
    }
  };

  return (
    <RichtextBoxContainer className={className} fontSize={fontSize}>
      <CKEditor
        editor={InlineEditor}
        onBlur={(value: any, editor: any) => handleChange(value, editor)}
        data={data}
        config={editorConfiguration}
        onFocus={(value: any, editor: any) => onFocus && onFocus(editor.getData())}
        spellCheck={false}
      />
    </RichtextBoxContainer>
  );
};

export default Richtextbox;
