import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { FlexHorizontal, Content } from '../../../Common';
import { SecondHeaderContainer, SecondHeaderRow } from './SecondHeader.styles';

interface DataStructure {
  value: string;
  label: string;
}

interface SectionProps {
  active: string;
  data: DataStructure[];
  onChange(value: string): unknown;
  adminPosition?: boolean;
  className?: string;
}

const SecondHeader: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation('user');
  // Declare props
  const { data, onChange, active, adminPosition, className } = props;
  // Declare dispatch, reducers
  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current && scrollRef && scrollRef.current) {
      const refParent = ref.current.children;
      let positionX = 0;
      for (var i = 0; i < refParent.length; i++) {
        if (refParent[i].classList.contains('active')) break;
        positionX += refParent[i].clientWidth + 50;
      }
      scrollRef.current.style.transition = 'all ease 0.25s';
      scrollRef.current.scrollLeft = positionX;
    }
  }, [active]);

  const handleClick = (value: string) => {
    if (onChange) onChange(value);
  };

  return (
    <SecondHeaderContainer
      className={`${className} ${adminPosition ? 'custom-label' : ''} ${adminPosition ? 'admin-position' : ''}`}
    >
      <Content className={`${adminPosition ? 'p-0' : ''}`}>
        <SecondHeaderRow ref={scrollRef}>
          <FlexHorizontal ref={ref} id="scroll-row">
            {!isEmpty(data) &&
              map(data, (item, index) => {
                const { label, value } = item;
                const activeClass = value === active ? 'active' : '';
                return (
                  <h3 className={activeClass} key={`item-${index}`} onClick={() => handleClick(value)}>
                    {t(`label.${label}`)}
                  </h3>
                );
              })}
          </FlexHorizontal>
        </SecondHeaderRow>
      </Content>
    </SecondHeaderContainer>
  );
};

export default SecondHeader;
