import styled, { keyframes } from 'styled-components';
import { PageContainer, BlackRegularParagraph, FlexVertical, FlexHorizontal } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

const slideLeft = keyframes`
  from {
    transform: translateX(15px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const StepForm = styled(FlexVertical)`
  text-align: center;
`;

const TitleStep = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 58px;
  color: ${ColorName.black};

  @media screen and (max-width: 436px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const DescriptionStep = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: ${ColorName.tundora};
  @media screen and (max-width: 436px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const OptionBox = styled(FlexVertical)`
  border-radius: 10px;
  transition: all ease 0.15s;
  width: 100%;
  padding-top: 100%;
  position: relative;
  &.active {
    cursor: pointer;
    background: ${ColorName.bianca};
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
  }
  @media screen and (min-width: 1025px) {
    &:hover {
      cursor: pointer;
      background: ${ColorName.bianca};
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 22px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      margin-top: 15px;
      color: ${ColorName.black};
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.005em;
      margin-top: 5px;
      color: ${ColorName.grannySmith};
    }
  }

  @media screen and (max-width: 436px) {
    margin: 0 auto;
    width: 100%;
    height: 200px;
    padding: 0;
  }
`;

const InputForm = styled(FlexHorizontal)`
  width: 100%;

  @media screen and (max-width: 836px) {
    > div {
      flex: 1;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & .message-box {
      margin-top: 1.3rem;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 436px) {
    & .message-box {
      margin-top: 1rem;
      margin-left: 0;
    }

    input,
    textarea {
      font-size: 14px;
    }
    input {
      padding: 0 10px;
    }
    textarea {
      padding: 5px 10px;
      height: 150px;
    }
  }
`;

const LabelInput = styled(BlackRegularParagraph)`
  margin-bottom: 0.5rem;
`;

const PrevButton = styled(FlexHorizontal)`
  width: max-content;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${ColorName.grannySmith};
  transition: all ease 0.25s;
  transform: translateY(15px);
  opacity: 0;
  animation: ${slideLeft} ease 0.25s forwards;
  animation-delay: 0.75s;

  @media screen and (max-width: 436px) {
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ContactContainer = styled(PageContainer)`
  & .dotted-border {
    border-top: 1px dotted black;
    margin: 100px 0;
    @media screen and (max-width: 836px) {
      margin: 50px 0;
    }
  }
  @media screen and (max-width: 436px) {
    ${OptionBox} {
      img {
        max-width: 26px;
      }
      h3 {
        font-size: 16px;
        line-height: 21px;
        margin-top: 5px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0px;
      }
    }
  }
`;

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const SpinLoader = styled.div`
  border: 5px solid ${ColorName.geyser};
  border-top: 5px solid ${ColorName.darkElectricBlue};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  left: calc(50% - 17px);
  top: 5px;
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  max-width: 1054px;
  margin: 0 auto;

  @media screen and (max-width: 836px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 600px;
  }

  @media screen and (max-width: 376px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const InformationLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1025px) {
    grid-template-columns: 1.2fr 1.3fr 1fr;
    max-width: unset;
  }

  @media screen and (max-width: 836px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: unset;
  }

  @media screen and (max-width: 836px) {
    grid-template-columns: repeat(1, 1fr);
    max-width: unset;
  }
`;

const CompanyBox = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  &:nth-child(1) {
    margin-left: unset;
  }

  &:nth-child(3) {
    margin-right: unset;
  }
  @media screen and (max-width: 836px) {
    margin: 0 0 25px 0;
  }
`;

const CompanyName = styled.h3`
  font-size: 18px;
  @media screen and (max-width: 436px) {
    font-size: 16px;
  }
`;

const CompanyDetails = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;

  &.not_phone {
    margin-top: 7px;
  }

  p {
    font-size: 16px;
    white-space: pre-line;
  }
  img {
    width: 20px;
    object-fit: contain;
  }
  & .icons {
    display: flex;
    align-items: center;
    height: 23px;
    margin-right: 10px;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
  a {
    font-size: 16px;
    font-weight: bold;
    color: ${ColorName.darkElectricBlue};
    text-decoration: none;
    line-height: 23px;
    transition: all ease 0.25s;

    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 436px) {
    p,
    a {
      font-size: 14px;
    }
    margin: 6px 0;
  }
`;

const InputField = styled.div`
  width: 100%;
  border: 1px solid ${ColorName.black};
  border-left: unset;
  display: flex;
  span {
    width: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Be Vietnam';
  @media screen and (max-width: 436px) {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

export {
  ContactContainer,
  InputForm,
  StepForm,
  TitleStep,
  DescriptionStep,
  OptionBox,
  LabelInput,
  SpinLoader,
  GridLayout,
  PrevButton,
  InformationLayout,
  CompanyName,
  CompanyDetails,
  CompanyBox,
  InputField,
  Input,
};
