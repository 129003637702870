/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { NormalTable } from "../../../components/Layout/CommonLayoutPart/Table";
import {
  AdminDropdown,
  FlexHorizontal,
  GeyserSolidButton,
  DarkElectriSolidcButton,
  CarrotSolidButton,
  InputSearchField,
  AdminPagination,
  ContentContainer,
  AdminLabel,
} from "../../../components/Common";
import { ROUTES, ColumnsTable } from "../../../constants";
import history from "../../../history";
import { CareersActions } from "../../../actions";
import { statusAlert } from "../../../libs";

// Declare actions
const {
  setCareersPagination,
  filterCareers,
  setCareersFilter,
  removeSingleCareer,
  setRemoveCareersIds,
  removeMultipleCareers,
  clearCareersFilter,
  clearRemoveCareersIds,
} = CareersActions;

const CareerManagement = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Careers.isLoading")
  );
  const payload = useSelector((state) => _.get(state, "Careers.payload"));
  const filter = useSelector((state) => _.get(state, "Careers.filter"));
  const pagination = useSelector((state) => _.get(state, "Careers.pagination"));
  const meta = useSelector((state) => _.get(state, "Careers.meta"));
  const removeIds = useSelector((state) => _.get(state, "Careers.removeIds"));

  useEffect(() => {
    dispatch(filterCareers({ ...filter, ...pagination }));
  }, []);

  // Handle change filter
  const handleChangeFilter = async (value: any, key: string) => {
    const newFilter = {
      ...filter,
      [key]: value,
    };
    await dispatch(setCareersFilter(newFilter));
    if (key !== "keyword")
      await dispatch(filterCareers({ ...newFilter, ...pagination }));
  };

  // Handle click function on each rows
  const handleViewDetails = async (value: any, key: string) => {
    if (key === "edit" && value)
      history.push(`${ROUTES.ADMIN.CAREER}/details?id=${value}`);
    if (key === "delete" && value) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningDeleteCareer")
      );
      if (isAgree) {
        let currentPage = _.get(pagination, "currentPage");
        if (payload.length === 1) currentPage = currentPage - 1;
        if (
          currentPage !== _.get(pagination, "currentPage") &&
          currentPage !== 1
        )
          dispatch(setCareersPagination({ ...pagination, currentPage }));
        await dispatch(removeSingleCareer(value));
        await dispatch(
          filterCareers({ ...filter, ...pagination, currentPage })
        );
        const findRemoveIds = _.includes(removeIds, value);
        if (findRemoveIds) {
          const resolveRemoveIds = _.filter(removeIds, (id) => id !== value);
          dispatch(setRemoveCareersIds(resolveRemoveIds));
        }
      }
    }
  };

  const handleSearch = async () => {
    await dispatch(filterCareers({ ...filter, ...pagination }));
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(setCareersPagination({ ...pagination, currentPage }));
    await dispatch(filterCareers({ ...filter, ...pagination, currentPage }));
    await dispatch(setRemoveCareersIds([]));
  };

  // Handle sort by ...
  const handleSort = async (key: string) => {
    const sortBy = _.get(filter, "sortBy");
    const resolveFilter = {
      ...filter,
      sortBy: key,
    };
    if (sortBy === key) {
      _.assign(resolveFilter, { sortBy: "", orderBy: "ASC" });
      await dispatch(
        filterCareers({
          ...resolveFilter,
          sortBy: key,
          orderBy: "ASC",
          ...pagination,
        })
      );
    } else {
      _.assign(resolveFilter, { orderBy: "DESC" });
      await dispatch(filterCareers({ ...resolveFilter, ...pagination }));
    }
    await dispatch(setCareersFilter(resolveFilter));
  };

  // Handle select all
  const handleSelectAll = () => {
    if (_.isArray(removeIds)) {
      const isMaxLength = removeIds.length === payload.length;
      if (isMaxLength) dispatch(setRemoveCareersIds([]));
      else {
        const ids: string[] = [];
        _.map(payload, (item) => {
          const id = _.get(item, "id");
          ids.push(id);
        });
        dispatch(setRemoveCareersIds(ids));
      }
    }
  };

  // Handle remove multiple
  const onRemoveMultiple = async () => {
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningDeleteAllCareers")
    );
    if (isAgree) {
      let currentPage = _.get(pagination, "currentPage");

      if (payload.length === removeIds.length && currentPage !== 1)
        currentPage = currentPage - 1;
      else currentPage = _.get(pagination, "currentPage");

      if (currentPage !== _.get(pagination, "currentPage"))
        dispatch(setCareersPagination({ ...pagination, currentPage }));

      await dispatch(removeMultipleCareers(removeIds));
      await dispatch(clearRemoveCareersIds());
      await dispatch(filterCareers({ ...filter, ...pagination, currentPage }));
    }
  };

  const handleClearFilter = async () => {
    await dispatch(clearCareersFilter());
    await dispatch(
      filterCareers({
        ...filter,
        ...pagination,
        keyword: "",
        status: "",
        currentPage: 1,
        language: "en",
      })
    );
  };

  const renderFilterBar = () => {
    return (
      <FlexHorizontal>
        <FlexHorizontal flex="1" alignCenter className="mr-1">
          <AdminLabel>{t("label.status")}</AdminLabel>
          <AdminDropdown
            width="12rem"
            data={[
              {
                label: "all",
                value: "",
              },
              { label: "active", value: "active" },
              {
                label: "inactive",
                value: "inactive",
              },
            ]}
            selectedValue={_.get(filter, "status")}
            onChange={(value: any) => handleChangeFilter(value, "status")}
          />
        </FlexHorizontal>
        <FlexHorizontal flex="1" alignCenter className="mr-1">
          <AdminLabel>{t("label.language")}</AdminLabel>
          <AdminDropdown
            width="12rem"
            data={[
              { label: "english", value: "en" },
              { label: "albanian", value: "al" },
            ]}
            selectedValue={_.get(filter, "language")}
            onChange={(value: any) => handleChangeFilter(value, "language")}
          />
        </FlexHorizontal>
        <FlexHorizontal flex="1" alignCenter className="mr-1">
          <InputSearchField
            flex="1"
            width="25rem"
            placeholder={t("placeholder.searchByTitle")}
            value={_.get(filter, "keyword")}
            onChange={(e: any) => handleChangeFilter(e.target.value, "keyword")}
            onKeydown={() => handleSearch()}
            onClick={() => handleSearch()}
          />
        </FlexHorizontal>
        <FlexHorizontal alignCenter>
          <GeyserSolidButton onClick={() => handleClearFilter()}>
            {t("button.reset")}
          </GeyserSolidButton>
          <CarrotSolidButton
            className="ml-1"
            onClick={() => history.push(ROUTES.ADMIN.CAREER_DETAILS)}
          >
            {t("button.create")}
          </CarrotSolidButton>
        </FlexHorizontal>
      </FlexHorizontal>
    );
  };

  const renderTable = () => {
    return (
      <div>
        <NormalTable
          className="mt-3"
          column={ColumnsTable.CAREER}
          data={payload}
          onClick={(value: any, key: string) => handleViewDetails(value, key)}
          fragment="0.3fr 3fr 1.2fr 1.3fr 0.7fr 0.5fr 0.5fr"
          onSort={(value: string) => handleSort(value)}
          sortBy={_.get(filter, "sortBy")}
          checked={removeIds}
          handleChecked={(ids: string[]) => dispatch(setRemoveCareersIds(ids))}
          lang={_.get(filter, "language")}
        />
      </div>
    );
  };

  const renderPagination = () => {
    if (_.isEmpty(payload)) return null;
    return (
      <FlexHorizontal minWidth="1035px" justifySpaceBetween className="mt-3">
        <FlexHorizontal>
          <DarkElectriSolidcButton
            onClick={() => handleSelectAll()}
            className="mr-1"
          >
            {removeIds.length === payload.length
              ? t("button.deselectAll")
              : t("button.selectAll")}
          </DarkElectriSolidcButton>
          <CarrotSolidButton
            onClick={() => !_.isEmpty(removeIds) && onRemoveMultiple()}
            className={`${_.isEmpty(removeIds) ? "disabled" : ""}`}
          >
            {t("button.delete")} ({_.isArray(removeIds) && removeIds.length})
          </CarrotSolidButton>
        </FlexHorizontal>
        <AdminPagination
          maxPage={_.get(meta, "totalPages")}
          currentPage={_.get(meta, "currentPage")}
          numberPageDisplay={2}
          totalCount={_.get(meta, "totalItems")}
          clickLinkPagination={(page: number) => handleClickPagination(page)}
          clickLinkNext={(page: number) => handleClickPagination(page)}
          clickLinkPrevious={(page: number) => handleClickPagination(page)}
          clickLinkLast={() => handleClickPagination(_.get(meta, "totalPages"))}
          clickLinkFirst={() => handleClickPagination(1)}
        />
      </FlexHorizontal>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {renderFilterBar()}
        {renderTable()}
        {renderPagination()}
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.CAREER}
      loading={isRequestLoading}
    />
  );
};

export default CareerManagement;
