import styled from 'styled-components';
import { ColorName } from '../../Variables';

const UserDatePickerContainer = styled.div`
  width: calc(100% + 10px);
  position: relative;

  & .react-datepicker__input-container {
    input {
      width: 100%;
      height: 40px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 23.5px;
      font-weight: 400;
      outline: none;
      transition: all ease 0.25s;
      border: 1px solid transparent;
      font-family: 'Be Vietnam';
      box-sizing: border-box;
      color: ${ColorName.black};
      border: 1px solid ${ColorName.black};
      &::placeholder {
        color: ${ColorName.grannySmith};
        font-size: 16px;
        line-height: 23.5px;
        font-weight: 400;
      }
      &:focus {
        box-shadow: rgb(232, 120, 68, 0.3) 0px 1px 2px 0px, rgb(232, 120, 68, 0.15) 0px 1px 3px 1px;
      }

      @media screen and (max-width: 436px) {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }

  & .react-datepicker__month-container {
    width: 350px;
  }

  & .react-datepicker__month {
    padding: 10px 0;
  }

  .react-datepicker__header {
    background-color: white;
  }

  & .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 10px;
  }
  & .react-datepicker__navigation--previous {
    left: 10px;
  }
  .react-datepicker__navigation--next {
    right: 10px;
  }

  & .react-datepicker__header {
    & .react-datepicker__current-month {
      font-size: 20px;
      line-height: 40px;
      color: ${ColorName.blueBayoux};
    }
  }

  & .react-datepicker__day-name {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: ${ColorName.burntSienna};
    text-transform: uppercase;
    width: 38px;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__week {
    & .react-datepicker__day {
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      color: ${ColorName.blueBayoux};
      width: 38px;
      height: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    & .react-datepicker__day--outside-month,
    & .react-datepicker__day--disabled {
      opacity: 0.5;
    }
    & .react-datepicker__day--today {
      background: ${ColorName.darkElectricBlue};
      color: white;
      border-radius: 10px;
    }
    & .react-datepicker__day--selected {
      background: ${ColorName.burntSienna};
      color: white;
      border-radius: 10px;
      .react-datepicker__day--today {
        background: ${ColorName.burntSienna};
      }
    }
    & .react-datepicker__day--keyboard-selected {
      background: ${ColorName.burntSienna};
    }
  }

  i {
    position: absolute;
    right: 10px;
    top: 12px;
  }
`;

export { UserDatePickerContainer };
