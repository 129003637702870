/* eslint import/no-anonymous-default-export: off */
import _ from 'lodash';
import { ROUTES } from '../constants';

const validateAdminRoutes = (router: string) => {
  const ADMIN_ROUTES = _.values(ROUTES.ADMIN);
  let isAdminRoutes = false;
  if (router) isAdminRoutes = _.includes(ADMIN_ROUTES, router);
  return isAdminRoutes;
};

export default { validateAdminRoutes };
