/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES } from "../../../constants";
import {
  FlexHorizontal,
  CarrotSolidButton,
  GeyserSolidButton,
  DefaultInput,
  AdminLabel,
  ContentContainer,
  ContentForm,
} from "../../../components/Common";
import { AccountActions } from "../../../actions";
import { statusAlert, Utils } from "../../../libs";

// Declare actions
const { setAccountDetails, changePassword, clearAccountDetails } =
  AccountActions;

const Login = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducer
  const dispatch = useDispatch();
  const details: any = useSelector((state) => _.get(state, "Account.details"));
  const isLoading = useSelector((state) => _.get(state, "Account.isLoading"));
  const userData = Utils.getSavedUserData();
  const username = _.get(userData, "username");

  // Handle change input to set account details
  const handleChange = (value: string, key: string) => {
    const resolvePayload = {
      ...details,
      [key]: value,
    };
    dispatch(setAccountDetails(resolvePayload));
  };

  // Validate data
  const checkValidateForm = () => {
    const validateData = [
      {
        value: _.get(details, "oldPassword"),
        label: t("alert.oldPassword"),
      },
      {
        value: _.get(details, "newPassword"),
        label: t("alert.newPassword"),
      },
      {
        value: _.get(details, "confirmNewPassword"),
        label: t("alert.confirmNewPassword"),
      },
    ];
    let emptyFields = "";
    let flag = true;
    let isValidLength = false;
    _.map(validateData, (item) => {
      if (!item.value) {
        flag = false;
        emptyFields = emptyFields + `\n${item.label}`;
      }
      if (item.value.length < 6 && !isValidLength) {
        flag = false;
        emptyFields = emptyFields + `\n${t(`alert.warningPasswordLength`)}`;
        isValidLength = true;
      }
    });
    return { flag, emptyFields };
  };

  // On submit
  const handleSubmit = async () => {
    const { flag, emptyFields } = checkValidateForm();
    if (!flag)
      return await statusAlert(
        "warning",
        `${t("alert.foundEmptyFields")} : ${emptyFields}`
      );
    if (_.get(details, "newPassword") !== _.get(details, "confirmNewPassword"))
      return await statusAlert("warning", t("alert.warningPasswordNotSame"));
    if (_.get(details, "oldPassword") === _.get(details, "newPassword"))
      return await statusAlert("warning", t("alert.warningSamePassword"));
    await dispatch(changePassword(details));
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        <ContentForm maxWidth="550px">
          <FlexHorizontal alignBaseline>
            <AdminLabel width="130px">{t("label.username")}</AdminLabel>
            <DefaultInput
              value={username || "N/I"}
              placeholder="Username"
              flex="1"
              disabled
            />
          </FlexHorizontal>
          <FlexHorizontal className="mr-row" alignBaseline>
            <AdminLabel width="130px">{t("label.oldPassword")}*</AdminLabel>
            <DefaultInput
              onChange={(e: any) =>
                handleChange(_.trim(e.target.value), "oldPassword")
              }
              value={_.get(details, "oldPassword") || ""}
              placeholder={t("placeholder.oldPassword")}
              flex="1"
              type="password"
            />
          </FlexHorizontal>
          <FlexHorizontal className="mr-row" alignBaseline>
            <AdminLabel width="130px">{t("label.newPassword")}*</AdminLabel>
            <DefaultInput
              onChange={(e: any) =>
                handleChange(_.trim(e.target.value), "newPassword")
              }
              value={_.get(details, "newPassword") || ""}
              placeholder={t("placeholder.newPassword")}
              flex="1"
              type="password"
            />
          </FlexHorizontal>
          <FlexHorizontal className="mr-row" alignBaseline>
            <AdminLabel width="130px">{t("label.confirmPassword")}*</AdminLabel>
            <DefaultInput
              flex="1"
              onChange={(e: any) =>
                handleChange(_.trim(e.target.value), "confirmNewPassword")
              }
              value={_.get(details, "confirmNewPassword") || ""}
              placeholder={t("placeholder.confirmNewPassword")}
              type="password"
            />
          </FlexHorizontal>

          <FlexHorizontal justifyFlexEnd className="mt-2">
            <CarrotSolidButton className="mr-1" onClick={() => handleSubmit()}>
              {t("button.save")}
            </CarrotSolidButton>
            <GeyserSolidButton onClick={() => dispatch(clearAccountDetails())}>
              {t("button.reset")}
            </GeyserSolidButton>
          </FlexHorizontal>
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.PROFILE}
      loading={isLoading}
    />
  );
};

export default Login;
