/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';

interface SectionProps {
  onAddMedia(): void;
  onChange(e: any): void;
  value: string;
  placeholder: string;
  defaultValue?: string;
  className?: string;
}

const ReactQuillEditor = (props: SectionProps) => {
  const { onAddMedia, value, onChange, placeholder, defaultValue, className } = props;

  const editor = useRef();

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };

  const plugin_command_2 = {
    name: 'customCommand_2',
    display: 'command',
    title: 'Insert media',
    buttonClass: '',
    innerHTML: '<i class="fas fa-plus"></i>',
    dataDisplay: 'container',

    add: function () {},
    action: function () {
      if (onAddMedia) onAddMedia();
    },
  };

  return (
    <div className={`ql-editor-container ${className}`} tabIndex={2}>
      <SunEditor
        setDefaultStyle="font-family: 'Be Vietnam'; font-size: 16px;"
        lang="en"
        defaultValue={defaultValue}
        width="100%"
        height="100%"
        placeholder={placeholder}
        setContents={value}
        autoFocus={true}
        getSunEditorInstance={getSunEditorInstance}
        onChange={(newValue: string) => onChange && onChange(newValue)}
        setOptions={{
          mode: 'classic',
          height: '200',
          plugins: [
            plugins.table,
            plugins.align,
            plugins.blockquote,
            plugins.horizontalRule,
            plugins.lineHeight,
            plugins.link,
            plugins.list,
            plugins.image,
            plugins.video,
            plugin_command_2,
          ],
          youtubeQuery: 'autoplay=1&mute=1&enablejsapi=1',
          buttonList: [
            ['undo', 'redo'],
            ['blockquote', 'bold', 'underline', 'italic', 'removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link'],
            ['customCommand_2'],
          ],
        }}
      />
    </div>
  );
};

export default ReactQuillEditor;
