const images = {
  logoHeader: require("../assets/images/main-logo.png"),
  logoFooter: require("../assets/images/main-logo.png"),
  smallLogo: require("../assets/images/small_logo.svg"),
  footerBg: require("../assets/images/footer.jpg"),
  careerBackground: require("../assets/images/career.jpg"),
  careerBlur: require("../assets/images/career_blur.jpg"),
  shortLogo: require("../assets/images/short_logo.svg"),
  logoName: require("../assets/images/logo_name.png"),
  logoImage: require("../assets/images/logo_image.png"),
  loginImage: require("../assets/images/login.svg"),
  footerMobile: require("../assets/images/footer_mobile.jpg"),
  logoMobile: require("../assets/images/main-logo.png"),
  careerBanner: require("../assets/images/careers_banner.jpg"),
  contactBanner: require("../assets/images/contact_banner.jpg"),
  IDRAAdvanceBanner: require("../assets/images/IDRA_advance_banner.jpg"),
  IDRADiGISBanner: require("../assets/images/IDRA_DiGIS_banner.jpg"),
  IDRAResearchBanner: require("../assets/images/IDRA_research_banner.jpg"),
  newsBanner: require("../assets/images/news_banner.jpg"),
  whoWeAreBanner: require("../assets/images/who_we_are_banner.jpg"),
  whoWeAre: require("../assets/images/who_we_are.svg"),
  login: require("../assets/images/login.svg"),
  pageNotFound: require("../assets/images/page_not_found.svg"),
  noResultFound: require("../assets/images/no_result_found.svg"),
  alumniNetwork: require("../assets/images/alumni_network_banner.jpg"),
  alumniPopupBackground: require("../assets/images/alumni_background.png"),
  alumniSocialNetwork: require("../assets/images/alumni_network.png"),
};

export default images;
