/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  EditContainerPageContainer,
  StepForm,
  TitleStep,
  DescriptionStep,
} from "./EditContactPage.styles";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import { ROUTES } from "../../../../constants";
import { useTranslation } from "react-i18next";

interface SectionProps {
  className?: string;
}
const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditContactPage = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload: any = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.CONTACT}`)
  );
  const lang: any = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );

  useEffect(() => {
    dispatch(getAllContentForUser());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  return (
    <EditContainerPageContainer className={className}>
      <StepForm>
        <TitleStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{
            __html: _.get(payload, `stepTwo.stepTitle.${lang}`),
          }}
          data-placeholder={t("placeholder.typeTheTitleHere")}
          onFocus={(e: any) =>
            dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
          }
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(
              _.trim(e.currentTarget.innerText),
              initialValue
            );
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepTwo: {
                    ...payload.stepTwo,
                    stepTitle: {
                      ...payload.stepTwo.stepTitle,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></TitleStep>
        <DescriptionStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{
            __html: _.get(payload, `stepTwo.stepDescription.${lang}`),
          }}
          data-placeholder={t("placeholder.typeTheDescriptionHere")}
          onFocus={(e: any) =>
            dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
          }
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(
              _.trim(e.currentTarget.innerText),
              initialValue
            );
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepTwo: {
                    ...payload.stepTwo,
                    stepDescription: {
                      ...payload.stepTwo.stepDescription,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></DescriptionStep>
      </StepForm>
    </EditContainerPageContainer>
  );
};

export default EditContactPage;
