/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  HeaderContainer,
  Module,
  Navbar,
  Logo,
  NavItem,
  SubMenu,
  SearchParent,
  MenuMobile,
  MenuMobileLayer,
  MenuMobileItem,
  HamburgerMenu,
  DesktopLang,
  ExpandIcon,
  MobileSubMenu,
  MobileSubMenuItem,
} from "./Header.styles";
import { Images, Icons } from "../../../../themes";
import history from "../../../../history";
import { ROUTES, DropdownOptions } from "../../../../constants";
import { Content, FlexHorizontal } from "../../../Common";
import { PageSettingsActions } from "../../../../actions";
import Utils from "../../../../libs/Utils";
import i18next from "../../../../i18next";
import { NAVIGATION_LINK, MENU_MOBILE } from "./NavigationList";
import API from "../../../../api";
import icons from "../../../../themes/Icons";

interface SectionProps {
  currentPage: string;
  onChange?(value: string): unknown;
}

// const SUB_ROUTES = [
//   ROUTES.USER.IDRA_ADVANCE,
//   ROUTES.USER.IDRA_DIGIS,
//   ROUTES.USER.IDRA_RESEARCH,
//   `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
//   `${ROUTES.USER.WHO_WE_ARE}?category=ourValues`,
//   `${ROUTES.USER.WHO_WE_ARE}?category=qualityAssurance`,
//   `${ROUTES.USER.WHO_WE_ARE}?category=ourTeam`,
//   `${ROUTES.USER.WHO_WE_ARE}?category=ourPartners`,
//   `${ROUTES.USER.WHO_WE_ARE}?category=ourClients`,
//   ROUTES.USER.ALUMNI_NETWORK,
// ];

// Declare actions
const { setUserLang } = PageSettingsActions;

const Header: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare props
  const { currentPage } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const locale = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const [className, setClassName] = useState<string>("");
  const [isShowMenuMobile, setIsShowMenuMobile] = useState<boolean>(false);
  // const [isShowSearchField, setIsShowSearchField] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  // const searchFilter = useSelector(state => _.get(state, 'Search.filter'));
  // const location = useLocation();
  // const queryStringValue = queryString.parse(location.search);
  // const searchRef = useRef<HTMLInputElement>(null);
  const [subMenuMobile, setSubMenuMobile] = useState<string>("");

  const reportWindowSize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1024) setIsShowMenuMobile(false);
  };

  useEffect(() => {
    document.body.style.overflowY = isShowMenuMobile ? "hidden" : "auto";
  }, [isShowMenuMobile]);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 90) setIsScrolling(true);
      else setIsScrolling(false);
    };

    // const filterValue = _.get(searchFilter, 'keyword');
    // const keyword = _.get(queryStringValue, 'keyword');

    const currentUserLang = Utils.getUserLocale();
    const currentLang = Utils.getCurrentLocale();
    if (currentUserLang !== locale) dispatch(setUserLang(currentUserLang));
    if (currentLang !== locale) Utils.savedCurrentLocale(currentUserLang);

    window.addEventListener("load", reportWindowSize);
    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("load", reportWindowSize);
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  useEffect(() => {
    reportWindowSize();
  }, [currentPage]);

  useEffect(() => {
    if (isScrolling) {
      const pageUsingBorder = [
        ROUTES.USER.RESEARCH_SOLUTIONS,
        ROUTES.USER.DEVELOPMENT_SOLUTIONS,
        ROUTES.USER.WHO_WE_ARE,
        ROUTES.USER.NEWS,
      ];
      const isInclude = _.includes(pageUsingBorder, currentPage)
        ? "border_bottom"
        : "shadow";
      setClassName(isInclude);
    } else setClassName("");
  }, [isScrolling]);

  // Event change locale
  const handleChangeLocale = (value: string) => {
    i18next.changeLanguage(value);
    API.setLangHeader(value);
    if (value) {
      dispatch(setUserLang(value));
      Utils.savedUserLocale(value);
    }
  };

  // const handleSearch = async () => {
  //   const filterValue = _.get(searchFilter, 'keyword');
  //   if (_.isEmpty(filterValue)) setIsShowSearchField(!isShowSearchField);
  //   else {
  //     const { type, keyword } = searchFilter;
  //     if (_.isString(type) && _.isString(keyword)) {
  //       await dispatch(search(searchFilter));
  //       document.documentElement.style.scrollBehavior = 'unset';
  //       history.push(`${ROUTES.USER.SEARCH}?type=${type}&keyword=${keyword}`);
  //     }
  //   }
  // };

  // const handleEnter = (e: any) => {
  //   if (e.keyCode === 13) {
  //     const keyword = _.get(searchFilter, 'keyword');
  //     const trimValue = _.trim(keyword);
  //     if (trimValue) handleSearch();
  //   }
  // };

  const handleChangeRouter = (value: any) => {
    if (value) {
      if (value === ROUTES.USER.SERVICES_SOLUTIONS) return;
      else {
        document.documentElement.style.scrollBehavior = "unset";
        history.push(value);
      }
    }
  };

  const renderLogo = () => {
    return (
      <Logo
        src={Images.logoHeader.default}
        alt="idra logo"
        onClick={() => {
          if (currentPage !== ROUTES.USER.HOMEPAGE) {
            document.documentElement.style.scrollBehavior = "unset";
            history.push(ROUTES.USER.HOMEPAGE);
          } else window.location.reload();
        }}
        loading="eager"
        style={{
          width: "150px",
          height: "50px",
          objectFit: "contain",
        }}
      />
    );
  };

  const renderSubMenu = (value: any[]) => {
    return (
      <SubMenu isLeft>
        {_.map(value, (item, index) => {
          const { label, location } = item;
          const activeClass = currentPage === location ? "active" : "";
          return (
            <NavItem
              key={`sub-item-${index}`}
              className={activeClass}
              onClick={() => handleChangeRouter(location)}
            >
              {t(`header.${label}`)}
            </NavItem>
          );
        })}
      </SubMenu>
    );
  };

  const renderLocaleLabel = () => {
    const findLang = _.find(DropdownOptions.LANGUAGE, { value: locale });
    if (findLang) return t(`dropdown.${_.get(findLang, "label")}`);
    return "English";
  };

  const renderNavigation = () => {
    const render = _.map(NAVIGATION_LINK, (item, index) => {
      const { label, location } = item;
      const activeClass = currentPage === location ? "active" : "";
      return (
        <NavItem
          subItem={item?.submenu?.length || 3}
          key={`navigation-item-${index}`}
          className={activeClass}
        >
          <span onClick={() => handleChangeRouter(location)}>
            {t(`header.${label}`)}
          </span>
          {_.isArray(item.submenu) &&
            !_.isEmpty(item.submenu) &&
            renderSubMenu(item.submenu)}
        </NavItem>
      );
    });
    return <Navbar>{render}</Navbar>;
  };

  const renderRightModule = () => {
    return (
      <Module className="flex-align-center">
        <DesktopLang className="desktop disabled" width="100px" />

        {currentPage !== ROUTES.USER.SEARCH && (
          <SearchParent onClick={() => history.push(ROUTES.USER.SEARCH)}>
            {/* <input
              ref={searchRef}
              type="text"
              placeholder="Search"
              onChange={(e: any) =>
                dispatch(
                  setSearchFilter({
                    ...searchFilter,
                    keyword: e.target.value,
                  })
                )
              }
              onKeyDown={(e: any) => handleEnter(e)}
              value={_.get(searchFilter, 'keyword')}
            />
            <i className="fas fa-times" /> */}
            <img src={Icons.search.default} alt="search" />
          </SearchParent>
        )}
        <HamburgerMenu>
          <div
            className={`btn ${isShowMenuMobile ? "active" : "not-active"}`}
            onClick={() => setIsShowMenuMobile(!isShowMenuMobile)}
          >
            <span />
            <span />
            <span />
          </div>
        </HamburgerMenu>
        <DesktopLang className="desktop" width="100px">
          <FlexHorizontal justifySpaceBetween alignCenter>
            {renderLocaleLabel()} <i className="fas fa-caret-down" />
          </FlexHorizontal>
          <SubMenu width="120px" isLeft>
            {_.map(DropdownOptions.LANGUAGE, (item, index) => {
              const { label, value } = item;
              return (
                <NavItem
                  key={`locale-${index}`}
                  onClick={() => handleChangeLocale(value)}
                >
                  {t(`dropdown.${label}`)}
                </NavItem>
              );
            })}
          </SubMenu>
        </DesktopLang>
      </Module>
    );
  };

  const onClickMobileMenu = (data: any) => {
    const child = _.get(data, "child");
    const isOpening = subMenuMobile === _.get(data, "label");
    if (child) return setSubMenuMobile(isOpening ? "" : _.get(data, "label"));
    else history.push(_.get(data, "location"));
  };

  const onClickChildItem = (item: any) => {
    if (_.get(item, "value")) handleChangeLocale(_.get(item, "value"));
    else history.push(_.get(item, "location"));
    setIsShowMenuMobile(false);
  };

  const renderMenuMobile = () => {
    return (
      <>
        {isShowMenuMobile && (
          <MenuMobileLayer
            onClick={() => setIsShowMenuMobile(false)}
            className={isShowMenuMobile ? "show" : ""}
          />
        )}
        <MenuMobile className={`${isShowMenuMobile ? "show" : ""}`}>
          <MenuMobileItem
            className={`${isShowMenuMobile ? "active" : ""} menu-label`}
            onClick={() => setIsShowMenuMobile(false)}
          >
            {t(`header.menu`)}{" "}
            <ExpandIcon src={icons.plus.default} rotate={45} />
          </MenuMobileItem>
          {_.map(MENU_MOBILE, (item, index) => {
            const { label, child } = item;
            return (
              <React.Fragment key={`menu-parent-${index}`}>
                <MenuMobileItem
                  className={`${isShowMenuMobile ? "active" : ""} `}
                  onClick={() => onClickMobileMenu(item)}
                >
                  {t(`header.${label}`)}{" "}
                  {child && (
                    <ExpandIcon
                      src={icons.plus.default}
                      rotate={subMenuMobile === label ? 135 : 0}
                      scale={0.7}
                    />
                  )}
                </MenuMobileItem>
                <MobileSubMenu
                  maxHeight={`${child ? child.length * 40 : 500}px`}
                  className={`${isShowMenuMobile ? "active" : ""} ${
                    subMenuMobile === label ? "show" : ""
                  }`}
                >
                  {_.map(child, (childItem, innerIndex) => {
                    return (
                      <MobileSubMenuItem
                        onClick={() => onClickChildItem(childItem)}
                        key={`sub-${label}-${innerIndex}`}
                      >
                        {t(`header.${_.get(childItem, "label")}`)}
                      </MobileSubMenuItem>
                    );
                  })}
                </MobileSubMenu>
              </React.Fragment>
            );
          })}
        </MenuMobile>
      </>
    );
  };

  return (
    <HeaderContainer className={className}>
      <Content
        maxWidth="1920px"
        className="flex-align-center flex-justify-between content header-content"
      >
        {renderLogo()}
        {renderNavigation()}
        {renderRightModule()}
        {renderMenuMobile()}
      </Content>
    </HeaderContainer>
  );
};
export default Header;
