import styled from 'styled-components';
import { ColorName } from '../../Variables';

const PreviewImageLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`;

const PreviewButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: ${ColorName.white};
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all ease 0.25s;

  &.prev-btn {
    top: calc(50% - 24px);
    left: 20px;
  }
  &.next-btn {
    top: calc(50% - 24px);
    right: 20px;
  }
  &.close-btn {
    top: 20px;
    right: 20px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

const PaginationImageList = styled.div`
  position: absolute;
  bottom: 10px;
  width: max-content;
  max-width: 100%;
  overflow: auto;
  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    filter: brightness(0.7);
    object-fit: cover;
    object-position: center;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
      filter: brightness(0.85);
    }
    &.active {
      filter: brightness(1);
    }
  }
`;

export { PreviewImageLayer, PreviewButton, PaginationImageList };
