/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import { statusAlert } from '../libs';

// SINGLE ACTIONS
const setMembersPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_MEMBER_PAYLOAD,
    payload,
  };
};

const clearMembersPayload = () => {
  return {
    type: ActionTypes.CLEAR_MEMBER_PAYLOAD,
  };
};

const setMembersDetails = (payload: any) => {
  return {
    type: ActionTypes.SET_MEMBER_DETAILS,
    payload,
  };
};

const clearMembersDetails = () => {
  return {
    type: ActionTypes.CLEAR_MEMBER_DETAILS,
  };
};

const setMembersFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_MEMBER_FILTER,
    payload,
  };
};

const clearMembersFilter = () => {
  return {
    type: ActionTypes.CLEAR_MEMBER_FILTER,
  };
};

const setMembersMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_MEMBERS_META,
    payload,
  };
};

const setMembersPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_MEMBERS_PAGINATION,
    payload,
  };
};

const setDefaultMembersMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_MEMBERS_META_PAGINATION,
  };
};

const setRemoveMembersIds = (payload: any) => {
  return {
    type: ActionTypes.SET_REMOVE_MEMBERS_IDS,
    payload,
  };
};

const clearRemoveMembersIds = () => {
  return {
    type: ActionTypes.CLEAR_REMOVE_MEMBERS_IDS,
  };
};

// ASYNC ACTIONS
const createMemberError = () => {
  return {
    type: ActionTypes.CREATE_MEMBER_FAILURE,
  };
};

const createMemberIsLoading = () => {
  return {
    type: ActionTypes.CREATE_MEMBER_REQUEST,
  };
};

const createMemberIsSuccess = (payload: any) => {
  return {
    type: ActionTypes.CREATE_MEMBER_SUCCESS,
    payload,
  };
};

const createMember = (data: any) => {
  return async (dispatch: any) => {
    dispatch(createMemberIsLoading());
    await API.createMember(data).then(async (res: any) => {
      const { payload, status } = res;
      if (status) dispatch(createMemberIsSuccess(payload));
      else dispatch(createMemberError());
    });
  };
};

const updateMemberError = () => {
  return {
    type: ActionTypes.UPDATE_MEMBER_FAILURE,
  };
};

const updateMemberIsLoading = () => {
  return {
    type: ActionTypes.UPDATE_MEMBER_REQUEST,
  };
};

const updateMemberIsSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_SUCCESS,
    payload,
  };
};

const updateMember = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateMemberIsLoading());
    await API.updateMember(data)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          dispatch(updateMemberIsSuccess(payload));
          statusAlert('success', message);
        } else {
          dispatch(updateMemberError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(updateMemberError()));
  };
};

const getAllMembersError = () => {
  return {
    type: ActionTypes.GET_ALL_MEMBERS_FAILURE,
  };
};

const getAllMembersIsLoading = () => {
  return {
    type: ActionTypes.GET_ALL_MEMBERS_REQUEST,
  };
};

const getAllMembersIsSuccess = () => {
  return {
    type: ActionTypes.GET_ALL_MEMBERS_SUCCESS,
  };
};

const getAllMembers = (data: any) => {
  return async (dispatch: any) => {
    dispatch(getAllMembersIsLoading());
    await API.getAllMembers(data)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          const { meta, items } = payload;
          dispatch(setMembersMeta(meta));
          dispatch(setMembersPayload(items));
          dispatch(getAllMembersIsSuccess());
        } else dispatch(getAllMembersError());
      })
      .catch(() => dispatch(getAllMembersError()));
  };
};

const getAllMembersForUserError = () => {
  return {
    type: ActionTypes.GET_MEMBERS_FOR_USER_FAILURE,
  };
};

const getAllMembersForUserIsLoading = () => {
  return {
    type: ActionTypes.GET_MEMBERS_FOR_USER_REQUEST,
  };
};

const getAllMembersForUserIsSuccess = () => {
  return {
    type: ActionTypes.GET_MEMBERS_FOR_USER_SUCCESS,
  };
};

const getAllMembersForUser = () => {
  return async (dispatch: any) => {
    dispatch(getAllMembersForUserIsLoading());
    await API.getAllMembersForUser()
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          dispatch(setMembersPayload(payload));
          dispatch(getAllMembersForUserIsSuccess());
        } else dispatch(getAllMembersForUserError());
      })
      .catch(() => dispatch(getAllMembersError()));
  };
};

const getMemberByIdError = () => {
  return {
    type: ActionTypes.GET_MEMBER_BY_ID_FAILURE,
  };
};

const getMemberByIdIsLoading = () => {
  return {
    type: ActionTypes.GET_MEMBER_BY_ID_REQUEST,
  };
};

const getMemberByIdIsSuccess = () => {
  return {
    type: ActionTypes.GET_MEMBER_BY_ID_SUCCESS,
  };
};

const getMemberById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getMemberByIdIsLoading());
    await API.getMemberById(id)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          dispatch(setMembersDetails(payload))
          dispatch(getMemberByIdIsSuccess());
        }
        else dispatch(getMemberByIdError());
      })
      .catch(() => dispatch(getMemberByIdError()));
  };
};

const removeSingleMemberError = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_MEMBER_FAILURE,
  };
};

const removeSingleMemberIsLoading = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_MEMBER_REQUEST,
  };
};

const removeSingleMemberIsSuccess = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_MEMBER_SUCCESS,
  };
};

const removeSingleMember = (id: string) => {
  return async (dispatch: any) => {
    dispatch(removeSingleMemberIsLoading());
    await API.removeSingleMember(id)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(removeSingleMemberIsSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeSingleMemberError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(removeSingleMemberError()));
  };
};

const removeMultipleMembersError = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_MEMBERS_FAILURE,
  };
};

const removeMultipleMembersIsLoading = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_MEMBERS_REQUEST,
  };
};

const removeMultipleMembersIsSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_MEMBERS_SUCCESS,
  };
};

const removeMultipleMembers = (ids: string[]) => {
  return async (dispatch: any) => {
    dispatch(removeMultipleMembersIsLoading());
    await API.removeMultipleMembers(ids)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(removeMultipleMembersIsSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeMultipleMembersError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(removeMultipleMembersError()));
  };
};

const updateMemberStatus = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateMemberIsLoading());
    await API.updateMember(data)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          dispatch(updateMemberIsSuccess(payload));
          statusAlert('success', message);
        } else {
          dispatch(updateMemberError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(updateMemberError()));
  };
};

const singleActions = {
  setMembersDetails,
  clearMembersDetails,
  setMembersPayload,
  clearMembersPayload,
  setMembersFilter,
  clearMembersFilter,
  setMembersMeta,
  setMembersPagination,
  setDefaultMembersMetaPagination,
  setRemoveMembersIds,
  clearRemoveMembersIds,
};

const asyncActions = {
  createMember,
  updateMember,
  getAllMembers,
  getMemberById,
  removeSingleMember,
  removeMultipleMembers,
  getAllMembersForUser,
  updateMemberStatus
};

export default {
  ...singleActions,
  ...asyncActions,
};
