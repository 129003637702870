import styled from 'styled-components';
import { FlexHorizontal } from '../../Common';
import { ColorName } from '../../Variables';

const PopupWrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -555;
  opacity: 0;
  /* transition: all ease 0.25s; */
  &.show {
    z-index: 555;
    opacity: 1;
  }
`;

const HeaderPopup = styled.div`
  height: 40px;
  background: ${ColorName.darkElectricBlue};
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 16px;
  color: ${ColorName.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ButtonBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  padding: 0 24px;
  align-items: center;
  box-sizing: border-box;
  background: ${ColorName.aquaHaze};
`;

export { PopupWrapper, HeaderPopup, ButtonBar };
