/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
  Content,
  FilterNews,
  NewsCard,
  UserPagination,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { NewsContainer, ContentCol, SVGImage } from "./News.styles";
import SkeletonLoading from "./SkeletonLoading";
import SecondHeader from "../../../components/Layout/CommonLayoutPart/SecondHeader";
import history from "../../../history";
import { FILTERS, SECOND_HEADER } from "../../../constants/Constants";
import { ROUTES } from "../../../constants/";
import { ArticlesActions } from "../../../actions";
import { Images } from "../../../themes";
import { Utils } from "../../../libs";
import { useLocation } from "react-router-dom";

// Declare actions
const { filterArticles } = ArticlesActions;
// Declare constants
const defaultFilter = {
  features: "news",
  categories: "researchNews",
  topic: "",
};

const ResearchNews = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const location = useLocation();
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const newsPayload: any = useSelector((state) =>
    _.get(state, "Articles.payload")
  );
  const meta = useSelector((state) => _.get(state, "Articles.meta"));
  const pagination: any = useSelector((state) =>
    _.get(state, "Articles.userMetaPagination")
  );
  const language = useSelector((state) =>
    _.get(state, "PageSettings.userLang")
  );
  const [isNoneFilter, setIsNoneFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<any>({
    features: "news",
    categories: "researchNews",
  });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const getSearchLocation = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const features = params.get("features");
    const topic = params.get("topic");
    const resolveFilter = { ...currentFilter };
    if (!features) {
      history.push(`${ROUTES.USER.RESEARCH_NEWS}?features=news`);
      _.assign(resolveFilter, { features: "news" });
    }
    if (features) _.assign(resolveFilter, { features });
    if (topic) _.assign(resolveFilter, { topic });
    setCurrentFilter({ ...resolveFilter });
    setFirstLoad(true);
  };

  useEffect(() => {
    getSearchLocation();
  }, []);

  useEffect(() => {
    if (isRequestLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        Utils.scrollToBottomOfBanner();
      }, 1500);
    }
  }, [isRequestLoading]);

  useEffect(() => {
    if (firstLoad)
      dispatch(
        filterArticles({
          ...pagination,
          ...currentFilter,
          language,
          type: "user",
        })
      );
    const currentCategory = _.get(currentFilter, "features");
    if (_.includes(["insights", "successStories"], currentCategory))
      setIsNoneFilter(true);
    else setIsNoneFilter(false);
  }, [currentFilter, language]);

  const onViewDetails = (id: string) => {
    document.documentElement.style.scrollBehavior = "unset";
    const search = location.search;
    history.push({
      pathname: `${ROUTES.USER.RESEARCH_NEWS}/${id}`,
      state: { from: ROUTES.USER.RESEARCH_NEWS, query: search },
    });
  };

  const onChangeFilter = async (newFilter: any, key: string, type?: string) => {
    if (key === "sub_header") {
      const currentCategory = _.get(currentFilter, "features");
      if (newFilter !== currentCategory) {
        const resolveFilter = {
          ...currentFilter,
          features: newFilter,
        };
        const searchQuery = "?";
        const query: any[] = [];
        _.map(resolveFilter, (item, key) => {
          if (!_.isEmpty(item)) query.push(`${key}=${item}`);
        });
        history.push({
          pathname: ROUTES.USER.RESEARCH_NEWS,
          search: searchQuery.concat(_.join(query, "&")),
        });
        Utils.scrollToBottomOfBanner();

        if (_.includes(["insights", "successStories"], currentCategory))
          _.assign(resolveFilter, { topic: "" });
        setCurrentFilter(resolveFilter);
      }
    } else {
      if (type === "reset") {
        const params = new URLSearchParams(location.search);
        params.delete("yearFrom");
        params.delete("yearTo");
        const newSearch = params.toString();
        history.push({
          pathname: `${ROUTES.USER.RESEARCH_NEWS}`,
          search: newSearch,
        });
        setCurrentFilter({
          ...newFilter,
          yearFrom: "",
          yearTo: "",
          currentPage: 1,
        });
      } else {
        const searchQuery = "?";
        const query: any[] = [];
        _.map(newFilter, (item, key) => {
          if (!_.isEmpty(item) && key !== "categories")
            query.push(`${key}=${item}`);
        });
        history.push({
          pathname: `${ROUTES.USER.RESEARCH_NEWS}`,
          search: searchQuery.concat(_.join(query, "&")),
        });
        setCurrentFilter(newFilter);
      }
    }
  };

  // Render news column
  const renderContent = () => {
    return (
      <ContentCol marginLeft={isNoneFilter ? 0 : 120}>
        {!_.isEmpty(newsPayload) ? (
          _.map(newsPayload, (news, index) => {
            return (
              <NewsCard
                key={`news-${index}`}
                data={news}
                onClick={(location: string) => onViewDetails(location)}
                pathname={ROUTES.USER.RESEARCH_NEWS}
                searchQuery={location.search}
              />
            );
          })
        ) : (
          <SVGImage
            src={Images.noResultFound.default}
            alt="no result found illustrators"
          />
        )}
        {!_.isEmpty(newsPayload) && (
          <div className="mt-2">
            <UserPagination
              currentPage={_.get(meta, "currentPage")}
              totalPages={_.get(meta, "totalPages")}
              onClick={(currentPage: number) =>
                setCurrentFilter({ ...currentFilter, currentPage })
              }
            />
          </div>
        )}
      </ContentCol>
    );
  };

  // Render filter column
  const renderFilter = () => {
    const currentFeatures = _.get(currentFilter, "features");
    if (_.includes(["news", "publications"], currentFeatures))
      return (
        <FilterNews
          data={FILTERS.RESEARCH_NEWS}
          activeFilter={currentFilter}
          onChange={(value: any) => onChangeFilter(value, "filter")}
          onClick={() => onChangeFilter(defaultFilter, "filter", "reset")}
        />
      );
    return null;
  };

  const renderMain = () => {
    return (
      <NewsContainer>
        <SecondHeader
          data={SECOND_HEADER.NEWS}
          active={_.get(currentFilter, "features")}
          onChange={(value: string) => onChangeFilter(value, "sub_header")}
        />
        <Content className="news mt-8">
          {renderFilter()}
          {isRequestLoading || isLoading ? (
            <SkeletonLoading marginLeft={isNoneFilter ? 0 : 120} />
          ) : (
            renderContent()
          )}
        </Content>
      </NewsContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_NEWS}
      titlePage={t("titlePage.researchNews")}
      headerTitle="Research News"
      metaDescription="Discover more about our latest news"
    />
  );
};

export default ResearchNews;
