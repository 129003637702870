import Alert from '../components/Layout/Popups/Alert';

const statusAlert = (type: string, message: string) => {
  return new Promise(resolve => {
    Alert({
      type,
      message,
    }).then(response => {
      if (response === 'ok') resolve(true);
      if (response === 'cancel') resolve(false);
    });
  });
};

export { statusAlert };
