/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';
import { CONSTANTS } from '../constants/Constants';

const defaultFilter = {
  keyword: '',
  type: CONSTANTS.SEARCH_TYPE.ARTICLES,
};

const initialState = {
  filter: defaultFilter,
  payload: [],
  isSuccess: false,
  isLoading: false,
  hasError: false,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_SEARCH_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_SEARCH_FILTER:
      return {
        ...state,
        filter: defaultFilter,
      };

    case ActionTypes.SET_SEARCH_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_SEARCH_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.SEARCH_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        hasError: false,
        payload: [],
      };
    case ActionTypes.SEARCH_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
        payload: [],
      };
    case ActionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };

    default:
      return state;
  }
};
