/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { EditPageContainer } from "./EditAreasOfInterestPage.styles";
import { EditContentActions } from "../../../../actions";
import { ROUTES } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { Richtextbox } from "../../../../components/Common";

interface SectionProps {
  className?: string;
}
const { getAllContentForUser, setEditContentPayload } = EditContentActions;

const EditContactPage = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload: any = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.AREAS_OF_INTEREST}`)
  );
  const lang: any = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );

  useEffect(() => {
    dispatch(getAllContentForUser());
  }, []);

  return (
    <EditPageContainer className={className}>
      <Richtextbox
        placeholder={t("placeholder.typeTheContentHere")}
        className="mt-1"
        onChange={(value: any) => {
          dispatch(
            setEditContentPayload({
              ...contentPayload,
              [ROUTES.USER.AREAS_OF_INTEREST]: {
                ...payload,
                content: {
                  ...payload.content,
                  [lang]: value,
                },
              },
            })
          );
        }}
        data={_.get(payload, `content.${lang}`) || ""}
        fontSize="16px"
        toolbar={[
          "bold",
          "link",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "undo",
          "redo",
        ]}
      />
    </EditPageContainer>
  );
};

export default EditContactPage;
