import React, { useRef, useState } from "react";
import styled from "styled-components";
import { HorizontalContainer } from "..";
import { ColorName } from "../../Variables";
interface SectionProps {
  width?: string;
  height?: string;
  onChange?(event: any): unknown;
  flex?: string;
  background?: string;
  placeholder?: string;
  onChange?(e: any): unknown;
  value?: any;
  disabled?: boolean;
  onClick?(): unknown;
  onKeydown?(): unknown;
  message?: string;
}

const UserInput = styled.input`
  width: ${(props: SectionProps) => props.width || "100%"};
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 23.5px;
  font-weight: 400;
  outline: none;
  transition: all ease 0.25s;
  border: 1px solid transparent;
  font-family: "Be Vietnam";
  box-sizing: border-box;
  color: ${ColorName.black};
  border: 1px solid ${ColorName.black};
  &::placeholder {
    color: ${ColorName.grannySmith};
    font-size: 16px;
    line-height: 23.5px;
    font-weight: 400;
  }
  &:focus {
    box-shadow: rgb(232, 120, 68, 0.3) 0px 1px 2px 0px,
      rgb(232, 120, 68, 0.15) 0px 1px 3px 1px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  @media screen and (max-width: 436px) {
    &::placeholder {
      font-size: 12px;
    }
  }
`;

const UserTextarea = styled.textarea`
  width: ${(props: SectionProps) => props.width || "100%"};
  height: ${(props: SectionProps) => props.height || "135px"};
  padding: 10px 20px;
  font-size: 16px;
  line-height: 23.5px;
  font-weight: 400;
  color: ${ColorName.black};
  outline: none;
  border: 1px solid ${ColorName.black};
  transition: all ease 0.25s;
  font-family: "Be Vietnam";
  box-sizing: border-box;
  resize: none;
  &::placeholder {
    font-size: 16px;
    line-height: 23.5px;
    font-weight: 400;
    color: ${ColorName.grannySmith};
  }
  &:focus {
    box-shadow: rgb(232, 120, 68, 0.3) 0px 1px 2px 0px,
      rgb(232, 120, 68, 0.15) 0px 1px 3px 1px;
  }
`;

const DefaultInput = styled.input`
  width: ${(props: SectionProps) => props.width || "100%"};
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 23.5px;
  font-weight: 400;
  outline: none;
  /* transition: all ease 0.25s; */
  border: 1px solid
    ${(props: SectionProps) =>
      props.message ? ColorName.vermilionBird : "transparent"};
  font-family: "Be Vietnam";
  box-sizing: border-box;
  &::placeholder {
    font-size: 16px;
    line-height: 23.5px;
    font-weight: 400;
  }
  flex: ${(props: SectionProps) => props.flex || "unset"};
  color: ${ColorName.darkElectricBlue};
  background: ${ColorName.aquaHaze};
  border-radius: 5px;
  font-size: 14px;
  &::placeholder {
    color: ${ColorName.casper};
    font-size: 14px;
  }
  &:focus {
    border: 1px solid
      ${(props: SectionProps) =>
        props.message ? ColorName.vermilionBird : ColorName.darkElectricBlue};
  }
  &:disabled {
    cursor: not-allowed;
  }

  &:disabled {
    opacity: 0.7;
  }
  &:required {
    border: 1px solid ${ColorName.burntSienna};
  }
`;

const Textarea = styled.textarea`
  width: ${(props: SectionProps) => props.width || "100%"};
  height: ${(props: SectionProps) => props.height || "100px"};
  flex: ${(props: SectionProps) => props.flex || "unset"};
  padding: 10px 20px;
  font-size: 14px;
  line-height: 23.5px;
  font-weight: 400;
  outline: none;
  border: 1px solid transparent;
  /* transition: all ease 0.25s; */
  font-family: "Be Vietnam";
  box-sizing: border-box;
  resize: none;
  color: ${ColorName.darkElectricBlue};
  background: ${ColorName.aquaHaze};
  border-radius: 5px;
  &::placeholder {
    color: ${ColorName.casper};
    font-size: 14px;
  }
  &:focus {
    border: 1px solid ${ColorName.darkElectricBlue};
  }
`;

const InputItalicPlaceholder = styled(DefaultInput)`
  background: ${(props: SectionProps) =>
    props.background || ColorName.aquaHaze};
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
`;

const DisabledInputField = styled(HorizontalContainer)`
  width: 100%;
  flex: 1;
  align-items: center;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  background: ${ColorName.geyser};
  border-radius: 5px;
  margin-right: 15px;
  position: relative;
  height: 40px;
  user-select: none;
  input {
    line-height: 21px;
    font-size: 14px;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    margin-right: 10px;
    color: ${ColorName.darkElectricBlue};
    user-select: none;
    overflow: hidden;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

const InputSeachFieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px 0 14px;
  width: ${(props: SectionProps) => props.width || "100%"};
  height: 40px;
  font-size: 16px;
  line-height: 23.5px;
  font-weight: 400;
  outline: none;
  /* transition: all ease 0.25s; */
  border: 1px solid transparent;
  font-family: "Be Vietnam";
  box-sizing: border-box;
  position: relative;
  flex: ${(props: SectionProps) => props.flex || "unset"};
  background: ${(props: SectionProps) =>
    props.background || ColorName.aquaHaze};
  border-radius: 5px;
  font-size: 14px;
  &.focus {
    border: 1px solid ${ColorName.darkElectricBlue};
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  i {
    opacity: 0.7;

    &:hover {
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    color: ${ColorName.darkElectricBlue};
    border: none;
    outline: none;
    background: transparent;
    font-size: 14px;
    &::placeholder {
      color: ${ColorName.casper};
      font-size: 14px;
      line-height: 23.5px;
      font-weight: 400;
    }
    &[value]:not([value=""]) ~ i {
      opacity: 1;
    }
  }
`;

const InputSearchField = (props: SectionProps) => {
  const {
    placeholder,
    onChange,
    value,
    disabled,
    onClick,
    onKeydown,
    background,
    width,
    flex,
  } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputSeachFieldContainer
      className={`${isFocus ? "focus" : ""} ${disabled ? "disabled" : ""}`}
      onClick={() => inputRef && inputRef.current && inputRef.current.focus()}
      background={background}
      width={width}
      flex={flex}
    >
      <input
        type="text"
        placeholder={placeholder}
        onChange={(e: any) => onChange && onChange(e)}
        value={value}
        ref={inputRef}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onKeyDown={(e) => onKeydown && e.keyCode === 13 && onKeydown()}
      />
      <i className="fas fa-search" onClick={() => onClick && onClick()} />
    </InputSeachFieldContainer>
  );
};

export {
  DefaultInput,
  InputItalicPlaceholder,
  Textarea,
  InputSearchField,
  UserInput,
  UserTextarea,
  DisabledInputField,
};
