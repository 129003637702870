import React from 'react';
import history from '../history';
import { useDispatch } from 'react-redux';
import { AuthenticationActions } from '../actions/';
import { ROUTES } from '../constants/';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { Utils } from '../libs';
import API from '../api';

const { isLogged } = AuthenticationActions;

const ValidationRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const arrayListNotAuthentication = _.values(ROUTES.USER);
  const arrayAdminRoutes = _.values(ROUTES.ADMIN);
  const pathName = location.pathname;

  const checkURLs = () => {
    let flag = false;
    if (arrayListNotAuthentication.includes(pathName)) flag = true;
    return flag;
  };

  const isAdminRoutes = () => {
    let flag = false;
    _.map(arrayAdminRoutes, value => {
      if (pathName.includes(value)) return (flag = true);
    });
    return flag;
  };

  const _checkAuthentication = () => {
    if (isAdminRoutes())
      Utils.authenticationValidate({
        found: (access_token: string) => {
          if (!checkURLs()) {
            if (!access_token) return history.push(ROUTES.ADMIN.LOGIN);
            else {
              dispatch(isLogged());
              API.setToken(access_token);
              if (pathName === ROUTES.ADMIN.LOGIN) history.push(ROUTES.ADMIN.DASHBOARD);
            }
          }
        },
        notFound: () => !checkURLs() && pathName !== ROUTES.ADMIN.LOGIN && history.push(ROUTES.ADMIN.LOGIN),
      });
  };

  return <>{_checkAuthentication()}</>;
};

export default ValidationRoute;
