/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import {
  Content,
  RegularParagraph,
  IDRACard,
  PageContainer,
  TitleIDRAPage,
  FlexHorizontal,
  IconIDRAPage,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES } from "../../../constants/";
import { Icons } from "../../../themes";

const IDRAResearch = () => {
  // Declare dispatch, reducers
  const payload = useSelector((state) =>
    get(state, `EditContent.payload[${ROUTES.USER.IDRA_RESEARCH}]`)
  );
  const lang = useSelector((state) => get(state, "PageSettings.userLang"));
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!isEmpty(payload) && descriptionRef && descriptionRef.current) {
      const pageDescription = get(payload, `pageDescription.${lang}`);
      descriptionRef.current.innerHTML = pageDescription;
    }
  }, [payload, lang]);

  const renderMain = () => {
    const pageTitle = get(payload, `pageTitle.${lang}`);
    const sections = get(payload, "sections");
    return (
      <PageContainer>
        <Content direction="column" className="mt-5 mb-8">
          <FlexHorizontal justifyCenter>
            <IconIDRAPage
              src={Icons.idraResearch.default}
              alt="IDRA Research icon"
            />
          </FlexHorizontal>
          <TitleIDRAPage className="mt-1 mb-3">{pageTitle}</TitleIDRAPage>
          <RegularParagraph
            textAlignJustify
            textAlignLastCenter
            ref={descriptionRef}
          />
          <IDRACard
            className="mt-5"
            colGap="35px"
            rowGap="30px"
            data={sections}
          />
        </Content>
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.IDRA_RESEARCH}
      headerTitle="IDRA Research"
      metaDescription="IDRA Research provides high-quality quantitative and qualitative research to private and institutional clients."
    />
  );
};

export default IDRAResearch;
