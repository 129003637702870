import styled from 'styled-components';
import { VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';
import { GrannyRegularParagraph } from '../Paragraph';

const ArrowIcon = styled.img`
  width: 30px;
  opacity: 0;
  position: absolute;
  bottom: 10px;
  left: 25px;
  transition: all ease 0.55s;
  transition-delay: 0.1s;
`;

const SectionIcon = styled.div`
  overflow: hidden;

  img {
    width: max-content;
    height: 50px;
    margin-right: 1.9em;
    transition: all ease-in-out 0.35s;
    transition-delay: 0.15s;
  }
`;

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 22px;
  margin: 10px 0;
  transition: all ease-in-out 0.35s;
  transition-delay: 0.2s;
`;

const CardDescription = styled(GrannyRegularParagraph)`
  height: auto;
  transition: all ease-in-out 0.35s;
  transition-delay: 0.2s;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CardContent = styled.div`
  padding: 50px 25px;

  @media screen and (max-width: 426px) {
    padding: 25px;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

const SectionCardContainer = styled(VerticalContainer)`
  background: ${ColorName.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.55s;
  transition-delay: 0.1s;
  position: relative;
  will-change: background;
  min-height: 180px;

  &.none_icon:hover {
    ${CardTitle},
    ${CardDescription} {
      transform: translateY(-25px);
      color: ${ColorName.white};
    }
  }

  &:hover {
    cursor: pointer;
    background: ${ColorName.steelTeal};

    & ${SectionIcon} {
      img {
        transform: translateY(-55px);
      }
    }
    ${CardTitle},
    ${CardDescription} {
      transform: translateY(-50px);
      color: ${ColorName.white};
    }
    & ${ArrowIcon} {
      opacity: 1;
      bottom: 30px;
    }
  }

  @media screen and (max-width: 426px) {
    ${SectionIcon} {
      height: 40px;
    }
    ${CardTitle} {
      font-size: 20px;
    }

    &:hover {
      & ${ArrowIcon} {
        opacity: 1;
        bottom: 20px;
      }
    }
  }
`;

const HiddenLink = styled.a`
  display: none;
`;

export { SectionCardContainer, HiddenLink, SectionIcon, ArrowIcon, CardTitle, CardContent, CardDescription };
