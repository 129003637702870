import styled, { keyframes } from 'styled-components';
import { FlexHorizontal, FlexVertical, PageContainer, BlackRegularParagraph } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

interface SectionProps {
  col?: string;
  rowGap?: string;
  colGap?: string;
  background: string;
  fontSize?: string;
}

const OutputMemberImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;

  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
`;

const scaleFadeIn = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const arrow = keyframes`
    0%,
    100% {
        transform: translateX(0);
        opacity: 1;
    }
    23% {
        transform: translateX(17px);
        opacity: 1;
    }
    24%,
    80% {
        transform: translateX(-22px);
        opacity: 0;
    }
    81% {
        opacity: 1;
        transform: translateX(-22px);
    }
`;

const newArrow = keyframes`
    20%,
    80% {
        transform: translateX(0);
        opacity: 1;
    }
    43% {
        transform: translateX(17px);
        opacity: 1;
    }
    44%,
    60% {
        transform: translateX(-22px);
        opacity: 0;
    }
    61% {
        opacity: 1;
        transform: translateX(-22px);
    }
`;

const arrowUp = keyframes`
    0%,
    100% {
        transform: rotate(-40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(.1);
    }
`;

const newArrowUp = keyframes`
    20%,
    80% {
        transform: rotate(-40deg) scaleX(1);
    }
    40%,
    60% {
        transform: rotate(0deg) scaleX(.1);
    }
`;

const border = keyframes`
  0% {
    left: 100%;
    background: ${ColorName.black};
  }
  
  30% {
    left: 0;
    background: ${ColorName.black};
  }
  
  70% {
    left: 0;
    background: ${ColorName.black};
  }
  
  100% {
    left: -100%;
    background: transparent;
  }
`;

const newBorder = keyframes`

    0% {
      left: 100%;
      background: transparent;
    }
    20% {
      left: 100%;
      background: transparent;
    }
    40% {
      left: 0;
      background: ${ColorName.black};
    }

    60% {
      left: 0;
      background: ${ColorName.black};
    }

    80% {
      left: -100%;
      background: ${ColorName.black};
    }
    100% {
      left: -100%;
      background: transparent;
    }
`;

const arrowDown = keyframes`
    0%,
    100% {
        transform: rotate(40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(.1);
    }`;

const newArrowDown = keyframes`
    20%,
    80% {
        transform: rotate(40deg) scaleX(1);
    }
    40%,
    60% {
        transform: rotate(0deg) scaleX(.1);
    }`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const WhoWeAreContainer = styled(PageContainer)`
  position: relative;

  #circle {
    position: fixed;
    border-radius: 54px;
    z-index: 5;
    height: 64px;
    width: 64px;
    border: 2px solid ${ColorName.white};
    pointer-events: none;
    transition: background ease-in 100ms, box-shadow ease-in 150ms, transform ease-in 150ms, border-color ease-in 100ms,
      width ease-in 100ms, height ease-in 100ms;
    transform: translate3d(0, 0, 0);
  }

  & .content {
    animation: ${fadeIn} ease 0.35s forwards;
  }

  & .ourmission-mobile {
    display: none;
  }

  @media screen and (max-width: 836px) {
    & .ourmission-mobile {
      display: flex;

      p {
        text-align: left;
      }
    }

    & .ourmission-desktop {
      display: none;
    }
  }
`;

const OurValueBox = styled(FlexVertical)`
  width: 100%;
  height: auto;
  align-items: flex-start;
  animation: ${scaleFadeIn} ease-in-out 0.3s forwards;

  & .image-value {
    width: auto;
  }

  & .description {
    display: flex;
    align-items: center;
  }
  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
  }
  & .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: ${ColorName.grannySmith};
    margin-top: 5px;
  }

  @media screen and (max-width: 426px) {
    padding: 15px 0;
    margin-top: -30px;
    border-bottom: 1px solid ${ColorName.black};
    .title {
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }

    .subtitle {
      font-size: 12px;
      line-height: 16px;
    }

    .description {
      flex-direction: column;
      align-items: flex-start;
    }

    & .image-value {
      img {
        max-width: 35px;
      }
    }
  }
`;

const MemberCard = styled(FlexVertical)`
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
  height: auto;
  overflow: hidden;
  position: relative;

  & .avatar {
    width: 100%;
  }

  & .empty_box {
    height: 75px;
  }

  & .information {
    width: 100%;
    height: 100%;
    max-height: 100%;
    /* padding: 0px 16px; */
    transition: all ease 0.35s;
    position: absolute;
    top: calc(100% - 75px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${ColorName.aquaHaze};
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 5px;
      background-color: ${ColorName.darkElectricBlue};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${ColorName.darkElectricBlue};
      border: 2px solid #555555;
    }

    .content {
      background: ${ColorName.white};
      padding: 10px 20px 10px 20px;
      box-sizing: border-box;
    }
    .flex_box {
      flex: 0;
      transition: all ease 0.35s;
    }

    h3,
    span {
      user-select: none;
    }

    h3 {
      font-size: 20px;
      line-height: 26px;
    }
    span {
      color: ${ColorName.darkElectricBlue};
      display: block;
      font-size: 1.8rem;
      font-weight: 600;
      margin: 5px 0 15px;

      &.read_more {
        color: ${ColorName.black};
        font-size: 1.6rem;
        img {
          margin-left: 0.5rem;
        }
      }
    }
    p {
      font-size: 1.7rem;
      font-weight: 400;
      line-height: 2.3rem;
      color: ${ColorName.black};
      margin-bottom: 35px;
    }
  }
  &.show {
    .information {
      top: 0;
      &:hover {
        cursor: pointer;
      }
    }
    .flex_box {
      flex: 1;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: ${ColorName.darkElectricBlue};
    }
  }

  @media screen and (max-width: 426px) {
    & .output {
      width: 100%;
    }
    & .information {
      & .content {
        padding: 10px;
      }
      h3 {
        font-size: 18px;
        line-height: 25px;
      }
      span {
        font-size: 14px;
        line-height: 18px;
      }
      p {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
`;

const PartOfContent = styled(FlexHorizontal)`
  flex-direction: column;
  height: 100%;
  padding-right: 180px;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: ${ColorName.black};
    margin: 0;
  }

  & .logo {
    margin-bottom: 15px;
    img {
      object-fit: contain;
      max-height: 51px;
    }
  }

  @media screen and (max-width: 426px) {
    padding: 0;
    margin-bottom: 2rem;
    & .logo img {
      max-height: 35px;
    }
  }

  @media screen and (max-width: 836px) {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const LabelContent = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 15px;

  @media screen and (max-width: 426px) {
    width: auto;
    height: 28px;
  }
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) => `repeat(${props.col}, 1fr)` || `repeat(1, 1fr)`};
  column-gap: ${(props: SectionProps) => props.colGap};
  row-gap: ${(props: SectionProps) => props.rowGap};
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${ColorName.grannySmith};
  opacity: 0.2;
  margin-right: 5px;
  transition: all ease 0.25s;

  &:hover,
  &.active {
    cursor: pointer;
    opacity: 1;
  }
`;

const fadeInDot = keyframes`
  0%, 100% {
    opacity: 0.2;
  }

  40%, 60% {
    opacity: 1;
  }

`;

const ClientGroupBar = styled(FlexHorizontal)`
  align-items: center;
  h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
  }

  & .dots_bar {
    ${Dot}:nth-child(1) {
      animation: ${fadeInDot} ease 3s infinite;
    }
    ${Dot}:nth-child(2) {
      animation: ${fadeInDot} ease 3s infinite;
      animation-delay: 0.6s;
    }
    ${Dot}:nth-child(3) {
      animation: ${fadeInDot} ease 3s infinite;
      animation-delay: 1.2s;
    }
    ${Dot}:nth-child(4) {
      animation: ${fadeInDot} ease 3s infinite;
      animation-delay: 1.8s;
    }
    ${Dot}:nth-child(5) {
      animation: ${fadeInDot} ease 3s infinite;
      animation-delay: 2.4s;
    }
  }
`;

const ButtonBar = styled(FlexHorizontal)`
  align-items: center;
`;

const Label = styled.h3`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 20px;
  transition: all ease 0.25s;
  @media screen and (max-width: 426px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Button = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &.left {
    transform: scaleX(-1);
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid #ccc;
    box-sizing: border-box;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: transparent;
    box-sizing: border-box;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all ease 0.5s;
    transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
  }
  &.first_load {
    & .content {
      background: ${ColorName.white};
    }
    &::after {
      animation: ${newBorder} 3.6s ease infinite;
    }
    i {
      animation: ${newArrow} 3.6s ease infinite;
      &:before {
        animation: ${newArrowUp} 3.6s ease infinite;
      }
      &:after {
        animation: ${newArrowDown} 3.6s ease infinite;
      }
    }
  }

  .content {
    width: 46px;
    height: 46px;
    background: transparent;
    position: absolute;
    z-index: 2;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: all ease 0.25s;
    will-change: background;
  }

  i {
    display: block;
    position: absolute;
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    z-index: 3;
    &:before,
    &:after {
      content: '';
      width: 10px;
      height: 2px;
      border-radius: 1px;
      position: absolute;
      background: black;
      left: 50%;
      top: 50%;
      margin: -1px 0 0 -5px;
      display: block;
      transform-origin: 9.5px 50%;
    }
    &:before {
      transform: rotate(-40deg);
    }
    &:after {
      transform: rotate(40deg);
    }
  }
  &.animate {
    & .content {
      background: ${ColorName.white};
    }
    &::after {
      animation: ${border} ease 1.3s;
      animation-delay: 0.3s;
    }
    i {
      animation: ${arrow} 1.6s ease forwards;
      &:before {
        animation: ${arrowUp} 1.6s ease forwards;
      }
      &:after {
        animation: ${arrowDown} 1.6s ease forwards;
      }
    }
  }

  @media screen and (max-width: 426px) {
    transform: scale(0.8);
    &.left {
      transform: scale(0.8) scaleX(-1);
    }
    &:nth-child(2) {
      margin-left: -10px;
    }
  }
`;

const TimelineForm = styled.div`
  /* width: 732px; */
  height: 1470px;
  margin: 0 auto;
  position: relative;

  #path {
    stroke-dasharray: 4684 4684;
    /* stroke-dashoffset: 4684; */
    transition: all ease 0.25s;
  }
  & .line-large-mobile {
    display: none;
  }

  & .line-mobile {
    display: none;
  }
  & .line-tablet {
    display: none;
  }

  @media screen and (max-width: 836px) {
    & .line-large-mobile {
      display: none;
    }
    & .line-mobile {
      display: none;
    }
    & .line-desktop {
      display: none;
    }
    & .line-tablet {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    & .line-large-mobile {
      display: block;
    }
    & .line-mobile {
      display: none;
    }
    & .line-tablet {
      display: none;
    }
    & .line-desktop {
      display: none;
    }
  }

  @media screen and (max-width: 436px) {
    & .line-large-mobile {
      display: none;
    }
    & .line-mobile {
      display: block;
    }
    & .line-tablet {
      display: none;
    }
    & .line-desktop {
      display: none;
    }
  }
`;

const TimelineLabel = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  color: ${ColorName.gullGray};

  @media screen and (max-width: 436px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const ArrowLabel = styled(TimelineLabel)`
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  width: max-content;
  top: -19px;
  right: 100%;
  font-size: 30px;

  @media screen and (max-width: 436px) {
    font-size: 24px;
    line-height: 31px;
    margin-top: 20px;
  }
`;

const ShortLine = styled.div`
  width: 100%;
  height: 50px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 12px;
    height: 50px;
    left: calc(50% - 6px);
    top: -12px;
    background: ${ColorName.gullGray};
  }
`;

const AboutUsContent = styled.div`
  width: 100%;
  height: auto;
  padding: 38px 50px 5px 50px;
  box-sizing: border-box;
  border: 10px dotted ${ColorName.gullGray};
  position: relative;

  &::before {
    content: '';
    width: 30%;
    height: 12px;
    position: absolute;
    background: ${ColorName.white};
    bottom: -12px;
    left: calc(35%);
  }

  @media screen and (max-width: 835px) {
    padding: 30px 30px;
    border: 10px dotted ${ColorName.gullGray};

    &::before {
      width: 35%;
      left: calc(34%);
    }
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding: 30px 30px;
    border: 10px dotted ${ColorName.gullGray};

    &::before {
      width: 35%;
      left: calc(34%);
    }
  }

  @media screen and (max-width: 436px) {
    border: none;
    padding: 0;
    height: auto;

    ${BlackRegularParagraph} {
      text-align: justify;
      flex: 1;
      width: 100%;
    }
  }
`;

const BoxArrow = styled.div`
  position: relative;
  user-select: none;
  transition: all ease 0.25s;
`;

const TimelineBorderVertical = styled.div`
  position: absolute;
  width: 12px;
  height: 100%;
  background: ${ColorName.gullGray};
  right: 0;
  bottom: 0;

  &.left {
    left: 0;
    right: unset;
  }
`;
const TimelineBorderBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 12px;
  background: ${ColorName.gullGray};
  bottom: 0;
  left: 0;
`;

const TimelineItem = styled(FlexVertical)`
  padding: 25px 50px;
  box-sizing: border-box;
  position: relative;
  height: 308px;

  &:nth-child(5) {
    ${TimelineBorderBottom} {
      width: 50%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 25px 30px;
  }

  @media screen and (max-width: 436px) {
    padding: 25px;
    height: 305px;
  }
`;

const PositionForm = styled.div`
  position: absolute;
  top: 0;
  padding-top: 90px;

  @media screen and (max-width: 436px) {
    padding-top: 117px;
  }
`;

const bottomToTop = keyframes`
  0% {
    transform: rotate(-90deg) translateX(25px) translateY(-2px);
  }

  45% {
    transform: rotate(-90deg) translateX(-40px) translateY(-2px);
  }

  55% {
    transform: rotate(-90deg) translateX(-40px) translateY(-2px);
  }

  100% {
    transform: rotate(-90deg) translateX(25px) translateY(-2px);

  }
`;

const bottomToTopImg = keyframes`
  0% {
    transform: translateY(-55px);
  }

  45% {
    transform: translateY(0px);
  }

  55% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-55px);

  }
`;

const BoxAnimation = styled(FlexVertical)`
  max-height: 200px;
  overflow: hidden;
  span {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${ColorName.gullGray};
    transform: rotate(-90deg) translateX(25px) translateY(-2px);
    animation: ${bottomToTop} ease 1.5s infinite;
  }
  img {
    height: 150px;
    transform: translateY(-55px);
    animation: ${bottomToTopImg} ease 1.5s infinite;
  }
`;

export {
  OutputMemberImage,
  WhoWeAreContainer,
  OurValueBox,
  MemberCard,
  PartOfContent,
  LabelContent,
  GridLayout,
  Image,
  ClientGroupBar,
  Dot,
  ButtonBar,
  Button,
  Label,
  TimelineForm,
  TimelineItem,
  TimelineLabel,
  ShortLine,
  AboutUsContent,
  BoxArrow,
  ArrowLabel,
  TimelineBorderVertical,
  TimelineBorderBottom,
  PositionForm,
  BoxAnimation,
};
