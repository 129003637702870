/* eslint import/no-anonymous-default-export: off */
import _ from "lodash";
import { ROUTES } from "../constants";
import { LOGOS_PAGE_KEY } from "../constants/Constants";
import CLIENTS_INDEX from "../constants/IndexClients";
import { Images } from "../themes";

interface DropdownDataStructure {
  value: string | number;
  label: string;
}

const generateDropdown = (
  data: DropdownDataStructure[],
  isFilter?: boolean
) => {
  const filterAll = {
    value: "",
    label: "all",
  };
  const result: DropdownDataStructure[] = [filterAll, ...data];
  if (!isFilter) return data;

  return result;
};

const GenerateYear = (selectedYear?: number | null | string) => {
  const result = [];
  const startYear = 2000;
  const currentYear = new Date().getFullYear();
  if (selectedYear) {
    for (let i = currentYear; i > _.toNumber(selectedYear); i--) {
      result.push({
        value: i,
        label: i,
      });
    }
    return result;
  }
  for (let i = currentYear; i > startYear; i--) {
    result.push({
      value: i,
      label: i,
    });
  }
  return result;
};

const GenerateYearFrom = (selectedYear?: number | null | string) => {
  const result = [];
  const startYear = 2000;
  const currentYear = new Date().getFullYear();
  if (selectedYear) {
    for (let i = _.toNumber(selectedYear) - 1; i > startYear; i--) {
      result.push({
        value: i,
        label: i,
      });
    }
    return result;
  }
  for (let i = currentYear; i > startYear; i--) {
    result.push({
      value: i,
      label: i,
    });
  }
  return result;
};

const GenerateBanner = (currentPage: string) => {
  switch (currentPage) {
    case ROUTES.USER.NEWS:
    case ROUTES.USER.NEWS_DETAILS:
      return Images.newsBanner.default;
    case ROUTES.USER.CAREER:
    case ROUTES.USER.CAREER_DETAILS:
      return Images.careerBanner.default;
    case ROUTES.USER.CONTACT:
      return Images.contactBanner.default;
    case ROUTES.USER.RESEARCH_SOLUTIONS:
    case ROUTES.USER.IDRA_RESEARCH:
    case ROUTES.USER.RESEARCH_SERVICES:
    case ROUTES.USER.RESEARCH_NEWS:
    case ROUTES.USER.RESEARCH_WORK:
    case ROUTES.USER.RESEARCH_WORK_DETAILS:
    case ROUTES.USER.RESEARCH_NEWS_DETAILS:
    case ROUTES.USER.RESEARCH_CLIENTS:
      return Images.IDRAResearchBanner.default;
    case ROUTES.USER.IDRA_ADVANCE:
    case ROUTES.USER.DEVELOPMENT_SOLUTIONS:
    case ROUTES.USER.DEVELOPMENT_WORK:
    case ROUTES.USER.DEVELOPMENT_WORK_DETAILS:
    case ROUTES.USER.DEVELOPMENT_NEWS:
    case ROUTES.USER.DEVELOPMENT_NEWS_DETAILS:
    case ROUTES.USER.DEVELOPMENT_CLIENTS:
      return Images.IDRAAdvanceBanner.default;
    case ROUTES.USER.IDRA_DIGIS:
      return Images.IDRADiGISBanner.default;
    case ROUTES.USER.WHO_WE_ARE:
      return Images.whoWeAreBanner.default;
    case ROUTES.USER.ALUMNI_NETWORK:
      return Images.alumniNetwork.default;
    case ROUTES.USER.ALUMNI_NETWORK_REGISTER:
      return Images.alumniNetwork.default;
    default:
      return Images.whoWeAreBanner.default;
  }
};

const generateCategories = () => {
  const payload = [
    {
      label: "socioEconomicDevelopment",
      value: "socioEconomicDevelopment",
    },
    {
      label: "governanceAndRuleofLaw",
      value: "governanceAndRuleOfLaw",
    },
    {
      label: "urbanPlanningAndGis",
      value: "urbanPlanningAndGis",
    },
    {
      label: "environment",
      value: "environment",
    },
  ];
  return payload;
};

const generateMonthYear = (date: string) => {
  if (date) {
    const MONTH_DATA = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December",
    };

    let splitDateTime: string[] = _.split(date, "-");

    if (splitDateTime.length === 2) {
      let resolveDateTime =
        _.get(MONTH_DATA, splitDateTime[1]) + " " + splitDateTime[0];
      return resolveDateTime;
    }
  }
};

const generateCurrency = (value: number) => {
  if (value && _.isNumber(value)) {
    const currency = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);
    return currency;
  }
  return "N/I";
};

const generateClientsPayload = (page: string, data: any) => {
  if (page && data) {
    const ids = [
      "3d1fa728-c754-4bd9-aade-d4e5af5499d4",
      "e5d6dfa1-10cd-4cff-a093-9070ecc6a261",
      "78586f35-2b49-438f-a5c6-369054b75933",
      "8190ae98-9507-40a1-a0fe-f152361fff60",
      "0e0f09e8-4116-4986-935d-c56926f1427d",
      "f4ac4390-6d88-4fc9-bf0b-e0e9784ae769",
      "b9934aab-8583-4a08-af2a-15cca8ba9093",
      "3d164176-1f1f-44ce-a2c8-841d883da202",
      "9bf21758-345c-4293-a1f5-4b82de7db1ad",
      "72fc8837-d0a2-4aa9-abed-43e4b4e1d93b",
      "17ce454a-364b-46c3-8e45-67a173bae183",
      "e7dd9418-167a-47a0-94b8-80a68f4af902",
      "2931cd96-a72d-4255-aa77-c84999ba6221",
      "bdd22721-df1a-413d-b46a-e2f094968fa6",
      "09c202ce-2ad4-46bf-ad70-e563f38d238d",
      "347e86fb-4eb8-41ab-b80e-7fc43d76755b",
      "b48adbc5-4edb-4ba5-b3e5-57e66675d58d",
      "6fa77cf7-62e5-4753-93f0-dd03d8f5aa91",
      "fec4afe8-cc7e-47fa-a51f-dc77608b8643",
      "8df9e62b-c774-4299-8804-f41cce88b79a",
      "6d6c81be-4225-47d4-b5e3-a58011b4384c",
    ];
    if (page === LOGOS_PAGE_KEY.HOMEPAGE) {
      const result: any[] = [];
      _.map(ids, (id) => {
        const findData: any = _.find(data, { id });
        if (findData) result.push(findData);
      });
      return result;
    }
    return data;
  }
  return [];
};

const generateLogos = (data: any, page: string, type: string) => {
  const pageIndex = _.get(CLIENTS_INDEX, page);
  const typeIndex = _.get(pageIndex, type);
  if (typeIndex) {
    const result = _.map(typeIndex, (item) => {
      const findLogo = _.find(data, { id: item });
      if (findLogo) return findLogo;
    });
    _.map(data, (item) => {
      const findLogo = _.find(result, { id: item.id });
      if (!findLogo) result.push(item);
    });
    return result;
  }
  return data;
};

const convertTime = (date: string) => {
  const newDate = new Date(date);
  var dd = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
  var MM = (newDate.getMonth() + 1 < 10 ? "0" : "") + (newDate.getMonth() + 1);
  var yyyy = newDate.getFullYear();
  return `${dd}.${MM}.${yyyy}`;
};

const convertDateTime = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString("en-GB", { timeZone: "UTC" });
};

const sourceImageToBaseURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const dataURLtoFile = (dataURL: any, filename: any) => {
  var arr = dataURL.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const calculateTime = (time: number, skipHour: number) => {
  const currentTime = new Date().getTime();
  const convertHour = skipHour * 60 * 60 * 1000;

  const result = currentTime > time + convertHour;

  return result;
};

export default {
  generateDropdown,
  GenerateYear,
  GenerateBanner,
  generateCategories,
  GenerateYearFrom,
  generateMonthYear,
  generateCurrency,
  generateClientsPayload,
  generateLogos,
  convertTime,
  convertDateTime,
  sourceImageToBaseURL,
  dataURLtoFile,
  calculateTime,
};
