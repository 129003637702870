/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { PopupWrapper, HeaderPopup } from "../DefaultPopup.styles";
import {
  GeyserSolidButton,
  CarrotSolidButton,
  InputSearchField,
  AdminDropdown,
} from "../../../Common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  OrderPopupContainer,
  FilterBar,
  FilterLabel,
  TableContent,
  ButtonBar,
  TabBar,
  TabBarItem,
  MediaItem,
  FunctionBox,
} from "./SelectMedia.styles";
import { MediaActions } from "../../../../actions";
import { statusAlert } from "../../../../libs";
import { useTranslation } from "react-i18next";

interface SectionProps {
  hidePopup(value?: string, type?: string): unknown;
  className?: string;
  tabBar?: any;
  selectedTab?: string;
  isSiteSettings?: boolean;
  onClose?(): void;
}

const FILE_TYPES = {
  image: ["image/png", "image/gif", "image/jpeg", "image/jpg"],
  pdf: ["application/pdf"],
  video: ["video/mp4", "video/avi"],
};

const { uploadMedia, getMediaByType, setMediaFilter, deleteMedia, getVideos } =
  MediaActions;

const OrderPopup = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  const { hidePopup, className, tabBar, selectedTab, onClose } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const mediaPayload = useSelector((state) =>
    _.get(state, "Media.mediaPayload")
  );
  const videos = useSelector((state) => _.get(state, "Media.videos"));
  const filter = useSelector((state) => _.get(state, "Media.filter"));

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTab) {
      const newFilter = {
        ...filter,
        type: selectedTab,
      };
      const { videoType, ...rest } = newFilter;
      dispatch(setMediaFilter(newFilter));
      dispatch(getMediaByType(rest));
    }

    return () => {
      dispatch(setMediaFilter({ ...filter, keyword: "" }));
    };
  }, []);

  const handleChangeFilter = async (value: any, key: string) => {
    const newFilter = {
      ...filter,
      [key]: value,
    };
    dispatch(setMediaFilter(newFilter));
    if (key === "type") await dispatch(getMediaByType(newFilter));
    if (key === "videoType") {
      if (value === "youtube")
        await dispatch(getVideos({ keyword: _.get(newFilter, "keyword") }));
      else
        await dispatch(
          getMediaByType({
            type: "video",
            keyword: _.get(newFilter, "keyword"),
          })
        );
    }
  };

  const handleClosePopup = (e: any) => {
    const isPopupWrapper = _.get(e.target, "classList").contains(
      "popup-wrapper"
    );
    if (isPopupWrapper && hidePopup) hidePopup();
  };

  const handleSearch = async () => {
    await dispatch(getMediaByType(filter));
  };

  const generateType = (fileType: string) => {
    if (_.includes(fileType, "image")) return "image";
    if (_.includes(fileType, "pdf")) return "pdf";
    return "video";
  };

  const onReset = async () => {
    const newFilter = {
      ...filter,
      keyword: "",
    };
    await dispatch(setMediaFilter(newFilter));
    await dispatch(getMediaByType(newFilter));
  };

  const handleChangeMedia = async (e: any) => {
    const validateByType: any[] = [];
    _.map(e.target.files, (file) => {
      const fileType = _.get(file, "type");
      const currentType = _.get(filter, "type");
      const acceptFile = _.get(FILE_TYPES, currentType);
      if (_.includes(acceptFile, fileType)) validateByType.push(file);
    });
    let filename = "";
    const formData = new FormData();
    formData.append("type", _.get(filter, "type"));
    if (!_.isEmpty(validateByType)) {
      _.map(validateByType, (file) => {
        const name = _.get(file, "name");
        formData.append("files[]", file);
        filename += name + "\n";
      });
    }
    const isAgree = await statusAlert(
      "warning",
      `${t("alert.warningUploadMedia")} ${filename}?`
    );
    if (isAgree) {
      await dispatch(uploadMedia(formData));
      await handleChangeFilter(generateType(_.get(filter, "type")), "type");
      await dispatch(
        getMediaByType({ type: generateType(_.get(filter, "type")) })
      );
    }
  };

  const generateAcceptFile = () => {
    const type = _.get(filter, "type");
    const acceptFile = _.get(FILE_TYPES, type);
    return _.join(acceptFile, ", ");
  };

  const handleClickButton = async (data: {
    key: string;
    value: any;
    mediaName?: any;
    mediaType?: string;
  }) => {
    const { key, value, mediaName, mediaType } = data;
    if (hidePopup && value) {
      if (key === "select") hidePopup(value, mediaType);
      if (key === "remove") {
        const isAgree = await statusAlert(
          "warning",
          `D${t("alert.warningRemoveMedia")} \n ${mediaName}?`
        );
        if (isAgree) {
          const type = _.get(filter, "type");
          await dispatch(deleteMedia(value));
          await dispatch(getMediaByType({ type }));
        }
      }
    }
  };

  const renderFilterBar = () => {
    return (
      <FilterBar>
        <FilterLabel className="mr-1">{t("label.filterBy")}</FilterLabel>
        <InputSearchField
          // background="white"
          width="250px"
          placeholder={t("placeholder.filterByTitle")}
          value={_.get(filter, "keyword")}
          onChange={(e: any) => handleChangeFilter(e.target.value, "keyword")}
          onKeydown={() => handleSearch()}
        />
        {_.get(filter, "type") === "video" && (
          <AdminDropdown
            className="mr-1 ml-1"
            width="150px"
            selectedValue={_.get(filter, "videoType") || ""}
            data={[
              { label: "aws", value: "aws" },
              { label: "youtube", value: "youtube" },
            ]}
            onChange={(value: string) => handleChangeFilter(value, "videoType")}
          />
        )}
        <GeyserSolidButton className="ml-1" onClick={() => onReset()}>
          {t("button.reset")}
        </GeyserSolidButton>
      </FilterBar>
    );
  };

  const renderMedias = () => {
    const type = _.get(filter, "type");
    if (_.isEmpty(mediaPayload)) return <h3>{t("label.noResultFound")}</h3>;
    const render = _.map(mediaPayload, (item, index) => {
      let thumbnailUrl;
      if (type === "pdf")
        thumbnailUrl =
          "https://blog.idrsolutions.com/wp-content/uploads/2020/10/pdf-1.png";
      else thumbnailUrl = _.get(item, "mediaUrl");
      return (
        <MediaItem key={`media-${_.get(item, "id")}`}>
          <FunctionBox>
            <div className="image-box">
              {type === "video" ? (
                <video controls id={`video-${index}`}>
                  <source src={thumbnailUrl} type="video/mp4" />
                </video>
              ) : (
                <LazyLoadImage
                  src={thumbnailUrl}
                  effect="blur"
                  height="100%"
                  width="100%"
                />
              )}
            </div>
            <div className="layer">
              <div
                className="select"
                onClick={() =>
                  handleClickButton({
                    key: "select",
                    value: _.get(item, "mediaUrl"),
                    mediaType: type,
                  })
                }
              >
                {t("button.select")}
              </div>
              <div
                className="remove"
                onClick={() =>
                  handleClickButton({
                    key: "remove",
                    value: _.get(item, "id"),
                    mediaName: _.get(item, "mediaName"),
                    mediaType: type,
                  })
                }
              >
                {t("button.remove")}
              </div>
            </div>
          </FunctionBox>
          <p>{_.get(item, "mediaName")}</p>
        </MediaItem>
      );
    });
    return render;
  };

  const renderVideo = () => {
    const items = _.get(videos, "items");
    if (_.get(filter, "videoType") === "aws") return renderMedias();
    const render = _.map(items, (item) => {
      let thumbnailUrl = _.get(item, "snippet.thumbnails.high.url");
      let videoUrl = _.get(item, "id.videoId");
      let title = _.get(item, "snippet.title");
      let kind = _.get(item, "id.kind");
      if (kind === "youtube#channel") return;
      return (
        <MediaItem key={`media-${videoUrl}`}>
          <FunctionBox>
            <div className="image-box">
              <LazyLoadImage
                src={thumbnailUrl}
                effect="blur"
                height="100%"
                width="100%"
              />
            </div>
            <div className="layer">
              <div
                className="select"
                onClick={() =>
                  handleClickButton({
                    key: "select",
                    value: `https://youtube.com/embed/${videoUrl}`,
                    mediaType: "video",
                  })
                }
              >
                {t("button.select")}
              </div>
            </div>
          </FunctionBox>
          <p>{title}</p>
        </MediaItem>
      );
    });
    return render;
  };

  const renderButtonBar = () => {
    const isShowUploadButton =
      (_.get(filter, "type") === "video" &&
        _.get(filter, "videoType") === "aws") ||
      _.get(filter, "type") === "image" ||
      _.get(filter, "type") === "pdf";
    return (
      <ButtonBar className="flex-justify-end flex-align-center">
        <input
          onChange={(e: any) => handleChangeMedia(e)}
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          multiple
          accept={generateAcceptFile()}
        />
        {isShowUploadButton && (
          <CarrotSolidButton
            className="mr-1"
            width="120px"
            onClick={() =>
              inputRef && inputRef.current && inputRef.current.click()
            }
          >
            {t("button.upload")} <i className="fas fa-cloud-upload-alt ml-1" />
          </CarrotSolidButton>
        )}
        <GeyserSolidButton onClick={() => onClose && onClose()}>
          {t("button.close")}
        </GeyserSolidButton>
      </ButtonBar>
    );
  };

  const renderMain = () => {
    return (
      <OrderPopupContainer>
        <HeaderPopup>{t("headerPopup.selectMedia")}</HeaderPopup>
        {renderFilterBar()}
        <TabBar>
          {_.map(tabBar, (item, index) => {
            const activeClass =
              _.get(filter, "type") === item.value ? "active" : "";
            return (
              <TabBarItem
                key={`bar-${index}`}
                className={activeClass}
                onClick={() => handleChangeFilter(item.value, "type")}
              >
                {_.get(item, "label")}
              </TabBarItem>
            );
          })}
        </TabBar>
        <TableContent>
          {_.get(filter, "type") === "video" ? renderVideo() : renderMedias()}
        </TableContent>
        {renderButtonBar()}
      </OrderPopupContainer>
    );
  };

  return (
    <PopupWrapper
      className={`popup-wrapper ${className}`}
      onClick={(e: any) => handleClosePopup(e)}
    >
      {renderMain()}
    </PopupWrapper>
  );
};

export default OrderPopup;
