import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const WorkBoxContainer = styled(VerticalContainer)`
  padding-bottom: 3em;
  margin-top: 25px;
  border-bottom: 1px solid ${ColorName.grannySmith};
  &:first-child {
    margin-top: 0;
  }

  & .hastag {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    span {
      font-size: 16px;
      color: #4f6c7a;
      font-weight: 600;
      margin-right: 10px;
    }
  }

  img {
    width: 100%;
    max-width: 300px;
  }

  span {
    color: ${ColorName.deepCarrotOrange};
    font-size: 16px;
    font-weight: 600;
  }
  a {
    color: ${ColorName.shark};
    font-weight: bold;
    font-size: 26px;
    line-height: 37px;
    margin: 10px 0;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; after 3 line show ...
    -webkit-box-orient: vertical; */
    text-decoration: none;
    /* text-align: justify; */
    /* text-align-last: left; */
    transition: all ease 0.25s;

    &:hover {
      cursor: pointer;
      color: ${ColorName.burntSienna};
      text-decoration: underline;
    }
  }
  p {
    margin: 0;
    color: ${ColorName.grannySmith};
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 24px;
  }
  .subtitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  & .link {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1024px) {
    a {
      font-size: 23px;
      line-height: 31px;
    }
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 22px;
      line-height: 30px;
      margin: 0 0 10px 0;
    }
    p {
      font-size: 14px;
      line-height: 21px;
    }
    & .hastag {
      span {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 436px) {
    a {
      font-size: 19px;
      line-height: 23px;
      margin: 10px 0 5px 0;
    }
    p {
      font-size: 13px;
      line-height: 18px;
    }
    & .hastag {
      span {
        font-size: 13px;
      }
    }
  }
`;

const NewsButton = styled(HorizontalContainer)`
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  & .icon_box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${ColorName.deepCarrotOrange};
    z-index: 1;
    i {
      font-size: 1.7rem;
      color: ${ColorName.white};
    }
  }
  h2 {
    font-size: 3rem;
  }
  p {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 20px 0 10px;
    transition: all ease-in-out 0.3s;
    color: ${ColorName.black};
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
    p {
      color: ${ColorName.white};
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0;
    background: ${ColorName.deepCarrotOrange};
    transition: all ease-in-out 0.3s;
    border-radius: 25px;
  }
  &:hover::before {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    & .icon_box {
      width: 35px;
      height: 35px;
      i {
        font-size: 12px;
      }
    }
    p,
    i {
      font-size: 12px;
    }

    &:before {
      width: 35px;
      height: 35px;
    }
  }

  @media screen and (max-width: 436px) {
    & .icon_box {
      width: 30px;
      height: 30px;
      i {
        font-size: 12px;
      }
    }
    p,
    i {
      font-size: 12px;
    }

    &:before {
      width: 30px;
      height: 30px;
    }
  }
`;

export { WorkBoxContainer, NewsButton };
