/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import history from "../../../history";
import { Icons } from "../../../themes";
import { useTranslation } from "react-i18next";
import Aos from "aos";

import { Utils } from "../../../libs";
import {
  FlexVertical,
  FlexHorizontal,
  OurlineGrowButton,
  Content,
  RegularParagraph,
  ClientsIndustries,
  BlackRegularParagraph,
  GrannyRegularParagraph,
  GridLayout,
  GridIDRAPage,
  MemberCard,
  OurTeamPagination,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import {
  WhoWeAreContainer,
  OurValueBox,
  // MemberCard,
  PartOfContent,
  Image,
  ButtonBar,
  TimelineLabel,
  AboutUsContent,
  BoxArrow,
  ArrowLabel,
  BoxAnimation,
} from "./WhoWeAre.styles";
import SecondHeader from "../../../components/Layout/CommonLayoutPart/SecondHeader";
import {
  SECOND_HEADER,
  CLIENTS_INDUSTRY_HEADER,
} from "../../../constants/Constants";
import { ROUTES, FRAME_MOTIONS } from "../../../constants";

const MEMBER_ITEM_PER_PAGE = 6;

const WhoWeAre = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare reducers
  const clientPayload = useSelector((state) =>
    _.get(state, `Clients.payload.${ROUTES.USER.WHO_WE_ARE}`)
  );
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Clients.isLoading")
  );
  const membersPayload: any = useSelector((state) =>
    _.get(state, "Members.payload")
  );

  const isRequestTeamMebers = useSelector((state) =>
    _.get(state, "Members.isLoading")
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.WHO_WE_ARE}`)
  );
  // Declare location
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare states
  const [category, setCurrentCategory] = useState<string>("aboutUs");
  const [currentType, setCurrentType] = useState<string>(
    "internationalOrganizationsOrInstitutions"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHideArrow] = useState<boolean>(false);
  const [currentMemberShow, setCurrentMemberShow] = useState<string>("");
  const [currentMemberPage, setCurrentMemberPage] = useState(0);

  const [contentMemberHeight, setContentMemberHeight] = useState<number>(0);
  // Declare refs
  const containerRef = useRef<HTMLDivElement>(null);

  const memberPayload = useMemo(
    () => _.flatMap(membersPayload, (value) => value),
    [membersPayload]
  );

  useEffect(() => {
    const circle = document.getElementById("circle") as HTMLDivElement;
    const circleStyle = circle.style;

    document.addEventListener("mousemove", (e: any) => {
      const isHoverOnMemberCard =
        e?.target?.offsetParent?.classList.contains("member-card");

      window.requestAnimationFrame(() => {
        if (!isHoverOnMemberCard) circleStyle.borderColor = "transparent";
        else circleStyle.borderColor = "white";

        circleStyle.top = `${e.clientY - circle.offsetHeight / 2}px`;
        circleStyle.left = `${e.clientX - circle.offsetWidth / 2}px`;
      });
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const paramCategory = _.get(queryStringValue, "category");
      if (paramCategory) setCurrentCategory(_.toString(paramCategory));
    }, 300);
  }, [queryStringValue]);

  useEffect(() => {
    Aos.init({ duration: 300 });
    setCurrentCategory(category);
  }, []);

  const handleMemberPageChange = (pageIndex: number) => {
    setCurrentMemberPage(pageIndex);
  };

  const getCurrentMemberPageData = () => {
    const startIndex = currentMemberPage * MEMBER_ITEM_PER_PAGE;
    const endIndex = startIndex + MEMBER_ITEM_PER_PAGE;
    return memberPayload?.slice(startIndex, endIndex);
  };

  const handleChangeCategory = (value: string) => {
    history.push(`${ROUTES.USER.WHO_WE_ARE}?category=${value}`);
    Utils.scrollToBottomOfBanner();
  };

  const renderReason = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <BlackRegularParagraph
          dangerouslySetInnerHTML={{
            __html: _.get(payload, `aboutUs.descriptionType.${lang}`),
          }}
        ></BlackRegularParagraph>
        <FlexHorizontal justifyCenter className="mt-5 mb-3">
          <BoxArrow className={`${isHideArrow ? "hide" : ""}`}>
            <ArrowLabel>{t("label.moreAboutUs")}</ArrowLabel>
            <BoxAnimation>
              <img src={Icons.downArrowAboutUs.default} alt="down arrow" />
              <span>{t("label.scroll")}</span>
            </BoxAnimation>
          </BoxArrow>
        </FlexHorizontal>
        <AboutUsContent
          data-aos="fade-down"
          data-aos-offset="-250"
          data-aos-duration="250"
          data-aos-easing="ease"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="bottom-center"
          className="flex-column flex-justify-between"
        >
          <BlackRegularParagraph
            dangerouslySetInnerHTML={{
              __html: _.get(
                payload,
                `aboutUs.currentResult.description.${lang}`
              ),
            }}
          ></BlackRegularParagraph>
          <FlexHorizontal justifySpaceBetween className="ourmission-desktop">
            {_.map(
              _.get(payload, "aboutUs.currentResult.missionAndVision"),
              (item, index) => {
                const title = _.get(item, `title.${lang}`);
                return (
                  <TimelineLabel
                    className="mt-4"
                    key={`description-result-desktop-${index}`}
                  >
                    {title}
                  </TimelineLabel>
                );
              }
            )}
          </FlexHorizontal>
        </AboutUsContent>
        <FlexVertical justifySpaceBetween className="mt-2 ourmission-mobile">
          {_.map(
            _.get(payload, "aboutUs.currentResult.missionAndVision"),
            (item, index) => {
              const title = _.get(item, `title.${lang}`);
              const description = _.get(item, `description.${lang}`);
              const order = _.get(item, `order`);
              return (
                <FlexVertical
                  data-aos="fade-left"
                  data-aos-offset="50"
                  data-aos-duration="250"
                  data-aos-easing="ease"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  data-aos-anchor-placement="bottom-center"
                  key={`current-result-${index}`}
                >
                  <TimelineLabel className="mt-6">{title}</TimelineLabel>
                  <BlackRegularParagraph
                    textAlignRight={order === 2}
                    maxWidth="360px"
                  >
                    {description}
                  </BlackRegularParagraph>
                </FlexVertical>
              );
            }
          )}
        </FlexVertical>
        <FlexHorizontal className="flex-justify-between mt-2 ourmission-desktop">
          {_.map(
            _.get(payload, "aboutUs.currentResult.missionAndVision"),
            (item, index) => {
              const description = _.get(item, `description.${lang}`);
              return (
                <BlackRegularParagraph
                  data-aos="fade-right"
                  data-aos-offset="-250"
                  data-aos-duration="250"
                  data-aos-easing="ease"
                  data-aos-mirror="false"
                  data-aos-once="true"
                  data-aos-anchor-placement="bottom-center"
                  textAlignLeft={_.parseInt(index) === 0}
                  textAlignRight={_.parseInt(index) === 1}
                  maxWidth="360px"
                  key={`description-result-${index}`}
                >
                  {description}
                </BlackRegularParagraph>
              );
            }
          )}
        </FlexHorizontal>
      </Content>
    );
  };

  const renderOurValues = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <GridIDRAPage col="2" rowGap="35px" colGap="140px">
          {_.map(_.get(payload, "ourValues"), (item, index) => {
            const icon = _.get(item, "icon");
            const title = _.get(item, `title.${lang}`);
            const description = _.get(item, `description.${lang}`);
            return (
              <OurValueBox key={`our-values-${index}`}>
                <div className="description">
                  <div className="image-value">
                    <Image src={icon} alt="our-values icon" />
                  </div>
                  <h3 className="title">{title}</h3>
                </div>
                <p className="subtitle">{description}</p>
              </OurValueBox>
            );
          })}
        </GridIDRAPage>
      </Content>
    );
  };

  const renderQualityAssurance = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <motion.div
          variants={FRAME_MOTIONS.CONTAINER}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={FRAME_MOTIONS.ITEM_EFFECT}>
            <RegularParagraph
              dangerouslySetInnerHTML={{
                __html: _.get(payload, `qualityAssurance.${lang}`),
              }}
            />
          </motion.div>
        </motion.div>
      </Content>
    );
  };

  const renderMember = (payload: any[]) => {
    const sortBy = _.sortBy(payload, ["fullname"]);
    const render = _.map(sortBy, (item) => {
      return (
        // <motion.div  variants={ANIMATION.item}>
        // </motion.div>
        <MemberCard
          key={`member-${_.get(item, "id")}`}
          data={item}
          active={currentMemberShow}
          onActive={(id: string) => setCurrentMemberShow(id)}
          onChangeHeight={(newHeight: number) => {
            if (newHeight > contentMemberHeight)
              setContentMemberHeight(newHeight);
          }}
          height={contentMemberHeight}
        />
      );
    });
    return render;
  };

  const renderOurTeam = () => {
    const payload = getCurrentMemberPageData();
    return (
      <Content className="mb-4 content" direction="column">
        <ButtonBar className="mt-6 mb-4" />
        <GridIDRAPage
          mimax="310px"
          className="pb-2"
          rowGap="50px"
          colGap="35px"
        >
          {!_.isEmpty(payload) ? (
            renderMember(payload)
          ) : (
            <h3>{t("label.noResultFound")}</h3>
          )}
        </GridIDRAPage>
        {!_.isEmpty(memberPayload) && (
          <OurTeamPagination
            active={currentMemberPage}
            onChange={handleMemberPageChange}
            totalItems={memberPayload.length}
            itemsPerPage={MEMBER_ITEM_PER_PAGE}
          />
        )}
      </Content>
    );
  };

  const renderOutPartner = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <motion.div
          variants={FRAME_MOTIONS.CONTAINER}
          initial="hidden"
          animate="visible"
        >
          <GridLayout
            col="2"
            colGap="100px"
            rowGap="20px"
            className="pb-2 custom-ourpartners"
          >
            {_.map(_.get(payload, "ourPartners"), (item, index: number) => {
              // https://jira.idra.al/browse/VI-33
              if (index !== 0) return;
              const logo =
                "https://idracompany.com/static/media/logo_header.513f0ab5.svg";
              const link = _.get(item, "link");
              const description = _.get(item, `description.${lang}`);
              return (
                <motion.div
                  variants={FRAME_MOTIONS.ITEM_EFFECT}
                  key={`partners-${index}`}
                >
                  <PartOfContent>
                    <div className="logo">
                      <img src={logo} alt="pertner logo" />
                    </div>
                    <GrannyRegularParagraph
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></GrannyRegularParagraph>
                    <OurlineGrowButton
                      className="mt-2"
                      label="visitWebsite"
                      isLink
                      url={link}
                    />
                  </PartOfContent>
                </motion.div>
              );
            })}
          </GridLayout>
        </motion.div>
      </Content>
    );
  };

  const renderClient = () => {
    return (
      <Content minHeight="600px" className="pt-4 pb-4" direction="column">
        <ClientsIndustries
          header={CLIENTS_INDUSTRY_HEADER.WHO_WE_ARE}
          data={_.get(clientPayload, currentType) || []}
          currentType={currentType}
          onChange={(value: string) => setCurrentType(value)}
          currentPage={ROUTES.USER.WHO_WE_ARE}
          onLoaded={() => setIsLoading(false)}
        />
      </Content>
    );
  };

  const renderMain = () => {
    return (
      <WhoWeAreContainer ref={containerRef}>
        <SecondHeader
          active={category}
          data={SECOND_HEADER.WHO_WE_ARE}
          onChange={(value: string) => handleChangeCategory(value)}
        />
        {category === "aboutUs" && renderReason()}
        {category === "ourValues" && renderOurValues()}
        {category === "qualityAssurance" && renderQualityAssurance()}
        {category === "ourTeam" && renderOurTeam()}
        {category === "ourPartners" && renderOutPartner()}
        {category === "ourClients" && renderClient()}
        <div id="circle"></div>
      </WhoWeAreContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.WHO_WE_ARE}
      loading={isLoading || isRequestLoading || isRequestTeamMebers}
      headerTitle="Who we are"
      metaDescription="IDRA Research & Consulting is a leading Albanian company which provides Insights into why things happen"
    />
  );
};

export default WhoWeAre;
