/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { PopupWrapper, HeaderPopup } from '../DefaultPopup.styles';
import { CarrotSolidButton, AdminDropdown, InputItalicPlaceholder, GeyserSolidButton } from '../../../Common';
import { OrderPopupContainer, FilterBar, FilterLabel, TableContent, ButtonBar } from './OrderPopup.styles';
import { SelectOrderTable } from '../../CommonLayoutPart/Table';
import { ArticlesActions } from '../../../../actions';
import { DropdownOptions } from '../../../../constants';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  hidePopup(value?: string): unknown;
  className?: string;
  locale?: string;
}

interface FilterStructure {
  keyword: string;
  topic: string;
}

const { getArticlesUnordered } = ArticlesActions;

const defaultFilter = {
  topic: '',
  keyword: '',
};

const OrderPopup = (props: SectionProps) => {
  const { t } = useTranslation('admin');
  const { hidePopup, className, locale } = props;
  const unorderedArticles = useSelector(state => _.get(state, 'Articles.payloadUnordered'));
  const [filter, setFilter] = useState<FilterStructure>(defaultFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticlesUnordered({ ...filter, locale }));
  }, []);

  const handleClosePopup = (e: any) => {
    const isPopupWrapper = _.get(e.target, 'classList').contains('popup-wrapper');
    if (isPopupWrapper && hidePopup) hidePopup();
  };

  const handleAddNews = (item: any, key: string) => {
    if (key === 'add' && hidePopup && item) hidePopup(item);
  };

  const onReset = async () => {
    await dispatch(getArticlesUnordered({ ...defaultFilter, locale }));
    setFilter(defaultFilter);
  };

  const renderFilterBar = () => {
    return (
      <FilterBar>
        <FilterLabel className="mr-1">{t('label.filterBy')}</FilterLabel>
        <AdminDropdown
          width="25rem"
          data={DropdownOptions.NEWS_TOPIC}
          selectedValue={_.get(filter, 'topic')}
          onChange={(value: any) =>
            setFilter({
              ...filter,
              topic: value,
            })
          }
          className="mr-1"
          background="white"
        />
        <InputItalicPlaceholder
          background="white"
          width="300px"
          placeholder={t('placeholder.filterByTitle')}
          className="mr-1"
          value={_.get(filter, 'keyword')}
          onChange={(e: any) =>
            setFilter({
              ...filter,
              keyword: e.target.value,
            })
          }
        />
        <CarrotSolidButton className="mr-1" onClick={() => dispatch(getArticlesUnordered({ ...filter, locale }))}>
          {t('button.search')}
        </CarrotSolidButton>
        <GeyserSolidButton onClick={() => onReset()}>{t('button.reset')}</GeyserSolidButton>
      </FilterBar>
    );
  };

  const renderTable = () => {
    return (
      <SelectOrderTable
        fragment="2fr 1fr 1fr 0.5fr"
        data={unorderedArticles}
        onClick={(item: any, key: string) => handleAddNews(item, key)}
      />
    );
  };

  const renderButtonBar = () => {
    return (
      <ButtonBar className="flex-justify-end flex-align-center">
        <CarrotSolidButton onClick={() => hidePopup && hidePopup()}>{t('button.close')}</CarrotSolidButton>
      </ButtonBar>
    );
  };

  const renderMain = () => {
    return (
      <OrderPopupContainer>
        <HeaderPopup>{t('headerPopup.reOrderArticles')}</HeaderPopup>
        {renderFilterBar()}
        <TableContent>{renderTable()}</TableContent>
        {renderButtonBar()}
      </OrderPopupContainer>
    );
  };

  return (
    <PopupWrapper className={`popup-wrapper ${className}`} onClick={(e: any) => handleClosePopup(e)}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default OrderPopup;
