import React, { useEffect, useRef, useState } from 'react';
import { DropdownGroup, DropdownItem, FilterItem } from './FilterDropdown.styles';
import _ from 'lodash';
import { FlexHorizontal } from '../FlexLayout';

interface SectionProps {
  options: any[];
  onChange(value: any): void;
  selectedValue: any;
  placeholder: string;
}

const FilterDropdown = (props: SectionProps) => {
  const { options, onChange, selectedValue, placeholder } = props;

  const useOutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsShowDropdown(false));

  const generateLabel = () => {
    const findItem = _.find(options, { value: selectedValue });
    return findItem?.label;
  };

  return (
    <FilterItem
      onClick={() => setIsShowDropdown(!isShowDropdown)}
      ref={ref}
      flex="1"
      className={`${isShowDropdown ? 'show' : ''}`}
    >
      <FlexHorizontal height="45px" alignCenter justifySpaceBetween>
        <p>{generateLabel() || placeholder}</p>
        <i className="fas fa-sort-down" />
      </FlexHorizontal>
      <DropdownGroup className={`${isShowDropdown ? 'show' : ''} from_year`}>
        <div>
          {_.map(options, (item, index) => {
            return (
              <DropdownItem
                onClick={() => {
                  onChange && onChange(item?.value);
                  setIsShowDropdown(false);
                }}
                key={`dropdown-from-${index}`}
              >
                {item.label}
              </DropdownItem>
            );
          })}
        </div>
      </DropdownGroup>
    </FilterItem>
  );
};

export default FilterDropdown;
