import styled from 'styled-components';
import { ColorName } from '../../../Variables';
import { FlexVertical, FlexHorizontal } from '../../../Common';

const OrderPopupContainer = styled(FlexVertical)`
  width: 100%;
  max-width: 810px;
  height: auto;
  min-height: 550px;
  max-height: 750px;
  background: ${ColorName.white};
  border-radius: 5px;
  overflow: hidden;

  h3 {
    font-size: 1.8rem;
    color: ${ColorName.darkElectricBlue};
  }
`;

const FilterBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
`;

const FilterLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${ColorName.darkElectricBlue};
`;

const TableContent = styled.div`
  width: 100%;
  height: 420px;
  overflow-y: auto;
  background: ${ColorName.white};
  padding: 34px 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
`;

const ButtonBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  padding: 0 24px;
  align-items: center;
  box-sizing: border-box;
  background: ${ColorName.aquaHaze};
`;

const TabBar = styled(FlexHorizontal)``;
const TabBarItem = styled(FlexHorizontal)`
  height: 44px;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: ${ColorName.geyser};
  color: ${ColorName.darkElectricBlue};
  /* transition: all ease 0.15s; */
  font-size: 14px;
  &:hover,
  &.active {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

const NewsCard = styled.div`
  display: flex;
  transition: all ease 0.25s;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 215px;

  .thumbnail {
    position: relative;
    width: 140px;
    height: 200px;
    overflow: hidden;
    transition: all ease 0.25s;
    background: white;
    img {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: top left;
    }
  }
  p {
    font-size: 1.5rem;
    line-height: 21px;
    font-weight: 600;
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
    border-color: ${ColorName.william};
  }
`;

export { OrderPopupContainer, FilterBar, FilterLabel, TableContent, ButtonBar, TabBar, TabBarItem, NewsCard };
