import React from 'react';
import _ from 'lodash';
import { TableContainer, TableContent } from './Table.styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  onClick(item: any, key: string): unknown;
  className?: string;
  data?: any[];
  column?: any[];
  fragment?: string;
}

const COLUMNS = [
  {
    label: 'title',
    value: 'title',
    isCenter: false,
    isSort: true,
  },
  {
    label: 'category',
    value: 'topic',
    isCenter: false,
    isSort: true,
  },
  {
    label: 'status',
    value: 'status',
    isCenter: true,
  },
  {
    label: '',
    value: 'add',
    isCenter: true,
  },
];

const DATA_TOPIC = [
  {
    label: 'socioEconomicDevelopment',
    value: 'socioEconomicDevelopment',
  },
  {
    label: 'governanceAndRuleofLaw',
    value: 'governanceAndRuleOfLaw',
  },
  {
    label: 'urbanPlanningAndGis',
    value: 'urbanPlanningAndGis',
  },
  {
    label: 'environment',
    value: 'environment',
  },
  {
    label: 'monitoringAndEvaluationServices',
    value: 'monitoringAndEvaluationServices',
  },
  {
    label: 'marketResearch',
    value: 'marketResearch',
  },
  {
    label: 'opinionPolls',
    value: 'opinionPolls',
  },
  {
    label: 'economicResearch',
    value: 'economicResearch',
  },
  {
    label: 'mediaResearch',
    value: 'mediaResearch',
  },
  {
    label: 'advertisingResearch',
    value: 'advertisingResearch',
  },
  {
    label: 'omnibus',
    value: 'omnibus',
  },
];

const Table: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation('admin');
  // Declare props
  const lang = useSelector(state => _.get(state, 'PageSettings.adminLang'));
  const { onClick, className, data, fragment } = props;
  // Declare dispatch, reducers

  const handleClick = (value: any, key: string) => {
    if (onClick) onClick(value, key);
  };

  const renderHeader = () => {
    const render = _.map(COLUMNS, (item, index) => {
      const { isCenter, isSort } = item;
      const centerClass = isCenter ? 'flex-justify-center' : 'flex-justify-between';
      return (
        <span key={`header-${index}`} className={`header ${centerClass}`}>
          {item.label ? t(`table.${item.label}`) : ''}
          {isSort ? <i className="fas fa-caret-down ml-2" onClick={() => handleClick(item.value, 'sort')} /> : null}
        </span>
      );
    });
    return render;
  };

  const renderData = () => {
    const render = _.map(data, (item, index) => {
      const innerRender = _.map(COLUMNS, (cell, innerIndex) => {
        const { isCenter } = cell;
        const centerClass = isCenter ? 'flex-justify-center' : 'flex-justify-between';
        if (_.isObject(_.get(item, cell.value))) {
          return (
            <div className={`content ${centerClass}`} key={`row-${index}-${innerIndex}`}>
              <span>{_.get(item, `${cell.value}.${lang}`)}</span>
            </div>
          );
        }
        if (cell.value === 'topic') {
          const findLabel = _.find(DATA_TOPIC, {
            value: _.get(item, cell.value),
          });
          return (
            <div className={`content ${centerClass}`} key={`row-${index}-${innerIndex}`}>
              <span>{_.get(findLabel, 'label') ? t(`table.${_.get(findLabel, 'label')}`) : 'N/I'}</span>
            </div>
          );
        }
        if (cell.value === 'status') {
          const value = _.get(item, cell.value);
          const labelStatus = value === 'active' || value === 1 ? t('table.active') : t('table.inactive');
          const statusClass = value === 'active' || value === 1 ? 'active' : 'inactive';
          return (
            <div className={`content ${centerClass} ${statusClass}`} key={`row-${index}-${innerIndex}`}>
              <span>{labelStatus}</span>
            </div>
          );
        }
        if (cell.value === 'add') {
          return (
            <div
              onClick={() => handleClick(item, 'add')}
              className={`content ${centerClass} add`}
              key={`row-${index}-${innerIndex}`}
            >
              <span>
                <i className="far fa-plus-square" />
                {t(`button.add`)}
              </span>
            </div>
          );
        }
        return (
          <div className={`content ${centerClass}`} key={`row-${index}-${innerIndex}`}>
            <span>{_.get(item, cell.value)}</span>
          </div>
        );
      });
      return innerRender;
    });
    return render;
  };

  return (
    <TableContainer className={className} minWidth="720px">
      <TableContent fragment={fragment}>
        {renderHeader()}
        {renderData()}
        {_.isEmpty(data) && (
          <>
            <span className="content no-result">{t('table.noResultFound')}</span>
          </>
        )}
      </TableContent>
    </TableContainer>
  );
};

export default Table;
