import React, { useState, useEffect } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import history from "../../../history";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";

import {
  SelectDropdown,
  UserCarrotButton,
  FlexHorizontal,
  PageContainer,
  SubTitle,
  FlexVertical,
  UserMonthPicker,
  Content,
  UserInput,
  UserCheckbox,
  UserMultipleDropdown,
  SectionTitle,
} from "../../../components/Common";
import { Utils, statusAlert, useMediaQuery } from "../../../libs";
import { AlumniNetworkActions } from "../../../actions";
import {
  DropdownOptions,
  ROUTES,
  COUNTRY_OPTIONS,
  ANIMATION,
} from "../../../constants";
import { IAlumniNetwork } from "../../../interfaces/AlumniNetwork.interfaces";
import { Icons } from "../../../themes";
import {
  LabelInput,
  InputForm,
  SpinLoader,
  PrevButton,
  InputField,
  Input,
} from "./AlumniNetworkRegister.styles";

interface IOption {
  label: string;
  value: string;
}

const { createAlumniNetwork } = AlumniNetworkActions;

const DEFAULT_DETAILS = {
  firstName: "",
  lastName: "",
  country: "",
  mobile: "",
  email: "",
  isEmployee: false,
  isExpert: false,
  linkedInLink: "",
  hearAbout: {
    idraStaff: false,
    idraWebsite: false,
    socialMediaPost: false,
    throughEmail: false,
  },
  interestsInJoining: {
    businessOpportunities: false,
    employmentOpportunities: false,
    adviceAndInformation: false,
    toConnectWithFormerColleagues: false,
  },
  extract: {
    employeeStartDate: "",
    employeeEndDate: "",
    employeePosition: "",
  },
  socialMedia: "",
  experts: [],
  actualCompany: "",
  actualPosition: "",
  expertOtherValue: "",
};

interface ICountryOption {
  label: string;
  value: string;
  region?: string;
}

// eslint-disable-next-line no-useless-escape
const regexCheckEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const regexCheckMobile = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const regexCheckMonthYear = /^(0[1-9]|1[0-2])\/\d{4}$/;

const AlumniaNetworkRegister = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const requestIsLoading = useSelector((state) =>
    _.get(state, "AlumniNetwork.requestIsLoading")
  );
  const [details, setDetails] = useState<IAlumniNetwork>(DEFAULT_DETAILS);
  const [countryOptions, setCountryOptions] = useState<ICountryOption[]>([]);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const locale = Utils.getUserLocale();

  useEffect(() => {
    const resolveOptions = _.map(COUNTRY_OPTIONS, (country) => {
      return {
        label: country.name,
        value: country.code,
        dial_code: country.dial_code,
      };
    });
    setCountryOptions(resolveOptions);
  }, []);

  const onChangeHandler = (
    value: string | boolean,
    key: string,
    subKey?: string
  ) => {
    setDetails((prevDetails: IAlumniNetwork) => ({
      ...prevDetails,
      [key]: subKey
        ? {
            ...prevDetails[key],
            [subKey]: value,
          }
        : value,
    }));
  };

  const renderAreaCode = () => {
    const findDialCode = _.find(
      countryOptions,
      (option) => option.value === details.country
    );

    if (findDialCode) {
      return _.get(findDialCode, "dial_code");
    }
    return "N/I";
  };

  const handleSubmit = async () => {
    const dataCheck = [
      {
        value: _.trim(_.get(details, "firstName")),
        label: t("label.firstName"),
      },
      {
        value: _.trim(_.get(details, "lastName")),
        label: t("label.lastName"),
      },
      {
        value: _.trim(_.get(details, "country")),
        label: t("label.country"),
      },
      {
        value: _.trim(_.get(details, "mobile")),
        label: t("label.phoneNumber"),
      },
      {
        value: _.trim(_.get(details, "email")),
        label: t("label.email"),
      },
      {
        value: _.trim(_.get(details, "actualCompany")),
        label: t("label.company"),
      },
      {
        value: _.trim(_.get(details, "actualPosition")),
        label: t("label.position"),
      },
      {
        value: _.get(details, "hearAbout"),
        label: t("label.hearAboutQuestion"),
      },
      {
        value: _.get(details, "interestsInJoining"),
        label: t("label.interestsInJoiningQuestion"),
      },
    ];

    if (
      details.experts &&
      _.some(details.experts, (expert) => expert.value === "other")
    )
      dataCheck.push({
        value: _.get(details, "expertOtherValue") || "",
        label: t("label.yourParticularExpertise"),
      });

    if (details.isEmployee)
      dataCheck.push(
        {
          value: _.get(details, "extract.employeePosition") || "",
          label: t("label.employeePosition"),
        },
        {
          value: _.get(details, "extract.employeeStartDate") || "",
          label: t("label.employeeStartDate"),
        },
        {
          value: _.get(details, "extract.employeeEndDate") || "",
          label: t("label.employeeEndDate"),
        }
      );

    if (details.isExpert)
      dataCheck.push({
        value: _.size(_.get(details, "experts")) > 0 ? "expert" : "",
        label: t("label.expert"),
      });

    let emptyFields = "";
    let wrongFields = "";
    let flag = true;
    if (!_.get(details, "isEmployee") && !_.get(details, "isExpert")) {
      emptyFields = emptyFields + `\n${t("label.commitmentInIdra")}`;
    }

    _.forEach(dataCheck, (item) => {
      const value = _.get(item, "value");
      if ((_.isString(value) || _.isBoolean(value)) && !value) {
        emptyFields = emptyFields + `\n${item.label}`;
      }
      if (_.isObject(value) && !_.some(value, (v) => v)) {
        emptyFields = emptyFields + `\n${item.label}`;
      }
    });

    if (
      _.get(details, "hearAbout.socialMediaPost") &&
      !_.get(details, "socialMedia")
    ) {
      emptyFields = emptyFields + `\n${t("label.socialMedia")}`;
    }

    if (details.email && !regexCheckEmail.test(details.email))
      wrongFields = wrongFields + `\n${t("alert.emailInvalidFormat")}`;

    if (
      details.mobile &&
      (!regexCheckMobile.test("0" + details.mobile) &&
        !regexCheckMobile.test(details.mobile))
    ) {
      wrongFields = wrongFields + `\n${t("alert.phoneNumberInvalidFormat")}`;
    }

    // validate startDate & endDate
    if (details.extract.employeeStartDate && details.extract.employeeEndDate) {
      const isValidStartDate = regexCheckMonthYear.test(
        details.extract.employeeStartDate
      );
      const isValidEndDate = regexCheckMonthYear.test(
        details.extract.employeeEndDate
      );

      if (!isValidStartDate) {
        wrongFields = wrongFields + `\n${t("alert.startDateInvalidFormat")}`;
      }

      if (!isValidEndDate) {
        wrongFields = wrongFields + `\n${t("alert.endDateInvalidFormat")}`;
      }

      if (isValidStartDate && isValidEndDate) {
        const startDate = new Date(
          details.extract.employeeStartDate.split("/").reverse().join("-")
        );
        const endDate = new Date(
          details.extract.employeeEndDate.split("/").reverse().join("-")
        );
        if (startDate > endDate)
          wrongFields = wrongFields + `\n${t("alert.startDateThanEndDate")}`;
      }
    }
    // check error
    if (!_.isEmpty(emptyFields) || !_.isEmpty(wrongFields)) flag = false;
    if (!flag)
      return await statusAlert(
        "warning",
        `${t("alert.foundEmptyFields")} : ${emptyFields} \n ${
          wrongFields ? `${t("alert.foundWrongTypes")} ${wrongFields}` : ""
        }`
      );
    else {
      const resolveDetails = _.cloneDeep(details);
      if (details.isEmployee) resolveDetails.experts = [];
      if (details.isExpert) {
        resolveDetails.extract.employeeStartDate = "";
        resolveDetails.extract.employeeEndDate = "";
        resolveDetails.extract.employeePosition = "";
      }
      resolveDetails.experts =
        resolveDetails.experts &&
        _.map(resolveDetails.experts, (expert) => expert.value);
      dispatch(createAlumniNetwork({ ...resolveDetails, status: "open" }));
    }
  };

  const socialMediaOptions = React.useMemo(() => {
    const options: IOption[] = [];
    _.forEach(DropdownOptions.ALUMNI_NETWORK.social, (item: IOption) =>
      options.push({
        label: t(`dropdown.${item.label}`),
        value: item.value,
      })
    );
    return options;
  }, [t]);

  const expertiseOptions = React.useMemo(() => {
    const options: IOption[] = [];
    _.forEach(DropdownOptions.ALUMNI_NETWORK.expertise, (item: IOption) =>
      options.push({
        label: t(`dropdown.${item.label}`),
        value: item.value,
      })
    );
    return options;
  }, [t]);

  const _renderFormData = () => {
    return (
      <>
        <motion.div variants={ANIMATION.item}>
          <FlexVertical>
            <SubTitle>{t("label.personalDetailsContact")}</SubTitle>
            <InputForm
              alignCenter
              className="mt-1"
              style={{ gap: isMobile ? 8 : 32 }}
            >
              <FlexVertical alignFlexStart flex="1">
                <LabelInput>{t("label.firstName")}</LabelInput>
                <UserInput
                  value={details.firstName}
                  onChange={(event: any) =>
                    onChangeHandler(event.target.value, "firstName")
                  }
                  placeholder={t("placeholder.firstName")}
                />
              </FlexVertical>
              <FlexVertical alignFlexStart flex="1">
                <LabelInput>{t("label.lastName")}</LabelInput>
                <UserInput
                  flex="1"
                  value={details.lastName}
                  onChange={(event: any) =>
                    onChangeHandler(event.target.value, "lastName")
                  }
                  placeholder={t("placeholder.lastName")}
                />
              </FlexVertical>
            </InputForm>
            <InputForm
              style={{ gap: isMobile ? 8 : 32 }}
              alignCenter
              className="mt-1"
            >
              <FlexVertical flex="1">
                <LabelInput>{t("label.country")}</LabelInput>
                <SelectDropdown
                  options={countryOptions}
                  value={details.country}
                  onChange={(value: any) => {
                    onChangeHandler(value, "country");
                  }}
                  className="alumni-dropdown"
                />
              </FlexVertical>
              <FlexVertical flex="1">
                <LabelInput>{t("label.phoneNumber")}</LabelInput>
                <InputField>
                  <span>({renderAreaCode()})</span>
                  <Input
                    value={details.mobile}
                    onChange={(e: any) =>
                      onChangeHandler(e.target.value, "mobile")
                    }
                    type="tel"
                    placeholder="123-456-7890"
                  />
                </InputField>
              </FlexVertical>
            </InputForm>
            <InputForm
              style={{ gap: isMobile ? 8 : 32 }}
              alignCenter
              className="mt-1"
            >
              <FlexVertical flex="1">
                <LabelInput>{t("label.email")}</LabelInput>
                <UserInput
                  value={details.email}
                  onChange={(event: any) =>
                    onChangeHandler(event.target.value, "email")
                  }
                  placeholder={t("placeholder.email")}
                />
              </FlexVertical>
              <FlexVertical flex="1">
                <LabelInput>
                  {t("label.linkedIn")} ({t("label.optional")})
                </LabelInput>
                <UserInput
                  value={details.linkedInLink}
                  onChange={(event: any) =>
                    onChangeHandler(event.target.value, "linkedInLink")
                  }
                  placeholder={t("placeholder.linkedIn")}
                />
              </FlexVertical>
            </InputForm>
          </FlexVertical>
        </motion.div>

        <motion.div variants={ANIMATION.item}>
          <FlexVertical className="mt-2">
            <SubTitle className="mb-2">{t("label.commitmentInIdra")}</SubTitle>
            <FlexVertical flex="1">
              <UserCheckbox
                value=""
                key="employee"
                label={t("label.employee")}
                checked={details.isEmployee}
                onChange={() =>
                  setDetails({
                    ...details,
                    isEmployee: !details.isEmployee,
                    isExpert: false,
                    // expert: "",
                  })
                }
                className="mb-1"
              />
              {details.isEmployee && (
                <motion.div variants={ANIMATION.item}>
                  <FlexHorizontal className="mb-1">
                    <InputForm style={{ gap: isMobile ? 8 : 32 }}>
                      <FlexVertical flex="1">
                        <LabelInput style={{ marginLeft: "26px" }}>
                          {t("label.latestPosition")}
                        </LabelInput>
                      </FlexVertical>
                      <FlexVertical flex="1">
                        <UserInput
                          value={details.extract.employeePosition}
                          placeholder={t("placeholder.latestPosition")}
                          onChange={(e) =>
                            onChangeHandler(
                              e.target.value,
                              "extract",
                              "employeePosition"
                            )
                          }
                        />
                      </FlexVertical>
                    </InputForm>
                  </FlexHorizontal>
                  <FlexHorizontal className="mb-1">
                    <InputForm style={{ gap: isMobile ? 8 : 32 }}>
                      <FlexVertical flex="1">
                        <LabelInput
                          style={{
                            marginLeft: "26px",
                          }}
                        >
                          {t("label.periodOfEmployment")}
                        </LabelInput>
                      </FlexVertical>
                      <FlexVertical flex="1">
                        <FlexHorizontal
                          alignCenter
                          style={{
                            gap: 30,
                            flexDirection:
                              isMobile || locale === "al" ? "column" : "row",
                            width: isMobile ? `calc(100% -  26px)` : "100%",
                            marginLeft: isMobile ? "26px" : 0,
                          }}
                        >
                          <FlexVertical flex="1">
                            <UserMonthPicker
                              label={t("label.startDate")}
                              value={details.extract.employeeStartDate}
                              onChange={(newValue) =>
                                onChangeHandler(
                                  newValue,
                                  "extract",
                                  "employeeStartDate"
                                )
                              }
                              locale={locale}
                              isMobile={isMobile}
                            />
                          </FlexVertical>
                          <FlexVertical flex="1">
                            <UserMonthPicker
                              label={t("label.endDate")}
                              value={details.extract.employeeEndDate}
                              onChange={(newValue) =>
                                onChangeHandler(
                                  newValue,
                                  "extract",
                                  "employeeEndDate"
                                )
                              }
                              locale={locale}
                              isMobile={isMobile}
                            />
                          </FlexVertical>
                        </FlexHorizontal>
                      </FlexVertical>
                    </InputForm>
                  </FlexHorizontal>
                </motion.div>
              )}
            </FlexVertical>
            <FlexHorizontal
              alignCenter
              style={{
                gap: isMobile ? 8 : 32,
                flexDirection: isMobile ? "column" : "row",
                minHeight: "42px",
              }}
            >
              <FlexVertical flex="1">
                <UserCheckbox
                  label={t("label.expert")}
                  value=""
                  onChange={() =>
                    setDetails({
                      ...details,
                      isExpert: !details.isExpert,
                      isEmployee: false,
                      // extract: {
                      //   employeePosition: "",
                      //   employeeStartDate: "",
                      //   employeeEndDate: "",
                      // },
                    })
                  }
                  checked={details.isExpert}
                />
              </FlexVertical>
              {details.isExpert && (
                <motion.div
                  variants={ANIMATION.item}
                  style={{
                    flex: "1",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <UserMultipleDropdown
                    className="alumni-dropdown"
                    options={expertiseOptions}
                    value={details?.experts}
                    onChange={(value: IOption[]) => {
                      if (value && _.some(value, (v) => v.value === "other"))
                        setDetails({ ...details, expertOtherValue: "" });
                      setDetails({ ...details, experts: value });
                    }}
                    placeholder={t("placeholder.selectExpert")}
                    isMultiple
                  />
                  {details.experts &&
                    _.some(
                      details.experts,
                      (expert) => expert.value === "other"
                    ) && (
                      <UserInput
                        className="mt-2"
                        value={details.expertOtherValue}
                        onChange={(event: any) =>
                          onChangeHandler(
                            event.target.value,
                            "expertOtherValue"
                          )
                        }
                        placeholder={t("placeholder.otherExpert")}
                      />
                    )}
                </motion.div>
              )}
            </FlexHorizontal>
          </FlexVertical>
        </motion.div>

        <motion.div variants={ANIMATION.item}>
          <FlexVertical className="mt-2">
            <SubTitle>{t("label.whereAreYouNow")}</SubTitle>
            <FlexVertical className="mt-1">
              <FlexVertical alignFlexStart flex="1">
                <LabelInput>{t("label.company")}</LabelInput>
                <UserInput
                  value={details.actualCompany}
                  placeholder={t("placeholder.company")}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "actualCompany")
                  }
                />
              </FlexVertical>
              <FlexVertical alignFlexStart flex="1" className="mt-2">
                <LabelInput>{t("label.position")}</LabelInput>
                <UserInput
                  value={details.actualPosition}
                  placeholder={t("placeholder.position")}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "actualPosition")
                  }
                />
              </FlexVertical>
            </FlexVertical>
          </FlexVertical>
        </motion.div>

        <motion.div variants={ANIMATION.item}>
          <FlexVertical className="mt-2">
            <LabelInput>{t("label.hearAboutQuestion")}</LabelInput>
            <FlexVertical>
              <UserCheckbox
                value=""
                key="idraStaff"
                label={t("label.idraStaff")}
                checked={details.hearAbout?.idraStaff}
                onChange={() =>
                  setDetails({
                    ...details,
                    socialMedia: "",
                    hearAbout: {
                      ...details.hearAbout,
                      idraStaff: !details.hearAbout?.idraStaff,
                      idraWebsite: false,
                      socialMediaPost: false,
                      throughEmail: false,
                    },
                  })
                }
                style={{ minHeight: "42px" }}
              />
              <UserCheckbox
                value=""
                key="idraWebsite"
                label={t("label.idraWebsite")}
                checked={details?.hearAbout?.idraWebsite}
                onChange={() =>
                  setDetails({
                    ...details,
                    socialMedia: "",
                    hearAbout: {
                      ...details.hearAbout,
                      idraWebsite: !details.hearAbout?.idraWebsite,
                      idraStaff: false,
                      socialMediaPost: false,
                      throughEmail: false,
                    },
                  })
                }
                // className="mb-2"
                style={{ minHeight: "42px" }}
              />
              <FlexHorizontal alignCenter style={{ minHeight: "42px" }}>
                <UserCheckbox
                  value=""
                  key="socialMedia"
                  label={t("label.socialMedia")}
                  checked={!!details.hearAbout?.socialMediaPost}
                  onChange={() =>
                    setDetails({
                      ...details,
                      hearAbout: {
                        ...details.hearAbout,
                        socialMediaPost: !details.hearAbout?.socialMediaPost,
                        idraStaff: false,
                        idraWebsite: false,
                        throughEmail: false,
                      },
                    })
                  }
                  // className="mb-2"
                />
                {details.hearAbout?.socialMediaPost && (
                  <motion.div variants={ANIMATION.item} className="ml-2">
                    <SelectDropdown
                      options={socialMediaOptions}
                      value={details.socialMedia}
                      onChange={(value: any) =>
                        onChangeHandler(value, "socialMedia")
                      }
                    />
                  </motion.div>
                )}
              </FlexHorizontal>
              <UserCheckbox
                value=""
                key="throughEmail"
                label={t("label.throughEmail")}
                checked={!!details.hearAbout?.throughEmail}
                onChange={() =>
                  setDetails({
                    ...details,
                    socialMedia: "",
                    hearAbout: {
                      ...details.hearAbout,
                      throughEmail: !details.hearAbout?.throughEmail,
                      idraStaff: false,
                      idraWebsite: false,
                      socialMediaPost: false,
                    },
                  })
                }
                style={{ minHeight: "42px" }}
              />
            </FlexVertical>
          </FlexVertical>
        </motion.div>

        <motion.div variants={ANIMATION.item}>
          <FlexVertical className="mt-2">
            <LabelInput>{t("label.interestsInJoiningQuestion")}</LabelInput>
            <FlexVertical>
              <UserCheckbox
                value=""
                key="businessOpportunities"
                label={t("label.businessOpportunities")}
                checked={!!details.interestsInJoining?.businessOpportunities}
                onChange={() =>
                  onChangeHandler(
                    !details.interestsInJoining?.businessOpportunities,
                    "interestsInJoining",
                    "businessOpportunities"
                  )
                }
                style={{ minHeight: "42px" }}
              />
              <UserCheckbox
                value=""
                key="employmentPpportunities"
                label={t("label.employmentPpportunities")}
                checked={!!details.interestsInJoining?.employmentOpportunities}
                onChange={() =>
                  onChangeHandler(
                    !details.interestsInJoining?.employmentOpportunities,
                    "interestsInJoining",
                    "employmentOpportunities"
                  )
                }
                style={{ minHeight: "42px" }}
              />
              <UserCheckbox
                value=""
                key="adviceAndInformation"
                label={t("label.adviceAndInformation")}
                checked={!!details.interestsInJoining?.adviceAndInformation}
                onChange={() =>
                  onChangeHandler(
                    !details.interestsInJoining?.adviceAndInformation,
                    "interestsInJoining",
                    "adviceAndInformation"
                  )
                }
                style={{ minHeight: "42px" }}
              />
              <UserCheckbox
                value=""
                key="toConnectWithFormerColleagues"
                label={t("label.toConnectWithFormerColleagues")}
                checked={
                  !!details.interestsInJoining?.toConnectWithFormerColleagues
                }
                onChange={() =>
                  onChangeHandler(
                    !details.interestsInJoining?.toConnectWithFormerColleagues,
                    "interestsInJoining",
                    "toConnectWithFormerColleagues"
                  )
                }
                style={{ minHeight: "42px" }}
              />
            </FlexVertical>
          </FlexVertical>
        </motion.div>
      </>
    );
  };

  const _renderButtonGroup = () => {
    return (
      <motion.div variants={ANIMATION.item} className="mt-3">
        <FlexHorizontal justifyFlexEnd alignCenter>
          <PrevButton
            className="mr-2"
            onClick={() => history.push(ROUTES.USER.ALUMNI_NETWORK)}
          >
            <img
              src={Icons.leftArrow.default}
              alt="left arrow"
              className="mr-1"
            />
            {t("button.back")}
          </PrevButton>
          <FlexVertical width="unset" position="relative">
            <UserCarrotButton
              onClick={handleSubmit}
              disabled={requestIsLoading}
              className={`${requestIsLoading ? "disabled" : ""}`}
            >
              {t("button.submit")}
            </UserCarrotButton>
            {requestIsLoading && <SpinLoader />}
          </FlexVertical>
        </FlexHorizontal>
      </motion.div>
    );
  };

  const renderMain = () => {
    return (
      <PageContainer>
        <Content className="pb-5 pt-5" direction="column">
          <motion.div
            variants={ANIMATION.container}
            initial="hidden"
            animate="visible"
          >
            <motion.div variants={ANIMATION.item}>
              <SectionTitle fontSize="2.6rem" className="center mb-3">
                {t("titlePage.alumniNetworkRegister")}
              </SectionTitle>
            </motion.div>
            {_renderFormData()}
            {_renderButtonGroup()}
          </motion.div>
        </Content>
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.ALUMNI_NETWORK_REGISTER}
      headerTitle={t("titlePage.alumniNetworkRegister")}
      loading={requestIsLoading}
      metaDescription="IDRA has started it’s first Alumni Network to bring together all past and present alumni"
    />
  );
};

export default AlumniaNetworkRegister;
