import styled from 'styled-components';
import { VerticalContainer } from '../../../../components/Common';
import { ColorName } from '../../../../components/Variables';
import { ContentEditableWrapper } from '../ContentEditableDetails.styles';

const EditContainerPageContainer = styled(ContentEditableWrapper)``;

const StepForm = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;
const StepLabel = styled.p``;

const TitleStep = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  line-height: 58px;
  color: ${ColorName.black};
`;

const DescriptionStep = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: ${ColorName.tundora};
`;

const OptionBox = styled(VerticalContainer)`
  border-radius: 10px;
  transition: all ease 0.15s;
  width: 100%;
  padding-top: 100%;
  position: relative;
  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 22px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      margin-top: 15px;
      color: ${ColorName.black};
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.005em;
      margin-top: 5px;
      color: ${ColorName.grannySmith};
    }
  }
`;

export { EditContainerPageContainer, StepForm, StepLabel, TitleStep, DescriptionStep, OptionBox };
