import styled from 'styled-components';
import { ColorName } from '../../../components/Variables';
import { HorizontalContainer, VerticalContainer, ContentContainer } from '../../../components/Common';

const ManageOrderArticlesContainer = styled(ContentContainer)`
  padding: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  .ck-rounded-corners {
    width: 100%;
    max-width: 520px;
    box-sizing: border-box;
  }
  .ck-editor__editable {
    height: auto;
    min-height: 100px;
  }
  .editable {
    & > {
    }
    padding: 3px 5px;
    /* margin-bottom: 10px; */
    border: 2px solid transparent;
    border-radius: 5px;
    outline: none;
    &:hover {
      border: 2px solid ${ColorName.geyser};
      cursor: text;
    }
    &:focus {
      border: 2px solid ${ColorName.darkElectricBlue};
    }
  }
  .editable:empty:before {
    content: attr(data-placeholder);
  }
  .editable:empty:focus:before {
    content: '';
  }
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${ColorName.darkElectricBlue};
  margin-right: 15px;
  width: 85px;
`;

const ContentEditableContainer = styled(VerticalContainer)``;

const AddMoreContent = styled(HorizontalContainer)`
  width: 100%;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
  border: 1px solid ${ColorName.darkElectricBlue};
  border-radius: 5px;
  height: 35px;

  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

const BoxContent = styled(VerticalContainer)``;

const ContentEditableWrapper = styled.div`
  height: auto;
  padding: 20px 60px;
  max-width: 1440px;
`;

const ScrollContent = styled.div`
  width: 100%;
  height: calc(100vh - 137px);
  overflow: auto;
`;

export {
  ManageOrderArticlesContainer,
  Label,
  ContentEditableContainer,
  AddMoreContent,
  BoxContent,
  ContentEditableWrapper,
  ScrollContent,
};
