import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkBoxContainer, NewsButton } from './WorkCard.styles';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import replace from 'lodash/replace';
import sanitizeHtml from 'sanitize-html';
interface DataStructure {
  id: string;
  thumbnailUrl: string;
  postedTime: string;
  title: string;
  brief_description: string;
  solutions:
    | {
        title: string;
      }[]
    | [];
}
interface SectionProps {
  data: DataStructure;
  onClick?(value: string): unknown;
  searchQuery: any;
  pathname: string;
}

const WorkCard: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation('user');
  const { onClick, data, searchQuery, pathname } = props;
  const { id, title, brief_description, solutions } = data;

  // Parse string to HTML
  const createMarkup = (html: any) => {
    return {
      __html: sanitizeHtml(html),
    };
  };

  // Decode wrong character
  const generateDescription = () => {
    const replaceSpecialCharacter = replace(brief_description, new RegExp('', 'g'), '―');
    const result: any = createMarkup(replaceSpecialCharacter);
    return result;
  };

  const renderHastag = () => {
    if (!solutions || isEmpty(solutions)) return null;
    const render = map(solutions, solution => {
      return <span key={`hastag-${get(solution, 'title')}`}>#{get(solution, 'title')}</span>;
    });
    return <div className="hastag">{render}</div>;
  };

  return (
    <WorkBoxContainer>
      <Link
        className="title"
        to={{
          pathname: `${pathname}/${id}`,
          state: { from: pathname, query: searchQuery },
        }}
      >
        {title}
      </Link>
      {renderHastag()}
      <p className="subtitle" dangerouslySetInnerHTML={generateDescription()} />
      <NewsButton className="mt-2" onClick={() => onClick && onClick(id)}>
        <div className="icon_box">
          <i className="fas fa-chevron-right"></i>
        </div>
        <p>{t('button.readMore')}</p>
      </NewsButton>
    </WorkBoxContainer>
  );
};

export default WorkCard;
