import React from 'react';
import map from 'lodash/map';
import { ContentCol, LoadingLayer } from './OurWork.styles';

const SkeletonLoading = () => {
  const layerLoading = [0, 1, 2, 3, 4];
  return (
    <ContentCol>
      {map(layerLoading, item => {
        return (
          <LoadingLayer key={`layer-loading-${item}`}>
            <div className="image" />
            <div className="flex-column">
              <div className="title" />
              <div className="description" />
              <div className="button" />
            </div>
          </LoadingLayer>
        );
      })}
    </ContentCol>
  );
};

export default SkeletonLoading;
