const ColorName = {
  alto: "#D5D5D5",
  aquaHaze: "#ECF5F5",
  azureishWhite: "#D6EEEE",
  bianca: "#FEFDFA",
  black: "#000000",
  blueBayoux: "#4e6c7a",
  bonJour: "#dddbdd",
  bridalHealth: "#FFFAF3",
  burntSienna: "#E87844",
  cararra: "#f2f1ed",
  casper: "#ACC3CE",
  darkElectricBlue: "#4E6C7A",
  deepCarrotOrange: "#E96F26",
  emerald: "#45C989",
  feijoa: "#A5DC86",
  froly: "#F27474",
  gainsboro: "#D2E3E6",
  geraldine: "#fb8883",
  geyser: "#D2E3E5",
  grannySmith: "#8D9A98",
  gray: "#808080",
  gullGray: "#9FB6BC",
  manhattan: "#F8BB86",
  mercury: "#E2E2E2",
  pampas: "#F2F0ED",
  pictonBlue: "#3FC3EE",
  raisinBlack: "#231F20",
  roseOfSharon: "#C34900",
  sail: "#9DE0F6",
  sandyBrown: "#F5A154",
  shamrock: "#3cd98d",
  shark: "#292C32",
  steelTeal: "#5C7D8C",
  submarine: "#b8c7c9",
  sweetCorn: "#F8D486",
  trinidad: "#DE530C",
  tundora: "#444444",
  white: "#ffffff",
  william: "#3C6379",
  vermilionBird: "#f44336", //color error
};

export default ColorName;
