import styled from "styled-components";
interface ISectionProps {
  disabled?: boolean;
}
const Container = styled.div`
  font-size: 12px;
  cursor: ${(props: ISectionProps) =>
    props.disabled ? "not-allowed" : "pointer"};
  border-radius: 5px;
  height: 100%;

  .select-prefix__control {
    color: #4e6c7a;
    background: #ecf5f5;
  }

  .select-prefix__value-container {
    min-height: 40px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    .select-prefix__multi-value {
      background: inherit;
      box-shadow: #4e6c7a 0px 2px 4px;
      .select-prefix__multi-value__label {
        color: #4e6c7a;
      }
    }
  }

  .select-prefix__option {
    color: #4e6c7a;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
  }
`;

export { Container };
