import styled from 'styled-components';
import { ColorName } from '../../../Variables';

const GalleryContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  max-height: 670px;
`;
const GalleryContent = styled.div`
  display: flex;
  overflow: hidden;
  height: 500px;
  background: ${ColorName.white};
`;

const GalleryContentItem = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  display: flex;
  overflow: auto;
`;

const SelectedMedia = styled.div`
  width: 150px;
  height: 100%;
  overflow: auto;
  padding: 15px 5px;
  box-sizing: border-box;

  & .media {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    &:hover {
      cursor: pointer;
      background: ${ColorName.casper};
    }
    img {
      height: 50px;
      width: auto;
      max-width: 120px;
      object-fit: contain;
    }
    span {
      margin-top: 10px;
      width: 100%;
      max-width: 120px;
      font-size: 1.4rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    & .index {
      background: rgba(0, 0, 0, 0.35);
      color: ${ColorName.white};
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .remove {
      background: ${ColorName.burntSienna};
      color: ${ColorName.white};
      font-weight: 500;
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const MediaBox = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  padding: 15px 30px;
  box-sizing: border-box;

  & .media {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    &:hover {
      cursor: pointer;
      background: ${ColorName.casper};
    }
    img {
      height: 50px;
      width: auto;
      max-width: 120px;
      object-fit: contain;
    }
    span {
      margin-top: 10px;
      width: 100%;
      max-width: 120px;
      font-size: 1.4rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    & .index {
      background: rgba(0, 0, 0, 0.35);
      color: ${ColorName.white};
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 2rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const GalleryOptions = styled.div`
  display: flex;
  padding: 0 15px;
  height: 32px;
  position: sticky;
  top: 0;
  background-color: ${ColorName.white};
`;

const GalleryOptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid transparent;
  span {
    font-size: 1.4rem;
  }
  img {
    margin-right: 5px;
  }

  &:hover {
    border-color: ${ColorName.black};
    cursor: pointer;
  }

  &.active {
    border-color: ${ColorName.black};
    span {
      font-weight: 500;
    }
  }
`;

export {
  GalleryContainer,
  GalleryContent,
  GalleryContentItem,
  SelectedMedia,
  MediaBox,
  GalleryOptions,
  GalleryOptionItem,
};
