import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PageSettingsActions } from '../../../actions';
import {
  IDRACardEditableContainer,
  CardContent,
  CardIcon,
  CardTitle,
  CardDescription,
} from './IDRACardEditable.styles';

interface DataStructure {
  title: {
    en: string;
    al: string;
  };
  description: {
    en: string;
    al: string;
  };
  icon: string;
}
interface SectionProps {
  data: DataStructure;
  onChange(value: DataStructure): unknown;
  keyCard?: string;
}

const { setStatusEdited, setContentEdited } = PageSettingsActions;

const IDRACardEditable = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation('admin');
  // Declare reducers, props
  const { data, onChange, keyCard } = props;
  const dispatch = useDispatch();
  const initialValue = useSelector(state => _.get(state, 'PageSettings.isEdited'));
  const isEditing = useSelector(state => _.get(state, 'PageSettings.contentEdited'));
  const lang = useSelector(state => _.get(state, 'EditContent.langEditing'));

  const handleChangeContent = (value: any, keyCard: string, defaultValue: string) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const newData = {
      ...data,
    };
    const newContent = {
      ...newData,
      [keyCard]: {
        ..._.get(newData, keyCard),
        [lang]: value,
      },
    };
    if (onChange) onChange(newContent);
  };

  return (
    <IDRACardEditableContainer key={`${keyCard}-${lang}`}>
      <CardContent>
        <CardIcon src={_.get(data, 'icon')} alt="card icon"/>
        <CardTitle
          className="editable"
          suppressContentEditableWarning
          contentEditable
          spellCheck={false}
          data-placeholder={t('placeholder.typeTheTitleHere')}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) =>
            handleChangeContent(e.currentTarget.innerText, 'title', _.trim(e.currentTarget.innerText))
          }
        >
          {_.get(data, `title.${lang}`)}
        </CardTitle>
        <CardDescription
          className="editable"
          suppressContentEditableWarning
          contentEditable
          spellCheck={false}
          data-placeholder={t('placeholder.typeTheDescriptionHere')}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) =>
            handleChangeContent(e.currentTarget.innerText, 'description', _.trim(e.currentTarget.innerText))
          }
        >
          {_.get(data, `description.${lang}`)}
        </CardDescription>
      </CardContent>
    </IDRACardEditableContainer>
  );
};

export default IDRACardEditable;
