import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
import { Container } from "./UserMultipleDropdown.styless";

interface OptionType {
  value: string;
  label: string;
}

interface ISectionProps {
  isMultiple?: boolean;
  options: { label: string; value: string }[];
  onChange(value: OptionType[] | []): void;
  value: any[];
  className?: string;
  placeholder?: string;
}

const UserMultipleDropdown: React.FC<ISectionProps> = ({
  options,
  onChange,
  value,
  className = "",
  placeholder = "Select...",
  isMultiple = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleSelectChange = (selectedOptions: any) => {
    const resolveOptions = !_.isEmpty(selectedOptions) ? selectedOptions : [];
    setSelectedOptions(resolveOptions);
    onChange(resolveOptions);
  };

  return (
    <Container className={className}>
      <Select
        closeMenuOnSelect={false}
        isMulti={isMultiple}
        placeholder={placeholder}
        options={options}
        onChange={handleSelectChange}
        value={selectedOptions}
        className="select-container"
        classNamePrefix="select-prefix"
      />
    </Container>
  );
};

export default UserMultipleDropdown;
