import styled from 'styled-components';
import { ColorName } from '../../../Variables';
import { FlexVertical, FlexHorizontal } from '../../../Common';

interface SectionProps {
  flex?: string;
  width?: string;
}

const SideBarContainer = styled(FlexHorizontal)`
  background: ${ColorName.white};
  height: 60px;
  margin-top: 26px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;

  i {
    font-size: 14px;
    color: ${ColorName.darkElectricBlue};
  }
`;

const TitlePage = styled.h2`
  font-size: 30px;
  line-height: 44px;
  font-weight: bold;
  color: ${ColorName.darkElectricBlue};
`;

const MenuOptions = styled(FlexVertical)`
  position: absolute;
  background: ${ColorName.white};
  width: ${(props: SectionProps) => props.width || '140px'};
  top: 100%;
  right: 0%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  z-index: -1;
  opacity: 0;
  /* transition: all ease 0.25s; */
  &:focus {
    outline: none;
  }

  &.show {
    opacity: 1;
    z-index: 10;
  }
`;

const MenuItem = styled(FlexHorizontal)`
  font-size: 16px;
  font-weight: 400;
  color: ${ColorName.darkElectricBlue};
  padding: 10px 14px;
  /* transition: all ease 0.2s; */
  &:hover {
    cursor: pointer;
    background: ${ColorName.casper};
  }
`;

const DropdownLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
`;

export { SideBarContainer, TitlePage, MenuOptions, MenuItem, DropdownLayer };
