import styled from 'styled-components';
import { ColorName } from '../../../components/Variables';
import { PageContainer, FlexHorizontal } from '../../../components/Common';
import generateResponsive from '../../../components/Common/responsive';
import responsiveValue from '../../../components/Common/responsive.value';

interface SectionProps {
  col?: string;
  gap?: string;
  background?: string;
  flex?: string;
  height?: string;
  fontSize?: string;
}

const HomepageContainer = styled(PageContainer)`
  & .show-all {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
    align-items: center;
    display: flex;
    img {
      width: 20px;
      margin-left: 5px;
    }

    @media screen and (max-width: 426px) {
      font-size: 12px;
      line-height: 18px;

      img {
        width: 15px;
      }
    }
  }

  #circle {
    display: block;
    position: absolute;
    margin: auto;
    width: 15px;
    height: 15px;
    margin-top: -7.5px;
    margin-left: -7.5px;
    z-index: 10;
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 50%;
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 1);
  }
`;

const SectionTitle = styled.h2`
  font-size: ${(props: SectionProps) => props.fontSize || '40px'};
  font-weight: bold;
  margin: 0;

  &.center {
    text-align: center;
  }

  ${generateResponsive(responsiveValue.SECTION_TITLE)};
`;

const CareerSection = styled.div`
  position: relative;
  width: 100%;
  position: relative;
  min-height: 190px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  p,
  h2 {
    color: ${ColorName.white};
  }
  img {
    width: 100%;
    object-fit: contain;
    visibility: hidden;
  }

  & .custom_content {
    bottom: 0;
    padding: 0;
  }

  & .blur_layout {
    z-index: 5;
    position: absolute;
    top: calc(50% - 10rem);
    left: calc(50% - 10rem);
    width: 20rem;
    height: 20rem;
    background: ${(props: SectionProps) => `url(${props.background})`} 50% 50% no-repeat fixed;
    background-size: cover;
    border-radius: 50%;
  }

  @media screen and (max-width: 836px) {
    background: ${(props: SectionProps) => `url(${props.background})`};
    background-size: cover;
    background-position: center;

    min-height: 350px;

    svg,
    img {
      display: none;
    }

    h2,
    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 436px) {
    min-height: 200px;
  }
`;

const ContactButton = styled(FlexHorizontal)`
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  & .icon_box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${ColorName.deepCarrotOrange};
    i {
      font-size: 1.7rem;
      color: ${ColorName.white};
    }
  }
  h2 {
    font-size: 3rem;
  }
  p {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 20px 0 10px;
    transition: all ease-in-out 0.3s;
  }

  &:hover {
    cursor: pointer;
    p {
      color: ${ColorName.white};
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0;
    z-index: -1;
    background: ${ColorName.deepCarrotOrange};
    transition: all ease-in-out 0.3s;
    border-radius: 25px;
  }
  &:hover::before {
    width: 100%;
  }
`;

const ContentScrollVertical = styled.div`
  @media screen and (max-width: 836px) {
    overflow: auto;
  }
`;

const HideDescription = styled.div`
  visibility: hidden;
  display: none;
`;

export { HomepageContainer, SectionTitle, CareerSection, ContactButton, ContentScrollVertical, HideDescription };
