import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../Variables';
import { FlexHorizontal, FlexVertical } from '../FlexLayout';

const scaleFadeIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const arrow = keyframes`
    0%,
    100% {
        transform: translateX(0);
        opacity: 1;
    }
    23% {
        transform: translateX(17px);
        opacity: 1;
    }
    24%,
    80% {
        transform: translateX(-22px);
        opacity: 0;
    }
    81% {
        opacity: 1;
        transform: translateX(-22px);
    }
`;

const newArrow = keyframes`
    20%,
    80% {
        transform: translateX(0);
        opacity: 1;
    }
    43% {
        transform: translateX(17px);
        opacity: 1;
    }
    44%,
    60% {
        transform: translateX(-22px);
        opacity: 0;
    }
    61% {
        opacity: 1;
        transform: translateX(-22px);
    }
`;

const arrowUp = keyframes`
    0%,
    100% {
        transform: rotate(-40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(.1);
    }
`;

const newArrowUp = keyframes`
    20%,
    80% {
        transform: rotate(-40deg) scaleX(1);
    }
    40%,
    60% {
        transform: rotate(0deg) scaleX(.1);
    }
`;

const border = keyframes`
  0% {
    left: 100%;
    background: ${ColorName.black};
  }
  
  30% {
    left: 0;
    background: ${ColorName.black};
  }
  
  70% {
    left: 0;
    background: ${ColorName.black};
  }
  
  100% {
    left: -100%;
    background: transparent;
  }
`;

const newBorder = keyframes`

    0% {
      left: 100%;
      background: transparent;
    }
    20% {
      left: 100%;
      background: transparent;
    }
    40% {
      left: 0;
      background: ${ColorName.black};
    }

    60% {
      left: 0;
      background: ${ColorName.black};
    }

    80% {
      left: -100%;
      background: ${ColorName.black};
    }
    100% {
      left: -100%;
      background: transparent;
    }
`;

const arrowDown = keyframes`
    0%,
    100% {
        transform: rotate(40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(.1);
    }`;

const newArrowDown = keyframes`
    20%,
    80% {
        transform: rotate(40deg) scaleX(1);
    }
    40%,
    60% {
        transform: rotate(0deg) scaleX(.1);
    }`;

const ClientsIndustriesContainer = styled(FlexVertical)`
  box-sizing: border-box;
  border-radius: 10px;

  img {
    object-fit: contain;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  h2 {
    font-size: 40px;
    line-height: 46px;
    color: ${ColorName.darkElectricBlue};
  }

  span {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${ColorName.casper};
  }

  &.show {
    .lazy-load-image-background {
      opacity: 1;
    }
  }

  & .lazy-load-image-background {
    opacity: 0;
  }

  & .logo {
    opacity: 0;
    display: inline-block;
    margin: 10px 20px;
    &.show {
      animation: ${scaleFadeIn} ease 0.25s forwards;
    }

    @media screen and (max-width: 426px) {
      margin: 10px 0;
    }
  }
`;

const ButtonBar = styled(FlexHorizontal)`
  align-items: center;
`;

const Label = styled.h3`
  font-size: 22px;
  font-weight: bold;
  /* text-transform: uppercase; */
  margin: 0 20px;
  transition: all ease 0.25s;

  @media screen and (max-width: 426px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

const Button = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &.left {
    transform: scaleX(-1);
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid #ccc;
    box-sizing: border-box;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: transparent;
    box-sizing: border-box;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all ease 0.5s;
    transform: rotate(45deg);
  }

  &:hover {
    cursor: pointer;
  }
  &.first_load {
    & .content {
      background: ${ColorName.white};
    }
    &::after {
      animation: ${newBorder} 3.6s ease infinite;
    }
    i {
      animation: ${newArrow} 3.6s ease infinite;
      &:before {
        animation: ${newArrowUp} 3.6s ease infinite;
      }
      &:after {
        animation: ${newArrowDown} 3.6s ease infinite;
      }
    }
  }

  .content {
    width: 46px;
    height: 46px;
    background: transparent;
    position: absolute;
    z-index: 2;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: all ease 0.25s;
    will-change: background;
  }

  i {
    display: block;
    position: absolute;
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    z-index: 3;
    &:before,
    &:after {
      content: '';
      width: 10px;
      height: 2px;
      border-radius: 1px;
      position: absolute;
      background: black;
      left: 50%;
      top: 50%;
      margin: -1px 0 0 -5px;
      display: block;
      transform-origin: 9.5px 50%;
    }
    &:before {
      transform: rotate(-40deg);
    }
    &:after {
      transform: rotate(40deg);
    }
  }
  &.animate {
    & .content {
      background: ${ColorName.white};
    }
    &::after {
      animation: ${border} ease 1.3s;
      animation-delay: 0.3s;
    }
    i {
      animation: ${arrow} 1.6s ease forwards;
      &:before {
        animation: ${arrowUp} 1.6s ease forwards;
      }
      &:after {
        animation: ${arrowDown} 1.6s ease forwards;
      }
    }
  }

  @media screen and (max-width: 426px) {
    transform: scale(0.8);
    &.left {
      transform: scale(0.8) scaleX(-1);
    }
    &:nth-child(2) {
      margin-left: -10px;
    }
  }
`;

const FlexItem = styled(FlexHorizontal)`
  width: max-content;
`;

export { ClientsIndustriesContainer, ButtonBar, Button, Label, FlexItem };
