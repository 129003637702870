/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import { TabsBarContainer, TabsBarItem } from "./TabsBar.styles";
import { useTranslation } from "react-i18next";

interface DataStructure {
  label: string;
  value: string;
}

interface SectionProps {
  data: DataStructure[];
  onChange(value: string): unknown;
  selectedTab: string;
  className?: string;
}

const TabsBar: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translations
  const {t} = useTranslation('admin');
  // Declare props
  const { data, onChange, selectedTab, className } = props;

  const handleChangeTab = (value: string) => {
    if (onChange) onChange(value);
  };

  return (
    <TabsBarContainer className={className}>
      {_.map(data, (item, index) => {
        const isActive = item.value === selectedTab ? "active" : "";
        return (
          <TabsBarItem
            className={isActive}
            key={`tab-${index}`}
            onClick={() => handleChangeTab(item.value)}
          >
            {t(`tab.${item.label}`)}
          </TabsBarItem>
        );
      })}
    </TabsBarContainer>
  );
};

export default TabsBar;
