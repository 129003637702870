/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const initialState = {
  isLoading: false,
  hasError: false,
  isSuccess: false,
};

export default (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {

    case ActionTypes.SEND_MAIL_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.SEND_MAIL_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.SEND_MAIL_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
