import React, { useRef, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { FlexHorizontal, FlexVertical } from "../FlexLayout";
import { ColorName } from "../../Variables";
import { useTranslation } from "react-i18next";

interface DataStructure {
  value: string | number | boolean;
  label: string | number;
}

interface SectionProps {
  width?: string;
  height?: string;
  data?: DataStructure[];
  className?: string;
  selectedValue?: string;
  onChange?(value: string | number | boolean): unknown;
  flex?: string;
  disabled?: boolean;
  background?: string;
  isNoneTranslate?: boolean;
}

const AdminDropdownGroup = styled(FlexVertical)`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: calc(100% - 2px);
  max-height: 0px;
  overflow-y: hidden;
  z-index: 5;
  /* transition: all ease 0.25s; */
  border: 1px solid transparent;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }
`;

const AdminDropdownItem = styled(FlexHorizontal)`
  height: 40px;
  background: ${ColorName.aquaHaze};
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  color: ${ColorName.darkElectricBlue};
  font-size: 14px;
  padding: 0 14px 0 16px;

  &.active,
  &:hover {
    color: ${ColorName.white};
    background: ${ColorName.darkElectricBlue};
  }
`;

const AdminLabelDropdown = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  color: ${ColorName.darkElectricBlue};
  background: ${(props: SectionProps) =>
    props.background || ColorName.aquaHaze};
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  padding: 0 14px 0 16px;
  p {
    font-size: 14px;
  }

  i {
    transform-origin: center;
    height: 6px;
    line-height: 0.7;
    &.rotate {
      transform: rotate(180deg);
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: 100%;
    background: transparent;
    left: 0;
  }
`;

const AdminFilterItem = styled(FlexHorizontal)`
  width: ${(props: SectionProps) => props.width || "100%"};
  flex: ${(props: SectionProps) => props.flex || "unset"};
  align-items: center;
  height: ${(props: SectionProps) => props.height || "40px"};
  background: ${ColorName.aquaHaze};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  /* transition: all ease 0.25s; */
  border-radius: 5px;
  position: relative;
  color: ${ColorName.darkElectricBlue};
  user-select: none;
  outline: none;
  * {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.6;

    &:hover {
      cursor: not-allowed;
      ${AdminDropdownGroup} {
        max-height: 0px;
        overflow-y: hidden;
      }
    }
  }

  &.focus {
    color: ${ColorName.white};
    cursor: pointer;

    ${AdminLabelDropdown} {
      border: 1px solid ${ColorName.darkElectricBlue};
    }
    ${AdminDropdownGroup} {
      max-height: 160px;
      overflow-y: auto;
      border: 1px solid ${ColorName.darkElectricBlue};
    }
  }
  &.active {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

const Dropdown = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  const {
    data,
    className,
    selectedValue,
    onChange,
    width,
    flex,
    disabled,
    background,
    height,
    isNoneTranslate,
  } = props;
  // Declare states
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const renderSelectedValue = () => {
    const findItem = _.find(data, { value: selectedValue });
    if (findItem)
      return isNoneTranslate
        ? _.get(findItem, "label")
        : t(`dropdown.${_.get(findItem, "label")}`);
    return t("dropdown.choose");
  };

  const handleSelectItem = (value: string | number | boolean) => {
    if (onChange && dropdownRef && dropdownRef.current) {
      dropdownRef.current.blur();
      setIsFocus(false);
      onChange(value);
    }
  };

  return (
    <AdminFilterItem
      flex={flex}
      width={width}
      height={height}
      className={`${className} ${isFocus ? "focus" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={() => !disabled && setIsFocus(!isFocus)}
      onBlur={() => !disabled && setIsFocus(false)}
      ref={dropdownRef}
      tabIndex={1}
    >
      <AdminLabelDropdown background={background}>
        <p>{renderSelectedValue()}</p>
        <i className={`fas fa-angle-down ${isFocus ? "rotate" : ""}`} />
      </AdminLabelDropdown>
      <AdminDropdownGroup>
        <div>
          {_.map(data, (item, index) => {
            const { value, label } = item;
            const matchSelected = _.isEqual(value, selectedValue)
              ? "active"
              : "";
            return (
              <AdminDropdownItem
                className={matchSelected}
                onClick={() => handleSelectItem(value)}
                key={`filter-${index}`}
              >
                {isNoneTranslate ? label : t(`dropdown.${label}`)}
              </AdminDropdownItem>
            );
          })}
        </div>
      </AdminDropdownGroup>
    </AdminFilterItem>
  );
};

export default Dropdown;
