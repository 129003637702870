import React, { useEffect, useRef, useState } from "react";
import Countries from "./Country";
import styled from "styled-components";
import _ from "lodash";
import { ColorName } from "../../../components/Variables";
import { useTranslation } from "react-i18next";

const InputDropdownContainer = styled.div``;

const Dropdown = styled.div``;

const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  width: 100px;
  height: 40px;
  border: 1px solid ${ColorName.black};
  font-size: 16px;
  span {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 436px) {
    span {
      font-size: 12px;
      line-height: 1.5;
    }
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  border: 1px solid transparent;
  height: 100%;
  max-height: 0px;
  overflow: hidden;
  background: ${ColorName.white};
  z-index: 10;
  outline: none;

  &.show {
    border: 1px solid ${ColorName.black};
    overflow: auto;
    max-height: 200px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
  height: 40px;
  transition: all ease 0.25s;
  background: ${ColorName.white};
  text-align: left;
  &.active,
  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
  &.virtual-hover {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }

  @media screen and (max-width: 436px) {
    font-size: 12px;
  }
`;

interface SectionProps {
  disabled?: boolean;
  selected: string;
  onChange(value: string, key: string): void;
}

const ContactDropdown = (props: SectionProps) => {
  const { selected, onChange } = props;
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const { t } = useTranslation("user");
  const ref = useRef<HTMLDivElement>(null);

  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const removeVirtualHover = () => {
    const findItemVirtualHover = document.querySelectorAll(".virtual-hover");
    if (!_.isEmpty(findItemVirtualHover))
      _.map(findItemVirtualHover, (item) =>
        item.classList.remove("virtual-hover")
      );
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowMenu(false);
        menuRef?.current?.blur();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let index = 0;
    let char = "";
    const handleKeydown = (e: any) => {
      const key = _.lowerCase(e.key);
      const filter = _.filter(
        Countries,
        (country) => _.lowerCase(country.name[0]) === key
      );
      if (key !== char) {
        char = key;
        index = 0;
      }
      if (index > filter.length - 1) index = 0;
      const data = filter[index];
      const findIndex = _.findIndex(Countries, data);
      if (menuRef && menuRef.current)
        menuRef.current.scrollTo({ top: findIndex * 40, left: 0 });

      removeVirtualHover();

      const currentEle = document.querySelector(`#area-code-${findIndex}`);
      if (currentEle) currentEle.classList.add("virtual-hover");

      index = index + 1;
    };
    if (isShowMenu) document.body.addEventListener("keydown", handleKeydown);
    else {
      document.body.removeEventListener("keydown", handleKeydown);
      index = 0;
      char = "";
      removeVirtualHover();
    }
  }, [isShowMenu]);

  const renderLabel = () => {
    if (selected) {
      const findLabel = _.find(Countries, { code: selected });
      if (findLabel) return _.get(findLabel, "name");
    }
    return t("label.region");
  };

  return (
    <InputDropdownContainer ref={ref}>
      <Dropdown>
        <DropdownLabel onClick={() => setIsShowMenu(!isShowMenu)}>
          <span>{renderLabel()}</span>
          <i className={`fas fa-angle-${isShowMenu ? "up" : "down"}`} />
        </DropdownLabel>
        <DropdownMenu
          className={`${isShowMenu ? "show" : ""}`}
          ref={menuRef}
          tabIndex={1}
          onBlur={() => {
            setIsShowMenu(false);
            if (inputRef && inputRef.current) inputRef.current.focus();
          }}
        >
          {_.map(Countries, (country, index) => {
            const active = selected === country.code ? "active" : "";
            return (
              <DropdownItem
                id={`area-code-${index}`}
                key={`item-${country.code}`}
                className={active}
                onClick={() => {
                  if (onChange) onChange(country.code, "region");
                  menuRef.current?.blur();
                  setIsShowMenu(false);
                }}
              >
                {country.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </InputDropdownContainer>
  );
};

export default ContactDropdown;
