import styled from "styled-components";
import {
  VerticalContainer,
  HorizontalContainer,
} from "../../../../components/Common";
import { ColorName } from "../../../../components/Variables";
import { ContentEditableWrapper } from "../ContentEditableDetails.styles";
import { Images } from "../../../../themes";

interface SectionProps {
  color?: string;
  background?: string;
}

interface SectionProps {
  col?: string;
  gap?: string;
  background?: string;
  flex?: string;
  height?: string;
  fontSize?: string;
}

const EditHomepageContainer = styled(ContentEditableWrapper)``;

const CareerSection = styled.div`
  position: relative;
  width: 100%;
  position: relative;
  min-height: 190px;
  background: ${(props: SectionProps) => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  p,
  h2 {
    color: ${ColorName.white};
  }
  img {
    width: 100%;
    object-fit: contain;
    visibility: hidden;
  }

  & .position-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 40px;
    box-sizing: border-box;
  }

  & .custom_content {
    bottom: 0;
    padding: 0;
  }

  & .blur_layout {
    z-index: 5;
    position: absolute;
    top: calc(50% - 10rem);
    left: calc(50% - 10rem);
    width: 20rem;
    height: 20rem;
    background: ${(props: SectionProps) => `url(${props.background})`} 50% 50%
      no-repeat fixed;
    background-size: cover;
    border-radius: 50%;
  }
`;

const FooterSection = styled.div`
  width: 100%;
  background-image: url(${Images.footerBg.default});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;
  position: relative;
  height: 500px;

  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }

  & .position-content {
    position: absolute;
    top: 50px;
    right: 50px;
    height: auto;
    p {
      width: 360px;
      margin-left: auto;
    }

    img {
      height: 50px;
      width: auto;
    }
  }
`;

const Title = styled.h3`
  font-weight: 700;
  max-width: 300px;
  text-align: center;
  margin-top: 7px;
  font-size: 26px;
  line-height: 35px;
`;

const Subtitle = styled.p`
  max-width: 370px;
  flex: 1;
  text-align: center;
  margin-top: 10px;
  font-size: 1.8rem;
  line-height: 25px;
  font-weight: 500;
  white-space: pre-line;
`;

const IconWrapper = styled(HorizontalContainer)`
  align-items: flex-end;
  flex: 0.6;
`;

const SolutionIcon = styled.img`
  transition: all ease 0.25s;
  width: 65%;
`;

const SolutionCardContainer = styled(VerticalContainer)`
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${(props: SectionProps) => `url('${props.background}')`};
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;

  ${Title}, ${Subtitle} {
    color: ${(props: SectionProps) => props.color || ColorName.black};
  }
`;

const CertificateCircle = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 4px solid ${ColorName.white};
  border-radius: 50%;
  margin-left: 15px;
  transition: all ease 0.25s;
  text-decoration: none;
  color: ${ColorName.black};
  span {
    font-size: 11px;
    transition: all ease 0.25s;
    font-weight: bold;
    color: ${ColorName.white};
  }
  i {
    font-size: 16px;
    color: ${ColorName.white};
    transition: all ease 0.25s;
    margin-top: 7px;
  }
  &:hover {
    cursor: pointer;
    border-color: ${ColorName.burntSienna};
    span,
    i {
      color: ${ColorName.burntSienna};
    }
  }

  &.add-more {
    opacity: 0.6;
    &:hover {
      opacity: 1;
      border-color: ${ColorName.white};
      span,
      i {
        color: ${ColorName.white};
      }
    }
  }

  @media screen and (max-width: 436px) {
    width: 60px;
    height: 60px;
    span {
      font-size: 10px;
    }
  }
`;

const RemoveButtonFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${ColorName.white};
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;

  i {
    font-size: 13px;
    color: ${ColorName.burntSienna};
  }
`;

const CertBox = styled.div`
  position: relative;

  &:hover {
    ${RemoveButtonFile} {
      cursor: pointer;
      opacity: 1;
      background: ${ColorName.burntSienna};
      i {
        color: ${ColorName.white};
      }
    }
  }
`;

export {
  EditHomepageContainer,
  CareerSection,
  FooterSection,
  SolutionCardContainer,
  Title,
  Subtitle,
  SolutionIcon,
  IconWrapper,
  CertificateCircle,
  CertBox,
  RemoveButtonFile,
};
