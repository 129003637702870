import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import history from '../../../history';
import { BannerCategory, ContentPosition, Content } from './DetailsBanner.styles';
import get from 'lodash/get';

interface SectionProps {
  banner: string;
  isEmptyRecents?: boolean;
  isLoading?: boolean;
  isNewsDetails?: boolean;
}

const DetailsBanner = (props: SectionProps) => {
  const location = useLocation();
  const { t } = useTranslation('user');
  const { banner, isEmptyRecents, isLoading } = props;

  const pathname = get(location, 'state.from');
  const search = get(location, 'state.query');

  return (
    <BannerCategory>
      <img src={banner} alt="details-banner" />
      {history.action !== 'POP' && (
        <ContentPosition>
          <Content maxWidth={isEmptyRecents ? '720px' : '1220px'} className="flex-row flex-align-end banner-content">
            {!isLoading && (
              <div className="flex-row flex-align-center mb-5">
                <Link
                  onClick={() => {
                    document.documentElement.style.scrollBehavior = 'unset';
                  }}
                  to={`${pathname}${search}`}
                >
                  {t('label.back')}
                </Link>
              </div>
            )}
          </Content>
        </ContentPosition>
      )}
    </BannerCategory>
  );
};

export default DetailsBanner;
