/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { PopupWrapper, HeaderPopup } from '../DefaultPopup.styles';
import { GeyserSolidButton, CarrotSolidButton, FlexHorizontal, DefaultInput, AdminLabel } from '../../../Common';
import { OrderPopupContainer, ButtonBar } from './SelectMedia.styles';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  onClose(label?: string): void;
  className?: string;
  value: string;
}

const PDFLabelPopup = (props: SectionProps) => {
  const { onClose, className, value } = props;
  const { t } = useTranslation('admin');
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (value) setLabel(value);
  }, [value]);

  const handleClosePopup = (e: any) => {
    const isPopupWrapper = _.get(e.target, 'classList').contains('popup-wrapper');
    if (isPopupWrapper && onClose) onClose();
  };

  const renderButtonBar = () => {
    return (
      <ButtonBar className="flex-justify-end flex-align-center">
        <CarrotSolidButton className="mr-1" width="120px" onClick={() => onClose && onClose(label)}>
          {t('button.save')}
        </CarrotSolidButton>
        <GeyserSolidButton onClick={() => onClose && onClose()}>{t('button.close')}</GeyserSolidButton>
      </ButtonBar>
    );
  };

  const renderMain = () => {
    return (
      <OrderPopupContainer>
        <HeaderPopup>{t('headerPopup.selectMedia')}</HeaderPopup>
        <FlexHorizontal alignCenter boxSizing="border-box" className="mt-3 mb-3 pl-2 pr-2">
          <AdminLabel width="150px">PDF Label:</AdminLabel>
          <DefaultInput value={label} onChange={(e: any) => setLabel(e.target.value)} />
        </FlexHorizontal>
        {renderButtonBar()}
      </OrderPopupContainer>
    );
  };

  return (
    <PopupWrapper className={`popup-wrapper ${className}`} onClick={(e: any) => handleClosePopup(e)}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default PDFLabelPopup;
