import React from 'react';
import { ImageLayoutContainer, LayoutContent, GridLayout, Image } from './ImageLayout.styles';

interface SectionProps {
  images: string[];
  type: string;
}

const ImageLayout = (props: SectionProps) => {
  const { images, type } = props;

  const renderGrid = () => {
    return (
      <LayoutContent>
        <GridLayout style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <Image>
            <img src={images[0]} alt="" />
          </Image>
          <Image>
            <img src={images[1]} alt="" />
          </Image>
          <Image>
            <img src={images[2]} alt="" />
          </Image>
          <Image>
            <img src={images[3]} alt="" />
          </Image>
        </GridLayout>
      </LayoutContent>
    );
  };

  const renderThumbnails = () => {
    return (
      <LayoutContent>
        <Image style={{ aspectRatio: '2/1' }}>
          <img src={images[0]} alt="" />
        </Image>
        <GridLayout style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <Image>
            <img src={images[1]} alt="" />
          </Image>
          <Image>
            <img src={images[2]} alt="" />
          </Image>
          <Image>
            <img src={images[3]} alt="" />
          </Image>
        </GridLayout>
      </LayoutContent>
    );
  };

  const renderSlideshow = () => {
    return (
      <LayoutContent>
        <Image style={{ aspectRatio: '2/1' }}>
          <img src={images[0]} alt="" />
        </Image>
      </LayoutContent>
    );
  };

  const renderMain = () => {
    if (type === 'grid') return renderGrid();
    if (type === 'thumbnails') return renderThumbnails();
    if (type === 'slideshow') return renderSlideshow();
    return renderGrid();
  };

  return <ImageLayoutContainer>{renderMain()}</ImageLayoutContainer>;
};

export default ImageLayout;
