/* eslint import/no-anonymous-default-export: off */
import { CONSTANTS } from '../constants';
import ActionTypes from '../constants/ActionTypes';

const initialState = {
  isSuccess: false,
  isLoading: false,
  hasError: false,
  userLang: CONSTANTS.LOCALE.ENGLISH,
  adminLang: CONSTANTS.LOCALE.ENGLISH,
  isExpandMenu: false,
  firstLoading: true,
  isEdited: false,
  contentEdited: '',
  ip: {},
  router: {
    prev: '',
    current: '',
  },
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_PAGE_ROUTER:
      return {
        ...state,
        router: payload,
      };
    case ActionTypes.SET_USER_LANG:
      return {
        ...state,
        userLang: payload,
      };
    case ActionTypes.SET_ADMIN_LANG:
      return {
        ...state,
        adminLang: payload,
      };
    case ActionTypes.SET_EXPAND_SIDE_MENU:
      return {
        ...state,
        isExpandMenu: payload,
      };

    case ActionTypes.SET_FIRST_LOAD_FOR_USER:
      return {
        ...state,
        firstLoading: payload,
      };

    case ActionTypes.SET_STATUS_EDITED:
      return {
        ...state,
        isEdited: payload,
      };

    case ActionTypes.SET_CONTENT_EDITED:
      return {
        ...state,
        contentEdited: payload,
      };

    case ActionTypes.GET_IP_LOCATION_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
        ip: {},
      };
    case ActionTypes.GET_IP_LOCATION_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        hasError: false,
        ip: {},
      };
    case ActionTypes.GET_IP_LOCATION_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        ip: payload,
      };

    default:
      return state;
  }
};
