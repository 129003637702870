/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';
import { LOGOS_PAGE_KEY } from '../constants/Constants';

const defaultDetails = {
  type: '',
  pages: [LOGOS_PAGE_KEY.HOMEPAGE],
  companyLogo: '',
};

const defaultFilter = {
  page: LOGOS_PAGE_KEY.HOMEPAGE,
  type: '',
  sortBy: '',
  orderBy: 'DESC',
};

const defaultPagination = {
  currentPage: 1,
  limit: 10,
};

const defaultMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payload: [],
  details: defaultDetails,
  filter: defaultFilter,
  pagination: defaultPagination,
  meta: defaultMeta,
  removeIds: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_CLIENTS_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_CLIENTS_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.SET_CLIENTS_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_CLIENTS_DETAILS:
      return {
        ...state,
        details: {
          type: '',
          pages: [LOGOS_PAGE_KEY.HOMEPAGE],
          companyLogo: '',
        },
      };

    case ActionTypes.SET_CLIENTS_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_CLIENTS_FILTER:
      return {
        ...state,
        filter: defaultFilter,
      };

    case ActionTypes.SET_CLIENTS_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_CLIENTS_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_DEFAULT_CLIENTS_META_PAGINATION:
      return {
        ...state,
        meta: defaultMeta,
        pagination: defaultPagination,
      };

    case ActionTypes.SET_REMOVE_CLIENTS_IDS:
      return {
        ...state,
        removeIds: payload,
      };
    case ActionTypes.CLEAR_REMOVE_CLIENTS_IDS:
      return {
        ...state,
        removeIds: [],
      };

    case ActionTypes.GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_ALL_CLIENTS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.FILTER_CLIENTS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.FILTER_CLIENTS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.FILTER_CLIENTS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.CREATE_CLIENT_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_CAREER_BY_ID_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_CAREER_BY_ID_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_CAREER_BY_ID_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_SINGLE_CAREER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_CAREER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_CAREER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_MULTIPLE_CLIENTS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_CLIENTS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_CLIENTS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };


    default:
      return state;
  }
};
