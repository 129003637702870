import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Images } from '../../../themes';
import { ColorName } from '../../Variables';

interface SectionProps {
  percent?: number;
  className?: string;
  opacity?: number;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }


  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
    z-index: -999;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${ColorName.white};
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  &.show {
    animation-name: ${fadeIn};
  }

  &.show.hide {
    animation-name: ${fadeOut};
  }

  .loader {
    width: 60vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & .label {
      font-size: 18px;
      color: ${ColorName.darkElectricBlue};
    }

    & .percent {
      font-size: 36px;
      font-weight: bold;
      transition: all ease 0.25s;
      color: ${ColorName.darkElectricBlue};
    }

    @media screen and (max-width: 836px) {
      width: 70vw;

      & .percent {
        font-size: 28px;
      }
      & .label {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 426px) {
      width: 80vw;

      & .percent {
        font-size: 24px;
      }
      & .label {
        font-size: 14px;
      }
    }
  }
`;

const Logo = styled.div`
  opacity: 1;
  position: relative;

  @media screen and (max-width: 836px) {
    img {
      height: 40px;
    }
  }
  @media screen and (max-width: 426px) {
    img {
      height: 35px;
      width: auto;
      object-fit: contain;
    }
  }

  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: calc(100% + 18px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: ${ColorName.white};
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    mix-blend-mode: color;
    box-shadow: -11px 0px 18px #888888;
    animation: 1s slide ease-in forwards;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  img.scale {
    animation: 3s scale infinite;
  }
`;

const LoadingLayer = (props: SectionProps) => {
  const { className } = props;
  const [imgClass, setImgClass] = useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      setImgClass('scale');
    }, 1000);
  }, []);

  return (
    <Wrapper className={`show ${className}`}>
      <div className="loader">
        <Logo>
          <img className={imgClass} src={Images.logoHeader.default} alt="logo" />
        </Logo>
      </div>
    </Wrapper>
  );
};

export default LoadingLayer;
