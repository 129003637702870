import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../constants";

const swipe = keyframes`
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  @media screen and (max-width: 436px) {
    padding-top: 15px;
  }

  .wrapper {
    display: flex;
  }

  img {
    max-width: auto;
    height: 100px;
    padding: 0 15px;
    object-fit: cover;
  }

  img:last-of-type {
    padding-left: 0;
  }
`;

const CarouselSection = styled.section`
  display: flex;
  animation: ${swipe}
    ${(props: { animationDuration: any }) => props.animationDuration} linear
    infinite backwards;
`;

const Carousel: React.FC = () => {
  const [key, setKey] = useState(0);
  const payload: any = useSelector((state) =>
    _.get(state, `Clients.payload.${ROUTES.USER.HOMEPAGE}`)
  );

  const animationDuration = `${(_.size(payload) * 2000) / 1000}s`;

  useEffect(() => {
    updateKey();
  }, [payload]);

  const updateKey = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const renderCarousel = () => {
    return _.map(payload, (item, index) => {
      const companyLogo = _.get(item, "companyLogo");
      const companyType = _.get(item, "type");
      return <img src={companyLogo} alt={`logo-${companyType}`} key={index} />;
    });
  };

  return (
    <CarouselContainer className="section">
      <div className="inner">
        <div className="wrapper">
          {_.times(3, (index) => (
            <CarouselSection
              key={`${index}-${key}`}
              animationDuration={animationDuration}
            >
              {renderCarousel()}
            </CarouselSection>
          ))}
        </div>
      </div>
    </CarouselContainer>
  );
};

export default Carousel;
