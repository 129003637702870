import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { DetailsWorkGroup, DetailsWorkItem } from './OurWorkDetails.styles';
import { FlexVertical, BlackRegularParagraph } from '../../../components/Common';
import { GenerateValue } from '../../../libs';
import SkeletonNewsDetails from './SkeletonLoading';

interface SectionProps {
  details: any;
  isLoading: boolean;
}

// Declare utils
const { generateMonthYear, generateCurrency } = GenerateValue;

const OurWorkDescription = (props: SectionProps) => {
  // Declare props
  const { details, isLoading } = props;
  // Declare translation
  const { t } = useTranslation('user');

  return (
    <DetailsWorkGroup>
      {isLoading ? (
        <SkeletonNewsDetails isRelated />
      ) : (
        <>
          {' '}
          <DetailsWorkItem className="flex-align-center">
            <b>{t('label.status')}:</b>
            <BlackRegularParagraph>{get(details, 'status')}</BlackRegularParagraph>
          </DetailsWorkItem>
          <DetailsWorkItem className="flex-align-center">
            <b>{t('label.startedIn')}:</b>
            <BlackRegularParagraph>{generateMonthYear(get(details, 'start_date')) || 'N/I'}</BlackRegularParagraph>
          </DetailsWorkItem>
          {get(details, 'status') === 'Completed' && (
            <DetailsWorkItem className="flex-align-center">
              <b>{t('label.endedIn')}:</b>
              <BlackRegularParagraph>{generateMonthYear(get(details, 'end_date')) || 'N/I'}</BlackRegularParagraph>
            </DetailsWorkItem>
          )}
          <DetailsWorkItem>
            <b>{t('label.topics')}:</b>
            <FlexVertical>
              {!isEmpty(get(details, 'solutions')) &&
                map(get(details, 'solutions'), (solution, index) => {
                  return (
                    <BlackRegularParagraph key={`solution-${index}`}>{get(solution, 'title')}</BlackRegularParagraph>
                  );
                })}
            </FlexVertical>
          </DetailsWorkItem>
          <DetailsWorkItem>
            <b>{t('label.client')}:</b>
            <FlexVertical>
              <BlackRegularParagraph>{get(details, 'name_of_client')}</BlackRegularParagraph>
            </FlexVertical>
          </DetailsWorkItem>
          {get(details, 'showOnWebsite') ? (
            <DetailsWorkItem className="flex-row flex-align-center">
              <b>{t('label.projectValue')}:</b>
              <FlexVertical flex='1'>
                <BlackRegularParagraph>
                  {generateCurrency(get(details, 'overall_project_value_euro'))}
                </BlackRegularParagraph>
              </FlexVertical>
            </DetailsWorkItem>
          ) : null}
        </>
      )}
    </DetailsWorkGroup>
  );
};

export default OurWorkDescription;
