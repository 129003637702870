/* eslint import/no-anonymous-default-export: off */
import _ from "lodash";
import { ActionTypes } from "../constants";
import API from "../api";
import history from "../history";
import { ROUTES } from "../constants";
import { statusAlert } from "../libs";

const generateClientsPayload = (data: any) => {
  const resolvePayload: any = {};
  const listKey = _.keys(data);
  _.map(listKey, (key) => {
    const currentData = _.get(data, key);
    if (key === ROUTES.USER.HOMEPAGE) {
      _.assign(resolvePayload, { [key]: currentData });
    } else {
      const sortData = _.sortBy(currentData, ["order"]);
      const listChildKey = {};
      _.map(sortData, (client) => {
        const type = _.get(client, "type");
        const isExist: any[] = _.get(listChildKey, type);
        if (isExist && _.isArray(isExist))
          _.assign(listChildKey, { [type]: [...isExist, client] });
        else _.assign(listChildKey, { [type]: [client] });
      });
      _.assign(resolvePayload, { [key]: listChildKey });
    }
  });
  if (resolvePayload) return resolvePayload;
  return null;
};

// SINGLE ACTIONS
const setClientsPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_CLIENTS_PAYLOAD,
    payload,
  };
};

const clearClientsPayload = () => {
  return {
    type: ActionTypes.CLEAR_CLIENTS_PAYLOAD,
  };
};

const setClientDetails = (payload: any) => {
  return {
    type: ActionTypes.SET_CLIENTS_DETAILS,
    payload,
  };
};

const clearClientDetails = () => {
  return {
    type: ActionTypes.CLEAR_CLIENTS_DETAILS,
  };
};

const setClientsFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_CLIENTS_FILTER,
    payload,
  };
};

const clearClientsFilter = () => {
  return {
    type: ActionTypes.CLEAR_CLIENTS_FILTER,
  };
};

const setClientsPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_CLIENTS_PAGINATION,
    payload,
  };
};

const setClientsMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_CLIENTS_META,
    payload,
  };
};

const setDefaultClientsMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_CLIENTS_META_PAGINATION,
  };
};

const setRemoveClientsIds = (payload: any) => {
  return {
    type: ActionTypes.SET_REMOVE_CLIENTS_IDS,
    payload,
  };
};

const clearRemoveClientsIds = () => {
  return {
    type: ActionTypes.CLEAR_REMOVE_CLIENTS_IDS,
  };
};

// ASYNC ACTIONS
const getAllClientsError = () => {
  return {
    type: ActionTypes.GET_ALL_CLIENTS_FAILURE,
  };
};

const getAllClientsLoading = () => {
  return {
    type: ActionTypes.GET_ALL_CLIENTS_REQUEST,
  };
};

const getAllClientsSuccess = () => {
  return {
    type: ActionTypes.GET_ALL_CLIENTS_SUCCESS,
  };
};

const getAllClients = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(getAllClientsLoading());
    await API.getAllClients(data)
      .then(async (res: any) => {
        const { status, payload } = res;
        if (status) {
          const { items, meta } = payload;
          dispatch(setClientsMeta(meta));
          dispatch(setClientsPayload(items));
          dispatch(getAllClientsSuccess());
        } else dispatch(getAllClientsError());
      })
      .catch(() => dispatch(getAllClientsError()));
  };
};

const getClientsForUserError = () => {
  return {
    type: ActionTypes.GET_CLIENTS_FOR_USER_FAILURE,
  };
};

const getClientsForUserLoading = () => {
  return {
    type: ActionTypes.GET_CLIENTS_FOR_USER_REQUEST,
  };
};

const getClientsForUserSuccess = () => {
  return {
    type: ActionTypes.GET_CLIENTS_FOR_USER_SUCCESS,
  };
};

const getClientsForUser = () => {
  return async (dispatch: any) => {
    dispatch(getClientsForUserLoading());
    await API.getClientsForUser()
      .then(async (res: any) => {
        const { payload, status } = res;
        const resolvePayload = generateClientsPayload(payload);
        if (status) {
          dispatch(setClientsPayload(resolvePayload));
          dispatch(getClientsForUserSuccess());
        } else dispatch(getClientsForUserError());
      })
      .catch(() => dispatch(getClientsForUserError()));
  };
};

const getClientByIdError = () => {
  return {
    type: ActionTypes.GET_CLIENT_BY_ID_FAILURE,
  };
};

const getClientByIdLoading = () => {
  return {
    type: ActionTypes.GET_CLIENT_BY_ID_REQUEST,
  };
};

const getClientByIdSuccess = () => {
  return {
    type: ActionTypes.GET_CLIENT_BY_ID_SUCCESS,
  };
};

const getClientById = (id: any) => {
  return async (dispatch: any) => {
    dispatch(getClientByIdLoading());
    await API.getClientById(id)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          dispatch(setClientDetails(payload));
          dispatch(getClientByIdSuccess());
        } else dispatch(getClientByIdError());
      })
      .catch(() => dispatch(getClientByIdError()));
  };
};

const createClientError = () => {
  return {
    type: ActionTypes.CREATE_CLIENT_FAILURE,
  };
};

const createClientLoading = () => {
  return {
    type: ActionTypes.CREATE_CLIENT_REQUEST,
  };
};

const createClientSuccess = (payload: any) => {
  return {
    type: ActionTypes.CREATE_CLIENT_SUCCESS,
    payload,
  };
};

const createClient = (data: any) => {
  return async (dispatch: any) => {
    dispatch(createClientLoading());
    await API.createClient(data)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          await history.push(ROUTES.ADMIN.CLIENTS);
          dispatch(createClientSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(createClientError());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(createClientError()));
  };
};

const updateClientError = () => {
  return {
    type: ActionTypes.UPDATE_CLIENT_FAILURE,
  };
};

const updateClientLoading = () => {
  return {
    type: ActionTypes.UPDATE_CLIENT_REQUEST,
  };
};

const updateClientSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CLIENT_SUCCESS,
    payload,
  };
};

const updateClient = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateClientLoading());
    await API.updateClient(data)
      .then(async (res: any) => {
        const { message, status, payload } = res;
        if (status) {
          await history.push(ROUTES.ADMIN.CLIENTS);
          dispatch(updateClientSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateClientError());
          statusAlert("error", message);
        }
      })
      .catch((e: any) => dispatch(updateClientError()));
  };
};

const removeSingleClientError = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_CLIENT_FAILURE,
  };
};

const removeSingleClientLoading = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_CLIENT_REQUEST,
  };
};

const removeSingleClientSuccess = (payload: any) => {
  return {
    type: ActionTypes.REMOVE_SINGLE_CLIENT_SUCCESS,
    payload,
  };
};

const removeSingleClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(removeSingleClientLoading());
    await API.removeSingleClient(id)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          await dispatch(getAllClients());
          dispatch(removeSingleClientSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(removeSingleClientError());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(removeSingleClientError()));
  };
};

const removeMultipleClientsError = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CLIENTS_FAILURE,
  };
};

const removeMultipleClientsLoading = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CLIENTS_REQUEST,
  };
};

const removeMultipleClientsSuccess = (payload: any) => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CLIENTS_SUCCESS,
    payload,
  };
};

const removeMultipleClients = (ids: string[]) => {
  return async (dispatch: any) => {
    dispatch(removeMultipleClientsLoading());
    await API.removeMultipleClients(ids)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          dispatch(removeMultipleClientsSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(removeMultipleClientsError());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(removeMultipleClientsError()));
  };
};

const singleActions = {
  setClientsPayload,
  clearClientsPayload,
  setClientDetails,
  clearClientDetails,
  setClientsFilter,
  clearClientsFilter,
  setClientsPagination,
  setClientsMeta,
  setDefaultClientsMetaPagination,
  setRemoveClientsIds,
  clearRemoveClientsIds,
};

const asyncActions = {
  createClient,
  updateClient,
  getAllClients,
  getClientById,
  getClientsForUser,
  removeSingleClient,
  removeMultipleClients,
};

export default {
  ...singleActions,
  ...asyncActions,
};
