/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { ShowMoreContent, PageContainer } from '../../../components/Common';
import { DefaultUserLayout } from '../../../components/Layout/DefaultLayout';
import SecondHeader from '../../../components/Layout/CommonLayoutPart/SecondHeader';
import { SECOND_HEADER } from '../../../constants/Constants';
import { ROUTES } from '../../../constants';
import history from '../../../history';
import { Utils } from '../../../libs';

const defaultCurrentActive = {
  omnibus: 'omnibus-1',
};

const ResearchSolutions = () => {
  // Declare translation
  const { t } = useTranslation('user');
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducers
  const payload = useSelector(state => _.get(state, `EditContent.payload.${ROUTES.USER.RESEARCH_SOLUTIONS}`));
  // Declare ref, states
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [currentActiveTab, setCurrentActiveTab] = useState<any>({});

  useEffect(() => {
    return () => {
      setCurrentCategory('');
      setCurrentActiveTab({});
    };
  }, []);

  useEffect(() => {
    const category = _.get(queryStringValue, 'category');
    if (_.isString(category) && category !== currentCategory) setCurrentCategory(category);
    setCurrentActiveTab(defaultCurrentActive);
  }, [currentCategory]);

  // Handle change category
  const handleChangeCategory = (value: string) => {
    Utils.scrollToBottomOfBanner();
    history.push(`${ROUTES.USER.RESEARCH_SOLUTIONS}?category=${value}`);
    setCurrentCategory(value);
    setCurrentActiveTab({
      ...defaultCurrentActive,
    });
  };

  // Handle show or hider section of category
  const handleShowOrHideCategory = (value: string) => {
    const currentValue = _.get(currentActiveTab, currentCategory);
    const newValue = currentValue === value ? '' : value;
    setCurrentActiveTab({
      ...currentActiveTab,
      [currentCategory]: newValue,
    });
  };

  const renderMain = () => {
    return (
      <PageContainer className="pb-4">
        <SecondHeader
          data={SECOND_HEADER.RESEARCH_SOLUTIONS}
          active={currentCategory}
          onChange={(value: string) => handleChangeCategory(value)}
        />
        <ShowMoreContent
          className="mt-3"
          data={_.get(payload, currentCategory)}
          type={currentCategory}
          onChange={(value: string) => handleShowOrHideCategory(value)}
          active={_.get(currentActiveTab, currentCategory)}
        />
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_SOLUTIONS}
      titlePage={t('titlePage.researchSolutions')}
      headerTitle="Research Solutions"
      metaDescription="We offer intergrated solutions to make the products and services with long-term success"
    />
  );
};

export default ResearchSolutions;
