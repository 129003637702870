import { create } from 'apisauce';

const BASE_URL = 'http://pea.idra.al/filterProjectToIdraWebsite';
// DEFINE THE URL API
const api = create({
  baseURL: `${BASE_URL}`,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
});

const getPEA = async (params?: any) => {
  const res = await api.get(BASE_URL, params);
  return new Promise((resolve, reject) => {
    if (res.status === 401) {
      reject(res.data);
    } else if (res.status === 501 || res.status === 422) {
      reject(res);
    } else if (res.problem) {
      reject('Server error');
    } else {
      resolve(res);
    }
  });
};

export { getPEA };
