import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../../components/Variables';

interface SectionProps {
  isRelated?: boolean;
}

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const CardLoading = styled.div`
  background: ${ColorName.white};
  transition: all ease-in-out 0.55s;
  transition-delay: 0.1s;
  position: relative;
  will-change: background;
  min-height: 237px;
  & .item-row {
    display: flex;
    margin-top: 15px;
  }
  & .item-column {
    width: 100%;
    margin-left: 10px;
  }
  & .description-row,
  & .title,
  & .title-second,
  & .description,
  & .thumbnail,
  & .timestamp,
  & .related-title,
  & .related-timestamp,
  & .related-label {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }
  & .title {
    width: 100%;
    height: 50px;
  }
  & .title-second {
    width: 50%;
    height: 50px;
    margin-top: 5px;
  }
  & .description {
    margin-top: 20px;
    width: 100%;
    height: 400px;
  }
  & .description-row {
    height: 41px;
    width: 100%;
    margin-top: 10px;
  }
`;

const SkeletonNewsDetails = (props: SectionProps) => {
  const { isRelated } = props;
  if (isRelated)
    return (
      <CardLoading>
        <div className="description-row" />
        <div className="description-row" />
        <div className="description-row" />
        <div className="description-row" />
        <div className="description-row" />
      </CardLoading>
    );
  return (
    <CardLoading>
      <div className="title" />
      <div className="title-second" />
      <div className="description" />
    </CardLoading>
  );
};

export default SkeletonNewsDetails;
