/* eslint import/no-anonymous-default-export: off */
import ROUTES from "./Routes";

export default {
  [ROUTES.ADMIN.DASHBOARD]: "dashboard",
  [ROUTES.ADMIN.PROFILE]: "profile",
  [ROUTES.ADMIN.ARTICLES]: "articlesManagement",
  [ROUTES.ADMIN.RECENT_ACTIVITY]: "recentActivity",
  [ROUTES.ADMIN.DRAFT_NEWS]: "draftArticles",
  [ROUTES.ADMIN.ORDER_NEWS]: "reOrderArticles",
  [ROUTES.ADMIN.CLIENTS]: "clientsManagement",
  [ROUTES.ADMIN.CLIENTS_DETAILS]: "clientDetails",
  [ROUTES.ADMIN.CAREER]: "careersManagement",
  [ROUTES.ADMIN.CAREER_DETAILS]: "careerDetails",
  [ROUTES.ADMIN.SITE_SETTINGS]: "heroImageOrVideo",
  [ROUTES.ADMIN.ARTICLES_DETAILS]: "articleDetails",
  [ROUTES.ADMIN.CAREER_DETAILS]: "careerDetails",
  [ROUTES.ADMIN.TEAM_MEMBERS]: "membersManagement",
  [ROUTES.ADMIN.TEAM_MEMBERS_DETAILS]: "memberDetails",
  [ROUTES.ADMIN.CONTENT_EDITABLE]: "editContent",
  [ROUTES.ADMIN.ALUMNI_NETWORK]: "alumniNetworkManagement",
  [ROUTES.ADMIN.ALUMNI_NETWORK_DETAILS]: "alumniNetworkDetails",
};
