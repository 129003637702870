import styled, { keyframes } from "styled-components";
import { VerticalContainer, HorizontalContainer } from "../CommonLayout";
import { ColorName } from "../../Variables";
import generateResponsive from "../responsive";
import responsiveValue from "../responsive.value";

interface SectionProps {
  color?: string;
  background?: string;
}

const showing = keyframes`
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
`;

const fadeIn = keyframes`
  0% {
    transform: translateX(-10px);
    opacity: 0;
    z-index: -1;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
    z-index: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
    z-index: 1;
  }

  80% {
    transform: translateX(10px);
    opacity: 0;
    z-index: -1;
  }

  100% {
    transform: translateX(-10px);
  }
`;

const SliderContainer = styled(VerticalContainer)`
  width: 100%;
  height: calc(100vh - 80px);
  max-height: 650px;
  position: relative;

  @media screen and (max-width: 426px) {
    height: 50vh;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
`;

const ImageItem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  transition: all ease 0.5s;
  opacity: 0;
  &.active {
    opacity: 1;
  }

  img,
  video {
    object-fit: cover;
  }

  @media screen and (max-width: 1025px) {
    img {
      object-position: 70% 50%;
    }
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Content = styled(VerticalContainer)`
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
  box-sizing: border-box;

  max-width: 1920px;
  @media screen and (max-width: 1919px) {
    max-width: 1440px;
  }

  ${generateResponsive(responsiveValue.CONTENT)};
`;

const ContentSlider = styled(HorizontalContainer)`
  margin-top: 70px;
  height: auto;
  position: relative;

  ${generateResponsive(responsiveValue.SLIDER_CONTENT)};
`;

const Title = styled.h2`
  max-width: 617px;

  ${generateResponsive(responsiveValue.SLIDER_TITLE)};
`;

const SliderBar = styled(HorizontalContainer)`
  margin-top: 15px;
`;

const BarItem = styled.div`
  width: 60px;
  height: 7px;
  border-radius: 5px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;

  span {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 7px;
    left: 0;
    top: 0;
    background: ${ColorName.black};
    transition: all ease 0.25s;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 7px;
    left: 0;
    top: 0;
    background: transparent;
    transition: all ease 0.25s;
  }

  &:hover {
    cursor: pointer;
    &::before {
      background: ${ColorName.black};
    }
  }

  &.active {
    opacity: 1;

    &::after {
      animation: ${showing} 6s infinite;
    }
  }

  @media screen and (max-width: 426px) {
    width: 45px;
    height: 9px;
    &::after {
      height: 9px;
    }

    &::before {
      height: 9px;
    }
  }
`;

const ContentItem = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  & .subtitle {
    width: max-content;
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: relative;
    text-decoration: none;

    span {
      font-size: 1.9rem;
      line-height: 3.2rem;
      font-weight: 400;
      max-width: 180px;
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      margin-right: 10px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 0px;
        height: 2px;
        top: 100%;
        left: 0;
        border-radius: 5px;
        background: ${(props: SectionProps) =>
          props.background || ColorName.white};
        transition: all ease 0.25s;
      }
    }

    i {
      font-size: 16px;
      margin-top: 10px;
      transform: translateY(-2px) translateX(-3px);
      opacity: 0;
      transition: all ease 0.25s;
      transition-delay: 0.25s;
    }

    &:hover {
      cursor: pointer;

      span:before {
        width: 100%;
      }
      i {
        opacity: 1;
        transform: translateY(-2px) translateX(5px);
      }
    }
  }

  &.show {
    animation: ${fadeIn} ease 1.25s forwards;
  }
  &.hide {
    animation: ${fadeOut} ease 0.5s forwards;
  }

  ${Title}, & .subtitle {
    color: ${(props: SectionProps) => props.color || ColorName.black};

    span:before {
      background: ${(props: SectionProps) => props.color || ColorName.black};
    }
  }
  ${BarItem} {
    span {
      background: ${(props: SectionProps) => props.color || ColorName.black};
      opacity: 0.3;
    }
    :after {
      background: ${(props: SectionProps) => props.color || ColorName.black};
    }
  }
`;

export {
  SliderContainer,
  BackgroundImage,
  ImageItem,
  ContentSlider,
  ContentItem,
  Title,
  ContentWrapper,
  Content,
  SliderBar,
  BarItem,
};
