import Service from "./Base";
import API from "../constants/API";

const { ALUMNI_NETWORK, CONTENT_EDITABLE } = API;

export function fetchAlumniNetworks(payload?: any) {
  return Service.get(ALUMNI_NETWORK.BASIC, payload);
}

export function createAlumniNetwork(payload: any) {
  return Service.post(ALUMNI_NETWORK.BASIC, payload);
}

export function getAlumniNetworkById(id: string) {
  return Service.get(`${ALUMNI_NETWORK.BASIC}/${id}`);
}

export function updateContentAlumniNetwork(payload: any) {
  return Service.put(CONTENT_EDITABLE.ALUMNI_NETWORK, payload);
}

export function denyAlumniNetwork(id: string) {
  return Service.put(`${ALUMNI_NETWORK.DENY}/${id}`, { status: "denied" });
}

export function approveAlumniNetwork(id: string) {
  return Service.put(`${ALUMNI_NETWORK.APPROVE}/${id}`, { status: "approved" });
}
