/* eslint import/no-anonymous-default-export: off */
import ActionTypes from "../constants/ActionTypes";

const defaultFilter = {
  features: "",
  categories: "",
  topic: "",
  dateFrom: "",
  dateTo: "",
  keyword: "",
  sortBy: "publishedAt",
  orderBy: "DESC",
  language: "en",
  type: "admin",
};

const defaultDetails = {
  isDraft: 1,
  thumbnail: "",
  features: "",
  categories: "",
  status: 1,
  topic: "",
  languageDisplay: "both",
  title: {
    al: "",
    en: "",
  },
  description: {
    al: "",
    en: "",
  },
  content: {
    al: "",
    en: "",
  },
  isShowOnHomepage: 0,
  croppedData: null,
  galleryImages: [],
};

const defaultPagination = {
  currentPage: 1,
  limit: 10,
};

const defaultMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

const defaultUserMetaPagination = {
  currentPage: 1,
  limit: 5,
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payloadOrdered: [],
  payloadUnordered: [],
  payloadLatest: [],
  payload: [],
  details: defaultDetails,
  filter: {
    admin: defaultFilter,
    user: {},
  },
  pagination: defaultPagination,
  meta: defaultMeta,
  userMetaPagination: defaultUserMetaPagination,
  removeIds: [],
  recentNews: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_PAYLOAD_ORDERED_ARTICLES:
      return {
        ...state,
        payloadOrdered: payload,
      };
    case ActionTypes.CLEAR_PAYLOAD_ORDERED_ARTICLES:
      return {
        ...state,
        payloadOrdered: [],
      };

    case ActionTypes.SET_PAYLOAD_UNORDERED_ARTICLES:
      return {
        ...state,
        payloadUnordered: payload,
      };
    case ActionTypes.CLEAR_PAYLOAD_UNORDERED_ARTICLES:
      return {
        ...state,
        payloadUnordered: [],
      };

    case ActionTypes.SET_PAYLOAD_ARTICLES:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_PAYLOAD_ARTICLES:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.SET_ARTICLES_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_ARTICLES_DETAILS:
      return {
        ...state,
        details: defaultDetails,
      };

    case ActionTypes.SET_ADMIN_ARTICLES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          admin: payload,
        },
      };
    case ActionTypes.SET_DEFAULT_ADMIN_ARTICLES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          admin: defaultFilter,
        },
      };

    case ActionTypes.SET_USER_ARTICLES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          user: payload,
        },
      };
    case ActionTypes.SET_DEFAULT_USER_ARTICLES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          user: {},
        },
      };

    case ActionTypes.SET_USER_ARTICLES_META_PAGINATION:
      return {
        ...state,
        userMetaPagination: payload,
      };
    case ActionTypes.SET_DEFAULT_USER_ARTICLES_META_PAGINATION:
      return {
        ...state,
        userMetaPagination: defaultUserMetaPagination,
      };

    case ActionTypes.SET_ADMIN_ARTICLES_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_ADMIN_ARTICLES_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_DEFAULT_ADMIN_ARTICLES_META_PAGINATION:
      return {
        ...state,
        meta: defaultMeta,
        pagination: defaultPagination,
      };

    case ActionTypes.SET_REMOVE_ARTICLES_IDS:
      return {
        ...state,
        removeIds: payload,
      };
    case ActionTypes.CLEAR_REMOVE_ARTICLES_IDS:
      return {
        ...state,
        removeIds: [],
      };

    case ActionTypes.GET_ARTICLES_BY_ID_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_ARTICLES_BY_ID_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.GET_ARTICLES_BY_ID_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.GET_ALL_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.GET_ALL_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.GET_ALL_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.FILTER_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.FILTER_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.FILTER_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.CREATE_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.CREATE_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.UPDATE_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.UPDATE_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ORDER_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ORDER_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ORDER_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_ORDERED_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payloadOrdered: [],
      };
    case ActionTypes.GET_ORDERED_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.GET_ORDERED_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_LATEST_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payloadLatest: [],
      };
    case ActionTypes.GET_LATEST_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        payloadLatest: payload,
      };
    case ActionTypes.GET_LATEST_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.GET_UNORDERED_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payloadUnordered: [],
      };
    case ActionTypes.GET_UNORDERED_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.GET_UNORDERED_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.REMOVE_SINGLE_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.REMOVE_SINGLE_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.REMOVE_MULTIPLE_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.REMOVE_MULTIPLE_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };

    case ActionTypes.GET_RECENT_ARTICLES_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        recentNews: [],
      };
    case ActionTypes.GET_RECENT_ARTICLES_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        recentNews: payload,
      };
    case ActionTypes.GET_RECENT_ARTICLES_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
        recentNews: [],
      };

    default:
      return state;
  }
};
