import _, { get } from "lodash";
import React, { useRef } from "react";
import { IDRACardItem, HiddenLink } from "./IDRACard.styles";
import { GridIDRAPage } from "../CommonLayout";
import { GrannyRegularParagraph } from "..";
import { Icons } from "../../../themes";
import history from "../../../history";
import { useSelector } from "react-redux";
import IDRA_ICONS from "./IdraIcons";

interface DataStructure {
  title: string;
  description: string;
  icon?: string;
  link?: string;
  type: string;
}

interface SectionProps {
  data: DataStructure[];
  col?: string;
  colGap: string;
  rowGap: string;
  className?: string;
  mimax?: string;
}

const IDRACard: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare props
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const { data, col, colGap, rowGap, className, mimax } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const handleClickCard = (link: string) => {
    if (!_.isEmpty(link)) {
      const isHttpLink = _.includes(link, "http");
      if (isHttpLink && linkRef && linkRef.current) {
        linkRef.current.href = link;
        linkRef.current.click();
      } else {
        document.documentElement.style.scrollBehavior = "unset";
        history.push(link);
      }
    }
  };

  return (
    <GridIDRAPage
      mimax={mimax}
      className={className}
      col={col}
      colGap={colGap}
      rowGap={rowGap}
    >
      <HiddenLink target="_blank" ref={linkRef} />

      {_.isArray(data) &&
        _.map(data, (item, index) => {
          return (
            <IDRACardItem
              key={`card-${index}`}
              onClick={() => item.link && handleClickCard(item.link)}
            >
              <div className="content">
                <div className="icon">
                  <img src={get(IDRA_ICONS, item.type)} alt="idra-icon" />
                </div>
                <h3>{_.get(item, `title.${lang}`)}</h3>
                <GrannyRegularParagraph>
                  {_.get(item, `description.${lang}`)}
                </GrannyRegularParagraph>
                <img
                  className="arrow"
                  src={Icons.bigWhiteRightArrow.default}
                  alt="arrow-icon"
                />
              </div>
            </IDRACardItem>
          );
        })}
    </GridIDRAPage>
  );
};

export default IDRACard;
