/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { NewsDetailsContainer } from "./NewsDetails.styles";
import { DetailsBanner } from "../../../components/Common";
import NewsContent from "./NewsContent";
import { Images } from "../../../themes";
import { ROUTES } from "../../../constants/";
import history from "../../../history";
import { ArticlesActions } from "../../../actions";

interface ParamsType {
  id: string;
  type: string;
}

// Declare actions
const { getArticlesById, getRecentArticles } = ArticlesActions;

const ResearchNewsDetails = () => {
  // Declare translations and route params type
  const { t } = useTranslation("user");
  const { id } = useParams<ParamsType>();
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const locale = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const details = useSelector((state) => _.get(state, "Articles.details"));
  const recentNews = useSelector((state) =>
    _.get(state, "Articles.recentNews")
  );
  // Declare states
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isRequestLoading) {
      setLocalLoading(true);
      setTimeout(() => {
        setLocalLoading(false);
      }, 1500);
    }
  }, [isRequestLoading]);

  useEffect(() => {
    if (id) dispatch(getArticlesById(id, "user"));
  }, [id]);

  useEffect(() => {
    const features = _.get(details, "features");
    if (features) dispatch(getRecentArticles({ features }, id));
  }, [details]);

  const renderMain = () => {
    const recentLabel = t(
      `label.recent${_.capitalize(_.get(details, "features"))}`
    );
    return (
      <NewsDetailsContainer>
        <DetailsBanner
          isNewsDetails
          isLoading={isRequestLoading || localLoading}
          isEmptyRecents={_.isEmpty(recentNews)}
          banner={Images.IDRAResearchBanner.default}
        />
        <NewsContent
          isLoading={isRequestLoading}
          recentNews={recentNews}
          details={details}
          recentLabel={recentLabel}
          locale={locale}
          onViewDetails={(id: string) => {
            document.documentElement.style.scrollBehavior = "unset";
            history.push(`${ROUTES.USER.RESEARCH_NEWS}/${id}`);
          }}
        />
      </NewsDetailsContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_NEWS_DETAILS}
      loading={isRequestLoading}
      headerTitle={`${
        isRequestLoading ? "Loading..." : _.get(details, `title.${locale}`)
      } | IDRA`}
    />
  );
};

export default ResearchNewsDetails;
