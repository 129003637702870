import React from 'react';
import { CardLoading } from './Skeleton.styles';

const SkeletonCareerCard = () => {
  return (
    <CardLoading>
      <div className="title" />
      <div className="description" />
    </CardLoading>
  );
};

export default SkeletonCareerCard;
