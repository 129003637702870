import React, { useState, useEffect } from "react";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  WrapperInput,
  CustomInput,
  LabelInput,
} from "./UserMonthPicker.styles";

interface ISectionProps {
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
  label: string;
  locale?: string;
  isMobile?: boolean;
}

interface IDateStructure {
  month: string;
  year: string;
}

// Outside of function
const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];

const AdminDatePicker: React.FC<ISectionProps> = ({
  value,
  onChange,
  disabled,
  label,
  locale,
  isMobile,
}) => {
  const [date, setDate] = useState<IDateStructure>({ month: "", year: "" });
  useEffect(() => {
    if (value) {
      const separator = "/";
      let [newMonth, newYear] = value.split(separator);
      if (newMonth !== date.month || newYear !== date.year)
        setDate({
          month: newMonth,
          year: newYear,
        });
    }
  }, [date.month, date.year, value]);

  const handleInputChange = (key: "month" | "year", value: string) => {
    const newValue = { ...date, [key]: value };
    setDate(newValue);
    onChange(
      `${
        !newValue.month && !newValue.year
          ? ""
          : `${newValue.month}/${newValue.year}`
      }`
    );
  };

  return (
    <Container>
      <LabelInput locale={locale} isMobile={isMobile}>
        {label}
      </LabelInput>
      <WrapperInput>
        <CustomInput
          type="number"
          placeholder="mm"
          maxLength={date.month ? 2 : 3}
          value={date.month}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const currentValue = event.target.value.replace(/[^0-9]/g, "");
            if (_.size(currentValue) > 2) return;
            handleInputChange("month", currentValue);
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) => {
            const value = event.target.value;
            const length = _.size(event.target.value);
            const formattedValue =
              length === 1 && parseInt(value) >= 1 && parseInt(value) <= 9
                ? "0" + value
                : value;
            event.target.value = formattedValue;
            handleInputChange("month", formattedValue);
          }}
          onKeyDown={(e) =>
            exceptThisSymbols.includes(e.key) && e.preventDefault()
          }
          disabled={disabled}
        />
        <span
          style={{
            fontWeight: 400,
            marginRight: "2px",
            fontSize: "15px",
          }}
        >
          /
        </span>
        <CustomInput
          type="number"
          placeholder="yyyy"
          maxLength={4}
          value={date.year}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const currentValue = event.target.value.replace(/[^0-9]/g, "");
            if (_.size(currentValue) > 4) return;
            handleInputChange("year", event.target.value);
          }}
          onKeyDown={(e) =>
            exceptThisSymbols.includes(e.key) && e.preventDefault()
          }
          disabled={disabled}
        />
      </WrapperInput>
    </Container>
  );
};
export default AdminDatePicker;
