import styled from "styled-components";
import { ColorName } from "../../Variables";

const DropdownContainer = styled.div`
  width: 300px;
  font-size: 12px;
  border-radius: 5px;
  background-color: ${ColorName.aquaHaze};
  .select-prefix__control {
    border-style: solid;
    border-color: transparent;
    box-shadow: none;
    border-width: 1px;
    background-color: ${ColorName.aquaHaze};
  }

  .select-prefix__control--is-focused {
    color: ${ColorName.white};
    cursor: pointer;
    outline: none;
    border-color: ${ColorName.black};
  }

  .select-prefix__control:hover {
    border-color: ${ColorName.black};
  }

  .select-prefix__value-container {
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .select-prefix__option {
    height: 40px;
    color: ${ColorName.darkElectricBlue};
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    cursor: pointer;
    &:hover,
    &.select-prefix__option--is-selected {
      background-color: ${ColorName.steelTeal};
      color: ${ColorName.white};
    }
  }

  .select-prefix__menu {
    margin-top: 5px;
    box-shadow: none;
    border: 1px solid ${ColorName.black};
    border-radius: 0;
  }

  .select-prefix__menu-list {
    padding: 0;
  }
`;

export { DropdownContainer };
