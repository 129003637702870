/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { PopupWrapper, HeaderPopup } from "../DefaultPopup.styles";
import {
  CarrotSolidButton,
  FlexHorizontal,
  FlexVertical,
  GeyserSolidButton,
  InputSearchField,
  OutlineButton,
} from "../../../Common";
import {
  OrderPopupContainer,
  FilterBar,
  FilterLabel,
  TableContent,
  ButtonBar,
  NewsCard,
} from "./SelectNews.styles";
import { ArticlesActions } from "../../../../actions";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../constants";
import { AdminPagination } from "../../../Common";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface SectionProps {
  onClose(id?: string): void;
  className?: string;
  selectedNews: string;
}

const CATEGORY = {
  developmentNews: ROUTES.USER.DEVELOPMENT_NEWS,
  researchNews: ROUTES.USER.RESEARCH_NEWS,
  general: ROUTES.USER.NEWS,
};

const { filterArticles } = ArticlesActions;

const OrderPopup = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  const { onClose, className, selectedNews } = props;
  const lang = useSelector((state) => _.get(state, "PageSettings.adminLang"));
  const isLoading = useSelector((state) => _.get(state, "Articles.isLoading"));
  const payload = useSelector((state) => _.get(state, "Articles.payload"));
  const meta = useSelector((state) => _.get(state, "Articles.meta"));
  const [keyword, setKeyword] = useState<string>("");

  const containerRef = useRef<HTMLDivElement>(null);

  const clickOutsidehandler = () => {
    onClose && onClose();
  };

  useOnClickOutside(containerRef, clickOutsidehandler);

  const dispatch = useDispatch();

  const onFilter = async () => {
    const newFilter = {
      keyword,
      currentPage: 1,
      limit: 10,
    };
    await dispatch(filterArticles(newFilter));
  };

  useEffect(() => {
    onFilter();
  }, []);

  const onReset = () => {
    setKeyword("");
    dispatch(filterArticles({}));
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(filterArticles({ keyword, currentPage, limit: 10 }));
  };

  const renderFilterBar = () => {
    return (
      <FilterBar>
        <FilterLabel className="mr-1">{t("label.filterBy")}</FilterLabel>
        <InputSearchField
          width="250px"
          placeholder={t("placeholder.filterByTitle")}
          value={keyword}
          onChange={(e: any) => setKeyword(e.target.value)}
          onKeydown={() => _.trim(keyword) && onFilter()}
        />
        <CarrotSolidButton className="ml-1" onClick={() => onFilter()}>
          {t("button.search")}
        </CarrotSolidButton>
        <GeyserSolidButton className="ml-1" onClick={() => onReset()}>
          {t("button.reset")}
        </GeyserSolidButton>
      </FilterBar>
    );
  };

  const renderThumbnail = (croppedData: any, thumbnail: string) => {
    const croppedArea = _.get(croppedData, "croppedAreaData");
    if (!croppedArea)
      return (
        <img
          style={{ width: "100%", height: "unset" }}
          src={thumbnail}
          alt=""
        />
      );
    else {
      const scale = 100 / croppedArea.width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto",
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
      };

      return (
        <div className="thumbnail">
          <img
            onContextMenu={(e: any) => e.preventDefault()}
            src={thumbnail}
            alt="news-thumbnail"
            style={imageStyle}
          />
        </div>
      );
    }
  };

  const renderNews = () => {
    if (!isLoading && _.isEmpty(payload)) return t("label.noResultFound");
    const render = _.map(payload, (item, index) => {
      const { croppedData, thumbnail, id, categories } = item;
      const currentCategory = _.get(CATEGORY, categories);
      const isSelected = `/${selectedNews}` === `${currentCategory}/${id}`;
      return (
        <NewsCard key={`news-${id}`}>
          {renderThumbnail(croppedData, thumbnail)}{" "}
          <FlexVertical flex="1" justifySpaceBetween>
            <p>{_.get(item, `title.${lang}`)}</p>
            <FlexHorizontal justifyFlexEnd>
              <OutlineButton
                className={isSelected ? "active" : ""}
                onClick={() =>
                  onClose &&
                  onClose(isSelected ? "" : `${currentCategory}/${id}`)
                }
              >
                {isSelected ? t("button.remove") : t("button.add")}
              </OutlineButton>
            </FlexHorizontal>
          </FlexVertical>{" "}
        </NewsCard>
      );
    });
    return render;
  };

  const renderButtonBar = () => {
    return (
      <ButtonBar className="flex-justify-end flex-align-center">
        <GeyserSolidButton onClick={() => onClose && onClose()}>
          {t("button.close")}
        </GeyserSolidButton>
      </ButtonBar>
    );
  };

  const renderMain = () => {
    return (
      <OrderPopupContainer ref={containerRef}>
        <HeaderPopup>{t("headerPopup.selectNews")}</HeaderPopup>
        {renderFilterBar()}
        <div>
          <TableContent>{renderNews()}</TableContent>
          {!isLoading && !_.isEmpty(payload) && (
            <FlexHorizontal className="pt-1 pb-1" justifyCenter>
              <AdminPagination
                maxPage={_.get(meta, "totalPages")}
                currentPage={_.get(meta, "currentPage")}
                numberPageDisplay={2}
                totalCount={_.get(meta, "totalItems")}
                clickLinkPagination={(page: number) =>
                  handleClickPagination(page)
                }
                clickLinkNext={(page: number) => handleClickPagination(page)}
                clickLinkPrevious={(page: number) =>
                  handleClickPagination(page)
                }
                clickLinkLast={() =>
                  handleClickPagination(_.get(meta, "totalPages"))
                }
                clickLinkFirst={() => handleClickPagination(1)}
              />
            </FlexHorizontal>
          )}
        </div>
        {renderButtonBar()}
      </OrderPopupContainer>
    );
  };
  return (
    <PopupWrapper className={`popup-wrapper ${className}`}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default OrderPopup;
