import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { DefaultInput } from "../Input";
interface ISectionProps {
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
}

const AdminDatePicker: React.FC<ISectionProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <DefaultInput
      value={value}
      placeholder="mm/yyyy"
      onChange={(e) => {
        let newValue = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
        const regex = /^(0[1-9]|1[0-2])$/; //regex check month
        if (newValue.length === 2 && !regex.test(newValue)) {
          e.target.value = "";
          return;
        }
        if (newValue.length > 2) {
          newValue = newValue.slice(0, 2) + "/" + newValue.slice(2); // Add "/" after the 2nd character
        }
        onChange(newValue);
      }}
      maxLength={7}
      disabled={disabled}
    />
  );
};

export default AdminDatePicker;
