import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../Variables';
import { FlexVertical } from '../FlexLayout';

interface SectionProps {
  top?: string;
}

const slideDownToTop = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const OutputMemberImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;

  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
`;

const ContentInformation = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  /* padding: 0px 16px; */
  transition: all ease 0.35s;
  position: absolute;
  top: ${(props: SectionProps) => props.top || 'calc(100% - 74px)'};
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }

  .content {
    background: ${ColorName.white};
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
  }
  .flex_box {
    flex: 0;
    transition: all ease 0.35s;
  }

  h3,
  span {
    user-select: none;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  span {
    color: ${ColorName.darkElectricBlue};
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 5px 0 15px;
  }
  p {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.3rem;
    color: ${ColorName.black};
    margin-bottom: 35px;
  }
`;

const DefaultInformation = styled.div`
  background: ${ColorName.white};
  padding: 10px 20px 0 20px;
  box-sizing: border-box;
  > h3 {
    font-size: 20px;
    line-height: 26px;
  }
  > span {
    color: ${ColorName.darkElectricBlue};
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 5px 0 15px;
  }
`;

const DetailsInformation = styled(DefaultInformation)`
  padding: 10px 20px 10px 20px;
  transition: all ease 0.25s;
`;

const MemberCardContainer = styled(FlexVertical)`
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
  height: auto;
  overflow: hidden;
  position: relative;

  animation: ${slideDownToTop} cubic-bezier(0.51, 0.92, 0.24, 1.15) 0.45s forwards;

  & .default_name {
    color: transparent;
  }
  & .default_position {
    color: transparent;
  }

  & .avatar {
    width: 100%;
  }

  & .empty_box {
    height: 75px;
  }

  &:hover {
    ${ContentInformation} {
      pointer-events: none;
    }
  }

  &.show {
    ${ContentInformation} {
      top: 0;
      &:hover {
        cursor: pointer;
      }
      &::-webkit-scrollbar {
        display: block;
      }
    }
    ${ContentInformation} {
      pointer-events: all;
    }
    .flex_box {
      flex: 1;
    }
  }

  @media screen and (max-width: 426px) {
    & .output {
      width: 100%;
    }
    & .content {
      padding: 10px;
    }
    h3 {
      font-size: 18px;
      line-height: 25px;
    }
    span {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 13px;
      line-height: 19px;
    }
  }
`;

export { MemberCardContainer, OutputMemberImage, DefaultInformation, DetailsInformation, ContentInformation };
