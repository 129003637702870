import styled from 'styled-components';

const TextareaAutosizeContainer = styled.textarea`
  width: 100%;
  display: block;
  font-family: 'Be Vietnam';
  padding: 5px 3px;
  border: 2px solid transparent;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  &:hover {
    border: 2px solid #ccc;
  }

  &.title {
    font-size: 30px;
    font-weight: bold;
    border-radius: 5px;

    color: black;
  }

  &.desc {
    font-size: 16px;
    font-weight: 400;
    color: #8d9a98;
  }
`;

export { TextareaAutosizeContainer };
