import { ROUTES } from '.';

const CLIENTS_INDEX = {
  [ROUTES.USER.HOMEPAGE]: [
    '612e0fd3-f49a-4627-bb15-d49033cd4a2c',
    '22b4e9f1-d7ac-4105-8d0d-3b1e8623b307',
    'f4b7a458-f89a-466d-bc30-13d5378cfae3',
    'd70cd09a-48e3-4a70-be7f-bcbce4b2f5eb',
    '347e86fb-4eb8-41ab-b80e-7fc43d76755b',
    'b2d7fa8a-e7f7-4003-b400-3a2250cfb513',
    'b5c7a732-f57d-4190-9b7f-03d732778a83',
    'fcafcd44-7d87-47a4-a7d2-ac13255bc0c9',
    '3d1fa728-c754-4bd9-aade-d4e5af5499d4',
    '8869f0cc-f2a8-4d03-8997-ca4a2cd3de05',
    'c632d9a8-f599-4113-b5b5-a4e5aee2ab1b',
    '8d60ed67-29fa-4f09-999b-e0d5f00304c3',
    '3add334f-ae2d-4928-80f5-cd7722f79112',
    '09c202ce-2ad4-46bf-ad70-e563f38d238d',
    'fec4afe8-cc7e-47fa-a51f-dc77608b8643',
    '2aa6e41e-5953-4e27-ad53-75897370c08d',
    '3a050a6f-0c13-4dd6-9a1c-4e637641062a',
    '62735520-925a-4aac-9cd7-bcd834f7f405',
    '54fe5e34-24b1-4d5c-8de0-492076d68c4e',
    '8a36cdd1-74e1-4ec5-b26f-872cbb32b92b',
    '86072c9c-0359-47eb-a9f2-f3fc747a1637',
    '612e0fd3-f49a-4627-bb15-d49033cd4a2c',
    '22b4e9f1-d7ac-4105-8d0d-3b1e8623b307',
    'f4b7a458-f89a-466d-bc30-13d5378cfae3',
    'd70cd09a-48e3-4a70-be7f-bcbce4b2f5eb',
    '347e86fb-4eb8-41ab-b80e-7fc43d76755b',
    'b2d7fa8a-e7f7-4003-b400-3a2250cfb513',
    'b5c7a732-f57d-4190-9b7f-03d732778a83',
    'fcafcd44-7d87-47a4-a7d2-ac13255bc0c9',
    '3d1fa728-c754-4bd9-aade-d4e5af5499d4',
    '8869f0cc-f2a8-4d03-8997-ca4a2cd3de05',
    'c632d9a8-f599-4113-b5b5-a4e5aee2ab1b',
    '8d60ed67-29fa-4f09-999b-e0d5f00304c3',
  ],
};

export default CLIENTS_INDEX;
