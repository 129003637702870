import styled from 'styled-components';
import { ColorName } from '../../../components/Variables';

const PreviewLayer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
`;

const PreviewVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const PreviewContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
`;

const PreviewLabel = styled.h3`
  font-size: 30px;
  line-height: 40px;
  max-width: 480px;
`;

const PreviewSubtitle = styled.span`
  display: block;
  font-size: 16px;
  margin-top: 15px;
  text-transform: uppercase;
`;

const PreviewModuleBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 37px;
  height: max-content;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 3px;
  background: ${ColorName.white};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${ColorName.darkElectricBlue};
`;

const PreviewDots = styled.div`
  width: 45px;
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;

  span {
    position: absolute;
    height: 80px;
    top: 0;
    left: 0;

    &.dot-background {
      width: 100%;
      opacity: 0.3;
    }
    &.dot-grow {
      width: 45%;
    }
  }
`;

const ModuleButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid ${ColorName.darkElectricBlue};
  background: ${ColorName.white};
  color: ${ColorName.darkElectricBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 3px;
  position: relative;

  input[type="color"] {
    opacity: 0;
    height: 0;
    right: 0;
    top: 100%;
    width: 0;
    position: absolute;
    &::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
      padding: 0;
    }

    &::-webkit-color-swatch-wrapper {
      border: none;
      border-radius: 50%;
      padding: 0;
    }
  }

  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    i {
      color: ${ColorName.white};
    }
  }
`;

const PreViewSiteSettingContainer = styled.div`
  background: white;
  width: 70vw;
  max-width: 1440px;
  position: relative;
  border: 1px solid ${ColorName.darkElectricBlue};
  box-sizing: border-box;
`;

const PreviewButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 35px;
  height: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ColorName.geyser};
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export {
  PreviewLayer,
  PreViewSiteSettingContainer,
  PreviewContent,
  PreviewDots,
  PreviewImage,
  PreviewVideo,
  PreviewLabel,
  PreviewSubtitle,
  PreviewModuleBar,
  ModuleButton,
  PreviewButton,
};
