import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../../Variables';

interface SectionProps {
  background?: string;
  color?: string;
}

const VisiblePopup = keyframes`
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.2,1.2);
  }
  100% {
    transform: scale(1,1);
  }
`;

// RENDER ICONS

const IconWrapper = styled.div`
  margin: 0 auto;

  .screenAlert-icon {
    border-radius: 50%;
    border: 4px solid gray;
    box-sizing: content-box;
    height: 80px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 80px;
  }
  .screenAlert-icon + .screenAlert-icon {
    margin-top: 50px;
  }

  /* CSS SUCCESS */
  .screenAlert-icon.screenAlert-success {
    border-color: ${ColorName.feijoa};
  }
  .screenAlert-icon.screenAlert-success:after,
  .screenAlert-icon.screenAlert-success:before {
    background-color: transparent !important;
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px;
  }
  .screenAlert-icon.screenAlert-success:before {
    border-radius: 120px 0 0 120px;
    left: -33px;
    top: -7px;
    transform-origin: 60px 60px;
    transform: rotate(-45deg);
  }
  .screenAlert-icon.screenAlert-success:after {
    border-radius: 0 120px 120px 0;
    left: 30px;
    top: -11px;
    transform-origin: 0 60px;
    transform: rotate(-45deg);
  }
  .screenAlert-icon.screenAlert-success .screenAlert-placeholder {
    border-radius: 50%;
    border: 4px solid rgba(165, 220, 134, 0.2);
    box-sizing: content-box;
    height: 80px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 80px;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-success .screenAlert-line {
    background-color: ${ColorName.feijoa};
    border-radius: 2px;
    display: block;
    height: 5px;
    position: absolute;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-tip {
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
    width: 25px;
  }
  .screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-long {
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
    width: 47px;
  }
  .animateSuccessTip {
    animation: animateSuccessTip 0.75s;
  }
  .animateSuccessLong {
    animation: animateSuccessLong 0.75s;
  }
  /* ANIMATE SUCCESS */
  @keyframes animateSuccessTip {
    0%,
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes animateSuccessLong {
    0%,
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

  /* CSS ALERT */
  .screenAlert-icon.screenAlert-error {
    border-color: ${ColorName.froly};
  }
  .screenAlert-icon.screenAlert-error:after,
  .screenAlert-icon.screenAlert-error:before {
    background-color: transparent !important;
    content: '';
    height: 120px;
    position: absolute;
    transform: rotate(45deg);
    width: 60px;
  }
  .screenAlert-icon.screenAlert-error:before {
    border-radius: 120px 0 0 120px;
    left: -33px;
    top: -7px;
    transform-origin: 60px 60px;
    transform: rotate(-45deg);
  }
  .screenAlert-icon.screenAlert-error:after {
    border-radius: 0 120px 120px 0;
    left: 30px;
    top: -11px;
    transform-origin: 0 60px;
    transform: rotate(-45deg);
  }
  .screenAlert-icon.screenAlert-error .screenAlert-placeholder {
    border-radius: 50%;
    border: 4px solid rgba(165, 220, 134, 0.2);
    box-sizing: content-box;
    height: 80px;
    left: -4px;
    position: absolute;
    top: -4px;
    width: 80px;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line {
    background-color: ${ColorName.feijoa};
    border-radius: 2px;
    display: block;
    height: 5px;
    position: absolute;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-tip {
    left: 14px;
    top: 46px;
    transform: rotate(45deg);
    width: 25px;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-long {
    right: 8px;
    top: 38px;
    transform: rotate(-45deg);
    width: 47px;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-x-mark {
    display: block;
    position: relative;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-placeholder {
    border: 4px solid rgba(200, 0, 0, 0.2);
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line {
    background-color: ${ColorName.froly};
    top: 37px;
    width: 47px;
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-left {
    left: 17px;
    transform: rotate(45deg);
  }
  .screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-right {
    right: 16px;
    transform: rotate(-45deg);
  }
  .animateXLeft {
    animation: animateXLeft 0.75s;
  }
  .animateXRight {
    animation: animateXRight 0.75s;
  }
  /* ANIMATE CROSS X */
  @keyframes animateXLeft {
    0%,
    65% {
      left: 82px;
      top: 95px;
      width: 0;
    }
    84% {
      left: 14px;
      top: 33px;
      width: 47px;
    }
    100% {
      left: 17px;
      top: 37px;
      width: 47px;
    }
  }
  @keyframes animateXRight {
    0%,
    65% {
      right: 82px;
      top: 95px;
      width: 0;
    }
    84% {
      right: 14px;
      top: 33px;
      width: 47px;
    }
    100% {
      right: 16px;
      top: 37px;
      width: 47px;
    }
  }

  /* CSS WARNING */
  .screenAlert-icon.screenAlert-warning {
    border-color: ${ColorName.manhattan};
  }
  .screenAlert-icon.screenAlert-warning:before {
    animation: pulseWarning 2s linear infinite;
    background-color: transparent !important;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  .screenAlert-icon.screenAlert-warning:after {
    background-color: transparent !important;
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .screenAlert-icon.screenAlert-warning .screenAlert-body {
    background-color: ${ColorName.manhattan};
    border-radius: 2px;
    height: 47px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 10px;
    width: 5px;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-warning .screenAlert-dot {
    background-color: ${ColorName.manhattan};
    border-radius: 50%;
    bottom: 10px;
    height: 7px;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    width: 7px;
    z-index: 2;
  }
  .scaleWarning {
    animation: scaleWarning 0.75s infinite alternate;
  }
  .pulseWarningIns {
    animation: pulseWarningIns 0.75s infinite alternate;
  }
  /* ANIMATE WARNING */
  @keyframes scaleWarning {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseWarning {
    0% {
      background-color: ${ColorName.white};
      transform: scale(1);
      opacity: 0.5;
    }
    30% {
      background-color: ${ColorName.white};
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      background-color: ${ColorName.manhattan};
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes pulseWarningIns {
    0% {
      background-color: ${ColorName.sweetCorn};
    }
    100% {
      background-color: ${ColorName.manhattan};
    }
  }

  /* CSS INFO */
  .screenAlert-icon.screenAlert-info {
    border-color: ${ColorName.sail};
  }
  .screenAlert-icon.screenAlert-info:before {
    animation: pulseInfo 2s linear infinite;
    background-color: transparent !important;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  .screenAlert-icon.screenAlert-info:after {
    background-color: transparent !important;
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .screenAlert-icon.screenAlert-info .screenAlert-body {
    background-color: ${ColorName.pictonBlue};
    border-radius: 2px;
    height: 47px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 10px;
    width: 5px;
    z-index: 2;
  }
  .screenAlert-icon.screenAlert-info .screenAlert-dot {
    background-color: ${ColorName.pictonBlue};
    border-radius: 50%;
    bottom: 10px;
    height: 7px;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    width: 7px;
    z-index: 2;
  }
  .scaleInfo {
    animation: scaleInfo 0.75s infinite alternate;
  }
  .pulseInfoIns {
    animation: pulseInfoIns 0.75s infinite alternate;
  }
  /* ANIMATE INFO */
  @keyframes scaleInfo {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseInfo {
    0% {
      background-color: ${ColorName.white};
      transform: scale(1);
      opacity: 0.5;
    }
    30% {
      background-color: ${ColorName.white};
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      background-color: ${ColorName.sail};
      transform: scale(2);
      opacity: 0;
    }
  }
  @keyframes pulseInfoIns {
    0% {
      background-color: ${ColorName.pictonBlue};
    }
    100% {
      background-color: ${ColorName.sail};
    }
  }

  @media screen and (max-width: 426px) {
    transform: scale(0.8);
    img {
      width: 100%;
    }
  }
`;

// RENDER CONTAINER

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  background-color: rgba(16, 16, 16, 0.7);
  width: 100vw;
  height: 100%;
`;

const Container = styled.div`
  background: ${ColorName.white};
  border-radius: 5px;
  border: 1px solid ${ColorName.white};
  min-height: 250px;
  max-height: 450px;
  width: 600px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  animation: ${VisiblePopup} 0.5s;
  & .alert__message {
    overflow: auto;
    font-size: 14px;
    margin: unset;
    text-align: center;
    white-space: pre-line;
    line-height: 21px;
    color: ${ColorName.grannySmith};

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${ColorName.aquaHaze};
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ColorName.darkElectricBlue};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${ColorName.darkElectricBlue};
      border: 2px solid #555555;
    }
  }

  @media screen and (max-width: 426px) {
    width: 70%;
    min-height: 200px;

    & .alert__message {
      flex: 1;
    }
  }
`;

const Title = styled.p`
  margin: 0.5em auto;
  font-size: 30px;
  font-weight: bold;
  line-height: 44px;
  &.title_success {
    color: ${ColorName.emerald};
  }
  &.title_error {
    color: ${ColorName.burntSienna};
  }
  &.title_warning {
    color: ${ColorName.sandyBrown};
  }

  @media screen and (max-width: 426px) {
    font-size: 24px;
    margin: 0 auto;
  }
`;

const Button = styled.button`
  color: ${(props: SectionProps) => props.color || 'white'};
  background: ${(props: SectionProps) => props.background || ColorName.burntSienna};
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  border-radius: 5px;
  padding: 5px 10px;
  min-width: 100px;
  height: 45px;
  font-size: 14px;
  transition: all ease 0.25s;
  &:hover {
    filter: brightness(95%);
  }

  @media screen and (max-width: 426px) {
    min-width: 70px;
    height: 35px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 3em;
  button {
    margin-right: 1em;
  }
  button:last-child {
    margin-right: 0;
  }
`;

export { Wrapper, Container, IconWrapper, Title, Button, ButtonWrapper };
