/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES, CONSTANTS } from "../../../constants";
import { ColorName } from "../../../components/Variables";
import {
  AdminDropdown,
  GeyserSolidButton,
  CarrotSolidButton,
  FlexHorizontal,
  TabBar,
  AdminLabel,
  FlexVertical,
  Radio,
  ContentForm,
  ContentContainer,
  UpdatedLabel,
  ReactQuill,
  TextareaAutosize,
} from "../../../components/Common";
import { PreviewCareers, SelectMedia } from "../../../components/Layout/Popups";
import { CareersActions, PageSettingsActions } from "../../../actions";
import { statusAlert } from "../../../libs";
import history from "../../../history";
import { TAB_BAR } from "../../../constants/Constants";

const { createCareer, updateCareer, getCareerById, clearCareersDetails } =
  CareersActions;
const { setStatusEdited } = PageSettingsActions;

const CareerDetails = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducer
  const dispatch = useDispatch();
  const careerDetails = useSelector((state) => _.get(state, "Careers.details"));
  const requestIsLoading = useSelector((state) =>
    _.get(state, "Careers.isLoading")
  );
  const mediaIsLoading = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const timezone = useSelector((state) =>
    _.get(state, "PageSettings.ip.timezone")
  );
  // Declare states
  const [selectedTab, setSelectedTab] = useState<string>("options");
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowPreviewPopup, setIsShowPreviewPopup] = useState<boolean>(false);

  const [details, setDetails] = useState<any>({});

  const detailsRef = useRef<any>(null);
  detailsRef.current = details;

  useEffect(() => {
    const id = _.get(queryStringValue, "id");
    if (_.isString(id) && _.includes(id, "-")) dispatch(getCareerById(id));

    return () => {
      dispatch(clearCareersDetails());
      dispatch(setStatusEdited(false));
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(careerDetails)) {
      setDetails(careerDetails);
    }
  }, [careerDetails]);

  const onChangeHandler = async (
    value: any,
    key: string,
    defaultValue?: any
  ) => {
    const resolveDetails = {
      ...detailsRef.current,
      [key]: value,
    };
    setDetails(resolveDetails);
  };

  const checkValidateForm = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    const validateData = [
      {
        label: t("alert.status"),
        value: _.get(details, "status"),
      },
    ];
    if (languageDisplay === "both")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.en"),
            label: t("alert.titleInEnglish"),
          },
          {
            value: _.get(details, "description.en"),
            label: t("alert.descriptionInEnglish"),
          },
          {
            value: _.trim(_.get(details, "content.en")),
            label: t("alert.contentInEnglish"),
          },
          {
            value: _.get(details, "title.al"),
            label: t("alert.titleInShqip"),
          },
          {
            value: _.get(details, "description.al"),
            label: t("alert.descriptionInShqip"),
          },
          {
            value: _.trim(_.get(details, "content.al")),
            label: t("alert.contentInShqip"),
          },
        ]
      );
    if (languageDisplay === "en")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.en"),
            label: t("alert.titleInEnglish"),
          },
          {
            value: _.get(details, "description.en"),
            label: t("alert.descriptionInEnglish"),
          },
          {
            value: _.trim(_.get(details, "content.en")),
            label: t("alert.contentInEnglish"),
          },
        ]
      );
    if (languageDisplay === "al")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.al"),
            label: t("alert.titleInShqip"),
          },
          {
            value: _.get(details, "description.al"),
            label: t("alert.descriptionInShqip"),
          },
          {
            value: _.trim(_.get(details, "content.al")),
            label: t("alert.contentInShqip"),
          },
        ]
      );
    let flag = true;
    let message = "";
    _.map(validateData, (item) => {
      if (!item.value) {
        message = message + `\n ${item.label}`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const handleSubmit = async () => {
    const { flag, message } = await checkValidateForm();
    if (!flag)
      return await statusAlert(
        "warning",
        `${t("alert.foundEmptyFields")} : ${message}`
      );

    const isHasId = _.get(details, "id");
    if (isHasId) await dispatch(updateCareer(details));
    else await dispatch(createCareer(details));
  };

  const handleHidePopup = (value: string, type: string) => {
    if (value) {
      let resolveValue;
      if (type === "pdf")
        resolveValue = `<a href=\"${value}\" title=\"Here\">${
          selectedTab === "en" ? "HERE" : "KËTU"
        }</a>`;
      else if (type === "image") {
        const altImage = _.replace(
          _.lowerCase(_.get(details, "title.en")),
          /\s/g,
          "-"
        );
        resolveValue = `<img src=\"${value}\" alt=\"${altImage}">`;
      } else
        resolveValue = `<iframe class="ql-video" frameborder="0" allowfullscreen="true" scrolling="no" sanbox="" loading="eager" src="${value}" controls></iframe>`;

      const detailsContent = _.get(details, `content.${selectedTab}`);
      if (selectedTab === "en")
        onChangeHandler(
          {
            ...details.content,
            [selectedTab]: `${detailsContent} ${resolveValue}`,
          },
          "content"
        );
      if (selectedTab === "al")
        onChangeHandler(
          {
            ...details.content,
            [selectedTab]: `${detailsContent} ${resolveValue}`,
          },
          "content"
        );
    }
    setIsShowPopup(false);
  };

  const onBack = async () => {
    if (isEditing) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningConfirmLeaveWhenEditing")
      );
      if (isAgree) history.goBack();
    } else history.goBack();
  };

  const renderCareerOptions = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    if (selectedTab === "options")
      return (
        <FlexVertical alignFlexEnd>
          <FlexHorizontal alignCenter flex="1" className="mt-1">
            <AdminLabel width="100px">{t("label.language")}</AdminLabel>
            {_.map(
              [
                { label: "bothLanguages", value: "both" },
                { label: "onlyEnglish", value: "en" },
                { label: "onlyShqip", value: "al" },
              ],
              (item, index) => {
                const className =
                  item.value === languageDisplay ? "active" : "";
                return (
                  <Radio
                    className={className}
                    flex="1"
                    key={`radio-${index}`}
                    onClick={(value: string) =>
                      onChangeHandler(value, "languageDisplay")
                    }
                    value={item.value}
                    label={t(`label.${item.label}`)}
                    defaultSelected={languageDisplay}
                  />
                );
              }
            )}
          </FlexHorizontal>
          <FlexHorizontal className="mr-row" alignCenter flex="1">
            <AdminLabel width="100px">{t("label.status")}</AdminLabel>
            <AdminDropdown
              width="200px"
              data={[
                { label: "active", value: "active" },
                { label: "inactive", value: "inactive" },
              ]}
              selectedValue={_.get(details, "status")}
              onChange={(value: any) => onChangeHandler(value, "status")}
            />
          </FlexHorizontal>
          <FlexHorizontal className="mr-row" alignCenter flex="1">
            <AdminLabel width="100px">{t("label.form")}</AdminLabel>
            <AdminDropdown
              width="200px"
              data={[
                { label: "specificForm", value: true },
                { label: "unspecificForm", value: false },
              ]}
              selectedValue={_.get(details, "specific")}
              onChange={(value: any) => onChangeHandler(value, "specific")}
            />
          </FlexHorizontal>
        </FlexVertical>
      );
  };

  const renderPopups = () => {
    return (
      <>
        {isShowPopup && (
          <SelectMedia
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(value: string, type: string) =>
              handleHidePopup(value, type)
            }
            tabBar={TAB_BAR.CAREERS_POPUP}
            selectedTab="pdf"
          />
        )}
        {isShowPreviewPopup && (
          <PreviewCareers
            details={details}
            className={`${isShowPreviewPopup ? "show" : ""}`}
            onClose={() => setIsShowPreviewPopup(false)}
          />
        )}
      </>
    );
  };

  const renderCareerDetails = () => {
    const isSelectedContent = selectedTab === "en" || selectedTab === "al";
    if (isSelectedContent)
      return (
        <React.Fragment key={`content-${selectedTab}`}>
          <TextareaAutosize
            type="title"
            placeholder={t("placeholder.typeTheTitleHere")}
            value={_.get(details, `title.${selectedTab}`)}
            onChange={(event: any) =>
              onChangeHandler(
                {
                  ...details.title,
                  [selectedTab]: event.target.value,
                },
                "title",
                _.trim(event.target.value)
              )
            }
          />
          <TextareaAutosize
            type="desc"
            placeholder={t("placeholder.typeTheDescriptionHere")}
            value={_.get(details, `description.${selectedTab}`)}
            onChange={(event: any) =>
              onChangeHandler(
                {
                  ...details.description,
                  [selectedTab]: event.target.value,
                },
                "description",
                _.trim(event.target.value)
              )
            }
          />
          <ReactQuill
            onAddMedia={() => setIsShowPopup(true)}
            defaultValue={_.get(details, `content.[${selectedTab}]`)}
            value={_.get(details, `content.[${selectedTab}]`)}
            onChange={(value: any) =>
              onChangeHandler(
                {
                  ...details.content,
                  [selectedTab]: _.trim(value),
                },
                "content",
                _.trim(value)
              )
            }
            placeholder={t("placeholder.typeTheContentHere")}
          />
        </React.Fragment>
      );
  };

  const renderMain = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    const lastUpdated = _.get(details, "updatedAt");
    const currentTabBar = _.get(
      CONSTANTS.TAB_BAR.CAREER_DETAILS,
      languageDisplay
    );
    return (
      <ContentContainer>
        <ContentForm className="mb-2">
          <TabBar
            className="mb-2"
            selectedTab={selectedTab}
            data={currentTabBar}
            onChange={(value: string) => setSelectedTab(value)}
          />
          {lastUpdated && (
            <UpdatedLabel className="mt-1 mb-1">
              {t("label.lastUpdated")}: {lastUpdated}
            </UpdatedLabel>
          )}
          {renderCareerOptions()}
          {renderCareerDetails()}
          <FlexHorizontal alignCenter className="mt-3">
            <GeyserSolidButton className="mr-1" onClick={() => onBack()}>
              {t("button.back")}
            </GeyserSolidButton>
            <FlexHorizontal alignCenter justifyFlexEnd>
              <CarrotSolidButton
                className="mr-1"
                color={ColorName.burntSienna}
                onClick={() => setIsShowPreviewPopup(true)}
              >
                {t("button.preview")}
              </CarrotSolidButton>
              <CarrotSolidButton
                color={ColorName.burntSienna}
                onClick={() => handleSubmit()}
              >
                {t("button.save")}
              </CarrotSolidButton>
            </FlexHorizontal>
          </FlexHorizontal>
        </ContentForm>
        {renderPopups()}
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.CAREER_DETAILS}
      loading={requestIsLoading || mediaIsLoading}
    />
  );
};

export default CareerDetails;
