/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { SecondHeader } from "../../../../components/Layout/CommonLayoutPart";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import {
  EditWhoWeArePageContainer,
  OurValueBox,
  PartOfContent,
  TimelineLabel,
} from "./EditWhoWeArePage.styles";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../constants";
import {
  FlexVertical,
  OurlineGrowButton,
  BlackRegularParagraph,
  GridLayout,
  Richtextbox,
} from "../../../../components/Common";
import { useTranslation } from "react-i18next";

interface SectionProps {
  className?: string;
}

const SECOND_HEADER_TYPE = [
  {
    label: "aboutUs",
    value: "aboutUs",
  },
  {
    label: "qualityAssurance",
    value: "qualityAssurance",
  },
  {
    label: "ourValues",
    value: "ourValues",
  },
  {
    label: "ourPartners",
    value: "ourPartners",
  },
];

const { setEditContentPayload, getAllContentForUser } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditWhoWeArePage = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload: any = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.WHO_WE_ARE}`)
  );
  const lang: any = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );
  const [secondHeaderType, setSecondHeaderType] = useState<string>("aboutUs");
  const [defaultValue, setDefaultValue] = useState<string>("");

  useEffect(() => {
    dispatch(getAllContentForUser());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  const handleChange = (value: any, index?: number, key?: string) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const newPayload = {
      ...payload,
    };
    if (secondHeaderType === "ourValues" && _.isNumber(index)) {
      const getOurValues = _.get(payload, "ourValues");
      getOurValues[index] = value;
      _.assign(newPayload, { ourValues: getOurValues });
    }
    if (secondHeaderType === "qualityAssurance") {
      const getQualityAssurance = _.get(payload, "qualityAssurance");
      const qualityAssurance = {
        ...getQualityAssurance,
        [lang]: value,
      };
      _.assign(newPayload, { qualityAssurance });
    }
    if (secondHeaderType === "ourPartners" && _.isNumber(index)) {
      const ourPartners = _.get(payload, "ourPartners");
      ourPartners[index] = value;
      _.assign(newPayload, { ourPartners });
    }
    if (secondHeaderType === "aboutUs") {
      const aboutUs = _.get(payload, "aboutUs");
      if (_.isNumber(index) && !key) {
        const timeline = _.get(aboutUs, "timeline");
        timeline[index] = value;
        _.assign(newPayload, { aboutUs: { ...aboutUs, timeline } });
      } else if (_.isNumber(index) && key) {
        const currentResult = _.get(aboutUs, "currentResult");
        const missionAndVision = _.get(currentResult, "missionAndVision");
        missionAndVision[index] = value;
        _.assign(newPayload, {
          currentResult: {
            ...currentResult,
            missionAndVision,
          },
        });
      } else _.assign(newPayload, { aboutUs: value });
    }
    dispatch(
      setEditContentPayload({
        ...contentPayload,
        [ROUTES.USER.WHO_WE_ARE]: newPayload,
      })
    );
  };

  const renderAboutUs = () => {
    const aboutUs = _.get(payload, "aboutUs");
    return (
      <>
        <Richtextbox
          data={_.get(payload, `aboutUs.descriptionType.${lang}`) || ""}
          onFocus={(value: any) => dispatch(setContentEdited(_.trim(value)))}
          onChange={(value: string) => {
            setDefaultValue(_.trim(value));
            handleChange({
              ...aboutUs,
              descriptionType: {
                ...aboutUs.descriptionType,
                [lang]: value,
              },
            });
          }}
          fontSize="16px"
          toolbar={[
            "bold",
            "link",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
          ]}
        />
        <Richtextbox
          data={
            _.get(payload, `aboutUs.currentResult.description.${lang}`) || ""
          }
          onFocus={(value: any) => dispatch(setContentEdited(_.trim(value)))}
          onChange={(value: string) => {
            setDefaultValue(_.trim(value));
            handleChange({
              ...aboutUs,
              currentResult: {
                ...aboutUs.currentResult,
                description: {
                  ...aboutUs.currentResult.description,
                  [lang]: value,
                },
              },
            });
          }}
          fontSize="16px"
          toolbar={[
            "bold",
            "link",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
          ]}
        />
        <GridLayout col="2" colGap="50px" className="mt-4 mb-2">
          {_.map(
            _.get(payload, `aboutUs.currentResult.missionAndVision`),
            (item, index) => {
              const title = _.get(item, `title.${lang}`);
              const description = _.get(item, `description.${lang}`);
              return (
                <FlexVertical key={`current-result-${index}`}>
                  <TimelineLabel
                    className="year editable"
                    spellCheck={false}
                    contentEditable
                    suppressContentEditableWarning
                    dangerouslySetInnerHTML={{ __html: title }}
                    width="100%"
                    data-placeholder={t("placeholder.typeTheTitleHere")}
                    onFocus={(event: any) =>
                      dispatch(
                        setContentEdited(_.trim(event.currentTarget.innerText))
                      )
                    }
                    onBlur={(e: any) => {
                      setDefaultValue(_.trim(e.currentTarget.innertEXT));
                      handleChange(
                        {
                          ...item,
                          title: {
                            ...item.title,
                            [lang]: e.currentTarget.innerText,
                          },
                        },
                        _.parseInt(index),
                        "result"
                      );
                    }}
                  />
                  <BlackRegularParagraph
                    className="editable"
                    spellCheck={false}
                    contentEditable
                    suppressContentEditableWarning
                    data-placeholder={t("placeholder.typeTheDescriptionHere")}
                    dangerouslySetInnerHTML={{ __html: description }}
                    onFocus={(event: any) =>
                      dispatch(
                        setContentEdited(_.trim(event.currentTarget.innerText))
                      )
                    }
                    onBlur={(e: any) => {
                      setDefaultValue(_.trim(e.currentTarget.innerText));
                      handleChange(
                        {
                          ...item,
                          description: {
                            ...item.description,
                            [lang]: e.currentTarget.innerText,
                          },
                        },
                        _.parseInt(index),
                        "result"
                      );
                    }}
                  />
                </FlexVertical>
              );
            }
          )}
        </GridLayout>
      </>
    );
  };

  const renderOurValues = () => {
    return (
      <GridLayout className="mt-2 mb-3" col="2" rowGap="35px" colGap="140px">
        {_.map(_.get(payload, "ourValues"), (item, index) => {
          const icon = _.get(item, "icon");
          const title = _.get(item, `title.${lang}`);
          const description = _.get(item, `description.${lang}`);
          return (
            <OurValueBox key={`our-values-${index}`}>
              <div className="description">
                <div className="image-value">
                  <img src={icon} alt="our-value icon" />
                </div>
                <h3
                  spellCheck={false}
                  suppressContentEditableWarning
                  contentEditable={true}
                  data-placeholder={t("placeholder.typeTheTitleHere")}
                  onFocus={(event: any) =>
                    dispatch(
                      setContentEdited(_.trim(event.currentTarget.innerText))
                    )
                  }
                  onBlur={(e: any) => {
                    setDefaultValue(e.currentTarget.innerText);
                    handleChange(
                      {
                        ...item,
                        title: {
                          ...item.title,
                          [lang]: e.currentTarget.innerText,
                        },
                      },
                      _.parseInt(index)
                    );
                  }}
                  className="title editable"
                >
                  {title}
                </h3>
              </div>
              <p
                spellCheck={false}
                suppressContentEditableWarning
                contentEditable={true}
                data-placeholder={t("placeholder.typeTheDescriptionHere")}
                onFocus={(event: any) =>
                  dispatch(
                    setContentEdited(_.trim(event.currentTarget.innerText))
                  )
                }
                onBlur={(e: any) => {
                  setDefaultValue(_.trim(e.currentTarget.innerText));
                  handleChange(
                    {
                      ...item,
                      description: {
                        ...item.description,
                        [lang]: e.currentTarget.innerText,
                      },
                    },
                    _.parseInt(index)
                  );
                }}
                className="subtitle editable"
              >
                {description}
              </p>
            </OurValueBox>
          );
        })}
      </GridLayout>
    );
  };

  const renderQualityAssurance = () => {
    return (
      <Richtextbox
        className="mt-1"
        onChange={(value: any) => handleChange(value)}
        data={_.get(payload, `qualityAssurance.${lang}`) || ""}
        fontSize="16px"
        toolbar={[
          "bold",
          "link",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "undo",
          "redo",
        ]}
      />
    );
  };

  const renderOurPartners = () => {
    return (
      <GridLayout rowGap="20px" className="pb-2 custom-ourpartners">
        {_.map(_.get(payload, "ourPartners"), (item, index: number) => {
          // https://jira.idra.al/browse/VI-33
          if (index !== 0) return;
          const logo =
            "https://idracompany.com/static/media/logo_header.513f0ab5.svg";
          const link = _.get(item, "link");
          const description = _.get(item, `description.${lang}`);
          return (
            <PartOfContent key={`partners-${index}`}>
              <div className="logo">
                <img src={logo} alt="partner logo" />
              </div>
              <Richtextbox
                className="our-partners"
                fontSize="16px"
                onFocus={(value: any) =>
                  dispatch(setContentEdited(_.trim(value)))
                }
                onChange={(value: any) => {
                  setDefaultValue(_.trim(value));
                  handleChange(
                    {
                      ...item,
                      description: {
                        ...item.description,
                        [lang]: value,
                      },
                    },
                    index
                  );
                }}
                data={description}
              />
              <OurlineGrowButton
                className="mt-2"
                label="visitWebsite"
                isLink
                url={link}
                disabled
              />
            </PartOfContent>
          );
        })}
      </GridLayout>
    );
  };

  return (
    <EditWhoWeArePageContainer>
      <SecondHeader
        adminPosition
        active={secondHeaderType}
        onChange={(value: string) => setSecondHeaderType(value)}
        data={SECOND_HEADER_TYPE}
        className="mb-2"
      />
      {secondHeaderType === "aboutUs" && renderAboutUs()}
      {secondHeaderType === "ourValues" && renderOurValues()}
      {secondHeaderType === "qualityAssurance" && renderQualityAssurance()}
      {secondHeaderType === "ourPartners" && renderOurPartners()}
    </EditWhoWeArePageContainer>
  );
};

export default EditWhoWeArePage;
