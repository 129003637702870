import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  LoginContainer,
  ContentCol,
  InputField,
  LoginForm,
  CheckboxSquare,
  CheckboxContainer,
  Label,
  LoginContent,
} from "./Authentication.styles";
import { Icons, Images } from "../../../themes";

import { AuthenticationActions, AccountActions } from "../../../actions";
import {
  UserCarrotButton,
  Loading,
  FlexHorizontal,
} from "../../../components/Common";
import { Utils } from "../../../libs";

// Declare actions
const { login } = AuthenticationActions;
const { setAccountDetails } = AccountActions;

const Login = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducer
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    get(state, "Authentication.isLoading")
  );
  // Declare ref
  const usernameRef = useRef<HTMLInputElement>(null);
  // Declare states
  const [focusStatus, setFocusStatus] = useState<any>({
    username: false,
    password: false,
  });
  const [postData, setPostData] = useState<any>({
    username: "",
    password: "",
  });
  const [isRemember, setIsRemember] = useState<boolean>(false);
  const [isHidePassword, setIsHidePassword] = useState<boolean>(true);

  useEffect(() => {
    if (usernameRef && usernameRef.current) usernameRef.current.focus();
    const remember = Utils.getRemember();
    if (remember) setIsRemember(remember);
  }, []);

  // Handle submit login form
  const onSubmit = async () => {
    await dispatch(login(postData));
    await dispatch(
      setAccountDetails({
        ...postData,
        username: get(postData, "username"),
        password: "",
      })
    );
  };

  // Handle focus and blur on input
  const handleFocusAndBlur = (key: string, type: string) => {
    const status = type === "focus";
    const resolveStatus = {
      ...focusStatus,
      [key]: status,
    };
    setFocusStatus(resolveStatus);
  };

  // Handle change check remember
  const handleChangeRemember = () => {
    setIsRemember(!isRemember);
    Utils.setRemember(!isRemember);
  };

  const renderLoginIllustrator = () => {
    return (
      <ContentCol className="illustrator-col">
        <FlexHorizontal>
          <img src={Images.logoHeader.default} alt="small logo" />
        </FlexHorizontal>
        <FlexHorizontal flex="1" alignCenter justifyCenter>
          <img
            className="illustrator-login"
            src={Images.loginImage.default}
            alt="login illustrator"
          />
        </FlexHorizontal>
      </ContentCol>
    );
  };

  const renderInputForm = () => {
    return (
      <ContentCol background="white">
        <img
          className="medium-logo"
          src={Images.smallLogo.default}
          alt="small logo"
        />
        <LoginForm>
          <h3>{t("label.authentication")}</h3>
          <InputField
            className={`${get(focusStatus, "username") ? "focus" : ""}`}
            onChange={(e: any) =>
              setPostData({ ...postData, username: e.target.value })
            }
          >
            <img src={Icons.mail.default} alt="mail icon" />
            <input
              placeholder={t("placeholder.username")}
              type="text"
              ref={usernameRef}
              onFocus={() => handleFocusAndBlur("username", "focus")}
              onBlur={() => handleFocusAndBlur("username", "blur")}
              onKeyDown={(e: any) => e.keyCode === 13 && onSubmit()}
            />
          </InputField>
          <InputField
            className={`${get(focusStatus, "password") ? "focus" : ""}`}
            onChange={(e: any) =>
              setPostData({ ...postData, password: e.target.value })
            }
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                handleFocusAndBlur("password", "blur");
                onSubmit();
              }
            }}
          >
            <img src={Icons.key.default} alt="key icon" />
            <input
              placeholder={t("placeholder.password")}
              type={`${isHidePassword ? "password" : "text"}`}
              onFocus={() => handleFocusAndBlur("password", "focus")}
              onBlur={() => handleFocusAndBlur("password", "blur")}
            />
            <div
              className={`pwd_icon ${isHidePassword ? "hide" : "show"}`}
              onClick={() => setIsHidePassword(!isHidePassword)}
            >
              <i className="fas fa-eye"></i>
              <i className="fas fa-eye-slash"></i>
            </div>
          </InputField>
          <CheckboxContainer onClick={() => handleChangeRemember()}>
            <CheckboxSquare>
              <img src={Icons.checkboxSquare.default} alt="checkbox square" />
              <img
                src={Icons.tick.default}
                alt="tick icon"
                className={`tick-icon ${isRemember ? "checked" : ""}`}
              />
              <img
                src={Icons.solidSquare.default}
                alt="solid icon"
                className="square-icon"
              />
            </CheckboxSquare>
            <Label>{t("label.remember")}</Label>
          </CheckboxContainer>
          <UserCarrotButton
            width="100%"
            fontSize="16px"
            fontWeight="600"
            isUppercase
            onClick={() => onSubmit()}
          >
            {t("button.login")}
          </UserCarrotButton>
        </LoginForm>
      </ContentCol>
    );
  };

  const renderMain = () => {
    return (
      <LoginContainer>
        {isLoading && <Loading />}
        <LoginContent>
          {renderLoginIllustrator()}
          {renderInputForm()}
        </LoginContent>
      </LoginContainer>
    );
  };

  return renderMain();
};

export default Login;
