/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  // useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import i18next from "i18next";
// import { Helmet } from "react-helmet";
import { DefaultContent, FirstLoading } from "../../../components/Common";
import {
  DefaultLayoutContainer,
  TopicImage,
  SpaceHeaderBox,
  TopicLabel,
} from "./DefaultLayout.styles";
import { Header, Footer } from "../CommonLayoutPart";
import Utils from "../../../libs/Utils";
import {
  PageSettingsActions,
  // MediaActions,
  EditContentActions,
  ClientsActions,
  MembersActions,
} from "../../../actions";
import { ROUTES } from "../../../constants";
import { GenerateValue } from "../../../libs";
// import { Images, Icons } from '../../../themes';
import API from "../../../api";
import { useLocation } from "react-router-dom";
// import { AlumniNetworkPopup } from "../Popups";

interface SectionProps {
  content: JSX.Element;
  currentPage: string;
  loading?: boolean;
  titlePage?: string;
  onChange?(value: string): unknown;
  position?(value: number): unknown;
  headerTitle?: string;
  metaDescription?: string;
}

const { GenerateBanner } = GenerateValue;

const { setFirstLoad, setPageRouter } = PageSettingsActions;
const { getAllContentForUser } = EditContentActions;
const { getAllMembersForUser } = MembersActions;
const { getClientsForUser } = ClientsActions;
// const { getMediaByType } = MediaActions;

const DefaultUserLayout: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare all props
  const { content, currentPage, titlePage } = props;
  // Declare states
  const dispatch = useDispatch();
  const firstLoading = useSelector((state) =>
    _.get(state, "PageSettings.firstLoading")
  );
  const router = useSelector((state) => _.get(state, "PageSettings.router"));
  // const imagesPayload = useSelector(state => _.get(state, 'Media.mediaPayload'));
  // const [loadingPercent, setLoadingPercent] = useState<number>(0);
  // const [contentIsLoaded, setContentIsLoaded] = useState<boolean>(false);
  const topicRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const contentRequest = async () => {
    const startRequest = performance.now();
    Promise.all([
      dispatch(getAllContentForUser()),
      dispatch(getAllMembersForUser()),
      dispatch(getClientsForUser()),
      // dispatch(getMediaByType({ type: 'image' })),
    ]).then((values) => {
      const endRequest = performance.now();
      const totalTimeRequest = endRequest - startRequest;
      if (totalTimeRequest < 1000) {
        setTimeout(() => {
          dispatch(setFirstLoad(false));
          Utils.saveFirstLoad();
        }, 1000);
      } else {
        dispatch(setFirstLoad(false));
        Utils.saveFirstLoad();
      }
    });
  };

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, [pathname]);

  useEffect(() => {
    const lang = Utils.getUserLocale();
    API.setLangHeader(lang);
    Utils.setTypeUser("user");
  }, []);

  useEffect(() => {
    if (!firstLoading) document.body.style.overflowY = "auto";
    else {
      contentRequest();
      document.body.style.overflowY = "hidden";
    }
  }, [firstLoading]);

  // useEffect(() => {
  //   if (loadingPercent === 100) {
  //     dispatch(setFirstLoad(false));
  //     Utils.saveFirstLoad();
  //   }
  // }, [loadingPercent]);

  // useEffect(() => {
  //   if (firstLoading && contentIsLoaded) {
  //     const mediaSrc: string[] = [];
  //     // _.map(imagesPayload, image => {
  //     //   const mediaUrl = _.get(image, 'mediaUrl');
  //     //   mediaSrc.push(mediaUrl);
  //     // });
  //     const images: any[] = [];
  //     images.push([..._.values(Images), ..._.values(Icons)]);
  //     let loadedImage = 0;
  //     if (!_.isEmpty(images) && _.isArray(images)) {
  //       const loadImage = (image: any) => {
  //         return new Promise((resolve, reject) => {
  //           const loadImg = new Image();
  //           loadImg.src = image;
  //           loadImg.onload = () => {
  //             setLoadingPercent(_.floor(((loadedImage + 1) / images.length) * 100));
  //             loadedImage++;
  //             resolve(image);
  //           };
  //           loadImg.onerror = err => reject(err);
  //         });
  //       };
  //       Promise.all(images.map((image: any) => loadImage(image)))
  //         .then(() => window.scrollTo(0, 0))
  //         .catch(err => console.log('Failed to load images', err));
  //     }
  //   }
  // }, [contentIsLoaded]);

  useEffect(() => {
    const lang = Utils.getUserLocale();
    i18next.changeLanguage(lang);
    Utils.setTypeUser("user");

    const current = _.get(router, "current");
    dispatch(
      setPageRouter({
        prev: current,
        current: currentPage,
      })
    );
    // const url =
    //   'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg';
    // sourceImageToBaseURL(url).then((dataUrl: any) => {
    //   const getTime = new Date().getTime();
    //   const fileData = dataURLtoFile(dataUrl, `${getTime}.jpg`);
    // });
  }, [currentPage]);

  const renderHeaderImage = () => {
    const checkPageNotUseBanner = !_.includes(
      [
        ROUTES.USER.DEVELOPMENT_WORK_DETAILS,
        ROUTES.USER.DEVELOPMENT_NEWS_DETAILS,
        ROUTES.USER.HOMEPAGE,
        ROUTES.USER.NEWS_DETAILS,
        ROUTES.NOT_FOUND,
        ROUTES.USER.SEARCH,
        ROUTES.USER.RESEARCH_WORK_DETAILS,
        ROUTES.USER.RESEARCH_NEWS_DETAILS,
        ROUTES.USER.CAREER_DETAILS,
      ],
      currentPage
    );
    const generateBanner = GenerateBanner(currentPage);
    if (checkPageNotUseBanner)
      return (
        <TopicImage ref={topicRef} className="topic_image">
          <img src={generateBanner} alt="header-banner" />
          {titlePage && (
            <DefaultContent className="b-0 pb-3">
              <TopicLabel>{titlePage}</TopicLabel>
            </DefaultContent>
          )}
        </TopicImage>
      );
    return null;
  };
  return (
    <DefaultLayoutContainer>
      {/* <AlumniNetworkPopup /> */}
      {firstLoading && (
        <FirstLoading className={`${!firstLoading ? "hide" : ""}`} />
      )}
      <Header currentPage={currentPage} />
      <SpaceHeaderBox />
      {renderHeaderImage()}
      {content}
      <Footer />
    </DefaultLayoutContainer>
  );
};

export default DefaultUserLayout;
