import styled from 'styled-components';
import { ColorName } from '../../../Variables';
import { FlexVertical, FlexHorizontal } from '../../../Common';

const OrderPopupContainer = styled(FlexVertical)`
  width: 100%;
  max-width: 980px;
  height: auto;
  min-height: 550px;
  max-height: 750px;
  background: ${ColorName.white};
  border-radius: 5px;
  overflow: hidden;
`;

const FilterBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  background: ${ColorName.aquaHaze};
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
`;

const FilterLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${ColorName.darkElectricBlue};
`;

const TableContent = styled.div`
  width: 100%;
  height: 420px;
  overflow-y: auto;
  background: ${ColorName.white};
  padding: 12px 24px;
  box-sizing: border-box;
`;

const ButtonBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  padding: 0 24px;
  align-items: center;
  box-sizing: border-box;
  background: ${ColorName.aquaHaze};
`;

export { OrderPopupContainer, FilterBar, FilterLabel, TableContent, ButtonBar };
