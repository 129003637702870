import Service from './Base';
import API from '../constants/API';

const { CAREER } = API;

export function getCareerById(id: string) {
  return Service.get(`${CAREER.BASIC}/${id}`);
}

export function getAllCareers(data?: any) {
  return Service.get(CAREER.BASIC, data);
}

export function filterCareers(data: any) {
  return Service.get(CAREER.FILTER, data);
}

export function createCareer(data: any) {
  return Service.post(CAREER.BASIC, data);
}

export function updateCareer(data: any) {
  const { id } = data;
  return Service.put(`${CAREER.BASIC}/${id}`, data);
}

export function removeSingleCareer(id: any) {
  return Service.del(`${CAREER.BASIC}/${id}`);
}

export function removeMultipleCareers(ids: string[]) {
  return Service.del(CAREER.REMOVE, { ids });
}

export function applyCareer(payload: FormData) {
  return Service.postFormData(CAREER.APPLY, payload);
}
