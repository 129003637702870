import styled from 'styled-components';
import { ColorName } from '../../Variables';

const StepLine = styled.div`
  width: 100%;
  max-width: 300px;
  height: 2px;
  background: ${ColorName.casper};
  &.active {
    background: ${ColorName.burntSienna};
  }
`;

const StepLabel = styled.div`
  font-size: 1.4rem;
  position: absolute;
  width: max-content;
  top: 100%;
`;
const StepBar = styled.div`
  display: flex;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background: ${ColorName.white};
`;

const Dot = styled.div`
  font-size: 1.4rem;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${ColorName.casper};
`;

const StepDot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  &.active {
    ${Dot} {
      background: ${ColorName.burntSienna};
      color: ${ColorName.white};
    }
  }
`;

export { StepBar, StepDot, StepLabel, StepLine, Dot };
