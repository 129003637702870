/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from "../constants";
import _ from "lodash";
import API from "../api";
import history from "../history";
import { ROUTES } from "../constants";
import { statusAlert } from "../libs";
import { IAlumniNetwork } from "../interfaces/AlumniNetwork.interfaces";
import { IPagination, IMeta } from "../interfaces/Pagination.interfaces";

// SINGLE ACTIONS
const requestIsLoading = (payload: boolean) => {
  return {
    type: ActionTypes.SET_ALUMNI_NETWORK_IS_LOADING,
    payload,
  };
};

const setAlumniNetworkPagination = (payload: IPagination) => {
  return {
    type: ActionTypes.SET_ALUMNI_NETWORK_PAGINATION,
    payload,
  };
};

const setAlumniNetworkOrder = (payload: { name: string; email: string }) => {
  return {
    type: ActionTypes.SET_ALUMNI_NETWORK_ORDER,
    payload,
  };
};

const setAlumniNetworkMeta = (payload: IMeta) => {
  return {
    type: ActionTypes.SET_ALUMNI_NETWORK_META,
    payload,
  };
};

const resetAlumniNetworkReducer = () => {
  return {
    type: ActionTypes.RESET_ALUMNI_NETWORK_REDUCER,
  };
};

//ASYNC ACTIONS
const fetchSuccess = (payload: IAlumniNetwork[]) => {
  return {
    type: ActionTypes.FETCH_ALUMNI_NETWORK_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ActionTypes.FETCH_ALUMNI_NETWORK_FAILURE,
  };
};

const fetchAlumniNetworks = (pagination: IPagination) => {
  return async (dispatch: any) => {
    dispatch(requestIsLoading(true));
    await API.fetchAlumniNetworks(pagination)
      .then(async (res: any) => {
        const { status, payload } = res;
        if (status) {
          const items: IAlumniNetwork[] = _.get(payload, "items");
          const meta: IMeta = _.get(payload, "meta");
          dispatch(fetchSuccess(items));
          dispatch(setAlumniNetworkPagination(pagination));
          dispatch(setAlumniNetworkMeta(meta));
        } else dispatch(fetchFail());
      })
      .catch(() => dispatch(fetchFail()));
  };
};

const getByIdSuccess = (payload: IAlumniNetwork) => {
  return {
    type: ActionTypes.GET_ALUMNI_NETWORK_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ActionTypes.GET_ALUMNI_NETWORK_BY_ID_FAILURE,
  };
};

const getAlumniNetWorkById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(requestIsLoading(true));
    await API.getAlumniNetworkById(id)
      .then(async (res: any) => {
        const { payload }: { payload: IAlumniNetwork } = res;
        if (payload) {
          dispatch(getByIdSuccess(payload));
        } else dispatch(getByIdFail());
      })
      .catch(() => dispatch(getByIdFail()));
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ActionTypes.CREATE_ALUMNI_NETWORK_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ActionTypes.CREATE_ALUMNI_NETWORK_FAILURE,
  };
};

const createAlumniNetwork = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(requestIsLoading(true));
    await API.createAlumniNetwork(payload)
      .then(async (res: any) => {
        const { status, payload, message } = res;
        if (status) {
          dispatch(createSuccess(payload));
          statusAlert("success", message);
          history.push(ROUTES.USER.ALUMNI_NETWORK);
        } else {
          dispatch(createFail());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(createFail()));
  };
};

const approveSuccess = () => {
  return {
    type: ActionTypes.APPROVE_ALUMNI_NETWORK_SUCCESS,
  };
};

const approveFail = () => {
  return {
    type: ActionTypes.APPROVE_ALUMNI_NETWORK_FAILURE,
  };
};

const approveAlumniNetWork = (id: string) => {
  return async (dispatch: any) => {
    dispatch(requestIsLoading(true));
    await API.approveAlumniNetwork(id)
      .then(async (res: any) => {
        const { status, message, payload } = res;
        if (status) {
          dispatch(approveSuccess());
          statusAlert("success", message);
          dispatch(getByIdSuccess(payload));
        } else {
          dispatch(approveFail());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(approveFail()));
  };
};

const denySuccess = () => {
  return {
    type: ActionTypes.DENY_ALUMNI_NETWORK_SUCCESS,
  };
};

const denyFail = () => {
  return {
    type: ActionTypes.DENY_ALUMNI_NETWORK_FAILURE,
  };
};

const denyAlumniNetWork = (id: string) => {
  return async (dispatch: any) => {
    dispatch(requestIsLoading(true));
    await API.denyAlumniNetwork(id)
      .then(async (res: any) => {
        const { status, message, payload } = res;
        if (status) {
          dispatch(denySuccess());
          statusAlert("success", message);
          dispatch(getByIdSuccess(payload));
        } else {
          dispatch(denyFail());
          statusAlert("error", message);
        }
      })
      .catch(() => dispatch(denyFail()));
  };
};

export default {
  fetchAlumniNetworks,
  getAlumniNetWorkById,
  createAlumniNetwork,
  resetAlumniNetworkReducer,
  approveAlumniNetWork,
  denyAlumniNetWork,
  setAlumniNetworkOrder,
};
