import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import find from "lodash/find";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";

import { PreviewContainer, Content } from "./Preview.styles";
import { HeaderPopup, PopupWrapper, ButtonBar } from "../DefaultPopup.styles";
import {
  AdminDropdown,
  CarrotSolidButton,
  Description,
  TimeStamp,
  Title,
} from "../../../Common";
import { useTranslation } from "react-i18next";
import GridImageLayout from "../../../../containers/UserPage/NewsDetails/GridImageLayout";

interface ISectionProps {
  className?: string;
  details: any;
  onClose(): void;
}

const PreviewNews = (props: ISectionProps) => {
  const { t } = useTranslation("admin");
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { className, details, onClose } = props;
  const [locale, setLocale] = useState<string>("en");
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);

  useEffect(() => {
    if (details) {
      const languageDisplay = get(details, "languageDisplay");
      if (languageDisplay === "both")
        setLanguageOptions([
          { label: "english", value: "en" },
          { label: "albanian", value: "al" },
        ]);
      if (languageDisplay === "en") {
        setLanguageOptions([{ label: "english", value: "en" }]);
        setLocale("en");
      }
      if (languageDisplay === "al") {
        setLanguageOptions([{ label: "albanian", value: "al" }]);
        setLocale("al");
      }
    }
  }, [details]);

  const renderGalleryLayout = () => {
    const content = get(details, `content.${locale}`);
    if (content) {
      const galleryImages = get(details, "galleryImages");
      const splitContentByGallery = content.split(/##(GALLERY[0-9])##/g);
      const render = map(splitContentByGallery, (item, index) => {
        if (includes(item, "GALLERY")) {
          const findGallery = find(galleryImages, (image) => {
            const itemVariable = get(image, "variable");
            if (includes(itemVariable, item)) return item;
          });
          const type = get(findGallery, "type");
          return (
            <GridImageLayout
              style={{ margin: "10px 0" }}
              key={`layout-${type}-${index}`}
              data={findGallery}
            />
          );
        }
        return (
          <Description
            className={`preview preview-${index}`}
            ref={descriptionRef}
            // width={get(iframeRatio, 'width')}
            // height={get(iframeRatio, 'height')}
            dangerouslySetInnerHTML={{ __html: item }}
            key={`description-${index}`}
          ></Description>
        );
      });
      return render;
    }
  };

  const renderMain = () => {
    return (
      <Content>
        <HeaderPopup>{t("headerPopup.previewNews")}</HeaderPopup>
        <PreviewContainer>
          {!isEmpty(details) && (
            <div className="content">
              <AdminDropdown
                width="150px"
                selectedValue={locale}
                data={languageOptions}
                onChange={(value: string) => setLocale(value)}
              />
              <Title>
                {get(details, `title.${locale}`) || "Title is empty"}
              </Title>
              <TimeStamp className="mt-1 mb-2">
                <i className="fas fa-calendar-alt" />{" "}
                {get(details, "createdAt") || "Publish time is empty"}
              </TimeStamp>
              {renderGalleryLayout()}
            </div>
          )}
        </PreviewContainer>
        <ButtonBar className="flex-justify-end">
          <CarrotSolidButton onClick={() => onClose()}>
            {t("button.close")}
          </CarrotSolidButton>
        </ButtonBar>
      </Content>
    );
  };

  return (
    <PopupWrapper className={`popup-wrapper ${className}`}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default PreviewNews;
