import styled from 'styled-components';
import { ColorName } from '../../Variables';

interface SectionProps {
  height?: string;
  width?: string;
}

const CropperContainer = styled.div`
  display: flex;
  background: ${ColorName.geyser};
`;

const Viewer = styled.div`
  height: ${(props: SectionProps) => props.height || '420px'};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  div {
    height: ${(props: SectionProps) => props.height || '420px'};
  }
`;

const CropperDefault = styled.div`
  flex: 1;
  position: relative;
`;

const OutputImage = styled.div`
  position: relative;
  width: ${(props: SectionProps) => props.width || '300px'};
  top: 0;
  overflow: hidden;
  border: 1px solid ${ColorName.darkElectricBlue};
  box-sizing: border-box;
  background: white;
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
`;

export { CropperContainer, Viewer, OutputImage, CropperDefault };
