import Service from './Base';
import API from '../constants/API';

const { CLIENT } = API;

export function getAllClients(params: any) {
  return Service.get(CLIENT.BASIC, params);
}

export function getClientsForUser() {
  return Service.get(CLIENT.GET_FOR_USER);
}

export function getClientById(id: string) {
  return Service.get(`${CLIENT.BASIC}/${id}`);
}

export function createClient(data: any) {
  return Service.post(CLIENT.BASIC, data);
}

export function updateClient(data: any) {
  const { id } = data;
  return Service.put(`${CLIENT.BASIC}/${id}`, data);
}

export function removeSingleClient(id: string) {
  return Service.del(`${CLIENT.BASIC}/${id}`);
}

export function removeMultipleClients(data: any) {
  return Service.put(CLIENT.UPDATE_MULTIPLE, { data });
}
