import styled from 'styled-components';
import { ColorName } from '../../Variables';

const ImageLayoutContainer = styled.div`
  /* padding: 15px; */
`;

const LayoutContent = styled.div`
  width: 100%;
  max-width: 720px;
  background: ${ColorName.casper};
`;

const GridLayout = styled.div`
  display: grid;
  grid-gap: 15px;
  padding: 15px;
`;

const Image = styled.div`
  aspect-ratio: 1/1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export { ImageLayoutContainer, LayoutContent, GridLayout, Image };
