import { ROUTES } from ".";

const CONSTANTS = {
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  LOCALE: {
    EN: "en",
    AL: "al",
  },
  SEARCH_TYPE: {
    CAREER: "career",
    ARTICLES: "articles",
  },
};

const LOCAL_STORAGE_KEYS = {
  USER_LOCALE: "@IDRA:user_locale",
  ADMIN_LOCALE: "@IDRA:admin_locale",
  NAME_KEY: "@IDRA:name",
  USER_DATA_KEY: "@IDRA:udata",
  USER_EXTRA_DATA_KEY: "@IDRA:uxdata",
  BASIC_USER_DATA_KEY: "@IDRA:budata",
  SECURE_TOKEN: "@IDRA:secure_token",
  REMEMBER: "@IDRA:remember",
  CURRENT_LOCALE: "@IDRA:current_locale",
  FIRST_LOAD: "@IDRA:first_load",
  ALUMNI_POPUP: "@IDRA:alumni_popup",
};

const LOCALE = {
  ENGLISH: "en",
  ALBANIAN: "al",
};

const LOGOS_PAGE_KEY = {
  HOMEPAGE: "98f31c11-7bbb-4dfc-bd24-8df159b294f2",
  OUR_CLIENTS: "fc7a3d0f-4e27-4c33-bd56-a58ea7f74002",
  RESEARCH_CLIENTS: "33b3e87e-5814-4a47-b323-ecf99680dfb2",
  DEVELOPMENT_CLIENTS: "07550c9b-9cad-476d-90de-a20650be28e7",
};

const SECOND_HEADER = {
  WHO_WE_ARE: [
    {
      label: "aboutUs",
      value: "aboutUs",
    },
    {
      label: "qualityAssurance",
      value: "qualityAssurance",
    },
    {
      label: "ourValues",
      value: "ourValues",
    },
    {
      label: "ourTeam",
      value: "ourTeam",
    },
    {
      label: "ourPartners",
      value: "ourPartners",
    },
    {
      label: "ourClients",
      value: "ourClients",
    },
  ],
  RESEARCH_SOLUTIONS: [
    {
      label: "marketResearch",
      value: "marketResearch",
    },
    {
      label: "opinionPolls",
      value: "opinionPolls",
    },
    {
      label: "economicResearch",
      value: "economicResearch",
    },
    {
      label: "mediaResearch",
      value: "mediaResearch",
    },
    {
      label: "advertisingResearch",
      value: "advertisingResearch",
    },
    {
      label: "omnibus",
      value: "omnibus",
    },
  ],
  DEVELOPMENT_SOLUTIONS: [
    {
      label: "socioEconomicDevelopment",
      value: "socioEconomicDevelopment",
    },
    {
      label: "governanceAndRuleofLaw",
      value: "governanceAndRuleOfLaw",
    },
    {
      label: "urbanPlanningAndGis",
      value: "urbanPlanningAndGis",
    },
    {
      label: "environment",
      value: "environment",
    },
    {
      label: "monitoringAndEvaluationServices",
      value: "monitoringAndEvaluationServices",
    },
  ],
  NEWS: [
    {
      label: "news",
      value: "news",
    },
    {
      label: "publications",
      value: "publications",
    },
    {
      label: "projects",
      value: "projects",
    },
    {
      label: "successStories",
      value: "successStories",
    },
  ],
  RESEARCH_SERVICES: [
    {
      label: "quantitativeResearch",
      value: "quantitativeResearch",
    },
    {
      label: "qualitativeResearch",
      value: "qualitativeResearch",
    },
  ],
};

const TAB_BAR = {
  CAREER_DETAILS: {
    both: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "english",
        value: "en",
      },
      {
        label: "shqip",
        value: "al",
      },
    ],
    en: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "english",
        value: "en",
      },
    ],
    al: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "shqip",
        value: "al",
      },
    ],
  },
  ARTICLES_DETAILS: {
    both: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "english",
        value: "en",
      },
      {
        label: "shqip",
        value: "al",
      },
    ],
    en: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "english",
        value: "en",
      },
    ],
    al: [
      {
        label: "announcementOptions",
        value: "options",
      },
      {
        label: "shqip",
        value: "al",
      },
    ],
  },
  SITE_SETTINGS: [
    {
      label: "english",
      value: "en",
    },
    {
      label: "shqip",
      value: "al",
    },
  ],
  CAREERS_POPUP: [
    {
      label: "Image",
      value: "image",
    },
    // {
    //   label: 'Video',
    //   value: 'video',
    // },
    {
      label: "PDF",
      value: "pdf",
    },
  ],
  ARTICLES_POPUP: [
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "PDF",
      value: "pdf",
    },
  ],
  ARTICLES_IMAGE_POPUP: [
    {
      label: "Image",
      value: "image",
    },
  ],
  SITE_SETTINGS_POPUP: [
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
  ],
  MEMBER_POPUP: [
    {
      label: "Image",
      value: "image",
    },
  ],
  CLIENTS_POPUP: [
    {
      label: "Image",
      value: "image",
    },
  ],
};

const FILTERS = {
  GENERAL: [
    {
      title: "filterBySolution",
      key: "categories",
      items: [
        {
          label: "general",
          value: "general",
        },
        {
          label: "IDRAResearch",
          value: "researchNews",
        },
        {
          label: "IDRAAdvance",
          value: "developmentNews",
        },
        {
          label: "IDRADiGIS",
          value: "DiGISNews",
        },
      ],
    },
  ],
  FILTER_IDRA_ADVANCE: [
    {
      title: "filterBySolution",
      key: "categories",
      items: [
        {
          label: "general",
          value: "general",
        },
        {
          label: "IDRAResearch",
          value: "researchNews",
        },
        {
          label: "IDRAAdvance",
          value: "developmentNews",
        },
        {
          label: "IDRADiGIS",
          value: "DiGISNews",
        },
      ],
    },
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "socioEconomicDevelopment",
          value: "socioEconomicDevelopment",
        },
        {
          label: "governanceAndRuleofLaw",
          value: "governanceAndRuleOfLaw",
        },
        {
          label: "urbanPlanningAndGis",
          value: "urbanPlanningAndGis",
        },
        {
          label: "environment",
          value: "environment",
        },
        {
          label: "monitoringAndEvaluationServices",
          value: "monitoringAndEvaluationServices",
        },
      ],
    },
  ],
  FILTER_IDRA_RESEARCH: [
    {
      title: "filterBySolution",
      key: "categories",
      items: [
        {
          label: "general",
          value: "general",
        },
        {
          label: "IDRAResearch",
          value: "researchNews",
        },
        {
          label: "IDRAAdvance",
          value: "developmentNews",
        },
        {
          label: "IDRADiGIS",
          value: "DiGISNews",
        },
      ],
    },
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "marketResearch",
          value: "marketResearch",
        },
        {
          label: "opinionPolls",
          value: "opinionPolls",
        },
        {
          label: "economicResearch",
          value: "economicResearch",
        },
        {
          label: "mediaResearch",
          value: "mediaResearch",
        },
        {
          label: "advertisingResearch",
          value: "advertisingResearch",
        },
        {
          label: "omnibus",
          value: "omnibus",
        },
      ],
    },
  ],
  FILTER_IDRA_DIGIS: [
    {
      title: "filterBySolution",
      key: "categories",
      items: [
        {
          label: "general",
          value: "general",
        },
        {
          label: "IDRAResearch",
          value: "researchNews",
        },
        {
          label: "IDRAAdvance",
          value: "developmentNews",
        },
        {
          label: "IDRADiGIS",
          value: "DiGISNews",
        },
      ],
    },
  ],
  RESEARCH_WORK: [
    {
      title: "filterByStatus",
      key: "status",
      items: [
        {
          label: "ongoing",
          value: "Ongoing",
        },
        {
          label: "completed",
          value: "Completed",
        },
      ],
    },
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "marketResearch",
          value: "19",
        },
        {
          label: "opinionResearch",
          value: "20",
        },
      ],
    },
  ],
  DEVELOPMENT_WORK: [
    {
      title: "filterByStatus",
      key: "status",
      items: [
        {
          label: "ongoing",
          value: "Ongoing",
        },
        {
          label: "completed",
          value: "Completed",
        },
      ],
    },
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "socioEconomicDevelopment",
          value: "100",
        },
        {
          label: "governanceAndRuleofLaw",
          value: "1",
        },
        {
          label: "urbanPlanningAndGis",
          value: "5",
        },
        {
          label: "environment",
          value: "4",
        },
        {
          label: "monitoringAndEvaluationServices",
          value: "6",
        },
      ],
    },
  ],
  NEWS: [
    {
      title: "filterBySolution",
      key: "categories",
      items: [
        {
          label: "general",
          value: "general",
        },
        {
          label: "IDRAResearch",
          value: "researchNews",
        },
        {
          label: "IDRAAdvance",
          value: "developmentNews",
        },
        {
          label: "IDRADiGIS",
          value: "DiGISNews",
        },
      ],
    },
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "socioEconomicDevelopment",
          value: "socioEconomicDevelopment",
        },
        {
          label: "governanceAndRuleofLaw",
          value: "governanceAndRuleOfLaw",
        },
        {
          label: "urbanPlanningAndGis",
          value: "urbanPlanningAndGis",
        },
        {
          label: "environment",
          value: "environment",
        },
        {
          label: "monitoringAndEvaluationServices",
          value: "monitoringAndEvaluationServices",
        },
        {
          label: "marketResearch",
          value: "marketResearch",
        },
        {
          label: "opinionPolls",
          value: "opinionPolls",
        },
        {
          label: "economicResearch",
          value: "economicResearch",
        },
        {
          label: "mediaResearch",
          value: "mediaResearch",
        },
        {
          label: "advertisingResearch",
          value: "advertisingResearch",
        },
        {
          label: "omnibus",
          value: "omnibus",
        },
      ],
    },
  ],
  RESEARCH_NEWS: [
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "marketResearch",
          value: "marketResearch",
        },
        {
          label: "opinionPolls",
          value: "opinionPolls",
        },
        {
          label: "economicResearch",
          value: "economicResearch",
        },
        {
          label: "mediaResearch",
          value: "mediaResearch",
        },
        {
          label: "advertisingResearch",
          value: "advertisingResearch",
        },
        {
          label: "omnibus",
          value: "omnibus",
        },
      ],
    },
  ],
  DEVELOPMENT_NEWS: [
    {
      title: "filterByTopic",
      key: "topic",
      items: [
        {
          label: "socioEconomicDevelopment",
          value: "socioEconomicDevelopment",
        },
        {
          label: "governanceAndRuleofLaw",
          value: "governanceAndRuleOfLaw",
        },
        {
          label: "urbanPlanningAndGis",
          value: "urbanPlanningAndGis",
        },
        {
          label: "environment",
          value: "environment",
        },
        {
          label: "monitoringAndEvaluation",
          value: "monitoringAndEvaluation",
        },
      ],
    },
  ],
};

const COMPANY_ADDRESS = [
  {
    className: "big-pin",
    companyName: "IDRA Albania - Headquarters",
    mail: "idra@idracompany.com",
    phone: "+355699846258",
    isLeft: true,
    direction:
      "https://www.google.com/maps/place/IDRA+-+Albania/@41.3353448,19.8196665,16z/data=!4m5!3m4!1s0x13503112d92454f1:0x2b2cb7ce6cf4210c!8m2!3d41.335845!4d19.821455",
  },
  {
    className: "medium-pin",
    companyName: "IDRA Kosova",
    mail: "idra.kosova@idracompany.com",
    phone: "+38338742966",
    direction:
      "https://www.google.com/maps/place/IDRA+Research+and+Consulting+-+Kosova/@42.6475475,21.170038,16.5z/data=!4m5!3m4!1s0x0:0x8d574ddc842fe89c!8m2!3d42.6475487!4d21.1717686",
    isCenter: true,
  },
  {
    className: "small-pin",
    companyName: "FLOW-IDRA Vietnam",
    mail: "services@flow-idra.com",
    direction:
      "https://www.google.com/maps/place/28+L%C3%BD+Th%C6%B0%E1%BB%9Dng+Ki%E1%BB%87t,+V%C4%A9nh+Ninh,+Th%C3%A0nh+ph%E1%BB%91+Hu%E1%BA%BF,+Th%E1%BB%ABa+Thi%C3%AAn+Hu%E1%BA%BF/@16.4586497,107.5912898,18.25z/data=!4m5!3m4!1s0x3141a14756066fab:0xcfb6ff2303b5c98b!8m2!3d16.4586086!4d107.59155",
    isRight: true,
  },
];

const CLIENTS_INDUSTRY_HEADER = {
  IDRA_ADVANCE: [
    {
      title: "internationalOrganizationsOrInstitutions",
      value: "internationalOrganizationsOrInstitutions",
    },
    {
      title: "publicInstitutions",
      value: "publicInstitutions",
    },
    {
      title: "developmentProgramsAndNGOs",
      value: "developmentProgramsAndNGOs",
    },
    {
      title: "consultancy",
      value: "consultancy",
    },
  ],
  IDRA_RESEARCH: [
    {
      title: "internationalOrganizationsOrInstitutions",
      value: "internationalOrganizationsOrInstitutions",
    },
    {
      title: "publicInstitutions",
      value: "publicInstitutions",
    },
    {
      title: "developmentProgramsAndNGOs",
      value: "developmentProgramsAndNGOs",
    },
    {
      title: "consumerProducts",
      value: "consumerProducts",
    },
    {
      title: "retail",
      value: "retail",
    },
    {
      title: "banking",
      value: "banking",
    },
    {
      title: "universities",
      value: "universities",
    },
    {
      title: "telecommunication",
      value: "telecommunication",
    },
    {
      title: "consultancy",
      value: "consultancy",
    },
    {
      title: "automotive",
      value: "automotive",
    },
    {
      title: "aviation",
      value: "aviation",
    },
    {
      title: "construction",
      value: "construction",
    },
    {
      title: "mediaAndAdvertising",
      value: "mediaAndAdvertising",
    },
    {
      title: "tobacco",
      value: "tobacco",
    },
    {
      title: "oil",
      value: "oil",
    },
    {
      title: "entertainment",
      value: "entertainment",
    },
  ],
  WHO_WE_ARE: [
    {
      title: "internationalOrganizationsOrInstitutions",
      value: "internationalOrganizationsOrInstitutions",
    },
    {
      title: "publicInstitutions",
      value: "publicInstitutions",
    },
    {
      title: "developmentProgramsAndNGOs",
      value: "developmentProgramsAndNGOs",
    },
    {
      title: "consumerProducts",
      value: "consumerProducts",
    },
    {
      title: "retail",
      value: "retail",
    },
    {
      title: "banking",
      value: "banking",
    },
    {
      title: "universities",
      value: "universities",
    },
    {
      title: "telecommunication",
      value: "telecommunication",
    },
    {
      title: "consultancy",
      value: "consultancy",
    },
    {
      title: "automotive",
      value: "automotive",
    },
    {
      title: "aviation",
      value: "aviation",
    },
    {
      title: "construction",
      value: "construction",
    },
    {
      title: "mediaAndAdvertising",
      value: "mediaAndAdvertising",
    },
    {
      title: "tobacco",
      value: "tobacco",
    },
    {
      title: "oil",
      value: "oil",
    },
    {
      title: "entertainment",
      value: "entertainment",
    },
  ],
};

const REPLACE_PAGE = {
  [ROUTES.USER.DEVELOPMENT_SOLUTIONS]: "developmentSolutions",
  [ROUTES.USER.RESEARCH_SOLUTIONS]: "researchSolutions",
  [ROUTES.USER.RESEARCH_SERVICES]: "researchServices",
  [ROUTES.USER.IDRA_ADVANCE]: "idraAdvance",
  [ROUTES.USER.IDRA_RESEARCH]: "idraResearch",
  [ROUTES.USER.IDRA_DIGIS]: "idraDiGIS",
  [ROUTES.USER.WHO_WE_ARE]: "whoWeAre",
  [ROUTES.USER.CONTACT]: "contact",
  [ROUTES.USER.CAREER]: "career",
  [ROUTES.USER.HOMEPAGE]: "homepage",
};

const MEDIAS = {
  MEDIA_TYPES: {
    IMAGE: "image",
    PDF: "pdf",
    VIDEO: "video",
  },
  MEDIA_TABS: [
    {
      label: "Image",
      value: "image",
    },
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "Upload",
      value: "upload",
    },
  ],
};

export {
  ROUTES,
  CONSTANTS,
  LOCAL_STORAGE_KEYS,
  SECOND_HEADER,
  FILTERS,
  COMPANY_ADDRESS,
  LOGOS_PAGE_KEY,
  CLIENTS_INDUSTRY_HEADER,
  REPLACE_PAGE,
  MEDIAS,
  LOCALE,
  TAB_BAR,
};
