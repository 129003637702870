/* eslint import/no-anonymous-default-export: off */
import ActionTypes from "../constants/ActionTypes";

const defaultDetails = {
  team: "",
  fullname: "",
  description: "",
  photo: "",
  position: "",
  croppedData: null,
};

const defaultPagination = {
  currentPage: 1,
  limit: 10,
};

const defaultFilter = {};

const defaultMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payload: [],
  details: defaultDetails,
  filter: defaultFilter,
  meta: defaultMeta,
  pagination: defaultPagination,
  removeIds: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_MEMBER_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_MEMBER_PAYLOAD:
      return {
        ...state,
        payload: defaultDetails,
      };

    case ActionTypes.SET_MEMBER_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_MEMBER_DETAILS:
      return {
        ...state,
        details: defaultDetails,
      };

    case ActionTypes.SET_MEMBER_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_MEMBER_FILTER:
      return {
        ...state,
        filter: defaultFilter,
      };

    case ActionTypes.SET_MEMBERS_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_MEMBERS_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_DEFAULT_MEMBERS_META_PAGINATION:
      return {
        ...state,
        meta: defaultMeta,
        pagination: defaultPagination,
      };

    case ActionTypes.SET_REMOVE_MEMBERS_IDS:
      return {
        ...state,
        removeIds: payload,
      };
    case ActionTypes.CLEAR_REMOVE_MEMBERS_IDS:
      return {
        ...state,
        removeIds: [],
      };

    case ActionTypes.GET_MEMBER_BY_ID_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_MEMBER_BY_ID_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.CREATE_MEMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.CREATE_MEMBER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_MEMBER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_ALL_MEMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_ALL_MEMBERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_ALL_MEMBERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_SINGLE_MEMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_MEMBER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_MULTIPLE_MEMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_MEMBERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_MEMBERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_MEMBERS_FOR_USER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_MEMBERS_FOR_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_MEMBERS_FOR_USER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
