/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ColorName } from "../../../components/Variables";
import { BREAK_POINTS } from "../../../constants";

interface SectionProps {
  isUppercase?: boolean;
  label?: string;
  width?: string;
  height?: string;
  onClick?(id?: string): unknown;
  flex?: string;
  isLink?: boolean;
  url?: string;
  className?: string;
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: string;
}

const DefaultButton = styled.div`
  width: ${(props: SectionProps) => props.width || "max-content"};
  height: ${(props: SectionProps) => props.height || "40px"};
  font-size: ${(props: SectionProps) => props.fontSize || "15px"};
  font-weight: ${(props: SectionProps) => props.fontWeight || "400"};
  text-transform: ${(props: SectionProps) =>
    props.isUppercase ? "uppercase" : "capitalize"};
  transition: all ease 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 21px;
  border-radius: 5px;
  user-select: none;
  padding: 0 20px;
  box-sizing: border-box;
`;

const UserCarrotButton = styled(DefaultButton)`
  border-radius: 0px;
  color: ${ColorName.white};
  text-transform: ${(props: SectionProps) =>
    props.isUppercase ? "uppercase" : "normal"};
  background: ${ColorName.roseOfSharon};
  font-size: 16px;
  height: 45px;

  &.disabled {
    opacity: 0.5;
    &:hover {
      color: ${ColorName.white};
      background: ${ColorName.roseOfSharon};
      cursor: not-allowed;
    }
  }

  &:hover {
    background: ${ColorName.trinidad};
    cursor: pointer;
  }
  ${BREAK_POINTS.MOBILE} {
    font-size: 12px;
    height: 30px;
    width: max-content;
  }
`;

const UserOutlineContainer = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  padding: 0;
  & .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${ColorName.deepCarrotOrange};
    z-index: 1;
    i {
      font-size: 1.7rem;
      color: ${ColorName.white};
    }
  }
  p {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 20px 0 10px;
    transition: all ease-in-out 0.3s;
    color: ${ColorName.black};
  }

  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0;
    background: ${ColorName.deepCarrotOrange};
    transition: all ease-in-out 0.3s;
    border-radius: 25px;
  }
  &:hover {
    cursor: pointer;
    p {
      color: ${ColorName.white};
      z-index: 1;
    }

    &::before {
      width: 100%;
    }
  }

  ${BREAK_POINTS.MOBILE} {
    height: 30px;
    & .icon {
      width: 30px;
      height: 30px;
    }
    p,
    i {
      font-size: 12px;
    }

    &:before {
      width: 30px;
      height: 30px;
    }
  }
`;

const OurlineGrowButton = (props: SectionProps) => {
  const { t } = useTranslation("user");
  const { label, onClick, isLink, url, className, disabled } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);

  const handleClick = () => {
    if (isLink && linkRef && linkRef.current) return linkRef.current.click();
    if (onClick) onClick();
  };

  return (
    <UserOutlineContainer
      className={className}
      onClick={() => !disabled && handleClick()}
    >
      <div className="icon">
        <i className="fas fa-chevron-right"></i>
      </div>
      <p>{t(`button.${label}`)}</p>
      {isLink && (
        <a
          ref={linkRef}
          target="_blank"
          href={url}
          style={{ visibility: "hidden" }}
          rel="noopener noreferrer"
          aria-label={`${label}`}
        />
      )}
    </UserOutlineContainer>
  );
};

const CarrotSolidButton = styled(DefaultButton)`
  color: ${ColorName.white};
  text-transform: ${(props: SectionProps) =>
    props.isUppercase ? "uppercase" : "normal"};
  background: ${ColorName.deepCarrotOrange};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.25s;
  user-select: none;
  font-weight: bold;

  &.opacity {
    opacity: 0.5;
  }
  ${BREAK_POINTS.MOBILE} {
    width: max-content;
    height: 36px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    padding: 0 20px;
  }

  &:hover {
    background: ${ColorName.trinidad};
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.7;
    &:hover {
      background: ${ColorName.deepCarrotOrange};
      cursor: not-allowed;
    }
  }
`;

const LoginButton = styled(DefaultButton)`
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${ColorName.white};
  width: ${(props: SectionProps) => props.width || "100%"};
  height: 45px;
  background: ${ColorName.deepCarrotOrange};
  font-weight: bold;
  &:hover {
    background: ${ColorName.trinidad};
    cursor: pointer;
  }
`;

const GeyserSolidButton = styled(DefaultButton)`
  color: ${ColorName.darkElectricBlue};
  background: ${ColorName.geyser};
  font-weight: bold;
  &:hover {
    background: ${ColorName.submarine};
    cursor: pointer;
  }
`;

const DarkElectriSolidcButton = styled(DefaultButton)`
  color: ${ColorName.white};
  font-weight: bold;
  background: ${ColorName.darkElectricBlue};
  &:hover {
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background: ${ColorName.burntSienna};
  color: ${ColorName.white};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  &:hover {
    cursor: pointer;
  }
`;

const OrderRowButton = styled.div`
  width: 100%;
  height: 35px;
  background: ${ColorName.geyser};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ColorName.darkElectricBlue};
  font-weight: 600;
  border: 1px solid transparent;
  /* transition: all ease 0.25s; */
  font-size: 14px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${ColorName.darkElectricBlue};
  }
`;

const RemoveButton = styled.div`
  padding: 5px 10px;
  position: absolute;
  font-size: 18px;
  color: ${ColorName.burntSienna};
  left: 100%;
  top: 5px;
  border: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    cursor: pointer;
    background: ${ColorName.burntSienna};
    color: ${ColorName.white};
  }
`;

const OutlineButton = styled.div`
  width: ${(props: SectionProps) => props.width || "max-content"};
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${ColorName.william};
  padding: 0 10px;
  box-sizing: border-box;
  color: ${ColorName.william};
  background: ${ColorName.white};
  transition: all ease 0.25s;
  border-radius: 5px;

  &.active {
    border: 1px solid ${ColorName.burntSienna};
    color: ${ColorName.burntSienna};
  }

  &:hover {
    color: ${ColorName.white};
    background: ${ColorName.william};
    cursor: pointer;

    &.active {
      color: ${ColorName.white};
      background: ${ColorName.burntSienna};
    }
  }

  &.disabled {
    color: ${ColorName.white};
    background: ${ColorName.william};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const DenyButton = styled(DefaultButton)`
  color: ${ColorName.white};
  font-weight: bold;
  background: ${ColorName.vermilionBird};
  &:hover {
    cursor: pointer;
  }
`;

export {
  CarrotSolidButton,
  OurlineGrowButton,
  LoginButton,
  GeyserSolidButton,
  DarkElectriSolidcButton,
  UserCarrotButton,
  SelectButton,
  OrderRowButton,
  RemoveButton,
  OutlineButton,
  DenyButton,
};
