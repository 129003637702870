import styled from "styled-components";
import {
  FlexVertical,
  FlexHorizontal,
  HorizontalContainer,
} from "../../../Common";
import { ColorName } from "../../../../components/Variables";
import { Images } from "../../../../themes";

interface SectionProps {
  flex?: string;
  width?: string;
  background?: string;
  position?: string;
}

const FooterContentTop = styled(HorizontalContainer)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const FooterCol = styled(FlexVertical)`
  flex: ${(props: SectionProps) => props.flex || "unset"};
  width: ${(props: SectionProps) => props.width || "max-content"};
  position: ${(props: SectionProps) => props.position || "unset"};
  & .logo {
    width: auto;
    margin-bottom: 10px;
  }

  h3 {
    color: ${ColorName.white};
    font-size: 22px;
    font-weight: bold;
    line-height: 58px;

    @media screen and (max-width: 436px) {
      font-size: 20px;
    }
  }

  & .title {
    font-size: 22px;
    font-weight: bold;
    color: ${ColorName.bianca};
    height: 43px;
    display: flex;
    align-items: center;
  }
`;

const FooterTop = styled(FlexVertical)`
  position: relative;

  & .footer-image {
    height: auto;
    min-height: 650px;
  }

  img {
    width: 100%;
  }

  & .footer-logo {
    margin: 0 auto;
  }

  & .logo_mobile {
    display: none;
  }
  & .logo_desktop:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1023px) {
    background: ${(props: SectionProps) =>
      `url('${Images.footerMobile.default}')`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-around;
    min-height: 600px;

    & .footer-image {
      display: none;
    }
    & .logo_desktop {
      display: none;
    }

    & .logo_mobile {
      display: block;
      img {
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 436px) {
    min-height: 470px;
  }
`;
const FooterBottom = styled(FlexVertical)`
  ${FooterCol} {
    font-weight: 600;
  }
`;

const CertificateCircle = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 4px solid ${ColorName.white};
  border-radius: 50%;
  margin-left: 15px;
  transition: all ease 0.25s;
  text-decoration: none;
  color: ${ColorName.black};
  span {
    font-size: 11px;
    transition: all ease 0.25s;
    font-weight: bold;
    color: ${ColorName.white};
  }
  i {
    font-size: 16px;
    color: ${ColorName.white};
    transition: all ease 0.25s;
    margin-top: 7px;
  }
  &:hover {
    border-color: ${ColorName.burntSienna};
    span,
    i {
      color: ${ColorName.burntSienna};
    }
    &::before {
      background: transparent;
    }
  }

  @media screen and (max-width: 436px) {
    width: 60px;
    height: 60px;
    span {
      font-size: 10px;
    }
  }
`;

const generatePositionOfAddress = (pin: string, key: string) => {
  const defaultPosition: any = {
    bigPin: {
      top: 42.71,
      left: 28.58,
    },
    mediumPin: {
      top: 53,
      left: 29.8,
    },
    smallPin: {
      top: 59.7,
      left: 42.19,
    },
  };

  const currentPosition = defaultPosition[pin][key] || 0;

  if (pin === "bigPin" && key === "left") {
    const bigPinNode: any = document.querySelector(".big-pin-reverse-content");
    const width = bigPinNode?.offsetWidth || 0;
    const newPosition = `calc(${currentPosition}% - ${width}px)`;
    return newPosition;
  }

  return `${currentPosition}%`;
};

const InformationBox = styled.div`
  display: none;
  flex-direction: column;
  background: #eee;
  position: absolute;
  border-radius: 5px;
  top: 100%;
  width: max-content;
  min-width: 260px;
  height: max-content;
  z-index: -1;
  transition: all ease 0.25s;
  white-space: pre-line;
`;

const CompanyBox = styled(FlexHorizontal)`
  position: absolute;
  width: max-content;

  h3 {
    line-height: 22px;
    color: ${ColorName.white};
    font-weight: bold;
    font-size: 19px;
    margin: 0 10px;
    transform: translateY(-10px);
    align-self: flex-start;
    width: max-content;
    user-select: none;
    transition: all ease 0.25s;
  }

  &.big-pin {
    top: ${() => generatePositionOfAddress("bigPin", "top")};
    left: ${() => generatePositionOfAddress("bigPin", "left")};
    z-index: 1;
    & ${InformationBox} {
      right: 30px;
      top: 20px;
    }
  }
  &.medium-pin {
    top: ${() => generatePositionOfAddress("mediumPin", "top")};
    left: ${() => generatePositionOfAddress("mediumPin", "left")};
  }
  &.small-pin {
    top: ${() => generatePositionOfAddress("smallPin", "top")};
    left: ${() => generatePositionOfAddress("smallPin", "left")};
  }
  &:hover {
    cursor: pointer;

    ${InformationBox} {
      z-index: 2;
      display: flex;
    }

    h3 {
      color: ${ColorName.burntSienna};
    }
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100vw;
  max-width: 1920px;
  // margin: 0 auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
`;

const IconFontAwesome = styled.i`
  color: ${ColorName.raisinBlack};
  font-size: 26px;
  transition: all ease 0.25s;

  &.fa-facebook-f {
    font-size: 35px;
  }
`;

const SocialBox = styled.a`
  width: 45px;
  height: 45px;
  background: ${ColorName.white};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.25s;
  margin-left: 15px;
  text-decoration: none;
  color: ${ColorName.black};
  &:hover {
    background: ${ColorName.burntSienna};
    &::before {
      width: 0px;
      background: transparent;
    }
  }

  @media screen and (max-width: 436px) {
    width: 40px;
    height: 40px;

    i {
      font-size: 22px;
      &.fa-facebook-f {
        font-size: 28px;
      }
    }
  }
`;

const CompanyDetails = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 5px 15px;
  p {
    font-size: 15px;
    white-space: pre-line;
  }
  img {
    width: 20px;
    object-fit: contain;
  }
  & .icons {
    display: flex;
    align-items: center;
    height: 23px;
    margin-right: 10px;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
  a {
    font-size: 15px;
    font-weight: bold;
    color: ${ColorName.darkElectricBlue};
    text-decoration: none;
    line-height: 23px;
    transition: all ease 0.25s;

    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 436px) {
    p,
    a {
      font-size: 14px;
    }
    margin: 6px 0;
  }
`;

export {
  FooterContainer,
  FooterTop,
  FooterBottom,
  FooterCol,
  CertificateCircle,
  SocialBox,
  CompanyBox,
  CompanyDetails,
  IconFontAwesome,
  FooterContentTop,
  InformationBox,
};
