import { ROUTES } from "../../../../constants";

const NAVIGATION_LINK = [
  {
    label: "whoWeAre",
    location: ROUTES.USER.WHO_WE_ARE,
    submenu: [
      {
        label: "aboutUs",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
      },
      {
        label: "qualityAssurance",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=qualityAssurance`,
      },
      {
        label: "ourValues",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourValues`,
      },
      {
        label: "ourTeam",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourTeam`,
      },
      {
        label: "ourPartners",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourPartners`,
      },
      {
        label: "ourClients",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourClients`,
      },
    ],
  },
  {
    label: "news",
    location: ROUTES.USER.NEWS,
  },
  {
    label: "areasOfInterest",
    location: ROUTES.USER.AREAS_OF_INTEREST,
  },
  {
    label: "contact",
    location: ROUTES.USER.CONTACT,
  },
];

const MENU_MOBILE = [
  {
    label: "whoWeAre",
    location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
    child: [
      {
        label: "aboutUs",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
      },
      {
        label: "qualityAssurance",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=qualityAssurance`,
      },
      {
        label: "ourValues",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourValues`,
      },
      {
        label: "ourTeam",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourTeam`,
      },
      {
        label: "ourPartners",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourPartners`,
      },
      {
        label: "ourClients",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourClients`,
      },
    ],
  },
  {
    label: "news",
    location: ROUTES.USER.NEWS,
  },
  {
    label: "areasOfInterest",
    location: ROUTES.USER.AREAS_OF_INTEREST,
  },
  {
    label: "contact",
    location: ROUTES.USER.CONTACT,
  },
  {
    label: "language",
    child: [
      {
        label: "english",
        value: "en",
      },
      {
        label: "albanian",
        value: "al",
      },
    ],
  },
];

export { NAVIGATION_LINK, MENU_MOBILE };
