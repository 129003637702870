/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import _ from "lodash";

import {
  SideBarContainer,
  TitlePage,
  MenuOptions,
  MenuItem,
  DropdownLayer,
} from "./SideBar.styles";
import { Icons } from "../../../../themes";
import history from "../../../../history";
import { PageTitle, ROUTES, DropdownOptions } from "../../../../constants";
import {
  AuthenticationActions,
  PageSettingsActions,
} from "../../../../actions";
import { statusAlert, Utils } from "../../../../libs";
import { FlexHorizontal } from "../../../Common";
import API from "../../../../api";

interface SectionProps {
  currentPage?: string;
}

// Declare constants
const { ADMIN_LANGUAGE_OPTIONS } = DropdownOptions;
// Declare actions
const { setAdminLang } = PageSettingsActions;
const { logout } = AuthenticationActions;

const SideBar: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  const { currentPage } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const locale = useSelector((state) => _.get(state, "PageSettings.adminLang"));
  // Declare ref, states
  const [isShowLangOptions, setIsShowLangOptions] = useState<boolean>(false);
  const [isShowUserOptions, setIsShowUserOptions] = useState<boolean>(false);
  const [showMenu, setIsShowMenu] = useState<any>({
    locale: false,
    profile: false,
  });

  useEffect(() => {
    const currentAdminLang = Utils.getAdminLocale();
    const currentLang = Utils.getCurrentLocale();
    if (currentAdminLang !== locale) dispatch(setAdminLang(currentAdminLang));
    if (currentLang !== currentAdminLang)
      Utils.savedCurrentLocale(currentAdminLang);
  }, []);

  // Handle change language
  const onChangeLanguage = (lang: string) => {
    if (lang) {
      setIsShowMenu({ ...showMenu, locale: false });
      API.setLangHeader(lang);
      i18next.changeLanguage(lang);
      Utils.savedAdminLocale(lang);
      dispatch(setAdminLang(lang));
    }
    setIsShowLangOptions(false);
  };

  // Handle change logout
  const onLogOut = async () => {
    const isAgree = await statusAlert("warning", t(`alert.warningLogOut`));
    if (isAgree) dispatch(logout());
  };

  const renderLanguageOptions = () => {
    const render = _.map(ADMIN_LANGUAGE_OPTIONS, (option, index) => {
      const { icon, lang } = option;
      const key = `lang-option-${index}`;
      if (locale === lang) return null;
      return (
        <MenuItem
          key={key}
          onClick={() => onChangeLanguage(lang)}
          className="flex-align-center flex-justify-between pl-1 pr-1"
        >
          <img className="mr-1" src={icon} alt="flag" />
        </MenuItem>
      );
    });
    return (
      <FlexHorizontal justifyCenter position="relative" className="mr-2">
        <FlexHorizontal
          alignCenter
          onClick={() => setIsShowLangOptions(!isShowLangOptions)}
        >
          <img
            src={
              locale === "en" ? Icons.english.default : Icons.albanian.default
            }
            alt={`${locale === "en" ? "US flag" : "AL flag"}`}
            className="mr-1"
          />
          <i className="fas fa-caret-down" />
        </FlexHorizontal>
        <MenuOptions className={isShowLangOptions ? "show" : ""} width="70px">
          {render}
        </MenuOptions>
      </FlexHorizontal>
    );
  };

  const renderProfileOptions = () => {
    return (
      <FlexHorizontal position="relative">
        <img
          src={Icons.defaultUser.default}
          alt="default user icon"
          onClick={() => setIsShowUserOptions(!isShowUserOptions)}
        />
        <MenuOptions className={isShowUserOptions ? "show" : ""}>
          <MenuItem
            onClick={() => {
              history.push(ROUTES.ADMIN.PROFILE);
              setIsShowUserOptions(false);
            }}
          >
            {t("label.profile")}
          </MenuItem>
          <MenuItem onClick={() => onLogOut()}>{t("label.logOut")}</MenuItem>
        </MenuOptions>
      </FlexHorizontal>
    );
  };

  return (
    <SideBarContainer>
      <TitlePage>
        {currentPage && t(`pageTitle.${_.get(PageTitle, currentPage)}`)}
      </TitlePage>
      <FlexHorizontal width="unset">
        {renderLanguageOptions()}
        {renderProfileOptions()}
      </FlexHorizontal>
      {(isShowLangOptions || isShowUserOptions) && (
        <DropdownLayer
          onClick={() => {
            setIsShowLangOptions(false);
            setIsShowUserOptions(false);
          }}
        />
      )}
    </SideBarContainer>
  );
};

export default SideBar;
