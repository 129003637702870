import styled from 'styled-components';
import { VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

interface SectionProps {
  col?: string;
  gap?: string;
  background?: string;
  flex?: string;
}

const ContentCol = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) => `repeat(${props.col}, 1fr)` || `repeat(1, 1fr)`};
  column-gap: 50px;
  row-gap: 68px;
  flex: 2.5;
`;

const SolutionCategory = styled.h3`
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 30px;
  color: ${ColorName.black};
  padding-right: 65px;
`;

const SolutionSubCategory = styled.h3`
  font-weight: 700;
  line-height: 2.3rem;
  color: ${ColorName.darkElectricBlue};
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding-right: 65px;
  margin-top: 5px;
`;

const SolutionTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  text-transform: uppercase;
`;

const ShowButton = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background: ${ColorName.pampas};
  border-radius: 50%;
  right: 10px;
  top: calc(50% - 25px);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: all ease 0.5s;
  }

  &:hover {
    cursor: pointer;
  }
  &.show img {
    transform: rotate(45deg);
  }
`;

const SolutionContent = styled(VerticalContainer)`
  max-height: 0px;
  height: 100%;
  overflow: hidden;
  transition: all ease 0.5s;
  margin: 0;

  &.show {
    margin: 1rem 0 4rem;
  }

  strong,
  b {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.8rem;

    @media screen and (max-width: 426px) {
      font-size: 16px;
    }
  }
`;

const GifImage = styled.div`
  margin-right: 3rem;

  img {
    max-width: 60px;
    object-fit: contain;
    @media screen and (max-width: 426px) {
      max-width: 30px;
    }
  }
`;

const HeadingWrapper = styled(VerticalContainer)`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 15px 0;
`;

const ShowMoreContentContainer = styled.div`
  width: 100%;
  padding-bottom: 3rem;

  @media screen and (max-width: 426px) {
    ${SolutionCategory} {
      font-size: 20px;
      line-height: 26px;
    }
    ${SolutionSubCategory} {
      font-size: 16px;
      line-height: 21px;
    }
    ${ShowButton} {
      width: 40px;
      height: 40px;
    }
  }
`;

export {
  ShowMoreContentContainer,
  ContentCol,
  SolutionCategory,
  SolutionSubCategory,
  SolutionTitle,
  SolutionContent,
  ShowButton,
  GifImage,
  HeadingWrapper,
};
