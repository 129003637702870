/* eslint import/no-anonymous-default-export: off */
export default {
  container: {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.45,
        staggerChildren: 0.3,
      },
    },
  },

  item: {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  },
};
