import React from 'react';
import _ from 'lodash';
import {
  SolutionCardContainer,
  Title,
  Subtitle,
  SolutionIcon,
  IconWrapper,
  SolutionContent,
  BackgroundZoomIn,
} from './SolutionCard.styles';
import { useSelector } from 'react-redux';
// import _ from "lodash";

interface DataStructure {
  title: {
    en: string;
    al: string;
  };
  description: {
    en: string;
    al: string;
  };
  icon: string;
  background?: string;
  location?: string;
}
interface SectionProps {
  data: DataStructure;
  onClick?(value: string): unknown;
  color?: string;
}

const SolutionCard: React.FC<SectionProps> = (props: SectionProps) => {
  const { color, onClick, data } = props;
  const { title, description, icon, background, location } = data;
  const lang = useSelector(state => _.get(state, 'PageSettings.userLang'));

  return (
    <SolutionCardContainer onClick={() => onClick && _.isString(location) && onClick(location)} color={color}>
      <BackgroundZoomIn background={background} />
      <SolutionContent>
        <IconWrapper>
          <SolutionIcon src={icon} alt="solution-icon"/>
        </IconWrapper>
        <Title>{_.get(title, lang)}</Title>
        <Subtitle>{_.get(description, lang)}</Subtitle>
      </SolutionContent>
    </SolutionCardContainer>
  );
};

export default SolutionCard;
