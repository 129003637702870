/* eslint-disable no-useless-escape */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { motion } from "framer-motion";
import { Content, RegularParagraph } from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";

import { ROUTES, FRAME_MOTIONS } from "../../../constants";
import { MainContainer } from "./AreasOfInterest.styles";

const AreasOfInterest = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const payload = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.AREAS_OF_INTEREST}`)
  );

  useEffect(() => {}, []);

  const renderMain = () => {
    return (
      <MainContainer>
        <Content className="mt-6 mb-8 content" direction="column">
          {!_.isEmpty(_.get(payload, `content.${lang}`)) ? (
            <motion.div
              variants={FRAME_MOTIONS.CONTAINER}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={FRAME_MOTIONS.ITEM_EFFECT}>
                <RegularParagraph
                  dangerouslySetInnerHTML={{
                    __html: _.get(payload, `content.${lang}`) || "",
                  }}
                />
              </motion.div>
            </motion.div>
          ) : (
            <h3>{t("label.noResultFound")}</h3>
          )}
        </Content>
      </MainContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.AREAS_OF_INTEREST}
    />
  );
};

export default AreasOfInterest;
