import styled from "styled-components";
import { VerticalContainer, HorizontalContainer } from "../CommonLayout";
import { ColorName } from "../../Variables";
import _ from "lodash";

const widthInit = 260;

const OutputImage = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;
  padding-top: 143%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
`;

const calculateRatio = (width: number) => {
  const height = _.round(width / (295 / 400));
  return `${height}px`;
};

const OutputNewsImage = styled.div`
  position: relative;
  width: ${widthInit}px;
  height: ${calculateRatio(widthInit)};
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;
  /* padding-top: 48%; */
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  @media screen and (max-width: 600px) {
    margin-right: 15px;
    width: ${190}px;
    height: ${calculateRatio(190)};
  }
  @media screen and (max-width: 436px) {
    margin-right: 15px;
    width: 170px;
    height: 244px;
  }
  @media screen and (max-width: 320px) {
    margin-right: 15px;
    width: 100%;
    height: 290px;
  }
`;

const NewsHomepageCard = styled(VerticalContainer)`
  max-width: 400px;
  box-sizing: border-box;
  transition: all ease 0.25s;

  @media screen and (max-width: 1025px) {
    max-width: 100%;
  }
  @media screen and (max-width: 836px) {
    width: 250px;
  }
  @media screen and (max-width: 426px) {
    width: 175px;
  }

  & .news-content {
    flex: 1;
  }

  & .thumbnail {
    position: relative;
    /* max-height: 447px; */

    @media screen and (max-width: 1025px) {
      max-height: 650px;
    }

    @media screen and (max-width: 836px) {
      max-height: 650px;
    }

    & .arrow {
      position: absolute;
      font-size: 24px;
      bottom: 16px;
      right: 26px;
      opacity: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.25s;

      @media screen and (max-width: 426px) {
        display: none;
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  & .content {
    padding: 0.5em 0;
    transition: all ease 0.25s;
  }

  span {
    margin-top: 0.5em;
    font-size: 16px;
    font-weight: 500;
    color: ${ColorName.grannySmith};
    margin: 0;
  }

  & .title {
    display: block;
    margin-top: 0.2em;
    font-size: 19px;
    line-height: 31px;
    font-weight: 500;
    color: ${ColorName.black};
    margin-bottom: 0;
    text-decoration: none;

    @media screen and (max-width: 426px) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    transition: all ease 0.25s;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  a {
    transition: all ease 0.25s;
  }

  p {
    opacity: 0;
    transition: all ease 0.25s;
    color: ${ColorName.grannySmith};
    @media screen and (max-width: 426px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    p,
    img,
    a,
    ${OutputImage} {
      transform: translateY(-40px);
      opacity: 1;
    }

    a {
      color: ${ColorName.burntSienna};
    }

    & .thumbnail .arrow {
      right: 16px;
      opacity: 1;
    }
  }
`;

const NewsButton = styled(HorizontalContainer)`
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  & .icon_box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
    background: ${ColorName.deepCarrotOrange};
    i {
      font-size: 1.7rem;
      color: ${ColorName.white};
    }
  }
  h2 {
    font-size: 3rem;
  }
  p {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 20px 0 10px;
    transition: all ease-in-out 0.3s;
    color: ${ColorName.black};
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
    p {
      color: ${ColorName.white};
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0;
    background: ${ColorName.deepCarrotOrange};
    transition: all ease-in-out 0.3s;
    border-radius: 25px;
  }
  &:hover::before {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    & .icon_box {
      width: 35px;
      height: 35px;
      i {
        font-size: 12px;
      }
    }
    p,
    i {
      font-size: 12px;
    }

    &:before {
      width: 35px;
      height: 35px;
    }
  }

  @media screen and (max-width: 436px) {
    & .icon_box {
      width: 30px;
      height: 30px;
      i {
        font-size: 12px;
      }
    }
    p,
    i {
      font-size: 12px;
    }

    &:before {
      width: 30px;
      height: 30px;
    }
  }
`;

const DefaultNewsCard = styled.div`
  display: flex;
  padding-bottom: 3.5rem;
  margin-top: 3.5rem;
  & .preview {
    max-height: 150px;
    overflow: hidden;
  }
  & .preview span,
  & .preview p {
    color: ${ColorName.black} !important;
  }
  img {
    width: 100%;
    max-width: 295px;
    height: 420px;
    object-fit: cover;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  & .news-content {
    flex: 1;
  }

  &:hover {
    img {
      cursor: pointer;
    }
  }

  & .content {
    span {
      color: ${ColorName.gray};
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    a {
      color: ${ColorName.shark};
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      margin: 0 0 10px 0;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        color: ${ColorName.burntSienna};
        text-decoration: underline;
      }
    }
    p {
      margin: 0;
      color: ${ColorName.grannySmith};
      font-size: 15px;
      font-weight: 400;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 1120px) {
        -webkit-line-clamp: 4;
      }
      @media screen and (max-width: 600px) {
        -webkit-line-clamp: 3;
      }
      @media screen and (max-width: 436px) {
        -webkit-line-clamp: 2;
      }
    }
    & .link {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      ${NewsButton} {
        p {
          color: ${ColorName.black};
          font-weight: 600;
        }
        &:hover {
          color: ${ColorName.white};

          p {
            color: ${ColorName.white};
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .content {
      a {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    img {
      width: 100%;
      max-width: unset;
      filter: blur(0);
      -webkit-filter: blur(0);
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      /* max-height: 225px; */
    }
    .content {
      margin-left: 0 !important;
      span {
        margin: 5px 0;
        font-size: 13px;
      }
      a {
        font-size: 16px;
        line-height: 23px;
        margin: 0 0 5px 0;
      }
      p {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  @media screen and (max-width: 436px) {
    .content {
      margin-left: 0 !important;
      span {
        margin: 5px 0;
        font-size: 11px;
      }
      a {
        font-size: 15px;
        line-height: 19px;
        margin: 0 0 10px 0;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    flex-direction: column;
  }
`;

export {
  NewsHomepageCard,
  DefaultNewsCard,
  NewsButton,
  OutputImage,
  OutputNewsImage,
};
