import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Icons } from "../../../themes";
import {
  CropperContainer,
  Viewer,
  OutputImage,
  CropperDefault,
} from "./CropImage.styles";

const CROP_AREA_ASPECT = 295 / 420;

interface CroppedAreaStructure {
  x: number;
  width: number;
  y: number;
  height: number;
}

interface DataStructure {
  cropped: {
    x: number;
    y: number;
  };
  zoomed: number;
  croppedAreaData: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface CropImageStructure {
  imageUrl: string;
  data?: DataStructure;
  handleChange?(data: DataStructure): unknown;
  isMembers?: boolean;
}

const CropImage = (props: CropImageStructure) => {
  const { imageUrl, data, handleChange, isMembers } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<CroppedAreaStructure>({
    width: 0,
    x: 0,
    y: 0,
    height: 0,
  });

  useEffect(() => {
    if (data) {
      const { cropped, zoomed, croppedAreaData } = data;
      setCrop(cropped);
      setZoom(zoomed);
      setCroppedArea(croppedAreaData);
    }
  }, [data]);

  const handleCropComplete = (newCropValue: any) => {
    if (handleChange)
      handleChange({
        zoomed: zoom,
        cropped: crop,
        croppedAreaData: newCropValue,
      });
  };

  const Output = (props: { croppedArea: CroppedAreaStructure }) => {
    const { croppedArea } = props;
    const scale = 100 / croppedArea.width;
    const transform = {
      x: `${-croppedArea.x * scale}%`,
      y: `${-croppedArea.y * scale}%`,
      scale,
      width: "calc(100% + 0.5px)",
      height: "auto",
    };

    const imageStyle = {
      transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
      width: transform.width,
      height: transform.height,
    };
    if (!imageUrl) {
      return (
        <OutputImage width="300px" className="output">
          <img src={Icons.emptyImage.default} alt="empty-thumbnail" />
        </OutputImage>
      );
    }
    return (
      <OutputImage width={`${isMembers ? "400px" : ""}`} className="output">
        <img
          src={imageUrl || Icons.emptyImage.default}
          alt="news-thumbnail"
          style={imageStyle}
        />
      </OutputImage>
    );
  };

  return (
    <CropperContainer>
      <CropperDefault>
        <Cropper
          image={imageUrl}
          aspect={isMembers ? 400 / 400 : CROP_AREA_ASPECT}
          crop={crop}
          zoom={zoom}
          onCropChange={(newCrop: any) => setCrop(newCrop)}
          onZoomChange={(newZoom: number) => setZoom(newZoom)}
          onCropAreaChange={(croppedArea: CroppedAreaStructure) =>
            setCroppedArea(croppedArea)
          }
          onCropComplete={(newCropComplete: any) =>
            handleCropComplete(newCropComplete)
          }
        />
      </CropperDefault>
      <Viewer height={`${isMembers ? "400px" : ""}`}>
        <div>{croppedArea && <Output croppedArea={croppedArea} />}</div>
      </Viewer>
    </CropperContainer>
  );
};

export default CropImage;
