import React from 'react';
import _ from 'lodash';
import { StepBar, StepDot, StepLabel, StepLine, Dot } from './Step.styles';

interface SectionProps {
  step: {
    label: string;
    value: string;
  }[];
  active: string;
  onChangeStep(step: string): void;
}

const Step = (props: SectionProps) => {
  const { step, active, onChangeStep } = props;
  const renderMain = () => {
    const render = _.map(step, (item, index) => {
      const { label, value } = item;
      const findIndex = _.findIndex(step, item => item.value === active);
      const activeClass = index <= findIndex ? 'active' : '';
      return (
        <React.Fragment key={`step-${index}`}>
          {index > 0 && <StepLine className={activeClass} />}
          <StepDot className={activeClass} onClick={() => onChangeStep(value)}>
            <Dot>{index + 1}</Dot>
            <StepLabel>{label}</StepLabel>
          </StepDot>
        </React.Fragment>
      );
    });
    return render;
  };
  return <StepBar>{renderMain()}</StepBar>;
};

export default Step;
