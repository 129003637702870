import _ from "lodash";
import styled from "styled-components";
import { ColorName } from "../../../components/Variables";
import { BREAK_POINTS } from "../../../constants";
import { FlexHorizontal } from "../../Common";
import generateResponsive from "../../Common/responsive";
import responsiveValue from "../../Common/responsive.value";

interface SectionProps {
  background?: string;
}

const InitCommonSpaceCss = () => {
  const initNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const commonCSS: string[] = [];
  _.map(initNumber, (style) => {
    commonCSS.push(`
      & .mr-${style} {margin-right: ${style}rem;}
      & .ml-${style} {margin-left: ${style}rem;}
      & .mt-${style} {margin-top: ${style}rem;}
      & .mb-${style} {margin-bottom: ${style}rem;}
      & .m-${style} {margin: ${style}rem;}
      & .pr-${style} {padding-right: ${style}rem;}
      & .pl-${style} {padding-left: ${style}rem;}
      & .pt-${style} {padding-top: ${style}rem;}
      & .pb-${style} {padding-bottom: ${style}rem;}
      & .p-${style} {padding: ${style}rem;}

      @media screen and (min-width: 1025px) and (max-width: 1920px) {
        & .desktop-mr-${style} {margin-right: ${style}rem;}
        & .desktop-ml-${style} {margin-left: ${style}rem;}
        & .desktop-mt-${style} {margin-top: ${style}rem;}
        & .desktop-mb-${style} {margin-bottom: ${style}rem;}
        & .desktop-m-${style} {margin: ${style}rem;}
        & .desktop-pr-${style} {padding-right: ${style}rem;}
        & .desktop-pl-${style} {padding-left: ${style}rem;}
        & .desktop-pt-${style} {padding-top: ${style}rem;}
        & .desktop-pb-${style} {padding-bottom: ${style}rem;}
        & .desktop-p-${style} {padding: ${style}rem;}
      }
      
      @media screen and (max-width: 836px) {
        & .mr-${style} {margin-right: ${style / 1.5}rem;}
        & .ml-${style} {margin-left: ${style / 1.5}rem;}
        & .mt-${style} {margin-top: ${style / 1.5}rem;}
        & .mb-${style} {margin-bottom: ${style / 1.5}rem;}
        & .m-${style} {margin: ${style / 1.5}rem;}
        & .pr-${style} {padding-right: ${style / 1.5}rem;}
        & .pl-${style} {padding-left: ${style / 1.5}rem;}
        & .pt-${style} {padding-top: ${style / 1.5}rem;}
        & .pb-${style} {padding-bottom: ${style / 1.5}rem;}
        & .p-${style} {padding: ${style / 1.5}rem;}
      }

      @media screen and (max-width: 426px) {
        & .mr-${style} {margin-right: ${style / 2}rem;}
        & .ml-${style} {margin-left: ${style / 2}rem;}
        & .mt-${style} {margin-top: ${style / 2}rem;}
        & .mb-${style} {margin-bottom: ${style / 2}rem;}
        & .m-${style} {margin: ${style / 2}rem;}
        & .pr-${style} {padding-right: ${style / 2}rem;}
        & .pl-${style} {padding-left: ${style / 2}rem;}
        & .pt-${style} {padding-top: ${style / 2}rem;}
        & .pb-${style} {padding-bottom: ${style / 2}rem;}
        & .p-${style} {padding: ${style / 2}rem;}
      }
    `);
  });
  commonCSS.push(`
    .mr-auto {margin-right: auto;}
    .ml-auto {margin-left: auto;}
    .mt-auto {margin-top: auto;}
    .mb-auto {margin-bottom: auto;}
    & .m-auto {margin: auto;}
    & .m-unset {margin: unset;}
    & .p-unset {padding: unset;}
  `);
  return commonCSS.join(" ");
};

const InitFlexDirectionStyles = () => {
  const initNumberStyles = [0, 1];
  const initStyle = ["row", "column"];
  const common: string[] = [];
  _.map(initNumberStyles, (number) => {
    common.push(`
      & .flex-${initStyle[number]} {display: flex; flex-direction: ${initStyle[number]};}
    `);
  });
  return common.join(" ");
};

const InitFlexJustifyStyles = () => {
  const initNumberStyles = [0, 1, 2, 3, 4];
  const initStyle = [
    "flex-start",
    "flex-end",
    "space-between",
    "center",
    "space-around",
  ];
  const initStyleName = ["-start", "-end", "-between", "-center", "-around"];
  const common: string[] = [];
  _.map(initNumberStyles, (number) => {
    common.push(`
      & .flex-justify${initStyleName[number]} {justify-content: ${initStyle[number]};}
    `);
  });
  return common.join(" ");
};

const InitFlexAlignStyles = () => {
  const initNumberStyles = [0, 1, 2, 3];
  const initStyle = ["flex-start", "flex-end", "center", "baseline"];
  const initStyleName = ["-start", "-end", "-center", "-baseline"];
  const common: string[] = [];
  _.map(initNumberStyles, (number) => {
    common.push(`
      & .flex-align${initStyleName[number]} {align-items: ${initStyle[number]};}
    `);
  });
  return common.join(" ");
};

const InitFlexWrapStyles = () => {
  const initNumberStyles = [0, 1];
  const initStyle = ["wrap", "nowrap"];
  const common: string[] = [];
  _.map(initNumberStyles, (number) => {
    common.push(`
      & .flex-${initStyle[number]} {flex-wrap: ${initStyle[number]};}
    `);
  });
  return common.join(" ");
};

const InitTextAlignStyles = () => {
  const initNumberStyles = [0, 1, 2];
  const initStyle = ["left", "right", "center"];
  const common: string[] = [];
  _.map(initNumberStyles, (number) => {
    common.push(`
      & .text-${initStyle[number]} {text-align: ${initStyle[number]};}
    `);
  });
  return common.join(" ");
};

const DefaultLayoutContainer = styled.div`
  ${InitCommonSpaceCss()}
  ${InitFlexDirectionStyles()}
  ${InitFlexJustifyStyles()}
  ${InitFlexAlignStyles()}
  ${InitFlexWrapStyles()}
  ${InitTextAlignStyles()}
 
  background: ${ColorName.white};

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  * {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${ColorName.aquaHaze};
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ColorName.darkElectricBlue};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${ColorName.darkElectricBlue};
      border: 2px solid #555555;
    }

    @media screen and (max-width: 836px) {
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${ColorName.aquaHaze};
      }
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background-color: ${ColorName.darkElectricBlue};
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${ColorName.darkElectricBlue};
        border: 2px solid #555555;
      }
    }
  }

  & .sun-editor .se-toolbar {
    position: sticky;
    top: -29px;
  }

  & .ql-editor {
    overflow: hidden;
    iframe {
      width: 100%;
      height: 390px;
    }

    * {
      font-size: 16px;
      line-height: 23px;
    }
  }
  & .ql-toolbar.ql-snow {
    position: sticky;
    top: -30px;
    background: white;
    z-index: 1;
  }
  & .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const Container = styled.div`
  display: flex;
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ColorName.white};
`;

const ContainerContent = styled.div`
  display: block;
  flex: 1;
  background: ${ColorName.white};
`;

const AdminContent = styled(FlexHorizontal)`
  width: 100%;
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 86px);
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }
`;

const TopicImage = styled.div`
  width: 100vw;
  box-sizing: border-box;
  background-position: bottom;
  position: relative;
  background: ${(props: SectionProps) => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 1920px;
  margin: 0 auto;
  img {
    width: 100%;
    object-fit: contain;
  }
  h2 {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  ${BREAK_POINTS.MOBILE} {
    h2 {
      display: none;
    }
  }
`;

const TopicLabel = styled.h3`
  font-size: 33px;
  font-weight: bold;
  margin: 0;

  ${generateResponsive(responsiveValue.HEADER_IMAGE_LABEL)}
`;

const CurrentPageBar = styled(FlexHorizontal)`
  height: 100px;
  background: ${ColorName.darkElectricBlue};
  padding: 30px 40px;
  box-sizing: border-box;
  align-items: flex-end;
  h3 {
    color: ${ColorName.white};
    font-size: 30px;
    line-height: 40px;
  }
`;

const SpaceHeaderBox = styled.div`
  height: 80px;

  ${BREAK_POINTS.MOBILE} {
    height: 60px;
  }
`;

export {
  DefaultLayoutContainer,
  Container,
  ContainerMenu,
  ContainerContent,
  TopicImage,
  CurrentPageBar,
  AdminContent,
  SpaceHeaderBox,
  TopicLabel,
};
