/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  Content,
  FilterNews,
  NewsCard,
  UserPagination,
} from "../../../components/Common";
import SkeletonLoading from "./SkeletonLoading";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import SecondHeader from "../../../components/Layout/CommonLayoutPart/SecondHeader";
import { NewsContainer, ContentCol, SVGImage } from "./News.styles";
import { ROUTES } from "../../../constants";
import { SECOND_HEADER, FILTERS } from "../../../constants/Constants";
import { ArticlesActions } from "../../../actions";
import history from "../../../history";
import { Images } from "../../../themes";
import { Utils } from "../../../libs";
import { useLocation } from "react-router-dom";

// Declare actions
const { filterArticles } = ArticlesActions;
// Declare constants

const News = () => {
  // Declare dispatch, reducer
  const dispatch = useDispatch();
  const location = useLocation();
  // const filter = useSelector(state => _.get(state, `Articles.filter.user`));
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const newsPayload: any = useSelector((state) =>
    _.get(state, "Articles.payload")
  );
  const meta = useSelector((state) => _.get(state, "Articles.meta"));
  const pagination: any = useSelector((state) =>
    _.get(state, "Articles.userMetaPagination")
  );
  const language = useSelector((state) =>
    _.get(state, "PageSettings.userLang")
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<any>({ features: "news" });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const getSearchLocation = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const features = params.get("features");
    const categories = params.get("categories");
    const topic = params.get("topic");
    const resolveFilter = { ...currentFilter };
    if (!features) {
      history.push(`${ROUTES.USER.NEWS}?features=news`);
      _.assign(resolveFilter, { features: "news" });
    }
    if (features) _.assign(resolveFilter, { features });
    if (categories) _.assign(resolveFilter, { categories });
    if (topic) _.assign(resolveFilter, { topic });
    setCurrentFilter({ ...resolveFilter });
    setFirstLoad(true);
  };

  useEffect(() => {
    getSearchLocation();
  }, []);

  useEffect(() => {
    if (firstLoad)
      dispatch(
        filterArticles({
          ...pagination,
          ...currentFilter,
          language,
          type: "user",
        })
      );
  }, [language, currentFilter]);

  useEffect(() => {
    if (isRequestLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        Utils.scrollToBottomOfBanner();
      }, 1500);
    }
  }, [isRequestLoading]);

  // Handle click view details
  const handleViewDetails = async (id: string) => {
    const currentSolution = _.get(currentFilter, "categories");
    document.documentElement.style.scrollBehavior = "unset";
    const search = location.search;
    if (currentSolution === "researchNews")
      history.push({
        pathname: `${ROUTES.USER.RESEARCH_NEWS}/${id}`,
        state: { from: ROUTES.USER.NEWS, query: search },
      });
    else if (currentSolution === "developmentNews")
      history.push({
        pathname: `${ROUTES.USER.DEVELOPMENT_NEWS}/${id}`,
        state: { from: ROUTES.USER.NEWS, query: search },
      });
    else
      history.push({
        pathname: `${ROUTES.USER.NEWS}/${id}`,
        state: { from: ROUTES.USER.NEWS, query: search },
      });
  };

  const onChangeFilter = async (newFilter: any, key: string, type?: string) => {
    if (key === "sub_header") {
      const currentCategory = _.get(currentFilter, "features");
      if (newFilter !== currentCategory) {
        const resolveFilter = {
          ...currentFilter,
          features: newFilter,
        };
        const searchQuery = "?";
        const query: any[] = [];
        _.map(resolveFilter, (item, key) => {
          if (!_.isEmpty(item)) query.push(`${key}=${item}`);
        });
        history.push({
          pathname: ROUTES.USER.NEWS,
          search: searchQuery.concat(_.join(query, "&")),
        });
        Utils.scrollToBottomOfBanner();

        if (_.includes(["insights", "successStories"], currentCategory))
          _.assign(resolveFilter, { topic: "" });
        setCurrentFilter(resolveFilter);
      }
    } else {
      if (type === "reset") {
        const params = new URLSearchParams(location.search);
        params.delete("yearFrom");
        params.delete("yearTo");
        const newSearch = params.toString();
        history.push({
          pathname: `${ROUTES.USER.NEWS}`,
          search: newSearch,
        });
        setCurrentFilter({
          ...newFilter,
          yearFrom: "",
          yearTo: "",
          currentPage: 1,
        });
      } else {
        const searchQuery = "?";
        const query: any[] = [];
        _.map(newFilter, (item, key) => {
          if (!_.isEmpty(item)) query.push(`${key}=${item}`);
        });

        history.push({
          pathname: `${ROUTES.USER.NEWS}`,
          search: searchQuery.concat(_.join(query, "&")),
        });
        setCurrentFilter({ ...newFilter, currentPage: 1 });
      }
    }
  };

  // Handle generate filter column
  const handleDataFilter = () => {
    return FILTERS.NEWS;
  };

  // Render filter column
  const renderFilter = () => {
    const defaultFilter = {
      ...currentFilter,
      categories: "",
      topic: "",
      page: "news",
    };
    return (
      <FilterNews
        data={handleDataFilter()}
        activeFilter={currentFilter}
        onChange={(value: any) => onChangeFilter(value, "filter")}
        onClick={() => onChangeFilter(defaultFilter, "filter", "reset")}
      />
    );
  };

  const renderContent = () => {
    return (
      <ContentCol marginLeft={120}>
        {!_.isEmpty(newsPayload) ? (
          _.map(newsPayload, (news, index) => {
            return (
              <NewsCard
                key={`news-${index}`}
                data={news}
                onClick={(location: string) => handleViewDetails(location)}
                pathname={ROUTES.USER.NEWS}
                searchQuery={location.search}
              />
            );
          })
        ) : (
          <SVGImage
            src={Images.noResultFound.default}
            alt="no result found illustrators"
          />
        )}
        {!_.isEmpty(newsPayload) && (
          <div className="mt-2">
            <UserPagination
              currentPage={_.get(meta, "currentPage")}
              totalPages={_.get(meta, "totalPages")}
              onClick={(currentPage: number) =>
                setCurrentFilter({ ...currentFilter, currentPage })
              }
            />
          </div>
        )}
      </ContentCol>
    );
  };

  const renderMain = () => {
    return (
      <NewsContainer>
        <SecondHeader
          data={SECOND_HEADER.NEWS}
          active={_.get(currentFilter, "features")}
          onChange={(value: string) => onChangeFilter(value, "sub_header")}
        />
        <Content className="mt-8 news">
          {renderFilter()}
          {isRequestLoading || isLoading ? (
            <SkeletonLoading marginLeft={120} />
          ) : (
            renderContent()
          )}
        </Content>
      </NewsContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.NEWS}
      headerTitle="General News"
      metaDescription="Discover more about our latest news"
    />
  );
};

export default News;
