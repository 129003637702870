import styled from 'styled-components';
import { FlexHorizontal, PageContainer } from '../../../components/Common';
import generateResponsive from '../../../components/Common/responsive';
import responsiveValue from '../../../components/Common/responsive.value';
import { ColorName } from '../../../components/Variables';

const OutputImage = styled.div`
  position: relative;
  width: 120px;
  height: 157px;
  overflow: hidden;
  transition: all ease 0.25s;
  background: white;
  img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }
  @media screen and (max-width: 600px) {
    width: 120px;
    height: 157px;
  }
  @media screen and (max-width: 436px) {
    margin-right: 15px;
    width: 110px;
    height: 150px;
  }
  @media screen and (max-width: 320px) {
    margin-right: 15px;
    width: 100%;
    height: 290px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${ColorName.grannySmith};
`;

const RelatedPost = styled(FlexHorizontal)`
  a {
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    color: ${ColorName.shark};
    font-weight: 700;

    &:hover {
      color: ${ColorName.burntSienna};
      cursor: pointer;
      text-decoration: underline;
    }
  }
  & .timestamp {
    font-size: 14px;
    line-height: 20px;
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    a {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 16px;
      line-height: 23px;
      margin: 0;
    }
    & .timestamp {
      font-size: 13px;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 436px) {
    a {
      font-size: 14px;
      line-height: 21px;
      margin: 10px 0 5px 0;
    }
    & .timestamp {
      font-size: 12px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 320px) {
    flex-direction: column;
  }
`;

const NewsDetailsContainer = styled(PageContainer)`
  width: 100%;

  & .back_link {
    font-size: 22px;
    font-weight: bold;
    color: ${ColorName.grannySmith};
    text-decoration: underline;
    margin-right: 35px;
    transition: all ease 0.25s;
    &:hover {
      cursor: pointer;
      color: ${ColorName.black};
    }
  }

  @media screen and (max-width: 436px) {
    .back_link {
      display: none;
      /* font-size: 18px; */
    }
    .banner-content {
      padding: 0 20px;
    }

    ${RelatedPost} {
      .output {
        max-width: 100%;
      }
      img {
        width: 100%;
        object-position: center;
      }

      & .related-news {
        padding: 0 !important;
        margin-bottom: 2rem;
      }
    }
  }
`;

const DetailsContentColumn = styled.div`
  display: flex;
  justify-content: center;

  ${generateResponsive(responsiveValue.NEWS_DETAILS_CONTENT)}

  @media screen and (max-width: 1439px) {
    display: block;
  }
`;

export { NewsDetailsContainer, Line, RelatedPost, OutputImage, DetailsContentColumn };
