import React, { useEffect } from 'react';
import { TextareaAutosizeContainer } from './TextareaAutosize.styles';

interface ISectionProps {
  type: 'title' | 'desc';
  onChange(e: any): void;
  value: string;
  placeholder?: string;
  onFocus?(e: any): void;
}

const TextareaAutosize: React.FC<ISectionProps> = ({ type, onChange, value, placeholder = '', onFocus }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [value]);

  const handleInputChange = (e: any) => {
    onChange(e);
  };

  return (
    <TextareaAutosizeContainer
      ref={textareaRef}
      className={type}
      value={value}
      placeholder={placeholder}
      onChange={(e: any) => handleInputChange(e)}
      onFocus={(e: any) => onFocus && onFocus(e)}
    ></TextareaAutosizeContainer>
  );
};

export default TextareaAutosize;
