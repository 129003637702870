import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PreviewContainer, Content } from './Preview.styles';
import { HeaderPopup, PopupWrapper, ButtonBar } from '../DefaultPopup.styles';
import { AdminDropdown, CarrotSolidButton, Description, Title } from '../../../Common';
import { useTranslation } from 'react-i18next';

interface ISectionProps {
  className?: string;
  details: any;
  onClose(): void;
}

const PreviewCareers = (props: ISectionProps) => {
  const { t } = useTranslation('admin');
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { className, details, onClose } = props;
  const [locale, setLocale] = useState<string>('en');
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);

  useEffect(() => {
    if (details) {
      const languageDisplay = get(details, 'languageDisplay');
      if (languageDisplay === 'both')
        setLanguageOptions([
          { label: 'english', value: 'en' },
          { label: 'albanian', value: 'al' },
        ]);
      if (languageDisplay === 'en') {
        setLanguageOptions([{ label: 'english', value: 'en' }]);
        setLocale('en');
      }
      if (languageDisplay === 'al') {
        setLanguageOptions([{ label: 'albanian', value: 'al' }]);
        setLocale('al');
      }
    }
  }, [details]);

  const renderMain = () => {
    return (
      <Content>
        <HeaderPopup>{t('headerPopup.previewCareers')}</HeaderPopup>
        <PreviewContainer>
          {!isEmpty(details) && (
            <div className="content">
              <AdminDropdown
                width="150px"
                selectedValue={locale}
                data={languageOptions}
                onChange={(value: string) => setLocale(value)}
              />
              <Title className="mt-4 mb-2">{get(details, `title.${locale}`) || 'Title is empty'}</Title>
              <Description
                className="preview"
                ref={descriptionRef}
                dangerouslySetInnerHTML={{ __html: get(details, `content.${locale}`) || 'Content is empty' }}
              />
            </div>
          )}
        </PreviewContainer>
        <ButtonBar className="flex-justify-end">
          <CarrotSolidButton onClick={() => onClose()}>{t('button.close')}</CarrotSolidButton>
        </ButtonBar>
      </Content>
    );
  };

  return <PopupWrapper className={`popup-wrapper ${className}`}>{renderMain()}</PopupWrapper>;
};

export default PreviewCareers;
