/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const initialState = {
  isLogged: false,
  hasError: false,
  isLoading: false,
  isSuccess: false,
};

export default (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case ActionTypes.IS_LOGGED:
      return {
        ...state,
        isLogged: true,
      };

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        isLogged: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
