import DOMPurify from 'dompurify';
import get from 'lodash/get';

const createMarkup = (html: any) => {
  const config = { ALLOWED_ATTR: ['style', 'href', 'target'], ALLOWED_TAGS: ['b', 'strong'] };
  const allowed_properties = {
    fontWeight: 'font-weight',
    fontStyle: 'font-style',
  };

  // Specify if CSS functions are permitted
  var allow_css_functions = true;

  const validateStyles = (output: any, styles: any) => {
    // Validate regular CSS properties
    for (var prop in styles) {
      if (typeof styles[prop] === 'string') {
        if (styles[prop] && get(allowed_properties, prop)) {
          if (allow_css_functions || !/\w+\(/.test(styles[prop])) {
            output.push(get(allowed_properties, prop) + ':' + styles[prop] + ';');
          }
        }
      }
    }
  };

  // Add a hook to enforce CSS attribute sanitization
  DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
    if (node.hasAttribute('style')) {
      var output: any[] = [];
      validateStyles(output, node.style);
      // re-add styles in case any are left
      if (output.length) {
        node.setAttribute('style', output.join(''));
      } else {
        node.removeAttribute('style');
      }
    }
  });

  return {
    __html: DOMPurify.sanitize(html, config),
  };
};

export default createMarkup;
