import styled from 'styled-components';
import { FlexHorizontal } from '../FlexLayout';
import { ColorName } from '../../Variables';

interface SectionProps {
  isLeftLabel?: boolean;
}

const RadioLabel = styled.label`
  font-size: 14px;
  color: ${ColorName.darkElectricBlue};
  margin-right: ${(props: SectionProps) => (props.isLeftLabel ? '5px' : '0px')};
  margin-left: ${(props: SectionProps) => (!props.isLeftLabel ? '5px' : '0px')};
  &:hover {
    cursor: pointer;
  }
`;

const RadioCircleSolid = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${ColorName.darkElectricBlue};
  top: 2px;
  left: 2px;
  opacity: 0;
`;

const RadioCircleBorder = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid ${ColorName.darkElectricBlue};
  position: relative;
`;

const RadioContainer = styled(FlexHorizontal)`
  align-items: center;
  width: max-content;
  &:hover {
    cursor: pointer;
    ${RadioCircleSolid} {
      opacity: 1;
    }
  }
  &.active ${RadioCircleSolid} {
    opacity: 1;
  }
`;

export { RadioContainer, RadioCircleBorder, RadioCircleSolid, RadioLabel };
