import styled from 'styled-components';
import { ColorName } from '../../../Variables';

const PreviewContainer = styled.div`
  height: calc(100vh - 100px);
  background: ${ColorName.white};
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;

  & .content {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  width: 100vw;
  height: 100vh;
`;

export { PreviewContainer, Content };
