import styled, { keyframes } from 'styled-components';
import { BlackRegularParagraph, FlexHorizontal } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

const slideLeft = keyframes`
  from {
    transform: translateX(15px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const LabelInput = styled(BlackRegularParagraph)`
  margin-bottom: 0.5rem;
`;

const PrevButton = styled(FlexHorizontal)`
  width: max-content;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${ColorName.grannySmith};
  transition: all ease 0.25s;
  transform: translateY(15px);
  opacity: 0;
  animation: ${slideLeft} ease 0.25s forwards;
  animation-delay: 0.75s;

  @media screen and (max-width: 436px) {
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SelectButton = styled.div`
  i {
    font-size: 30px;
    color: ${ColorName.william};
  }
  input {
    display: none;
  }

  label {
    font-size: 14px;
    width: 100%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const SpinLoader = styled.div`
  border: 5px solid ${ColorName.geyser};
  border-top: 5px solid ${ColorName.darkElectricBlue};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  left: calc(50% - 17px);
  top: 5px;
`;

export { LabelInput, PrevButton, SelectButton, SpinLoader };
