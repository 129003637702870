import styled from 'styled-components';
import { ColorName } from '../../Variables';
import generateResponsive from '../responsive';
import responsiveValue from '../responsive.value';

interface SectionProps {
  maxWidth?: string;
}

const BannerCategory = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }

  a {
    color: ${ColorName.black};
    font-size: 22px;
    font-weight: bold;
    margin-right: 35px;
    transition: all ease 0.25s;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @media screen and (max-width: 426px) {
      font-size: 16px;
    }
  }
`;

const ContentPosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${(props: SectionProps) => (props.maxWidth ? props.maxWidth : '1380px')};
  margin: 0 auto;
  height: 100%;
  ${generateResponsive(responsiveValue.CONTENT)}

  @media screen and (max-width: 1439px) {
    max-width: 720px;
  }
`;

export { BannerCategory, ContentPosition, Content };
