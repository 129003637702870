import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ColorName } from '../../Variables';

const delay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);

  .loader {
    margin: 0 auto;
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);

    > div {
      height: 100%;
      width: 8px;
      display: inline-block;
      float: left;
      margin-left: 2px;
      animation: ${delay} 0.8s infinite ease-in-out;
    }

    .bar1 {
      background-color: ${ColorName.white};
    }
    .bar2 {
      background-color: ${ColorName.white};
      animation-delay: -0.7s;
    }
    .bar3 {
      background-color: ${ColorName.white};
      animation-delay: -0.6s;
    }
    .bar4 {
      background-color: ${ColorName.white};
      animation-delay: -0.5s;
    }
    .bar5 {
      background-color: ${ColorName.white};
      animation-delay: -0.4s;
    }
    .bar6 {
      background-color: ${ColorName.white};
      animation-delay: -0.3s;
    }
  }
`;

const LoadingLayer = () => {
  return (
    <Wrapper>
      <div className="loader">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
        <div className="bar6"></div>
      </div>
    </Wrapper>
  );
};

export default LoadingLayer;
