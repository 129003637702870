import styled from 'styled-components';
import { ColorName } from '../../../../components/Variables';
import { FlexVertical, FlexHorizontal, VerticalContainer } from '../../../Common';

interface SectionProps {
  width?: string;
  maxHeight?: string;
  flex?: string;
  direction?: string;
  isLeft?: boolean;
  isRight?: boolean;
  rotate?: number;
  scale?: number;
  subItem?: number;
}

const generatePosition = (pos?: boolean) => {
  if (pos) return 0;
  return 'unset';
};

const HeaderContainer = styled.div`
  display: flex;
  overflow: visible;
  height: 80px;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 15;
  background: ${ColorName.white};
  transition: all ease 0.25s;

  &.shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  &.border_bottom {
    border-bottom: 1px solid ${ColorName.grannySmith};
  }

  & .mobile-icon {
    display: none;
    img {
      width: 20px;
    }
  }

  @media screen and (max-width: 426px) {
    height: 60px;
    & .content {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    & .desktop {
      display: none;
    }
    & .mobile-icon {
      display: flex;
    }
  }
`;

const Logo = styled.img`
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 426px) {
    height: 35px;
  }
`;

const Module = styled.div`
  display: flex;

  i.fa-bars {
    display: none;
  }

  @media screen and (max-width: 1025px) {
    & .dropdown {
      display: none;
    }
    i,
    i.fa-bars {
      display: inline-block;
      font-size: 20px;
      margin-left: 5px;
    }
  }

  img {
    &:hover {
      cursor: pointer;
    }
  }
`;
const SubMenu = styled.div`
  position: absolute;
  width: ${(props: SectionProps) => props.width || '180px'};
  background: ${ColorName.white};
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 10px 0;
  top: calc(100% + 25px);
  left: ${(props: SectionProps) => generatePosition(props.isLeft)};
  right: ${(props: SectionProps) => generatePosition(props.isRight)};
  transition: all ease 0.25s;
  height: auto;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 15;

  @media screen and (max-width: 426px) {
    top: calc(100% + 5px);
    width: 100px;
  }
`;

const NavItem = styled(FlexHorizontal)`
  width: ${(props: SectionProps) => props.width || 'max-content'};
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 26px;
  margin: 0 16px;
  position: relative;
  align-items: center;
  position: relative;
  transition: 0.3s;
  height: 35px;
  @media screen and (max-width: 426px) {
    font-size: 1.4rem;
    height: 30px;
  }
  i {
    transition: all ease-in-out 0.25s;
  }
  &:after {
    position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: 3px;
    height: 2px;
    background: ${ColorName.black};
  }
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 25px;
    width: 100%;
    display: none;
  }

  &.active:after,
  &:hover:after {
    width: 100%;
    left: 0;
  }
  &.disabled:hover:after {
    width: 0%;
    left: 0;
  }

  &:hover {
    cursor: pointer;
    &:before {
      display: block;
    }
    ${SubMenu} {
      max-height: ${(props: SectionProps) => (props.subItem ? `${props.subItem * 35 + 20}px` : `125px`)};
      padding: 10px 0;
    }
    i {
      transform: rotate(180deg);
    }
  }
`;

const Navbar = styled(FlexHorizontal)`
  flex: 1;
  margin-left: 15px;
  max-width: 1280px;

  > ${NavItem}:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 1800px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 1025px) {
    display: none;
  }
`;

const MenuIcon = styled(FlexVertical)`
  display: none;
  font-size: 2.2rem;

  transition: all ease 0.25s;

  &.show {
    opacity: 1;
  }
  @media screen and (max-width: 836px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SearchParent = styled(FlexHorizontal)`
  align-items: center;
  border-bottom: 1px solid transparent;
  margin-right: 5px;
  transition: all ease-in-out 0.25s;
  width: 30px;
  position: relative;
  img {
    transition: all ease-in-out 0.25s;
    position: absolute;
    right: 0;
    transform: scale(0.8) translateY(3px);
  }

  i {
    transition: all ease-in-out 0.25s;
    position: absolute;
    right: 35px;
    font-size: 18px;
    opacity: 0;
    z-index: -1;
  }

  input {
    border: none;
    outline: none;
    font-size: 16px;
    padding-right: 30px;
    width: 0px;
    transition: all ease-in-out 0.25s;
    box-sizing: border-box;
    // padding-right: 0px;
    right: 0;
    position: absolute;
  }

  &.show {
    img {
      transform: scale(0.75) translateY(0px);
    }
    input {
      width: 200px;
      border-bottom: 1px solid ${ColorName.black};
    }
  }

  @media screen and (max-width: 426px) {
    height: 35px;
    justify-content: flex-end;
    width: 100%;
    i {
      font-size: 15px;
      right: 25px;
    }
    &.show {
      input {
        width: 100px;
        border-bottom: 1px solid ${ColorName.black};
      }
    }

    input {
      padding-right: 25px;
    }
  }
`;

const MenuMobileLayer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: all ease 0.25s;

  &.show {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const MenuMobile = styled(VerticalContainer)`
  padding: 10px 30px;
  position: fixed;
  top: 0;
  right: 0;
  visibility: hidden;
  width: calc(100vw - 65px);
  height: 100vh;
  max-height: none;
  z-index: 99;
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: white;
  box-shadow: none;
  touch-action: manipulation;
  transform: translate(calc(100vw + 65px));
  box-sizing: border-box;

  @media screen and (min-width: 437px) {
    width: 400px;
    transform: translate(400px);
  }

  &.show {
    visibility: visible;
    transform: translate(0);
  }
`;

const MenuMobileItem = styled(FlexHorizontal)`
  align-items: center;
  transition: 0.3s;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  text-transform: uppercase;
  color: ${ColorName.grannySmith};
  margin: 5px 0;
  opacity: 0;
  transition: opacity 0.5s ease 0.25s, transform 0.5s ease 0.25s;
  transform: translate(65px);
  font-size: 16px;

  &.menu-label {
    margin-bottom: 15px;
    font-weight: bold;
    color: ${ColorName.black};
  }

  &.active {
    opacity: 1;
    transform: translate(0);
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 426px) {
    font-size: 16px;
    height: 40px;
  }
`;

const DesktopLang = styled(NavItem)`
  &:hover {
    cursor: default;
  }
  @media screen and (max-width: 426px) {
    width: 70px;
  }
`;

const HamburgerMenu = styled.div`
  border-radius: 2px;
  width: 20px;
  height: 12px;
  display: none;

  @media screen and (max-width: 1025px) {
    display: block;
  }

  .btn {
    position: absolute;
    width: 20px;
    cursor: pointer;
  }

  span {
    display: block;
    width: 100%;
    border-radius: 3px;
    height: 3px;
    background: ${ColorName.black};
    transition: all 0.3s;
    position: relative;
  }

  span + span {
    margin-top: 3px;
  }

  .active span:nth-child(1) {
    animation: ease 0.7s top forwards;
  }

  .not-active span:nth-child(1) {
    animation: ease 0.7s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease 0.7s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease 0.7s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease 0.7s bottom forwards;
  }

  .not-active span:nth-child(3) {
    animation: ease 0.7s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 6px;
      transform: rotate(0);
    }
    100% {
      top: 6px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 6px;
      transform: rotate(45deg);
    }
    50% {
      top: 6px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 6px;
      transform: rotate(0);
    }
    100% {
      bottom: 6px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 6px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 6px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ExpandIcon = styled.img`
  width: 16px;
  height: 16px;
  position: relative;
  transition: all ease 0.35s;
  transform: ${(props: SectionProps) =>
    `rotate(${props.rotate ? props.rotate : 0}deg) scale(${props.scale ? props.scale : 1})`};
`;

const MobileSubMenu = styled.div`
  width: 100%;
  height: 100%;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: max-height ease 0.25s, opacity 0.5s ease 0.25s, transform 0.5s ease 0.25s;
  transform: translate(65px);
  font-size: 16px;

  &.menu-label {
    margin-bottom: 15px;
    font-weight: bold;
    color: ${ColorName.black};
  }

  &.active {
    opacity: 1;
    transform: translate(0);
  }

  &.show {
    max-height: ${(props: SectionProps) => props.maxHeight || '500px'};
  }
`;

const MobileSubMenuItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 436px) {
    font-size: 15px;
  }
`;

export {
  Logo,
  HeaderContainer,
  Module,
  Navbar,
  NavItem,
  SubMenu,
  MenuIcon,
  SearchParent,
  MenuMobileLayer,
  MenuMobile,
  MenuMobileItem,
  HamburgerMenu,
  DesktopLang,
  ExpandIcon,
  MobileSubMenu,
  MobileSubMenuItem,
};
