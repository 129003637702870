import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { HorizontalContainer } from '../CommonLayout';

const Wrapper = styled(HorizontalContainer)`
  display: flex;
  place-content: center;
`;

const Link = styled.a`
  display: flex;
  width: 40px;
  justify-content: center;
  height: 40px;
  border-radius: 7px;
  color: ${ColorName.william};
  background: ${ColorName.white};
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  /* transition: all ease 0.25s; */
  box-shadow: 0px 4px 4px rgba(62, 138, 182, 0.25);
  &.arrow {
    background: ${ColorName.aquaHaze};
    display: flex;
    align-items: center;
    width: 40px;
    color: ${ColorName.william};
    margin: 0 3px;
    opacity: 0.7;
    &.clickable {
      opacity: 1;
      cursor: pointer;
    }
    span {
      line-height: 0;
    }
  }
  &.active,
  &:hover {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
    &.arrow {
      cursor: not-allowed;
      background: ${ColorName.aquaHaze};
      color: ${ColorName.darkElectricBlue};

      &.clickable {
        background: ${ColorName.darkElectricBlue};
        color: ${ColorName.white};
        cursor: pointer;
      }
    }
  }
`;

export { Wrapper, Link };
