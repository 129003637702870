import styled from 'styled-components';
import { ColorName } from '../../Variables';
import { HorizontalContainer, VerticalContainer } from '../CommonLayout';

const DropdownItem = styled(HorizontalContainer)`
  height: auto;
  min-height: 25px;
  padding: 10px 0;
  background: ${ColorName.white};
  display: flex;
  align-items: center;
  padding-left: 26px;
  text-transform: capitalize;

  &:hover {
    color: ${ColorName.white};
    background: ${ColorName.darkElectricBlue};
  }
`;

const DropdownGroup = styled(VerticalContainer)`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: calc(100% - 2px);
  max-height: 0px;
  overflow-y: hidden;
  z-index: 1;
  transition: all ease 0.25s;
  border: 1px solid transparent;

  &.show {
    background: ${ColorName.white};
    color: ${ColorName.black};
    border: 1px solid ${ColorName.darkElectricBlue};
    max-height: 180px;
    overflow-y: auto;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }
`;

const FilterItem = styled(HorizontalContainer)`
  align-items: center;
  width: 100%;
  height: 45px;

  background: ${ColorName.pampas};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 1em;
  font-size: 1.6rem;
  transition: all ease 0.25s;
  padding-left: 26px;
  padding-right: 14px;
  position: relative;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  i {
    font-size: 2rem;
  }

  &.active {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
  & .filter_group {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }

  &.show {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }

  &.implement {
    width: 100%;
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      background: ${ColorName.pampas};
      color: ${ColorName.black};
      cursor: not-allowed;

      ${DropdownGroup} {
        background: ${ColorName.pampas};
        color: ${ColorName.black};
      }
      ${DropdownItem} {
        background: ${ColorName.white};
        color: ${ColorName.black};
      }
    }
  }

  @media screen and (max-width: 836px) {
    width: max-content;
    margin: 3px;
    white-space: pre;
    border-right: 1px solid #ccc;
    &:last-child {
      border-right: unset;
    }
  }

  @media screen and (max-width: 426px) {
    font-size: 14px;
    p {
      font-size: 14px;
    }
  }
`;

export { FilterItem, DropdownGroup, DropdownItem };
