/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { ShowMoreContent, PageContainer } from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { SECOND_HEADER } from "../../../constants/Constants";
import { ROUTES } from "../../../constants";
import SecondHeader from "../../../components/Layout/CommonLayoutPart/SecondHeader";
import history from "../../../history";
import { Utils } from "../../../libs";

const DEFAULT_ACTIVE_TAB = {
  quantitativeResearch: "quantitativeResearch-2",
  qualitativeResearch: "qualitativeResearch-2",
};

const Services = () => {
  const { t } = useTranslation("user");
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducer
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.RESEARCH_SERVICES}`)
  );
  // Declare states
  const [currentTab, setCurrentTab] = useState<string>("");
  const [currentActiveTab, setCurrentActiveTab] = useState<any>({});

  useEffect(() => {
    const category = _.get(queryStringValue, "category");
    if (_.isString(category) && category !== currentTab)
      setCurrentTab(category);
    else setCurrentTab("quantitativeResearch");
    setCurrentActiveTab(DEFAULT_ACTIVE_TAB);

    return () => {
      setCurrentTab("");
      setCurrentActiveTab({});
    };
  }, []);

  // Handle show or hide section of category
  const handleShowOrHideCategory = (value: string) => {
    const currentValue = _.get(currentActiveTab, currentTab);
    const newTab = currentValue === value ? "" : value;
    setCurrentActiveTab({
      ...currentActiveTab,
      [currentTab]: newTab,
    });
  };

  // Handle change category
  const handleChangeCategory = async (value: string) => {
    Utils.scrollToBottomOfBanner();
    history.push(`${ROUTES.USER.RESEARCH_SERVICES}?category=${value}`);
    setCurrentTab(value);
  };

  const renderMain = () => {
    return (
      <PageContainer className="pb-4">
        <SecondHeader
          data={SECOND_HEADER.RESEARCH_SERVICES}
          onChange={(value: string) => handleChangeCategory(value)}
          active={currentTab}
        />
        <ShowMoreContent
          className="mt-4 mb-2"
          data={_.get(payload, currentTab)}
          type={currentActiveTab}
          onChange={(value: string) => handleShowOrHideCategory(value)}
          active={_.get(currentActiveTab, currentTab)}
        />
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_SERVICES}
      titlePage={t("titlePage.researchServices")}
      headerTitle="Research Service"
      metaDescription="A full-scale and high-quality market research services, both quantitative and qualitative"
    />
  );
};

export default Services;
