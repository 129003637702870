import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContainer, Options, OptionItem, LabelDropdown } from './Dropdown.styles';

interface DropdownStructure {
  label: string;
  value: string;
}
interface SectionProps {
  options: DropdownStructure[];
  selectedValue: string;
  defaultValue?: string;
  onChange(value: string): unknown;
  className?: string;
}

const Dropdown: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare props
  const { options, selectedValue, defaultValue, onChange, className } = props;

  // Declare translation
  const { t } = useTranslation('user');

  const onChangeValue = (value: string) => {
    if (value && onChange) onChange(value);
  };

  // Render value selected
  const renderValue = (value: string) => {
    const findValue = _.find(options, { value });
    if (!findValue) return t('dropdown.choose');
    const label = _.get(findValue, 'label');
    return t(`dropdown.${label}`);
  };

  // Render list item of options
  const renderOptions = () => {
    if (!_.isEmpty(options)) {
      const render = _.map(options, (option, index) => {
        const label = _.get(option, 'label');
        const value = _.get(option, 'value');
        const isSelected = _.isEqual(selectedValue, value);
        if (value === selectedValue) return null;
        return (
          <OptionItem key={`option-${index}`} onClick={() => onChangeValue(value)}>
            <p> {t(`dropdown.${label}`)}</p> {isSelected && <i className="fas fa-check" />}
          </OptionItem>
        );
      });

      return <Options>{render}</Options>;
    }
    return null;
  };

  return (
    <DropdownContainer className={className}>
      {defaultValue && !selectedValue && (
        <LabelDropdown>
          <p>{renderValue(defaultValue)}</p>
          <i className="fas fa-sort-down" />
        </LabelDropdown>
      )}
      {selectedValue && (
        <LabelDropdown>
          <p>{renderValue(selectedValue)}</p>
          <i className="fas fa-sort-down" />
        </LabelDropdown>
      )}
      {renderOptions()}
    </DropdownContainer>
  );
};

export default Dropdown;
