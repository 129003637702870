/* eslint import/no-anonymous-default-export: off */
import React from "react";
import _ from "lodash";
import { TableContainer, TableContent } from "./Table.styles";
import { useTranslation } from "react-i18next";
import ColorName from "../../../Variables/ColorName";
import { COUNTRY_OPTIONS } from "../../../../constants";

interface SectionProps {
  onClick?(id: string, key: string): void;
  onSort?(key: string): void;
  className?: string;
  data?: any[];
  column?: any[];
  fragment?: string;
  sortBy?: string;
  orderBy?: string;
  checked?: string[];
  handleChecked?(value: string[]): void;
  lang?: string;
}

const AlumniNetworkTable: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  // const lang = useSelector(state => _.get(state, 'PageSettings.adminLang'));
  const {
    onClick,
    onSort,
    className,
    data,
    column,
    fragment,
    sortBy,
    orderBy,
    checked,
    handleChecked,
    lang,
  } = props;
  // Declare dispatch, reducers

  const handleClick = (value: any, key: string) => {
    if (onClick) onClick(value, key);
  };

  const handleSort = (key: string) => {
    if (onSort) onSort(key);
  };

  const handleCheckedOnRow = (id: string) => {
    if (handleChecked && _.isArray(checked)) {
      const checkExist = _.includes(checked, id);
      if (checkExist) {
        const filterId = _.filter(checked, (row) => row !== id);
        handleChecked(filterId);
      } else handleChecked([...checked, id]);
    }
  };

  const generateStatusLabel = (status: string) => {
    const createData = {
      open: ColorName.pictonBlue,
      denied: ColorName.geraldine,
      approved: ColorName.shamrock,
    };
    return createData[status as keyof typeof createData] || "";
  };

  const renderHeader = () => {
    const render = _.map(column, (item, index) => {
      const arrowClass =
        sortBy === item.value && orderBy === "ASC" ? "up" : "down";
      const { isCenter, isSort } = item;
      const centerClass = isCenter ? "flex-justify-center" : "";
      return (
        <span key={`header-${index}`} className={`header ${centerClass}`}>
          {item.label ? t(`table.${item.label}`) : ""}
          {}
          {isSort ? (
            <div>
              {item.value !== sortBy || !orderBy ? (
                <i
                  className="fa fa-sort ml-2"
                  onClick={() => handleSort(item.value)}
                />
              ) : (
                <i
                  className={`fas fa-caret-${arrowClass} ml-2`}
                  onClick={() => handleSort(item.value)}
                />
              )}
            </div>
          ) : null}
        </span>
      );
    });
    return render;
  };

  const renderData = () => {
    const render = _.map(data, (item, index) => {
      const innerRender = _.map(column, (cell, innerIndex) => {
        const { isCenter } = cell;
        const centerClass = isCenter ? "flex-justify-center" : "";
        const backgroundClass = index % 2 === 0 ? "background" : "";
        if (_.isObject(_.get(item, cell.value))) {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{_.get(item, `${cell.value}.${lang}`)}</span>
            </div>
          );
        }

        if (cell.value === "name") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{`${_.get(item, "firstName")}  ${_.get(
                item,
                "lastName"
              )}`}</span>
            </div>
          );
        }
        if (cell.value === "country") {
          const country = _.find(
            COUNTRY_OPTIONS,
            (country) => country.code === _.get(item, "country")
          );
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{country ? country.name : _.get(item, "country")}</span>
            </div>
          );
        }

        if (cell.value === "mobile") {
          const country = _.find(
            COUNTRY_OPTIONS,
            (country) => country.code === _.get(item, "country")
          );
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                ({_.get(country, "dial_code")}) {_.get(item, "mobile")}
              </span>
            </div>
          );
        }

        if (cell.value === "updatedAt" || cell.value === "createdAt") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {new Date(_.get(item, cell.value)).toLocaleString("en-GB")}
              </span>
            </div>
          );
        }
        if (cell.value === "status") {
          const value = _.get(item, cell.value);
          const labelStatus = t(`label.${value}`);

          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span
                style={{
                  color: generateStatusLabel(value),
                  fontWeight: "bold",
                }}
              >
                {labelStatus}
              </span>
            </div>
          );
        }
        if (cell.value === "id") {
          const rowId = _.get(item, "id");
          const checkIncludes = _.includes(checked, rowId);
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleCheckedOnRow(rowId)}>
                {checkIncludes ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }

        if (cell.value === "index") {
          const value = _.get(item, cell.value);
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {value === 1 ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (cell.value === "edit") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleClick(item.id, "edit")}>
                <i className="fas fa-pen" />
              </span>
            </div>
          );
        }
        if (cell.value === "remove") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleClick(item.id, "delete")}>
                <i className="fas fa-trash-alt" />
              </span>
            </div>
          );
        }
        if (cell.value === "view") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content view ${backgroundClass}`}
              onClick={() => handleClick(item.newsId, "view")}
            >
              <span className="button">View Details</span>
            </div>
          );
        }
        if (cell.value === "isShowOnHomepage") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content show_homepage ${backgroundClass}`}
            >
              <span>
                {_.get(item, "isShowOnHomepage") ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (cell.value === "createdAt" || cell.value === "updatedAt") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {new Date(_.get(item, cell.value)).toLocaleString("en-GB")}
              </span>
            </div>
          );
        }
        if (cell.value === "section") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{t(`table.${_.get(item, cell.value)}`)}</span>
            </div>
          );
        }
        if (cell.value === "requestType") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span className={`${_.get(item, cell.value)} request-type`}>
                {t(`table.${_.get(item, cell.value)}`)}
              </span>
            </div>
          );
        }
        return (
          <div
            key={`row-${index}-${innerIndex}`}
            className={`${centerClass} content ${backgroundClass}`}
          >
            <span>{_.get(item, cell.value)}</span>
          </div>
        );
      });
      return innerRender;
    });
    return render;
  };

  return (
    <TableContainer className={className}>
      <TableContent fragment={fragment}>
        {renderHeader()}
        {renderData()}
        {_.isEmpty(data) && (
          <>
            <span />
            <span className="content no-result">
              {t("table.noResultFound")}
            </span>
          </>
        )}
      </TableContent>
    </TableContainer>
  );
};

export default AlumniNetworkTable;
