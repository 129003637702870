/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payload: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_SITE_SETTINGS_PAYLOAD:
      return {
        ...state,
        payload: payload,
      };
    case ActionTypes.CLEAR_SITE_SETTINGS_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.GET_SITE_SETTINGS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.GET_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.GET_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.UPDATE_SITE_SETTINGS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        payload: payload,
      };

    case ActionTypes.REMOVE_SITE_SETTING_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SITE_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SITE_SETTING_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
