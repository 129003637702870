/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  EditHomepageContainer,
  FooterSection,
  CertificateCircle,
  CertBox,
  RemoveButtonFile,
} from "./EditHomepage.styles";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../constants";
import {
  QuoteWhoWeAre,
  GridLayout,
  SectionTitle,
  RegularParagraph,
  WhiteRegularParagraph,
  FlexVertical,
  FlexHorizontal,
} from "../../../../components/Common";
import { Images } from "../../../../themes";
import { useTranslation } from "react-i18next";
import { SelectMedia, LabelPDF } from "../../../../components/Layout/Popups";
import { statusAlert } from "../../../../libs";

interface SectionProps {
  className?: string;
}

const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditIDRAPage = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload: any = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const payload: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.HOMEPAGE}`)
  );
  const lang: any = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const editingPage = useSelector((state) =>
    _.get(state, "EditContent.pageEditing")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );

  const [isShowMediaPopup, setIsShowMediaPopup] = useState<boolean>(false);
  const [isShowLabelPdf, setIsShowLabelPdf] = useState<boolean>(false);
  const [indexLabelPdf, setIndexLabelPdf] = useState<number>(0);

  const [newCert, setNewCert] = useState<any>({
    label: "",
    link: "",
  });

  useEffect(() => {
    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getAllContentForUser());
  }, [editingPage]);

  const handleChange = (value: string, key: string, defaultValue: string) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const newContent = {
      ...payload,
      [key]: value,
    };
    dispatch(
      setEditContentPayload({
        ...contentPayload,
        [ROUTES.USER.HOMEPAGE]: newContent,
      })
    );
  };

  const handleHidePopup = (value: string, type: string) => {
    setNewCert({ ...newCert, link: value });
    setIsShowLabelPdf(true);
  };

  const handleClosePDFPopp = (label?: string) => {
    if (label) {
      setNewCert({ ...newCert, label });
      const homepageContent = _.get(contentPayload, ROUTES.USER.HOMEPAGE);
      const certFooter = _.get(homepageContent, "footer.certs");
      const resolveCerts = _.cloneDeep(certFooter);
      if (indexLabelPdf)
        resolveCerts[indexLabelPdf - 1] = { ...newCert, label };
      else resolveCerts[resolveCerts.length] = { ...newCert, label };
      dispatch(
        setEditContentPayload({
          ...contentPayload,
          [ROUTES.USER.HOMEPAGE]: {
            ...homepageContent,
            footer: { ...homepageContent.footer, certs: resolveCerts },
          },
        })
      );
      setIndexLabelPdf(0);
      setNewCert({ label: "", link: "" });
      setIsShowMediaPopup(false);
      setIsShowLabelPdf(false);
    } else setIsShowLabelPdf(false);
  };

  const handleRemovePdf = async (removeIndex: number, label: string) => {
    const isAgree = await statusAlert(
      "warning",
      `${t("alert.warningRemovePdfFile")} ${label}?`
    );
    if (isAgree) {
      const homepageContent = _.get(contentPayload, ROUTES.USER.HOMEPAGE);
      const certFooter = _.get(homepageContent, "footer.certs");
      const resolveCerts = _.filter(
        certFooter,
        (cert, index) => _.toNumber(index) !== removeIndex
      );
      dispatch(
        setEditContentPayload({
          ...contentPayload,
          [ROUTES.USER.HOMEPAGE]: {
            ...homepageContent,
            footer: { ...homepageContent.footer, certs: resolveCerts },
          },
        })
      );
    }
  };

  // RENDER CERTIFICATE
  const renderCertificate = () => {
    const homepageContent = _.get(contentPayload, ROUTES.USER.HOMEPAGE);
    const certsFooter = _.get(homepageContent, "footer.certs");
    return (
      <FlexHorizontal justifyFlexEnd>
        <CertificateCircle
          className="add-more"
          onClick={() => setIsShowMediaPopup(true)}
        >
          <span>Add more</span>
        </CertificateCircle>
        {_.isArray(certsFooter) &&
          !_.isEmpty(certsFooter) &&
          _.map(certsFooter, (cert, index) => (
            <CertBox>
              <CertificateCircle
                onClick={() => {
                  setIsShowMediaPopup(true);
                  setNewCert({ ...newCert, label: _.get(cert, "label") });
                  setIndexLabelPdf(index + 1);
                }}
              >
                <span>{_.get(cert, "label")}</span>
                <i className="fas fa-check-circle" />
              </CertificateCircle>
              <RemoveButtonFile
                onClick={() => handleRemovePdf(index, _.get(cert, "label"))}
              >
                <i className="fas fa-times" />
              </RemoveButtonFile>
            </CertBox>
          ))}
      </FlexHorizontal>
    );
  };

  return (
    <EditHomepageContainer className={className}>
      {isShowMediaPopup && (
        <SelectMedia
          className={`${isShowMediaPopup ? "show" : ""}`}
          hidePopup={(value: string, type: string) => {
            handleHidePopup(value, type);
          }}
          onClose={() => {
            setIsShowMediaPopup(false);
            setIsShowLabelPdf(false);
          }}
          selectedTab="pdf"
          tabBar={[{ label: "Pdf", value: "pdf" }]}
        />
      )}
      {isShowLabelPdf && (
        <LabelPDF
          className={`${isShowMediaPopup ? "show" : ""}`}
          onClose={(label?: string) => handleClosePDFPopp(label)}
          value={_.get(newCert, "label")}
        />
      )}
      <GridLayout col="2">
        <img src={Images.whoWeAre.default} alt="who we are illustrator" />
        <FlexVertical>
          <SectionTitle
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            onFocus={(e: any) =>
              dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
            }
            data-placeholder={t("placeholder.typeTheTitleHere")}
            onBlur={(e: any) =>
              handleChange(
                {
                  ...payload.whoWeAre,
                  title: {
                    ...payload.whoWeAre.title,
                    [lang]: e.currentTarget.innerText,
                  },
                },
                "whoWeAre",
                _.trim(e.currentTarget.innerText)
              )
            }
            className="editable mb-0"
          >
            {_.get(payload, `whoWeAre.title.${lang}`)}
          </SectionTitle>
          <QuoteWhoWeAre
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            data-placeholder={t("placeholder.typeTheDescriptionHere")}
            onFocus={(e: any) =>
              dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
            }
            onBlur={(e: any) =>
              handleChange(
                {
                  ...payload.whoWeAre,
                  description: {
                    ...payload.whoWeAre.description,
                    [lang]: e.currentTarget.innerText,
                  },
                },
                "whoWeAre",
                _.trim(e.currentTarget.innerText)
              )
            }
            className="editable mt-0"
          >
            {_.get(payload, `whoWeAre.description.${lang}`)}
          </QuoteWhoWeAre>
        </FlexVertical>
      </GridLayout>
      <SectionTitle
        contentEditable
        suppressContentEditableWarning
        spellCheck={false}
        data-placeholder={t("placeholder.typeTheTitleHere")}
        onFocus={(e: any) =>
          dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
        }
        onBlur={(e: any) =>
          handleChange(
            {
              ...payload.news,
              [lang]: e.currentTarget.innerText,
            },
            "news",
            _.trim(e.currentTarget.innerText)
          )
        }
        className="editable mt-2"
      >
        {_.get(payload, `news.${lang}`)}
      </SectionTitle>
      <SectionTitle
        contentEditable
        suppressContentEditableWarning
        spellCheck={false}
        data-placeholder={t("placeholder.typeTheTitleHere")}
        onFocus={(e: any) =>
          dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
        }
        onBlur={(e: any) =>
          handleChange(
            {
              ...payload.trustedBy,
              [lang]: e.currentTarget.innerText,
            },
            "trustedBy",
            _.trim(e.currentTarget.innerText)
          )
        }
        className="editable"
      >
        {_.get(payload, `trustedBy.${lang}`)}
      </SectionTitle>
      <FlexVertical className="mt-2">
        <SectionTitle
          contentEditable
          suppressContentEditableWarning
          spellCheck={false}
          className="center mb-0 editable"
          data-placeholder={t("placeholder.typeTheTitleHere")}
          onFocus={(e: any) =>
            dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
          }
          onBlur={(e: any) =>
            handleChange(
              {
                ...payload.contact,
                title: {
                  ...payload.contact.title,
                  [lang]: e.currentTarget.innerText,
                },
              },
              "contact",
              _.trim(e.currentTarget.innerText)
            )
          }
        >
          {_.get(payload, `contact.title.${lang}`)}
        </SectionTitle>
        <RegularParagraph
          contentEditable
          suppressContentEditableWarning
          spellCheck={false}
          textAlignCenter
          className="mb-2 editable"
          data-placeholder={t("placeholder.typeTheSubtitleHere")}
          onFocus={(e: any) =>
            dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
          }
          onBlur={(e: any) =>
            handleChange(
              {
                ...payload.contact,
                subtitle: {
                  ...payload.contact.subtitle,
                  [lang]: e.currentTarget.innerText,
                },
              },
              "contact",
              _.trim(e.currentTarget.innerText)
            )
          }
        >
          {_.get(payload, `contact.subtitle.${lang}`)}
        </RegularParagraph>
      </FlexVertical>
      <FooterSection>
        <div className="position-content">
          <FlexVertical justifyCenter>
            <img src={Images.logoFooter.default} alt="logo" />
          </FlexVertical>
          <WhiteRegularParagraph
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            className="mt-2 mb-2 editable"
            data-placeholder={t("placeholder.typeTheDescriptionHere")}
            onFocus={(e: any) =>
              dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
            }
            onBlur={(e: any) =>
              handleChange(
                {
                  ...payload.footer,
                  [lang]: e.currentTarget.innerText,
                },
                "footer",
                _.trim(e.currentTarget.innerText)
              )
            }
          >
            {_.get(payload, `footer.${lang}`)}
          </WhiteRegularParagraph>

          {renderCertificate()}
        </div>
      </FooterSection>
    </EditHomepageContainer>
  );
};

export default EditIDRAPage;
