import styled from 'styled-components';
import { VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const IDRACardItem = styled(VerticalContainer)`
  background: ${ColorName.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.55s;
  transition-delay: 0.1s;
  position: relative;
  will-change: background;
  min-height: 237px;

  & .icon {
    overflow: hidden;
  }

  & .content {
    padding: 40px 25px;
  }
  img {
    width: max-content;
    height: 50px;
    margin-right: 1.9em;
    transition: all ease-in-out 0.35s;
    transition-delay: 0.15s;
  }

  & .arrow {
    width: 30px;
    opacity: 0;
    position: absolute;
    bottom: 10px;
    left: 25px;
    transition: all ease 0.55s;
    transition-delay: 0.1s;
  }

  &:hover {
    cursor: pointer;
    background: ${ColorName.steelTeal};

    & .icon img {
      transform: translateY(-55px);
    }
    h3,
    p {
      transform: translateY(-50px);
      color: ${ColorName.white};
    }
    & .arrow {
      opacity: 1;
      bottom: 40px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
    margin: 10px 0;
    transition: all ease-in-out 0.35s;
    transition-delay: 0.2s;
  }
  p {
    height: auto;
    transition: all ease-in-out 0.35s;
    transition-delay: 0.2s;
  }

  @media screen and (max-width: 426px) {
    min-height: 200px;
    & .content {
      padding: 20px 25px;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    img {
      height: 40px;
    }
    h3 {
      font-size: 20px;
      margin: 5px 0;
    }

    &.active,
    &:hover {
      & .arrow {
        bottom: 25px;
      }
    }
  }
`;

const HiddenLink = styled.a`
  display: none;
`;

export { IDRACardItem, HiddenLink };
