import styled from 'styled-components';
import { ContentEditableWrapper } from '../ContentEditableDetails.styles';

const EditIDRAPageContainer = styled(ContentEditableWrapper)`
  h3 {
    font-size: 30px;
    line-height: 43px;
    padding: 3px 5px;
    box-sizing: border-box;
  }
`;

export { EditIDRAPageContainer };
