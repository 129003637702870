import styled from 'styled-components';
import { ColorName } from '../../../../components/Variables';

const SecondHeaderContainer = styled.div`
  position: sticky;
  top: 80px;
  z-index: 4;
  background-color: white;
  border-bottom: 1px solid ${ColorName.grannySmith};
  transition: all ease 0.25s;

  &.admin-position {
    top: 0px;
  }

  @media screen and (max-width: 426px) {
    top: 60px;
  }

  &.active {
    border-top: 1px solid ${ColorName.grannySmith};
    border-bottom: unset;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  h3 {
    width: max-content;
    min-width: max-content;
    font-weight: 700;
    font-size: 1.6rem;
    color: ${ColorName.grannySmith};
    transition: all ease 0.25s;
    margin-right: 50px;
    padding: 10px 0;
    user-select: none;

    &.active,
    &:hover {
      cursor: pointer;
      color: ${ColorName.deepCarrotOrange};
    }
  }

  &.custom-label {
    h3 {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 426px) {
    h3 {
      font-size: 14px;
    }
  }
`;

const SecondHeaderRow = styled.div`
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export { SecondHeaderContainer, SecondHeaderRow };
