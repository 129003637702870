import styled from 'styled-components';
import { ColorName } from '../../../components/Variables';

const GalleryContainer = styled.div`
  position: relative;
  label {
    position: absolute;
    top: 5px;
    left: 5px;
    background: ${ColorName.william};
    color: ${ColorName.white};
    font-size: 1.6rem;
    padding: 10px 10px;
    z-index: 10;
  }
  
  & .edit-btn,
  & .remove-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    z-index: 10;
    
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    background: ${ColorName.william};
    color: ${ColorName.white};
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background: ${ColorName.burntSienna};
    }
  }

  & .remove-btn {
    right: 40px;
  }
`;

export { GalleryContainer };
