import styled from 'styled-components';
import { VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const IDRACardEditableContainer = styled(VerticalContainer)`
  background: ${ColorName.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  will-change: background;
  min-height: 237px;
`;

const CardContent = styled.div`
  padding: 40px 25px;
`;

const CardIcon = styled.img`
  width: max-content;
  height: 50px;
  margin-right: 1.9em;
`;

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 22px !important;
  margin: 10px 0;
  padding: 3px 5px;
  box-sizing: border-box;
`;

const CardDescription = styled.p`
  height: auto;
  color: ${ColorName.grannySmith};
  padding: 3px 5px;
  box-sizing: border-box;
`;

export { IDRACardEditableContainer, CardContent, CardIcon, CardTitle, CardDescription };
