import styled from "styled-components";
import { ColorName } from "../../Variables";
import { BlackRegularParagraph } from "../../Common";
import generateResponsive from "../responsive";
import responsiveValue from "../responsive.value";

interface SectionProps {
  width?: string;
  height?: string;
}

const TitleIDRAPage = styled.h2`
  font-size: 30px;
  line-height: 43px;
  text-align: center;

  @media screen and (max-width: 426px) {
    font-size: 22px;
    line-height: 35px;
  }
`;

const IconIDRAPage = styled.img`
  max-width: 26px;
`;

const Title = styled.h2`
  font-size: 30px;
  line-height: 38px;
  text-align: left;
  @media screen and (max-width: 426px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const SubTitle = styled.h3`
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  @media screen and (max-width: 426px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const TimeStamp = styled.p`
  color: ${ColorName.gray};
  font-weight: bold;
  margin-top: 5px;
`;

const Description = styled(BlackRegularParagraph)`
  > * {
    font-family: "Be Vietnam";
    white-space: pre-line;
  }

  p,
  span,
  li {
    font-size: 16px !important;
    color: ${ColorName.black} !important;
    line-height: 24px;
    text-align: justify !important;
  }

  ul {
    margin: 10px 0;
  }

  li {
    margin-bottom: 5px;
  }

  iframe {
    width: ${(props: SectionProps) => props.width || "100%"};
    height: ${(props: SectionProps) => props.height || "400px"};
  }

  img {
    max-width: 100% !important;
    height: auto;
    object-fit: contain;
  }
  @media screen and (max-width: 426px) {
    p,
    span,
    li {
      font-size: 14px !important;
      line-height: 20px;
    }
  }
`;

const ParseContent = styled.div`
  h2 {
    font-size: 30px;
    line-height: 38px;
    text-align: justify;
  }
  p,
  li,
  a {
    font-size: 16px;
    line-height: 25px;
  }
  ul {
    margin: 0;
  }

  @media screen and (max-width: 426px) {
    h2 {
      font-size: 25px;
      line-height: 31px;
      text-align: justify;
    }
    p,
    li,
    a,
    span {
      font-size: 14px !important;
      line-height: 20px;
    }
  }
`;

const DescriptionPosition = styled.div`
  position: relative;
`;

const DetailsContent = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: auto;

  ${generateResponsive(responsiveValue.NEWS_CONTENT)}

  @media screen and (max-width: 1439px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 426px) {
    padding: 20px;
  }
`;

const RelatedContent = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: auto;
  margin-left: 100px;

  ${generateResponsive(responsiveValue.NEWS_CONTENT)}

  @media screen and (max-width: 1439px) {
    margin: 40px auto 0;
    max-width: 720px;
  }

  @media screen and (max-width: 426px) {
    margin: 0 auto 0;
    padding: 20px;
  }
`;

const AdminLabel = styled.label`
  width: ${(props: SectionProps) => props.width || "max-content"};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${ColorName.darkElectricBlue};
  margin-right: 15px;
`;

const BorderBox = styled.div`
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 29px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
`;

const UpdatedLabel = styled.p`
  font-size: 14px;
  font-style: italic;
  color: ${ColorName.grannySmith};
`;

const LineSection = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background: ${ColorName.black};
`;

export {
  Title,
  SubTitle,
  Description,
  DetailsContent,
  RelatedContent,
  TimeStamp,
  DescriptionPosition,
  AdminLabel,
  TitleIDRAPage,
  IconIDRAPage,
  BorderBox,
  ParseContent,
  UpdatedLabel,
  LineSection,
};
