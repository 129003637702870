/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  AdminDropdown,
  AdminLabel,
  ContentContainer,
  FlexHorizontal,
  OrderRowButton,
} from "../../../components/Common";
import { OrderTable } from "../../../components/Layout/CommonLayoutPart/Table";
import { OrderPopup } from "../../../components/Layout/Popups";
import { ROUTES, ColumnsTable, DropdownOptions } from "../../../constants";
import { ArticlesActions } from "../../../actions";
import { GenerateValue, statusAlert } from "../../../libs";

const { updateOrderArticles, getArticlesOrdered } = ArticlesActions;

const ManageOrderArticles = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const newsShowOnPage: any = useSelector((state) =>
    _.get(state, "Articles.payloadOrdered")
  );
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const isExpand = useSelector((state) =>
    _.get(state, "PageSettings.isExpandMenu")
  );
  // Declare states
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [langOrdering, setLangOrdering] = useState<string>("en");

  useEffect(() => {
    dispatch(getArticlesOrdered({ locale: langOrdering }));
  }, [langOrdering]);

  const handleViewDetails = async (value: any, key: string) => {
    if (key === "delete" && value) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningRemoveOrderArticles")
      );
      if (isAgree)
        await dispatch(
          updateOrderArticles({
            ...value,
            isShowOnHomepage: 0,
            orderNumber: null,
          })
        );
    }
  };

  const handleAddNews = async (item: any) => {
    const getCurrentId = _.get(item, "id");
    const findNewsShowOnPage = _.find(newsShowOnPage, { id: getCurrentId });
    setIsShowPopup(false);
    if (!findNewsShowOnPage && item)
      await dispatch(updateOrderArticles({ ...item, isShowOnHomepage: 1 }));
  };

  const handleUpdateOrder = async (item: any) => {
    if (item) await dispatch(updateOrderArticles(item));
  };

  const renderPopup = () => {
    return (
      <>
        {isShowPopup && (
          <OrderPopup
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(item: any) => handleAddNews(item)}
            locale={langOrdering}
          />
        )}
      </>
    );
  };

  const renderTable = () => {
    return (
      <>
        <FlexHorizontal alignCenter>
          <AdminLabel>{t("label.language")}</AdminLabel>
          <AdminDropdown
            width="150px"
            selectedValue={langOrdering}
            onChange={(value: string) => value && setLangOrdering(value)}
            data={GenerateValue.generateDropdown(DropdownOptions.LANGUAGE)}
          />
        </FlexHorizontal>
        <OrderTable
          className="mt-1"
          column={ColumnsTable.ORDER_NEWS}
          data={newsShowOnPage}
          onClick={(value: any, key: string) => handleViewDetails(value, key)}
          fragment="3fr 2fr 1.1fr 0.5fr 0.6fr 0.5fr"
          handleChangeOrder={(data: any) => handleUpdateOrder(data)}
          lang={langOrdering}
        />

        {_.isArray(newsShowOnPage) && newsShowOnPage.length < 4 && (
          <OrderRowButton onClick={() => setIsShowPopup(!isShowPopup)}>
            {t("button.add")}
          </OrderRowButton>
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer isExpand={isExpand}>
        {renderPopup()}
        {renderTable()}
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.ORDER_NEWS}
      loading={isRequestLoading}
    />
  );
};

export default ManageOrderArticles;
