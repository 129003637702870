import styled, { keyframes } from 'styled-components';
import { FlexVertical, PageContainer } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

const OurWorkContainer = styled(PageContainer)`
  padding: 80px 0 100px;

  @media screen and (max-width: 836px) {
    padding: 40px 0 40px 0;
  }

  @media screen and (max-width: 436px) {
    padding: 20px 0 40px 0;
  }
`;

const ContentCol = styled(FlexVertical)`
  flex: 1;
  margin-left: 120px;

  @media screen and (max-width: 1366px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 1025px) {
    margin-left: 80px;
  }

  @media screen and (max-width: 836px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 426px) {
    margin-left: 0px;
  }
`;

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const LoadingLayer = styled.div`
  width: 100%;
  height: 300px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${ColorName.grannySmith};
  margin-top: 40px;

  &:nth-child(1) {
    margin-top: 0px;
  }

  & .title {
    width: 90%;
    position: relative;
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .description {
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }

  & .button {
    position: relative;
    width: 150px;
    height: 50px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: ${ColorName.bonJour};
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: ${`linear-gradient(to right, ${ColorName.mercury} 25%, ${ColorName.alto} 50%, ${ColorName.mercury} 100%)`};
      animation: ${shimmer} 2s infinite;
      content: '';
    }
  }
`;

const SVGImage = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export { SVGImage, OurWorkContainer, ContentCol, LoadingLayer };
