/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const defaultMetaPagination = {
  currentPage: 1,
  totalPages: 0,
};

const defaultFilter = {
  status: '',
  topic: '',
  from_year: '',
  to_year: '',
  page: '',
  country: '',
};

const initialState = {
  filter: defaultFilter,
  meta: defaultMetaPagination,
  payload: [],
  details: {},
  country: [],
  isSuccess: false,
  isLoading: false,
  hasError: false,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_OUR_WORK_META_PAGINATION:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_DEFAULT_OUR_WORK_META_PAGINATION:
      return {
        ...state,
        meta: defaultMetaPagination,
      };

    case ActionTypes.SET_OUR_WORK_COUNTRY:
      return {
        ...state,
        country: payload,
      };

    case ActionTypes.SET_OUR_WORK_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_OUR_WORK_DETAILS:
      return {
        ...state,
        details: {},
      };

    case ActionTypes.SET_OUR_WORK_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_OUR_WORK_FILTER:
      return {
        ...state,
        filter: defaultFilter,
        meta: defaultMetaPagination,
      };

    case ActionTypes.SET_OUR_WORK_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_OUR_WORK_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        hasError: false,
      };
    case ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
      };
    case ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };

    case ActionTypes.GET_ALL_OUR_WORK_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        hasError: false,
      };
    case ActionTypes.GET_ALL_OUR_WORK_PROJECT_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
      };
    case ActionTypes.GET_ALL_OUR_WORK_PROJECT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
      };
    default:
      return state;
  }
};
