/* eslint import/no-anonymous-default-export: off */
import map from "lodash/map";
import keys from "lodash/keys";

const generateStyle = (style: { [key: string]: string }) => {
  let result = "";
  const keyStyle = keys(style);
  map(keyStyle, (item, index) => {
    result += `${item}: ${style[item]};${
      index === keyStyle.length - 1 ? "" : "\n"
    }`;
  });
  return result;
};

const generateResponsive = (value: any[]) => {
  let result = "";
  map(value, (item) => {
    const breakpoint = `
      ${item.breakpoint} {
        ${generateStyle(item.style)}
      }
    `;
    result += breakpoint;
  });
  return result;
};

export default generateResponsive;
