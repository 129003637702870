import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import history from "../../../../history";
import { Images, Icons } from "../../../../themes";
import { ROUTES } from "../../../../constants";
import { PageSettingsActions } from "../../../../actions";
import {
  SideMenuContainer,
  NavigationGroup,
  NavigationItem,
  ExpandIcon,
  LogoName,
} from "./SideMenu.styles";
import { FlexHorizontal } from "../../../Common";
import { statusAlert } from "../../../../libs";

interface SectionProps {
  currentPage: string;
}

const MENU = [
  {
    icon: Icons.dashboard.default,
    label: "dashboard",
    value: ROUTES.ADMIN.DASHBOARD,
  },
  {
    icon: Icons.articles.default,
    label: "articles",
  },
  {
    label: "publishedArticles",
    value: ROUTES.ADMIN.ARTICLES,
  },
  {
    label: "draftArticles",
    value: ROUTES.ADMIN.DRAFT_NEWS,
  },
  // {
  //   label: "reOrderArticles",
  //   value: ROUTES.ADMIN.ORDER_NEWS,
  // },
  {
    icon: Icons.siteSettings.default,
    label: "heroImageOrVideo",
    value: ROUTES.ADMIN.SITE_SETTINGS,
  },
  {
    icon: Icons.members.default,
    label: "ourTeam",
    value: ROUTES.ADMIN.TEAM_MEMBERS,
  },
  {
    icon: Icons.trustedBy.default,
    label: "ourClients",
    value: ROUTES.ADMIN.CLIENTS,
  },
  {
    icon: Icons.articles.default,
    label: "editContent",
    value: ROUTES.ADMIN.CONTENT_EDITABLE,
  },
];

const { setExpandSideMenu } = PageSettingsActions;

const SideMenu: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  const { currentPage } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const isExpand = useSelector((state) =>
    _.get(state, "PageSettings.isExpandMenu")
  );
  const isEdited = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );

  const handleChangePage = async (value: string) => {
    if (isEdited) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningConfirmLeaveWhenEditing")
      );
      if (isAgree) return history.push(value);
    } else history.push(value);
  };

  return (
    <SideMenuContainer className={`${isExpand ? "expand" : ""}`}>
      <ExpandIcon onClick={() => dispatch(setExpandSideMenu(!isExpand))}>
        <img
          className={`${isExpand ? "rotate" : ""}`}
          src={Icons.expandIcon.default}
          alt="expand arrow icon"
        />
      </ExpandIcon>
      <FlexHorizontal justifyCenter className="mb-3">
        <LogoName>
          {isExpand ? (
            <img src={Images.logoImage.default} alt="logo text" />
          ) : (
            <img src={Images.logoName.default} alt="logo name" />
          )}
        </LogoName>
      </FlexHorizontal>
      <NavigationGroup>
        {_.map(MENU, (item, index) => {
          const { label, value, icon } = item;
          const activeClass = currentPage === value ? "active" : "";
          return (
            <NavigationItem
              key={`sidemenu-${index}`}
              onClick={() => _.isString(value) && handleChangePage(value)}
              className={`${activeClass} ${!value ? "disabled" : ""}`}
            >
              <div className="menu-icon">
                {icon ? (
                  <img src={icon} alt="menu-icon" />
                ) : (
                  <div className="line" />
                )}
              </div>
              <p>{t(`label.${label}`)}</p>
            </NavigationItem>
          );
        })}
      </NavigationGroup>
    </SideMenuContainer>
  );
};

export default SideMenu;
