/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import replace from "lodash/replace";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";
import sanitizeHtml from "sanitize-html";
import { useLocation } from "react-router-dom";

import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { Images } from "../../../themes";
import {
  Title,
  Description,
  DetailsContent,
  DetailsBanner,
  SkeletonNewsDetails,
} from "../../../components/Common";
import { ROUTES } from "../../../constants";
import { OurWorkActions } from "../../../actions";
import OurWorkDescription from "./OurWorkDescription";
import {
  DetailsContentColumn,
  NewsDetailsContainer,
} from "../NewsDetails/NewsDetails.styles";
import history from "../../../history";

interface ParamsType {
  id: string;
  type: string;
}

// Declare actions
const { getProjectById, clearOurWorkDetails } = OurWorkActions;

const ResearchWorkDetails = () => {
  // Declare use location
  const { id } = useParams<ParamsType>();
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const location = useLocation();
  const details = useSelector((state) => get(state, "OurWork.details"));
  const isLoading = useSelector((state) => get(state, "OurWork.isLoading"));

  useEffect(() => {
    const search = location.search;
    const idNumber = toNumber(id);
    if (!isNumber(idNumber))
      history.push({
        pathname: ROUTES.USER.RESEARCH_WORK,
        state: { from: ROUTES.USER.RESEARCH_WORK, query: search },
      });
    const currentId = get(details, "id");
    if (currentId !== id) dispatch(getProjectById(id, "research"));

    return () => {
      dispatch(clearOurWorkDetails());
    };
  }, []);

  // Parse string to HTML
  const createMarkup = (html: any) => {
    return {
      __html: sanitizeHtml(html),
    };
  };

  // Decode wrong character
  const generateDescription = () => {
    const description = get(details, "brief_description");
    if (!description) return;
    const replaceSpecialCharacter = replace(
      description,
      new RegExp("", "g"),
      "―"
    );
    const replaceLiBreakLine = replace(
      replaceSpecialCharacter,
      new RegExp("<li>\\n", "g"),
      ""
    );
    const replaceParagraphBreakLine = replace(
      replaceLiBreakLine,
      new RegExp("<p>\\n", "g"),
      ""
    );
    const replaceSpace = replace(replaceParagraphBreakLine, /&nbsp;/g, "");
    const removeEmptyTag = replace(replaceSpace, /<[^/>][^>]*> <\/[^>]+>/, "");
    const result: any = createMarkup(removeEmptyTag);
    return result;
  };

  const renderMain = () => {
    return (
      <NewsDetailsContainer>
        <DetailsBanner
          isLoading={isLoading}
          banner={Images.IDRAResearchBanner.default}
        />
        <DetailsContentColumn>
          <DetailsContent>
            {isLoading ? (
              <SkeletonNewsDetails />
            ) : (
              <>
                <Title className="mt-1 mb-2">{get(details, "title")}</Title>
                <Description
                  textAlignJustify
                  dangerouslySetInnerHTML={generateDescription()}
                />
              </>
            )}
          </DetailsContent>
          <OurWorkDescription isLoading={isLoading} details={details} />
        </DetailsContentColumn>
      </NewsDetailsContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_WORK_DETAILS}
      headerTitle={`${isLoading ? "Loading..." : get(details, "title")} | IDRA`}
    />
  );
};

export default ResearchWorkDetails;
