/* eslint import/no-anonymous-default-export: off */
import { ROUTES } from "../constants";
import ActionTypes from "../constants/ActionTypes";

const initialState = {
  isLoading: false,
  hasError: false,
  isSuccess: false,
  pageEditing: ROUTES.USER.HOMEPAGE,
  langEditing: "en",
  payload: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_LANGUAGE_EDITING:
      return {
        ...state,
        langEditing: payload,
      };
    case ActionTypes.SET_PAGE_EDITING:
      return {
        ...state,
        pageEditing: payload,
      };

    case ActionTypes.SET_EDIT_CONTENT_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_EDIT_CONTENT_PAYLOAD:
      return {
        ...state,
        payload: {},
      };

    case ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_CONTENT_IDRA_PAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_IDRA_PAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_IDRA_PAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_CONTENT_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_CONTENT_CAREERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_CAREERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_CAREERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_CONTENT_HOMEPAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_HOMEPAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CONTENT_HOMEPAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.UPDATE_AREAS_OF_INTEREST_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_AREAS_OF_INTEREST_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_AREAS_OF_INTEREST_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
        payload: {
          ...state.payload,
          [state.pageEditing]: payload,
        },
      };

    case ActionTypes.GET_EDIT_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
        payload: {},
      };
    case ActionTypes.GET_EDIT_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
        payload: {},
      };
    case ActionTypes.GET_EDIT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
