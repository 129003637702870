import React, { useState, useEffect } from "react";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";

import { DropdownContainer } from "./AdminAutocomplete.styles";

interface ISectionProps {
  options: { label: string; value: string | number }[];
  onChange(value: string | number): void;
  value: string | number;
  className?: string;
  placeholder?: string;
}

type OptionType = {
  value: string | number;
  label: string;
};

const AdminAutocomplete: React.FC<ISectionProps> = ({
  options,
  onChange,
  value,
  className = "",
  placeholder = "Select...",
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>();

  useEffect(() => {
    if (value) {
      const findOption = find(options, { value });
      if (!isEmpty(findOption)) setSelectedOption(findOption);
    } else setSelectedOption(undefined);
  }, [value, options]);

  const handleChange = (option: OptionType) => {
    onChange(option.value);
  };

  return (
    <DropdownContainer className={className}>
      <Select
        placeholder={placeholder}
        options={options}
        onChange={(newOption: any) => handleChange(newOption)}
        value={selectedOption || ""}
        className="select-container"
        classNamePrefix="select-prefix"
      />
    </DropdownContainer>
  );
};

export default AdminAutocomplete;
