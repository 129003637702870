import React from "react";
import {
  Content,
  DialogContainer,
  Header,
  FlexRow,
  Description,
  ButtonField,
  Button,
  ContentBackground,
} from "./AlumniNetwork.styles";
import { Images } from "../../../../themes";
import { ROUTES } from "../../../../constants";
import { useLocation } from "react-router-dom";
import { Utils, GenerateValue } from "../../../../libs";
import { useTranslation } from "react-i18next";

const { calculateTime } = GenerateValue;

const AlumniNetworkPopup = () => {
  const { t } = useTranslation("user");
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const closeTime = Utils.getClosePopupTime();
    const isExpiredCloseTime = calculateTime(closeTime, 1);
    if (!isOpen) {
      if (
        isExpiredCloseTime &&
        (pathname !== ROUTES.USER.ALUMNI_NETWORK ||
          pathname !== ROUTES.USER.ALUMNI_NETWORK_REGISTER)
      )
        setIsOpen(true);
    }
  }, [pathname, isOpen]);

  const onClickOutsideDialog = (e: any) => {
    const isDialog = e?.target?.nodeName === "DIALOG";
    if (isDialog) {
      onCloseDialog();
    }
  };

  const onCloseDialog = () => {
    setIsOpen(false);
    const newCloseTime = new Date().getTime();
    Utils.setClosePopupTime(newCloseTime);
  };

  return (
    <DialogContainer
      style={{ zIndex: isOpen ? 999 : -999 }}
      onClick={onClickOutsideDialog}
    >
      <ContentBackground>
        <Content>
          <Header>
            <img src={Images.logoHeader.default} alt="" />
          </Header>
          <FlexRow>
            <Description>
              <h3
                style={{
                  textTransform: "uppercase",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                {t("alert.weAre")}
              </h3>
              <h2
                style={{
                  textTransform: "uppercase",
                  color: "white",
                  fontWeight: 700,
                }}
              >
                {t("alert.IDRAAlumniNetwork")}
              </h2>
              <hr style={{ maxWidth: "150px", margin: "15px 0" }} />
              <p style={{ fontSize: "16px", fontWeight: 500, color: "white" }}>
                {t("alert.buildingBondsThatLast")}
              </p>
            </Description>
            <ButtonField>
              <Button
                to={ROUTES.USER.ALUMNI_NETWORK}
                onClick={() => onCloseDialog()}
              >
                <span>{t("button.joinUs")}</span>
              </Button>
            </ButtonField>
          </FlexRow>
        </Content>
      </ContentBackground>
    </DialogContainer>
  );
};

export default AlumniNetworkPopup;
