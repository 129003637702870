import styled from "styled-components";
import { Images } from "../../../../themes";
import { Link } from "react-router-dom";

const DialogContainer = styled.dialog`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0px;
  box-sizing: border-box;
  position: fixed;
  border: none;
`;

const Header = styled.div`
  padding: 20px;

  img {
    width: 100%;
    max-width: 110px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all ease 0.25s;

  @media screen and (max-width: 446px) {
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.4);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-position: center;
  padding: 30px 20px;
  transition: all ease 0.25s;

  @media screen and (max-width: 446px) {
    flex-direction: column;
  }
`;

const Heading = styled.div``;

const Description = styled.div``;

const ButtonField = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transition: all ease 0.25s;

  @media screen and (max-width: 446px) {
    margin-top: 20px;
  }
`;

const Button = styled(Link)`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background: black;
  padding: 10px 25px;
  position: relative;

  span {
    display: inline-block;
    transform: translateX(-10px);
    transition: all ease 0.25s;
  }

  ::before {
    content: "";
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 10px solid white;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: translateX(-10px);
    transition: all ease 0.25s;
    opacity: 0;
    top: calc(50% - 10px);
  }

  ::after {
    content: "";
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 10px solid white;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: translateX(0px);
    transition: all ease 0.25s;
    opacity: 1;
    top: calc(50% - 10px);
  }

  :hover {
    cursor: pointer;
    span {
      transform: translateX(10px);
    }
    ::before {
      transform: translateX(-5px);
      opacity: 1;
    }
    ::after {
      transform: translateX(5px);
      opacity: 0;
    }
  }
`;

const ContentBackground = styled.div`
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 450px;
  background-image: url("${Images.alumniPopupBackground.default}");
  background-repeat: no-repeat;
  background-position: center;
  transition: all ease 0.25s;

  @media screen and (max-width: 446px) {
    background-image: url("${Images.alumniSocialNetwork.default}");
    background-position: center;
    background-size: cover;
  }
`;

export {
  Content,
  DialogContainer,
  Heading,
  Header,
  FlexRow,
  Description,
  ButtonField,
  Button,
  ContentBackground,
};
