import styled from 'styled-components';
import { VerticalContainer, HorizontalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

interface SectionProps {
  color?: string;
  background?: string;
}

const Title = styled.h3`
  font-weight: 700;
  max-width: 300px;
  text-align: center;
  margin-top: 10px;
  font-size: 2.9rem;
  line-height: 35px;

  @media screen and (max-width: 426px) {
    margin-top: 5px;
    font-size: 20px;
    line-height: 29px;
  }
`;

const Subtitle = styled.p`
  max-width: 370px;
  flex: 1;
  text-align: center;
  margin-top: 5px;
  font-size: 2rem;
  line-height: 30px;
  font-weight: 500;
  white-space: pre-line;

  @media screen and (max-width: 426px) {
    margin-top: 5px;
    max-width: 210px;
    font-size: 12px;
    line-height: 18px;
    white-space: unset;
  }
`;

const IconWrapper = styled(HorizontalContainer)`
  align-items: flex-end;
  flex: 0.6;

  @media only screen and (max-width: 1440px) {
    flex: 0.5;
  }
  @media only screen and (max-width: 1280px) {
    flex: 0.45;
  }
  @media only screen and (max-width: 1366px) {
    flex: 0.4;
  }
  @media only screen and (max-width: 1024px) {
    flex: 0.6;
  }
  @media only screen and (max-width: 835px) {
    flex: 0.7;
    justify-content: center;
  }
`;

const SolutionIcon = styled.img`
  transition: all ease 0.25s;
  width: 65%;
  @media screen and (max-width: 426px) {
    transform: scale(0.8);
  }
`;

const SolutionContent = styled(VerticalContainer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;

const BackgroundZoomIn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props: SectionProps) => `url('${props.background}')`};
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.25s;
`;

const SolutionCardContainer = styled(VerticalContainer)`
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    ${BackgroundZoomIn} {
      transform: scale(1.1);
    }
    ${SolutionIcon} {
      transform: scale(1.2);
    }
    @media screen and (max-width: 426px) {
      ${BackgroundZoomIn} {
        transform: scale(1.1);
      }
      ${SolutionIcon} {
        transform: scale(1);
      }
    }
  }

  ${Title}, ${Subtitle} {
    color: ${(props: SectionProps) => props.color || ColorName.black};
  }
`;

export { SolutionCardContainer, SolutionContent, BackgroundZoomIn, Title, Subtitle, SolutionIcon, IconWrapper };
