/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import history from '../history';
import get from 'lodash/get';
import { ROUTES } from '../constants';
import { statusAlert, Utils } from '../libs';

const isLogged = () => {
  return {
    type: ActionTypes.IS_LOGGED,
  };
};

const loginHasError = () => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
  };
};

const loginRequest = () => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
  };
};

const loginIsSuccess = () => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
  };
};

const login = (data: any) => {
  return async (dispatch: any) => {
    dispatch(loginRequest());
    await API.login(data)
      .then(async (res: any) => {
        const { payload, status, message } = res.data;
        if (status) {
          const { accessToken, user } = payload;
          await dispatch(isLogged());
          await dispatch(loginIsSuccess());
          await Utils.saveUserData(user);
          await Utils.saveToken(accessToken);
          await API.setToken(get(accessToken, 'refresh_token'));
          history.push(ROUTES.ADMIN.DASHBOARD);
        } else {
          dispatch(loginHasError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(loginHasError()));
  };
};

const logout = () => {
  Utils.clearToken();
  Utils.clearUserData();
  Utils.clearAllSavedData();
  history.push(ROUTES.ADMIN.LOGIN);
  return {
    type: ActionTypes.LOGOUT,
  };
};

export default { isLogged, login, logout };
