import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FlexHorizontal } from '../../../components/Common';
import {
  PreviewLayer,
  PreViewSiteSettingContainer,
  PreviewContent,
  PreviewDots,
  PreviewImage,
  PreviewVideo,
  PreviewLabel,
  PreviewSubtitle,
  PreviewModuleBar,
  ModuleButton,
} from './PreviewSiteSetting.styles';

interface SiteSettingStructure {
  title: {
    en: string;
    al: string;
  };
  heroMedia: {
    link: string;
    type: string;
  };
  linkSection: string;
}

interface SectionProps {
  onClose(color?: string): void;
  data: SiteSettingStructure;
}

const PreviewSiteSetting = (props: SectionProps) => {
  const { t } = useTranslation('admin');
  const { onClose, data } = props;
  const [color, setColor] = useState<string>('');
  const colorRef = useRef<HTMLInputElement>(null);
  const lang = useSelector(state => _.get(state, 'PageSettings.adminLang'));

  useEffect(() => {
    const currentColor = _.get(data, 'sectionColor');
    setColor(currentColor);
  }, [data]);

  return (
    <PreviewLayer>
      <PreViewSiteSettingContainer>
        {_.get(data, 'heroMedia.type') === 'image' ? (
          <PreviewImage src={_.get(data, 'heroMedia.link')} />
        ) : (
          <PreviewVideo width="100%" autoPlay loop>
            <source src={_.get(data, 'heroMedia.link')} type="video/mp4" />
            Your browser does not support the video tag.
          </PreviewVideo>
        )}
        <PreviewContent>
          <PreviewLabel style={{ color }}>{_.get(data, `title.${lang}`)}</PreviewLabel>
          <PreviewSubtitle style={{ color }}>{t('label.learnMore')}</PreviewSubtitle>
          <FlexHorizontal className="mt-2">
            <PreviewDots>
              <span className="dot-background" style={{ background: color }} />
              <span className="dot-grow" style={{ background: color }} />
            </PreviewDots>
            <PreviewDots>
              <span className="dot-background" style={{ background: color }} />
            </PreviewDots>
            <PreviewDots>
              <span className="dot-background" style={{ background: color }} />
            </PreviewDots>
          </FlexHorizontal>
        </PreviewContent>
        <PreviewModuleBar>
          <ModuleButton title={t('button.close')} onClick={() => onClose()}>
            <i className="fas fa-times" />
          </ModuleButton>
          <ModuleButton title={t('button.color')} onClick={() => colorRef.current?.click()}>
            <i className="fas fa-palette" />
            <input type="color" ref={colorRef} onChange={(e: any) => setColor(e.target.value)} />
          </ModuleButton>
          <ModuleButton title={t('button.save')} onClick={() => onClose(color)}>
            <i className="fas fa-save" />
          </ModuleButton>
        </PreviewModuleBar>
      </PreViewSiteSettingContainer>
    </PreviewLayer>
  );
};

export default PreviewSiteSetting;
