/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import trim from "lodash/trim";
import get from "lodash/get";
import map from "lodash/map";
import ContactDropdown from "./ContactDropdown";
import Countries from "./Country";

import {
  FlexVertical,
  FlexHorizontal,
  Content,
  UserInput,
  UserTextarea,
  UserCarrotButton,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import {
  ContactContainer,
  InputForm,
  StepForm,
  TitleStep,
  DescriptionStep,
  LabelInput,
  SpinLoader,
  PrevButton,
  InformationLayout,
  CompanyName,
  CompanyDetails,
  CompanyBox,
  InputField,
  Input,
} from "./Contact.styles";
import { ROUTES, ANIMATION } from "../../../constants/";
import { Icons } from "../../../themes";
import { ContactActions } from "../../../actions";
import { statusAlert, Utils } from "../../../libs";
import Country from "./Country";
import _ from "lodash";
import icons from "../../../themes/Icons";

const COMPANY_INFORMATIONS = [
  {
    name: "IDRA Albania - Headquarters",
    address: "Siri Kodra str., Build. 94/5, Apt. 49",
    zip: "P.O.Box 1730, Tirana, Albania",
    mail: "idra@idracompany.com",
    phone: "+355699846258",
    direction:
      "https://www.google.com/maps/place/IDRA+-+Albania/@41.3353448,19.8196665,16z/data=!4m5!3m4!1s0x13503112d92454f1:0x2b2cb7ce6cf4210c!8m2!3d41.335845!4d19.821455",
  },
  {
    name: "IDRA Kosova",
    address: "Muharrem Fejza str., HC15/12, no.23",
    zip: "Zip code 10000, Prishtina, Kosova",
    mail: "idra.kosova@idracompany.com",
    phone: "+38338742966",
    direction:
      "https://www.google.com/maps/place/IDRA+Research+and+Consulting+-+Kosova/@42.6475475,21.170038,16.5z/data=!4m5!3m4!1s0x0:0x8d574ddc842fe89c!8m2!3d42.6475487!4d21.1717686",
  },
  {
    name: "FLOW - IDRA Vietnam",
    address:
      "28 Ly Thuong Kiet Street, Floor 7, HCC \n Building, Vinh Ninh Ward, Hue City\nThua Thien Hue Province",
    zip: "Zip Code 52000",
    mail: "services@flow-idra.com",
    direction:
      "https://www.google.com/maps/place/28+L%C3%BD+Th%C6%B0%E1%BB%9Dng+Ki%E1%BB%87t,+V%C4%A9nh+Ninh,+Th%C3%A0nh+ph%E1%BB%91+Hu%E1%BA%BF,+Th%E1%BB%ABa+Thi%C3%AAn+Hu%E1%BA%BF/@16.4586497,107.5912898,18.25z/data=!4m5!3m4!1s0x3141a14756066fab:0xcfb6ff2303b5c98b!8m2!3d16.4586086!4d107.59155",
  },
];

// Declare actions
const { sendMail } = ContactActions;
// Declare default mail details
const defaultMailDetails = {
  fullname: "",
  phoneNumber: "",
  email: "",
  message: "",
  service: "",
  region: "",
};

const Contact = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const requestIsLoading = useSelector((state) =>
    get(state, "Contact.isLoading")
  );
  const lang = useSelector((state) => get(state, "PageSettings.userLang"));
  const payload = useSelector((state) =>
    get(state, `EditContent.payload.${ROUTES.USER.CONTACT}`)
  );
  // Declare states
  const [currentStep, setCurrentStep] = useState<string>("first");
  const [mailDetails, setMailDetails] = useState<any>({});

  useEffect(() => {
    setMailDetails(defaultMailDetails);
    return () => {
      setMailDetails({});
      setCurrentStep("first");
    };
  }, []);

  // Handle change step
  const onChangeStep = (step: string) => {
    if (step) {
      Utils.scrollToBottomOfBanner();
      setCurrentStep(step);
    }
  };

  // Handle change input
  const onChangeInput = (value: string, key: string) => {
    const data = {
      ...mailDetails,
      [key]: value,
    };
    setMailDetails(data);
  };

  const checkEmptyField = () => {
    const dataCheck = [
      {
        label: t("label.fullname"),
        value: trim(get(mailDetails, "fullname")),
      },
      {
        label: t("label.phoneNumber"),
        value: trim(get(mailDetails, "phoneNumber")),
      },
      {
        label: t("label.email"),
        value: trim(get(mailDetails, "email")),
      },
      {
        label: t("label.message"),
        value: trim(get(mailDetails, "message")),
      },
      {
        label: t("label.region"),
        value: trim(get(mailDetails, "region")),
      },
    ];
    let message = "";
    let flag = false;
    map(dataCheck, (item) => {
      if (!item.value) {
        flag = true;
        message = message + `\n${item.label}`;
      }
    });
    return {
      flag,
      message,
    };
  };

  const checkTypeField = () => {
    let flagType = false;
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobilePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const isValidEmail = emailPattern.test(get(mailDetails, "email"));
    const isValidPhone = mobilePattern.test(
      "0" + get(mailDetails, "phoneNumber")
    );
    let messageType = `${t("alert.foundWrongTypes")}`;
    if (!isValidEmail) {
      flagType = true;
      messageType += `\n${t("label.email")}`;
    }
    if (!isValidPhone) {
      flagType = true;
      messageType += `\n${t("label.phoneNumber")}`;
    }
    if (
      !trim(get(mailDetails, "email")) &&
      !trim(get(mailDetails, "phoneNumber"))
    )
      return {
        messageType: "",
        flagType,
      };
    return { messageType, flagType };
  };

  // Handle send mail
  const onSubmit = async () => {
    const { messageType, flagType } = checkTypeField();
    const { flag, message } = checkEmptyField();
    if (flag || flagType)
      return await statusAlert(
        "warning",
        `${flag ? `${t("alert.foundEmptyFields")}: ${message}` : ""}\n${
          flagType ? messageType : ""
        }`
      );
    else {
      const service = get(mailDetails, "service") || "contact";
      const code = get(mailDetails, "region");
      const dialCode = _.find(Country, { code });
      dispatch(
        sendMail(
          {
            ...mailDetails,
            service,
            phoneNumber: `(${get(dialCode, "dial_code")})${get(
              mailDetails,
              "phoneNumber"
            )}`,
          },
          () => {
            setCurrentStep("second");
            setMailDetails(defaultMailDetails);
          }
        )
      );
    }
  };

  const renderAreaCode = () => {
    const region = _.get(mailDetails, "region");
    if (region) {
      const findArea = _.find(Countries, { code: region });
      if (findArea) return _.get(findArea, "dial_code");
    }
    return "N/I";
  };

  const onBlurPhoneNumber = (phoneNumber: any) => {
    if (phoneNumber.charAt(0) === "0") {
      const newPhoneNumber = phoneNumber.substring(1);
      onChangeInput(newPhoneNumber, "phoneNumber");
    }
  };

  const renderFirstStep = () => {
    const stepTitle = get(payload, `stepTwo.stepTitle.${lang}`);
    const stepDescription = get(payload, `stepTwo.stepDescription.${lang}`);
    return (
      <StepForm>
        <motion.div
          key="step-two"
          variants={ANIMATION.container}
          initial="hidden"
          animate="visible"
        >
          <motion.div className="flex-column" variants={ANIMATION.item}>
            <TitleStep>{stepTitle}</TitleStep>
            <DescriptionStep>{stepDescription}</DescriptionStep>
          </motion.div>
          <motion.div variants={ANIMATION.item}>
            <InputForm className="mt-9">
              <FlexVertical flex="1">
                <FlexVertical alignFlexStart>
                  <LabelInput>{t("label.yourName")}</LabelInput>
                  <UserInput
                    disabled={requestIsLoading}
                    value={get(mailDetails, "fullname")}
                    onChange={(e: any) =>
                      onChangeInput(e.target.value, "fullname")
                    }
                  />
                </FlexVertical>
                <FlexVertical alignFlexStart className="mt-2">
                  <LabelInput>{t("label.yourPhoneNumber")}</LabelInput>
                  <FlexHorizontal>
                    <ContactDropdown
                      selected={get(mailDetails, "region")}
                      onChange={(value: string, key: string) =>
                        onChangeInput(value, key)
                      }
                    />
                    <InputField>
                      <span>({renderAreaCode()})</span>
                      <Input
                        disabled={requestIsLoading}
                        value={get(mailDetails, "phoneNumber")}
                        onChange={(e: any) =>
                          onChangeInput(e.target.value, "phoneNumber")
                        }
                        onBlur={(e: any) => onBlurPhoneNumber(e.target.value)}
                        type="tel"
                        placeholder="123-456-7890"
                      />
                    </InputField>
                  </FlexHorizontal>
                </FlexVertical>
                <FlexVertical alignFlexStart className="mt-2">
                  <LabelInput>{t("label.yourEmailAddress")}</LabelInput>
                  <UserInput
                    disabled={requestIsLoading}
                    value={get(mailDetails, "email")}
                    onChange={(e: any) =>
                      onChangeInput(e.target.value, "email")
                    }
                    type="email"
                    placeholder="idra@idracompany.com"
                  />
                </FlexVertical>
              </FlexVertical>
              <FlexVertical
                alignFlexStart
                flex="2"
                className="ml-4 message-box"
              >
                <LabelInput>{t("label.yourMessage")}</LabelInput>
                <UserTextarea
                  disabled={requestIsLoading}
                  value={get(mailDetails, "message")}
                  onChange={(e: any) =>
                    onChangeInput(e.target.value, "message")
                  }
                  height="100%"
                />
              </FlexVertical>
            </InputForm>
          </motion.div>
          <FlexHorizontal justifySpaceBetween alignCenter className="mt-3">
            <PrevButton
              onClick={() => onChangeStep("first")}
              style={{
                visibility: "hidden",
              }}
            >
              <img
                src={Icons.leftArrow.default}
                alt="left arrow"
                className="mr-1"
              />
              {t("button.previous")}
            </PrevButton>
            <FlexVertical width="unset" className="mt-2" position="relative">
              <UserCarrotButton
                className={`${requestIsLoading ? "disabled" : ""}`}
                isUppercase
                onClick={() => !requestIsLoading && onSubmit()}
              >
                {t("button.sendMessage")}
              </UserCarrotButton>
              {requestIsLoading && <SpinLoader />}
            </FlexVertical>
          </FlexHorizontal>
        </motion.div>
      </StepForm>
    );
  };

  const renderSecondStep = () => {
    return (
      <StepForm>
        <motion.div
          key="first-step"
          variants={ANIMATION.container}
          initial="hidden"
          animate="visible"
        >
          <motion.div className="flex-column" variants={ANIMATION.item}>
            <TitleStep>{t("label.thankYouContactTitle")}</TitleStep>
            <DescriptionStep>{t("label.thankYouContactMsg")}</DescriptionStep>
          </motion.div>
        </motion.div>
      </StepForm>
    );
  };

  const renderCompanyInformation = () => {
    return (
      <InformationLayout>
        {map(COMPANY_INFORMATIONS, (information) => {
          const { name, address, direction, zip, mail, phone } = information;
          return (
            <CompanyBox key={`information-${name}`}>
              <CompanyName> {name}</CompanyName>
              <FlexVertical justifySpaceBetween>
                <CompanyDetails>
                  <div className="icons">
                    <img src={icons.addressContact.default} alt="" />
                  </div>
                  <p>{address}</p>
                </CompanyDetails>
                <CompanyDetails className={!phone ? "not_phone" : ""}>
                  <div className="icons">
                    <img src={icons.zipContact.default} alt="" />
                  </div>
                  <p>{zip}</p>
                </CompanyDetails>
                {mail && (
                  <CompanyDetails>
                    <div className="icons">
                      <img src={icons.mailContact.default} alt="" />
                    </div>
                    <p>{mail}</p>
                  </CompanyDetails>
                )}
              </FlexVertical>

              {phone && (
                <CompanyDetails>
                  <div className="icons">
                    <img src={icons.phoneContact.default} alt="" />
                  </div>
                  <p>{phone}</p>
                </CompanyDetails>
              )}
              <CompanyDetails>
                <a href={direction} target="_blank" rel="noopener noreferrer">
                  {t("label.getDirections")}
                </a>
                <div className="icons">
                  <img src={icons.directionContact.default} alt="" />
                </div>
              </CompanyDetails>
            </CompanyBox>
          );
        })}
      </InformationLayout>
    );
  };

  const renderMain = () => {
    return (
      <ContactContainer>
        <Content className="mt-6 mb-8" direction="column">
          {currentStep === "first" ? renderFirstStep() : renderSecondStep()}
          <div className="dotted-border" />
          {renderCompanyInformation()}
        </Content>
      </ContactContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.CONTACT}
      loading={requestIsLoading}
      headerTitle="Contact"
      metaDescription="How can we assist you?"
    />
  );
};

export default Contact;
