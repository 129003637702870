import Service from './Base';
import API from '../constants/API';

const { DASHBOARD, LOGS } = API;

export function getCountDashboard() {
  return Service.get(DASHBOARD.BASIC);
}

export function getLogsActivity(pagination: any) {
  return Service.get(LOGS.BASIC, pagination);
}
