import Service from './Base';
import API from '../constants/API';

const { MEDIA } = API;

export function getMediaByType(data: string) {
  return Service.get(MEDIA.BASIC, data);
}

export function getVideos(data: any) {
  return Service.get(MEDIA.VIDEO, data);
}


export function uploadMedia(data: any) {
  return Service.postFormData(MEDIA.BASIC, data);
}


export function deleteMedia(id: string) {
  return Service.del(`${MEDIA.BASIC}/${id}`);
}
