/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES, ColumnsTable } from "../../../constants";
import {
  FlexHorizontal,
  AdminPagination,
  ContentContainer,
} from "../../../components/Common";
import { NormalTable } from "../../../components/Layout/CommonLayoutPart/Table";
import { DashboardActions } from "../../../actions";

const { getLogsActivity, setLogsPagination } = DashboardActions;

const Dashboard = () => {
  const dispatch = useDispatch();
  const logs = useSelector((state) => get(state, "Dashboard.payloadLogs"));

  const isRequestLoading = useSelector((state) =>
    get(state, "Dashboard.isLoading")
  );
  const pagination: any = useSelector((state) =>
    get(state, "Dashboard.logsPagination")
  );
  const meta: any = useSelector((state) => get(state, "Dashboard.logsMeta"));
  const lang = useSelector((state) => get(state, "PageSettings.adminLang"));
  const isExpand = useSelector((state) =>
    _.get(state, "PageSettings.isExpandMenu")
  );

  useEffect(() => {
    dispatch(getLogsActivity(pagination));
  }, []);

  const handleClickPagination = async (currentPage: number) => {
    const newPagination = { ...pagination, currentPage };
    await dispatch(setLogsPagination(newPagination));
    await dispatch(getLogsActivity(newPagination));
  };

  const renderMain = () => {
    return (
      <ContentContainer isExpand={isExpand}>
        <NormalTable
          data={logs}
          column={ColumnsTable.RECENT_LOGS}
          fragment="0.8fr 1.1fr 3fr 1fr"
          lang={lang}
        />
        {!isEmpty(logs) && (
          <FlexHorizontal justifyFlexEnd className="mt-3 mb-1">
            <AdminPagination
              maxPage={get(meta, "totalPages") || 0}
              currentPage={get(meta, "currentPage") || 0}
              numberPageDisplay={2}
              totalCount={get(meta, "totalItems") || 0}
              clickLinkPagination={(page: number) =>
                handleClickPagination(page)
              }
              clickLinkNext={(page: number) => handleClickPagination(page)}
              clickLinkPrevious={(page: number) => handleClickPagination(page)}
              clickLinkLast={() =>
                handleClickPagination(get(meta, "totalPages"))
              }
              clickLinkFirst={() => handleClickPagination(1)}
            />
          </FlexHorizontal>
        )}
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.RECENT_ACTIVITY}
      loading={isRequestLoading}
    />
  );
};

export default Dashboard;
