import styled from 'styled-components';

interface SectionProps {
  flex?: string;
  justifyFlexStart?: boolean;
  justifyFlexEnd?: boolean;
  justifyCenter?: boolean;
  justifySpaceAround?: boolean;
  justifySpaceBetween?: boolean;
  justifySpaceEvenly?: boolean;
  alignFlexStart?: boolean;
  alignFlexEnd?: boolean;
  alignCenter?: boolean;
  alignStretch?: boolean;
  alignBaseline?: boolean;
  flexWrap?: boolean;
  position?: string;
  width?: string;
  margin?: string;
  height?: string;
  minWidth?: string;
  boxSizing?: string;
}

const generateAlignItems = (props: SectionProps) => {
  const { alignFlexStart, alignFlexEnd, alignBaseline, alignCenter, alignStretch } = props;
  if (alignFlexStart) return 'flex-start';
  if (alignFlexEnd) return 'flex-end';
  if (alignBaseline) return 'baseline';
  if (alignCenter) return 'center';
  if (alignStretch) return 'stretch';
  return 'unset';
};

const generateJustifyContent = (props: SectionProps) => {
  const {
    justifyFlexStart,
    justifyFlexEnd,
    justifyCenter,
    justifySpaceAround,
    justifySpaceBetween,
    justifySpaceEvenly,
  } = props;
  if (justifyFlexStart) return 'flex-start';
  if (justifyFlexEnd) return 'flex-end';
  if (justifyCenter) return 'center';
  if (justifySpaceAround) return 'space-around';
  if (justifySpaceBetween) return 'space-between';
  if (justifySpaceEvenly) return 'space-evenly';
  return 'unset';
};

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${(props: SectionProps) => props.flex || 'unset'};
  position: ${(props: SectionProps) => props.position || 'unset'};
  width: ${(props: SectionProps) => props.width || '100%'};
  min-width: ${(props: SectionProps) => props.minWidth || 'unset'};
  height: ${(props: SectionProps) => props.height || 'auto'};
  flex-wrap: ${(props: SectionProps) => (props.flexWrap ? 'wrap' : 'no-wrap')};
  align-items: ${(props: SectionProps) => generateAlignItems(props)};
  justify-content: ${(props: SectionProps) => generateJustifyContent(props)};
  margin: ${(props: SectionProps) => props.margin || 'unset'};
  box-sizing: ${(props: SectionProps) => props.boxSizing || 'unset'};

  &.mr-row {
    margin-top: 15px;
  }
  &.vertical-line {
    border: 1px solid #000; /* Adjust the color and thickness as needed */
    margin: 30px 0 10px 0;
  }
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props: SectionProps) => props.flex || 'unset'};
  position: ${(props: SectionProps) => props.position || 'unset'};
  width: ${(props: SectionProps) => props.width || '100%'};
  min-width: ${(props: SectionProps) => props.minWidth || 'unset'};
  height: ${(props: SectionProps) => props.height || 'auto'};
  flex-wrap: ${(props: SectionProps) => (props.flexWrap ? 'wrap' : 'no-wrap')};
  align-items: ${(props: SectionProps) => generateAlignItems(props)};
  justify-content: ${(props: SectionProps) => generateJustifyContent(props)};
  margin: ${(props: SectionProps) => props.margin || 'unset'};

  &.mr-row {
    margin-top: 15px;
  }
`;

export { FlexHorizontal, FlexVertical };
