import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import history from "../../../history";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  AdminDropdown,
  CarrotSolidButton,
  GeyserSolidButton,
  DenyButton,
  FlexHorizontal,
  DefaultInput,
  AdminLabel,
  ContentContainer,
  SubTitle,
  FlexVertical,
  AdminDatePicker,
  ContentForm,
  OutlineButton,
  AlumniCheckbox,
  AdminMultipleDropdown,
} from "../../../components/Common";
import { statusAlert } from "../../../libs";
import { AlumniNetworkActions } from "../../../actions";
import { DropdownOptions, ROUTES, COUNTRY_OPTIONS } from "../../../constants";
import { IAlumniNetwork } from "../../../interfaces/AlumniNetwork.interfaces";
import { ColorName } from "../../../components/Variables";

const {
  getAlumniNetWorkById,
  approveAlumniNetWork,
  denyAlumniNetWork,
  resetAlumniNetworkReducer,
} = AlumniNetworkActions;

const DEFAULT_DETAILS = {
  firstName: "",
  lastName: "",
  country: "",
  mobile: "",
  email: "",
  isEmployee: false,
  isExpert: false,
  linkedInLink: "",
  hearAbout: {
    idraStaff: false,
    idraWebsite: false,
    socialMediaPost: false,
    throughEmail: false,
  },
  interestsInJoining: {
    businessOpportunities: false,
    employmentOpportunities: false,
    adviceAndInformation: false,
    toConnectWithFormerColleagues: false,
  },
  extract: {
    employeeStartDate: "",
    employeeEndDate: "",
    employeePosition: "",
  },
  socialMedia: "",
  experts: [],
  actualCompany: "",
  actualPosition: "",
  status: "",
};

interface IOption {
  label: string;
  value: string;
}

const AlumniNetworkDetail: React.FC = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const location = useLocation();
  const id = queryString.parse(location.search)?.id;
  const requestIsLoading = useSelector((state) =>
    _.get(state, "AlumniNetwork.requestIsLoading")
  );
  const payload = useSelector((state) => _.get(state, "AlumniNetwork.details"));
  const [details, setDetails] = useState<IAlumniNetwork>(DEFAULT_DETAILS);
  const [countryOptions, setCountryOptions] = useState<IOption[]>([]);

  useEffect(() => {
    const resolveOptions = _.map(COUNTRY_OPTIONS, (country) => {
      return {
        label: country.name,
        value: country.code,
      };
    });
    setCountryOptions(resolveOptions);
    if (id) dispatch(getAlumniNetWorkById(id));
    else history.push(ROUTES.ADMIN.ALUMNI_NETWORK);
    return () => {
      setDetails(DEFAULT_DETAILS);
      dispatch(resetAlumniNetworkReducer());
    };
  }, [dispatch, id]);

  const expertiseOptions = React.useMemo(() => {
    const options: IOption[] = [];
    _.forEach(DropdownOptions.ALUMNI_NETWORK.expertise, (item: IOption) =>
      options.push({
        label: t(`dropdown.${item.label}`),
        value: item.value,
      })
    );
    return options;
  }, [t]);

  useEffect(() => {
    if (!_.isEmpty(payload) && typeof payload !== "undefined") {
      const clonePayload = _.cloneDeep(payload);
      if (!_.isEmpty(clonePayload)) {
        const resolveExpert =
          payload?.["experts" as keyof typeof payload] &&
          _.isArray(payload["experts"]) &&
          _.map(payload["experts"], (expert) => {
            return {
              value: expert,
              label: t(`dropdown.${expert}`),
            };
          });
        clonePayload["experts"] = resolveExpert;
        setDetails(clonePayload);
      }
    }
  }, [payload, t]);

  const onChangeHandler = (
    value: string | boolean,
    key: string,
    subKey?: string
  ) => {
    setDetails((prevDetails: IAlumniNetwork) => ({
      ...prevDetails,
      [key]: subKey
        ? {
            ...prevDetails[key],
            [subKey]: value,
          }
        : value,
    }));
  };

  const handleConfirmDeny = async () => {
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningDenyAlumniaNetwork")
    );
    if (id && isAgree) dispatch(denyAlumniNetWork(id));
  };

  const handleConfirmApprove = async () => {
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningApproveAlumniaNetwork")
    );
    if (id && isAgree) dispatch(approveAlumniNetWork(id));
  };

  const generateStatusLabel = (status: string) => {
    const createData = {
      open: ColorName.pictonBlue,
      denied: ColorName.geraldine,
      approved: ColorName.shamrock,
    };
    return createData[status as keyof typeof createData] || "";
  };

  const renderAreaCode = () => {
    const region = _.get(details, "country");
    if (region) {
      const findArea = _.find(
        COUNTRY_OPTIONS,
        (option) => option.code === region
      );
      if (findArea) return _.get(findArea, "dial_code");
    }
    return "N/I";
  };

  const _renderFormData = () => {
    const statusColor = details?.status
      ? generateStatusLabel(details?.status)
      : ColorName.black;
    return (
      <>
        {details?.status && (
          <OutlineButton
            className="mb-2"
            style={{
              pointerEvents: "none",
              color: statusColor,
              border: `3px solid ${statusColor}`,
              padding: "10px 20px",
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "20px",
              lineHeight: "30px",
            }}
          >
            {details.status}
          </OutlineButton>
        )}
        <FlexVertical>
          <SubTitle>{t("label.personalDetailsContact")}</SubTitle>
          <FlexHorizontal alignCenter className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AdminLabel width="150px">{t("label.firstName")}*</AdminLabel>
              <FlexVertical flex="1">
                <DefaultInput
                  value={details.firstName}
                  onChange={(event: any) =>
                    onChangeHandler(event.target.value, "firstName")
                  }
                  placeholder={t("placeholder.firstName")}
                  disabled
                />
              </FlexVertical>
            </FlexHorizontal>
            <FlexHorizontal alignCenter className="ml-2" flex="1">
              <AdminLabel width="150px">{t("label.lastName")}*</AdminLabel>
              <DefaultInput
                flex="1"
                value={details.lastName}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "lastName")
                }
                placeholder={t("placeholder.lastName")}
                disabled
              />
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AdminLabel width="150px">{t("label.country")}*</AdminLabel>
              <AdminDropdown
                isNoneTranslate
                data={countryOptions}
                flex="1"
                selectedValue={details.country}
                onChange={(value: any) => onChangeHandler(value, "country")}
                disabled
              />
            </FlexHorizontal>
            <FlexHorizontal alignCenter className="ml-2" flex="1">
              <AdminLabel width="150px">{t("label.phoneNumber")}*</AdminLabel>
              <DefaultInput
                height="40px"
                flex="1"
                value={`(${renderAreaCode()}) ${details.mobile}`}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "mobile")
                }
                placeholder={t("placeholder.phoneNumber")}
                disabled
              />
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AdminLabel width="150px">{t("label.email")}*</AdminLabel>
              <DefaultInput
                flex="1"
                value={details.email}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "email")
                }
                placeholder={t("placeholder.email")}
                disabled
              />
            </FlexHorizontal>
            <FlexHorizontal alignCenter className="ml-2" flex="1">
              <AdminLabel width="150px">
                {t("label.linkedIn")} ({t("label.optional")})
              </AdminLabel>
              <DefaultInput
                flex="1"
                value={details.linkedInLink}
                onChange={(event: any) =>
                  onChangeHandler(event.target.value, "linkedInLink")
                }
                placeholder={t("placeholder.linkedIn")}
                disabled
              />
            </FlexHorizontal>
          </FlexHorizontal>
        </FlexVertical>

        <FlexVertical className="mt-2">
          <SubTitle>{t("label.commitmentInIdra")}</SubTitle>
          <FlexVertical className="mb-2 mt-1" flex="1">
            <AlumniCheckbox
              value=""
              key="employee"
              label={t("label.employee")}
              checked={details.isEmployee}
              onChange={() =>
                onChangeHandler(!details.isEmployee, "isEmployee")
              }
              className="mb-1"
              disabled
            />
            {details.isEmployee && (
              <FlexVertical
                style={{ marginLeft: "26px", width: "calc(100% - 26px)" }}
              >
                <FlexHorizontal alignCenter className="mb-1">
                  <FlexVertical flex="1">
                    <AdminLabel>{t("label.latestPosition")}</AdminLabel>
                  </FlexVertical>
                  <FlexVertical flex="1">
                    <DefaultInput
                      value={details.extract.employeePosition}
                      placeholder={t("placeholder.latestPosition")}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "extract",
                          "employeePosition"
                        )
                      }
                      disabled
                    />
                  </FlexVertical>
                </FlexHorizontal>
                <FlexHorizontal alignCenter className="mb-1">
                  <FlexVertical flex="1">
                    <AdminLabel>{t("label.periodOfEmployment")}</AdminLabel>
                  </FlexVertical>
                  <FlexHorizontal alignCenter flex="1">
                    <AdminDatePicker
                      value={details.extract.employeeStartDate}
                      onChange={(newValue) =>
                        onChangeHandler(
                          newValue,
                          "extract",
                          "employeeStartDate"
                        )
                      }
                      disabled
                    />
                    <AdminLabel className="ml-1 mr-1">-</AdminLabel>
                    <AdminDatePicker
                      value={details.extract.employeeEndDate}
                      onChange={(newValue) =>
                        onChangeHandler(newValue, "extract", "employeeEndDate")
                      }
                      disabled
                    />
                  </FlexHorizontal>
                </FlexHorizontal>
              </FlexVertical>
            )}
          </FlexVertical>
          <FlexHorizontal alignCenter className="mt-1">
            <FlexHorizontal alignCenter flex="1">
              <AlumniCheckbox
                label={t("label.expert")}
                value=""
                onChange={() => onChangeHandler(!details.isExpert, "isExpert")}
                checked={details.isExpert}
                disabled
              />
            </FlexHorizontal>
            {details.isExpert && (
              <FlexHorizontal
                alignCenter
                className="ml-2"
                flex="1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FlexVertical>
                  <AdminMultipleDropdown
                    options={expertiseOptions}
                    flex="1"
                    value={details.experts}
                    onChange={(value: any) => onChangeHandler(value, "experts")}
                    disabled
                    isMultiple
                  />
                </FlexVertical>
                {details.expertOtherValue && (
                  <FlexVertical className="mt-1" style={{ height: "40px" }}>
                    <DefaultInput
                      value={details.expertOtherValue}
                      onChange={(event: any) =>
                        onChangeHandler(event.target.value, "expertOtherValue")
                      }
                      placeholder={t("placeholder.otherExpert")}
                      disabled
                    />
                  </FlexVertical>
                )}
              </FlexHorizontal>
            )}
          </FlexHorizontal>
        </FlexVertical>

        <FlexVertical className="mt-2">
          <SubTitle>{t("label.whereAreYouNow")}</SubTitle>
          <FlexVertical className="mt-1">
            <FlexHorizontal alignCenter justifySpaceBetween className="mb-1">
              <AdminLabel width="150px">{t("label.company")}</AdminLabel>
              <DefaultInput
                value={details.actualCompany}
                placeholder={t("placeholder.company")}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "actualCompany")
                }
                disabled
              />
            </FlexHorizontal>
            <FlexHorizontal alignCenter justifySpaceBetween className="mb-1">
              <AdminLabel width="150px">{t("label.position")}</AdminLabel>
              <DefaultInput
                value={details.actualPosition}
                placeholder={t("placeholder.position")}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "actualPosition")
                }
                disabled
              />
            </FlexHorizontal>
          </FlexVertical>
        </FlexVertical>

        <FlexVertical className="mt-2">
          <AdminLabel className="mb-1">
            {t("label.hearAboutQuestion")}
          </AdminLabel>
          <FlexVertical>
            <AlumniCheckbox
              value="idraStaff"
              key="idraStaff"
              label={t("label.idraStaff")}
              checked={details.hearAbout?.idraStaff}
              onChange={() =>
                onChangeHandler(
                  !details.hearAbout.idraStaff,
                  "hearAbout",
                  "idraStaff"
                )
              }
              disabled
            />
            <AlumniCheckbox
              value="idraWebsite"
              key="idraWebsite"
              label={t("label.idraWebsite")}
              checked={details?.hearAbout?.idraWebsite}
              onChange={() =>
                onChangeHandler(
                  !details.hearAbout.idraWebsite,
                  "hearAbout",
                  "idraWebsite"
                )
              }
              disabled
            />
            <FlexHorizontal alignCenter>
              <AlumniCheckbox
                value=""
                key="socialMedia"
                label={t("label.socialMedia")}
                checked={!!details.hearAbout?.socialMediaPost}
                onChange={() =>
                  onChangeHandler(
                    !details.hearAbout?.socialMediaPost,
                    "hearAbout",
                    "socialMediaPost"
                  )
                }
                disabled
              />
              {details.hearAbout?.socialMediaPost && (
                <AdminDropdown
                  width="200px"
                  className="ml-2"
                  data={DropdownOptions.ALUMNI_NETWORK.social}
                  selectedValue={details.socialMedia}
                  onChange={(value: any) =>
                    onChangeHandler(value, "socialMedia")
                  }
                  disabled
                />
              )}
            </FlexHorizontal>
            <AlumniCheckbox
              value=""
              key="throughEmail"
              label={t("label.throughEmail")}
              checked={!!details.hearAbout?.throughEmail}
              onChange={() =>
                onChangeHandler(
                  !details.hearAbout?.throughEmail,
                  "hearAbout",
                  "throughEmail"
                )
              }
              disabled
            />
          </FlexVertical>
        </FlexVertical>

        <FlexVertical className="mt-2">
          <AdminLabel className="mb-1">
            {t("label.interestsInJoiningQuestion")}
          </AdminLabel>
          <FlexVertical>
            <AlumniCheckbox
              value=""
              key="businessOpportunities"
              label={t("label.businessOpportunities")}
              checked={!!details.interestsInJoining?.businessOpportunities}
              onChange={() =>
                onChangeHandler(
                  !details.interestsInJoining?.businessOpportunities,
                  "interestsInJoining",
                  "businessOpportunities"
                )
              }
              disabled
            />
            <AlumniCheckbox
              value=""
              key="employmentPpportunities"
              label={t("label.employmentPpportunities")}
              checked={!!details.interestsInJoining?.employmentOpportunities}
              onChange={() =>
                onChangeHandler(
                  !details.interestsInJoining?.employmentOpportunities,
                  "interestsInJoining",
                  "employmentOpportunities"
                )
              }
              disabled
            />
            <AlumniCheckbox
              value=""
              key="adviceAndInformation"
              label={t("label.adviceAndInformation")}
              checked={!!details.interestsInJoining?.adviceAndInformation}
              onChange={() =>
                onChangeHandler(
                  !details.interestsInJoining?.adviceAndInformation,
                  "interestsInJoining",
                  "adviceAndInformation"
                )
              }
              disabled
            />
            <AlumniCheckbox
              value=""
              key="toConnectWithFormerColleagues"
              label={t("label.toConnectWithFormerColleagues")}
              checked={
                !!details.interestsInJoining?.toConnectWithFormerColleagues
              }
              onChange={() =>
                onChangeHandler(
                  !details.interestsInJoining?.toConnectWithFormerColleagues,
                  "interestsInJoining",
                  "toConnectWithFormerColleagues"
                )
              }
              disabled
            />
          </FlexVertical>
        </FlexVertical>
      </>
    );
  };

  const _renderButtonGroup = () => {
    return (
      <FlexHorizontal justifySpaceBetween className="mt-3">
        <FlexVertical>
          <GeyserSolidButton
            onClick={() => history.push(ROUTES.ADMIN.ALUMNI_NETWORK)}
            disabled={requestIsLoading}
          >
            {t("button.back")}
          </GeyserSolidButton>
        </FlexVertical>
        {details.status === "open" && (
          <FlexHorizontal justifyFlexEnd>
            <DenyButton
              className="mr-1"
              onClick={handleConfirmDeny}
              disabled={requestIsLoading}
            >
              {t("button.deny")}
            </DenyButton>
            <CarrotSolidButton
              onClick={handleConfirmApprove}
              disabled={requestIsLoading}
            >
              {t("button.approve")}
            </CarrotSolidButton>
          </FlexHorizontal>
        )}
      </FlexHorizontal>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        <ContentForm>
          {_renderFormData()}
          {_renderButtonGroup()}
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.ALUMNI_NETWORK_DETAILS}
      loading={requestIsLoading}
    />
  );
};

export default AlumniNetworkDetail;
