import styled, { keyframes } from "styled-components";
import {
  BlackRegularParagraph,
  FlexHorizontal,
} from "../../../components/Common";
import { ColorName } from "../../../components/Variables";

const LabelInput = styled(BlackRegularParagraph)`
  margin-bottom: 0.5rem;
`;

const InputForm = styled(FlexHorizontal)`
  width: 100%;

  @media screen and (max-width: 836px) {
    > div {
      flex: 1;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & .message-box {
      margin-top: 1.3rem;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 436px) {
    & .message-box {
      margin-top: 1rem;
      margin-left: 0;
    }

    input,
    textarea {
      font-size: 14px;
    }
    input {
      padding: 0 10px;
    }
    textarea {
      padding: 5px 10px;
      height: 150px;
    }
  }
`;

const InputField = styled.div`
  width: 100%;
  border: 1px solid ${ColorName.black};
  display: flex;
  height: 40px;
  span {
    width: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Be Vietnam";
  @media screen and (max-width: 436px) {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const SpinLoader = styled.div`
  border: 5px solid ${ColorName.geyser};
  border-top: 5px solid ${ColorName.darkElectricBlue};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  left: calc(50% - 17px);
  top: 5px;
`;

const slideLeft = keyframes`
  from {
    transform: translateX(15px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const PrevButton = styled(FlexHorizontal)`
  width: max-content;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${ColorName.grannySmith};
  transition: all ease 0.25s;
  transform: translateY(15px);
  opacity: 0;
  animation: ${slideLeft} ease 0.25s forwards;
  animation-delay: 0.75s;

  @media screen and (max-width: 436px) {
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export { LabelInput, InputForm, SpinLoader, PrevButton, InputField, Input };
