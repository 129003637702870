import styled from "styled-components";
import { BlackRegularParagraph } from "../../../components/Common";
interface SectionProps {
  maxLength?: number;
  locale?: string;
  isMobile?: boolean;
}

const Container = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const WrapperInput = styled("div")`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 5px;
  border: 1px solid black;
  text-align: center;
  &:focus-within {
    box-shadow: rgb(232, 120, 68, 0.3) 0px 1px 2px 0px,
      rgb(232, 120, 68, 0.15) 0px 1px 3px 1px;
  }
  min-width: 105px;
  transition: text-align 0.5s ease-in-out;
`;

const LabelInput = styled(BlackRegularParagraph)`
  margin-bottom: 0.5rem;
  width: max-content;
  min-width: ${(props: SectionProps) =>
    props.locale === "en" && !props.isMobile ? "80px" : "50%"};
`;

const CustomInput = styled("input")`
  width: ${(props: SectionProps) =>
    props.maxLength ? `calc(${props.maxLength}ch  + 1px)` : "auto"};
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 23.5px;
  font-weight: 400;
  transition: text-align 0.5s ease-in-out;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export { Container, WrapperInput, CustomInput, LabelInput };
