import styled from 'styled-components';
import { ColorName } from '../../../components/Variables';

interface SectionProps {
  bg?: string;
  area?: string;
}

const GridImageLayoutContainer = styled.div`
  margin: 10px 0;
`;

const GridContent = styled.div`
  display: grid;
`;

const ImageBox = styled.div`
  padding-top: 100%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  grid-area: ${(props: SectionProps) => props.area || ''};
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const MoreLayer = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 4rem;
  color: ${ColorName.white};
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

export { GridImageLayoutContainer, GridContent, ImageBox, MoreLayer };
