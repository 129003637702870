/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const defaultDetails = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  details: defaultDetails,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_ACCOUNT_DETAILS:
      return {
        ...state,
        details: defaultDetails,
      };

    case ActionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        details: defaultDetails,
      };
    default:
      return state;
  }
};
