/* eslint import/no-anonymous-default-export: off */
import ActionTypes from "../constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  isExpand: true,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_EXPAND_SIDEBAR:
      return {
        ...state,
        isExpand: payload,
      };

    default:
      return state;
  }
};
