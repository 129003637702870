/* eslint import/no-anonymous-default-export: off */
import { Icons } from "../../../themes";

export default {
  services: Icons.services.default,
  solutions: Icons.solutions.default,
  clients: Icons.clients.default,
  ourWork: Icons.ourWork.default,
  news: Icons.news.default,
  idraPoll: Icons.idraPoll.default,
};
