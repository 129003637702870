/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../constants";
// import NotFound from '../containers/UserPage/NotFound';
import Homepage from "../containers/UserPage/Homepage";

import WhoWeAre from "../containers/UserPage/WhoWeAre";
import ResearchSolutions from "../containers/UserPage/Solutions/ResearchSolutions";
import ResearchNewsDetails from "../containers/UserPage/NewsDetails/ResearchNewsDetails";
import ResearchNews from "../containers/UserPage/News/ResearchNews";
import NewsDetails from "../containers/UserPage/NewsDetails/GeneralNewsDetails";
import Contact from "../containers/UserPage/Contact";
import Career from "../containers/UserPage/Career";
import CareerDetails from "../containers/UserPage/CareerDetails";
import ResearchWork from "../containers/UserPage/OurWork/ResearchWork";
import ResearchWorkDetails from "../containers/UserPage/OurWorkDetails/ResearchWorkDetails";
import IDRAResearch from "../containers/UserPage/IDRASolutions/IDRAResearch";
import ResearchClients from "../containers/UserPage/Clients/ResearchClients";
import IDRAAdvance from "../containers/UserPage/IDRASolutions/IDRAAdvance";
import IDRADiGIS from "../containers/UserPage/IDRASolutions/IDRADiGIS";
import DevelopmentNews from "../containers/UserPage/News/DevelopmentNews";
import DevelopmentNewsDetails from "../containers/UserPage/NewsDetails/DevelopmentNewsDetails";
import DevelopmentSolutions from "../containers/UserPage/Solutions/DevelopmentSolutions";
import DevelopmentWork from "../containers/UserPage/OurWork/DevelopmentWork";
import DevelopmentWorkDetails from "../containers/UserPage/OurWorkDetails/DevelopmentWorkDetails";
import DevelopmentClients from "../containers/UserPage/Clients/DevelopmentClients";
import News from "../containers/UserPage/News/GeneralNews";
import ResearchServices from "../containers/UserPage/ResearchServices";
import Search from "../containers/UserPage/Search";

import Authentication from "../containers/AdminPage/Authentication";
import Dashboard from "../containers/AdminPage/Dashboard";
import ClientsManagement from "../containers/AdminPage/ClientManagement";
import ClientDetailsManagement from "../containers/AdminPage/ClientDetails";
import CarrerManagement from "../containers/AdminPage/CareerManagement";
import CareerDetailsManagement from "../containers/AdminPage/CareerDetails";
import NewsManagement from "../containers/AdminPage/NewsManagement";
import NewsDetailsManagement from "../containers/AdminPage/NewsDetails";
import OrderNewsManagement from "../containers/AdminPage/OrderNews";
import Profile from "../containers/AdminPage/Profile";
import RecentActivity from "../containers/AdminPage/RecentLogs";
import SiteSettings from "../containers/AdminPage/SiteSettings";
import DraftNews from "../containers/AdminPage/DraftNews";
import MemberManagement from "../containers/AdminPage/MemberManagement";
import MemberDetails from "../containers/AdminPage/MemberDetails";
import ContentEditable from "../containers/AdminPage/ContentEditableDetails";
import {
  AlumniNetworkManagement,
  AlumniNetworkDetail,
} from "../containers/AdminPage/AlumniNetwork";
import AlumniaNetwork from "../containers/UserPage/AlumniNetwork";
import AlumniaNetworkRegister from "../containers/UserPage/AlumniNetworkRegister";
import AreasOfInterest from "../containers/UserPage/AreasOfInterest";
import { NotFound } from "../containers/CommonPage";

// Not found
// import NotFound from '../containers/UserPage/NotFound';

const PortalRoute = () => (
  <Switch>
    <Route exact path={ROUTES.USER.HOMEPAGE}>
      <Homepage />
    </Route>
    {/* User */}
    <Route path={ROUTES.USER.WHO_WE_ARE}>
      <WhoWeAre />
    </Route>
    <Route path={ROUTES.USER.RESEARCH_SOLUTIONS}>
      <ResearchSolutions />
    </Route>
    <Route path={ROUTES.USER.RESEARCH_NEWS_DETAILS}>
      <ResearchNewsDetails />
    </Route>
    <Route exact path={ROUTES.USER.RESEARCH_NEWS}>
      <ResearchNews />
    </Route>
    <Route path={ROUTES.USER.NEWS_DETAILS}>
      <NewsDetails />
    </Route>
    <Route path={ROUTES.USER.CONTACT}>
      <Contact />
    </Route>
    <Route path={ROUTES.USER.CAREER_DETAILS}>
      <CareerDetails />
    </Route>
    <Route exact path={ROUTES.USER.CAREER}>
      <Career />
    </Route>
    <Route path={ROUTES.USER.IDRA_RESEARCH}>
      <IDRAResearch />
    </Route>
    <Route path={ROUTES.USER.RESEARCH_WORK_DETAILS}>
      <ResearchWorkDetails />
    </Route>
    <Route exact path={ROUTES.USER.RESEARCH_WORK}>
      <ResearchWork />
    </Route>
    <Route path={ROUTES.USER.RESEARCH_CLIENTS}>
      <ResearchClients />
    </Route>
    <Route path={ROUTES.USER.RESEARCH_SERVICES}>
      <ResearchServices />
    </Route>
    <Route path={ROUTES.USER.IDRA_ADVANCE}>
      <IDRAAdvance />
    </Route>
    <Route path={ROUTES.USER.DEVELOPMENT_NEWS_DETAILS}>
      <DevelopmentNewsDetails />
    </Route>
    <Route exact path={ROUTES.USER.DEVELOPMENT_NEWS}>
      <DevelopmentNews />
    </Route>
    <Route path={ROUTES.USER.DEVELOPMENT_SOLUTIONS}>
      <DevelopmentSolutions />
    </Route>
    <Route path={ROUTES.USER.DEVELOPMENT_WORK_DETAILS}>
      <DevelopmentWorkDetails />
    </Route>
    <Route exact path={ROUTES.USER.DEVELOPMENT_WORK}>
      <DevelopmentWork />
    </Route>
    <Route path={ROUTES.USER.DEVELOPMENT_CLIENTS}>
      <DevelopmentClients />
    </Route>
    <Route path={ROUTES.USER.IDRA_DIGIS}>
      <IDRADiGIS />
    </Route>
    <Route exact path={ROUTES.USER.NEWS}>
      <News />
    </Route>
    <Route path={ROUTES.USER.SEARCH}>
      <Search />
    </Route>
    <Route path={ROUTES.USER.ALUMNI_NETWORK}>
      <AlumniaNetwork />
    </Route>
    <Route path={ROUTES.USER.ALUMNI_NETWORK_REGISTER}>
      <AlumniaNetworkRegister />
    </Route>
    <Route path={ROUTES.USER.AREAS_OF_INTEREST}>
      <AreasOfInterest />
    </Route>

    {/* Admin */}
    <Route path={ROUTES.ADMIN.DASHBOARD}>
      <Dashboard />
    </Route>
    <Route path={ROUTES.ADMIN.LOGIN}>
      <Authentication />
    </Route>
    <Route path={ROUTES.ADMIN.RECENT_ACTIVITY}>
      <RecentActivity />
    </Route>
    <Route exact path={ROUTES.ADMIN.CAREER}>
      <CarrerManagement />
    </Route>
    <Route path={ROUTES.ADMIN.DRAFT_NEWS}>
      <DraftNews />
    </Route>
    <Route exact path={ROUTES.ADMIN.ARTICLES}>
      <NewsManagement />
    </Route>
    <Route path={ROUTES.ADMIN.ARTICLES_DETAILS}>
      <NewsDetailsManagement />
    </Route>
    <Route path={ROUTES.ADMIN.ORDER_NEWS}>
      <OrderNewsManagement />
    </Route>
    <Route path={ROUTES.ADMIN.CAREER_DETAILS}>
      <CareerDetailsManagement />
    </Route>
    <Route path={ROUTES.ADMIN.SITE_SETTINGS}>
      <SiteSettings />
    </Route>
    <Route path={ROUTES.ADMIN.PROFILE}>
      <Profile />
    </Route>
    <Route exact path={ROUTES.ADMIN.CLIENTS}>
      <ClientsManagement />
    </Route>
    <Route path={ROUTES.ADMIN.CLIENTS_DETAILS}>
      <ClientDetailsManagement />
    </Route>
    <Route exact path={ROUTES.ADMIN.TEAM_MEMBERS}>
      <MemberManagement />
    </Route>
    <Route path={ROUTES.ADMIN.TEAM_MEMBERS_DETAILS}>
      <MemberDetails />
    </Route>
    <Route exact path={ROUTES.ADMIN.CONTENT_EDITABLE}>
      <ContentEditable />
    </Route>
    <Route exact path={ROUTES.ADMIN.ALUMNI_NETWORK}>
      <AlumniNetworkManagement />
    </Route>
    <Route path={ROUTES.ADMIN.ALUMNI_NETWORK_DETAILS}>
      <AlumniNetworkDetail />
    </Route>
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
  // <Routes>
  //   <Route exact path={ROUTES.USER.HOMEPAGE} component={Homepage} />
  //   {/* User */}
  //   <Route path={ROUTES.USER.WHO_WE_ARE} component={WhoWeAre} />
  //   <Route
  //     path={ROUTES.USER.RESEARCH_SOLUTIONS}
  //     component={ResearchSolutions}
  //   />
  //   <Route
  //     path={ROUTES.USER.RESEARCH_NEWS_DETAILS}
  //     component={ResearchNewsDetails}
  //   />
  //   <Route path={ROUTES.USER.RESEARCH_NEWS} component={ResearchNews} />
  //   <Route path={ROUTES.USER.NEWS_DETAILS} component={NewsDetails} />
  //   <Route path={ROUTES.USER.CONTACT} component={Contact} />
  //   <Route path={ROUTES.USER.CAREER_DETAILS} component={CareerDetails} />
  //   <Route path={ROUTES.USER.CAREER} component={Career} />
  //   <Route path={ROUTES.USER.IDRA_RESEARCH} component={IDRAResearch} />
  //   <Route
  //     path={ROUTES.USER.RESEARCH_WORK_DETAILS}
  //     component={ResearchWorkDetails}
  //   />
  //   <Route path={ROUTES.USER.RESEARCH_WORK} component={ResearchWork} />
  //   <Route path={ROUTES.USER.RESEARCH_CLIENTS} component={ResearchClients} />
  //   <Route path={ROUTES.USER.RESEARCH_SERVICES} component={ResearchServices} />
  //   <Route path={ROUTES.USER.IDRA_ADVANCE} component={IDRAAdvance} />
  //   <Route
  //     path={ROUTES.USER.DEVELOPMENT_NEWS_DETAILS}
  //     component={DevelopmentNewsDetails}
  //   />
  //   <Route path={ROUTES.USER.DEVELOPMENT_NEWS} component={DevelopmentNews} />
  //   <Route
  //     path={ROUTES.USER.DEVELOPMENT_SOLUTIONS}
  //     component={DevelopmentSolutions}
  //   />
  //   <Route
  //     path={ROUTES.USER.DEVELOPMENT_WORK_DETAILS}
  //     component={DevelopmentWorkDetails}
  //   />
  //   <Route path={ROUTES.USER.DEVELOPMENT_WORK} component={DevelopmentWork} />
  //   <Route
  //     path={ROUTES.USER.DEVELOPMENT_CLIENTS}
  //     component={DevelopmentClients}
  //   />
  //   <Route path={ROUTES.USER.IDRA_DIGIS} component={IDRADiGIS} />
  //   <Route path={ROUTES.USER.NEWS} component={News} />
  //   <Route path={ROUTES.USER.SEARCH} component={Search} />
  //   <Route path={ROUTES.USER.ALUMNI_NETWORK} component={AlumniaNetwork} />
  //   <Route
  //     path={ROUTES.USER.ALUMNI_NETWORK_REGISTER}
  //     component={AlumniaNetworkRegister}
  //   />

  //   {/* Admin */}
  //   <Route path={ROUTES.ADMIN.DASHBOARD} component={Dashboard} />
  //   <Route path={ROUTES.ADMIN.LOGIN} component={Authentication} />
  //   <Route path={ROUTES.ADMIN.RECENT_ACTIVITY} component={RecentActivity} />
  //   <Route path={ROUTES.ADMIN.CAREER} component={CarrerManagement} />
  //   <Route path={ROUTES.ADMIN.DRAFT_NEWS} component={DraftNews} />
  //   <Route path={ROUTES.ADMIN.ARTICLES} component={NewsManagement} />
  //   <Route
  //     path={ROUTES.ADMIN.ARTICLES_DETAILS}
  //     component={NewsDetailsManagement}
  //   />
  //   <Route path={ROUTES.ADMIN.ORDER_NEWS} component={OrderNewsManagement} />
  //   <Route
  //     path={ROUTES.ADMIN.CAREER_DETAILS}
  //     component={CareerDetailsManagement}
  //   />
  //   <Route path={ROUTES.ADMIN.SITE_SETTINGS} component={SiteSettings} />
  //   <Route path={ROUTES.ADMIN.PROFILE} component={Profile} />
  //   <Route path={ROUTES.ADMIN.CLIENTS} component={ClientsManagement} />
  //   <Route
  //     path={ROUTES.ADMIN.CLIENTS_DETAILS}
  //     component={ClientDetailsManagement}
  //   />
  //   <Route path={ROUTES.ADMIN.TEAM_MEMBERS} component={MemberManagement} />
  //   <Route path={ROUTES.ADMIN.TEAM_MEMBERS_DETAILS} component={MemberDetails} />
  //   <Route path={ROUTES.ADMIN.CONTENT_EDITABLE} component={ContentEditable} />
  //   <Route
  //     path={ROUTES.ADMIN.ALUMNI_NETWORK}
  //     component={AlumniaNetworkManagement}
  //   />
  //   <Route
  //     path={ROUTES.ADMIN.ALUMNI_NETWORK_DETAILS}
  //     component={AlumniNetworkDetail}
  //   />
  //   <Route path="/*" component={NotFound} />
  // </Routes>
);

export default PortalRoute;
