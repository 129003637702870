import styled from 'styled-components';
import { HorizontalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const TabsBarContainer = styled(HorizontalContainer)`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
`;

const TabsBarItem = styled(HorizontalContainer)`
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${ColorName.darkElectricBlue};
  background: ${ColorName.geyser};
  align-items: center;
  justify-content: center;
  flex: 1;
  box-sizing: border-box;
  /* transition: all ease 0.25s; */

  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
  &.active {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

export { TabsBarContainer, TabsBarItem };
