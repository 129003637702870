import React, { useState, useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";
// import DatePicker from "react-datepicker";

import ReactDatePicker, { DatePickerProps } from "react-date-picker";

import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-date-picker/dist/DatePicker.css";

import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useSelector } from "react-redux";

interface SectionProps {
  value: string;
  onChange(value: string): unknown;
  className?: string;
  disabled?: boolean;
  isMaxDate?: string;
  isMinDate?: string;
}

interface ContainerProps {
  isFocus: boolean;
}

const Container = styled.div`
  width: 200px;
  cursor: pointer;
  border-radius: 5px;
  .react-date-picker {
    width: 200px;
    height: 40px;
    .react-date-picker__wrapper {
      border-radius: 5px;
      background: #ecf5f5;
      padding: 0 6px 0 13px;
      border: ${(props: ContainerProps) => {
        return props.isFocus ? "1px solid gray" : "none";
      }};
      .react-date-picker__inputGroup input {
        outline: none;
      }
      .react-date-picker__inputGroup__input {
        background: inherit;
      }
      .react-date-picker__button:not([disabled]) {
        color: #4e6c7a;
      }
      .react-date-picker__button:has([disabled]) {
        opacity: 0.5;
      }
    }

    .react-calendar__tile--active {
      background: #e96f26;
    }
  }
`;

const InputCalendar: React.FC<SectionProps> = (props: SectionProps) => {
  const { value, onChange, disabled, isMaxDate, isMinDate } = props;
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));

  const [key, setKey] = useState(Math.random());
  const [isFocus, setIsFocus] = useState(false);

  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsFocus(false));

  const onChangeHandler = (e: any) => {
    const newSelectedDate = e ? new Date(e).toDateString() : null;
    if (newSelectedDate && dayjs(newSelectedDate).isValid()) {
      if (onChange) onChange(newSelectedDate);
    }
  };

  // const CustomInput = React.forwardRef(
  //   ({ value, onClick, readOnly }: any, ref: any) => (
  //     <input
  //       className="custom-input"
  //       onClick={onClick}
  //       ref={ref}
  //       value={value}
  //       readOnly={readOnly}
  //       placeholder="dd/mm/yyyy"
  //     />
  //   )
  // );

  const config: DatePickerProps = {
    format: "dd/MM/yyyy",
    onCalendarOpen: () => {
      setIsFocus(true);
    },
    onCalendarClose: () => {
      setKey(Math.random());
      setIsFocus(false);
    },
    locale: lang === "en" ? "en" : "sq",
    value,
    onChange: (value) => onChangeHandler(value as any),
    clearIcon: false,
  };

  return (
    <Container ref={containerRef} isFocus={isFocus}>
      <ReactDatePicker
        key={key}
        isOpen={isFocus}
        {...config}
        minDate={isMinDate ? new Date(isMinDate) : undefined}
        maxDate={isMaxDate ? new Date(isMaxDate) : undefined}
        disabled={disabled}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        autoFocus={false}
        calendarIcon={
          <i
            className="fas fa-calendar-alt"
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
            }}
          />
        }
      />
    </Container>
  );
};

export default InputCalendar;
