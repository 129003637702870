/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import {
  SearchContainer,
  SearchContent,
  FilterBar,
  LoadingLayer,
  SVGImage,
  SearchInput,
} from "./Search.styles";
import { ROUTES } from "../../../constants";
import { SearchActions } from "../../../actions";
import {
  FlexHorizontal,
  FlexVertical,
  NewsCard,
  WorkCard,
} from "../../../components/Common";
import history from "../../../history";
import { CONSTANTS } from "../../../constants/Constants";
import { Icons, Images } from "../../../themes";
import { useTranslation } from "react-i18next";

const {
  searchArticles,
  searchOurWork,
  setSearchFilter,
  clearSearchPayload,
  setSearchPayload,
} = SearchActions;

const FILTERS = [
  {
    label: "news",
    value: "articles",
  },
  {
    label: "ourWork",
    value: "ourWork",
  },
];

const Search = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  // const locale = useSelector(state => _.get(state, 'PageSettings.userLang'));
  const filter = useSelector((state) => _.get(state, "Search.filter"));
  const requestIsLoading = useSelector((state) =>
    _.get(state, "Search.isLoading")
  );
  const payload = useSelector((state) => _.get(state, "Search.payload"));
  // Declare location
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const { type, keyword } = queryStringValue;
    if (_.isString(type) && _.isString(keyword) && _.trim(keyword)) {
      dispatch(setSearchFilter({ type, keyword: keyword ? keyword : "" }));
      if (type === "articles")
        dispatch(searchArticles({ type, keyword: _.trim(keyword) }));
      else dispatch(searchOurWork({ type, keyword: _.trim(keyword) }));
    }
  }, []);

  useEffect(() => {
    if (requestIsLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [requestIsLoading]);

  // Handle change type of sub heading
  const handleChangeType = async (type: string) => {
    const newFilter = {
      ...filter,
      type,
    };
    await setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
    await dispatch(setSearchPayload([]));
    await history.push(
      `${ROUTES.USER.SEARCH}?type=${_.get(newFilter, "type")}&keyword=${_.get(
        newFilter,
        "keyword"
      )}`
    );
    await dispatch(setSearchFilter(newFilter));
    const keyword = _.get(newFilter, "keyword");
    const trimKeyword = _.trim(keyword);
    if (trimKeyword) {
      if (type === "articles") await dispatch(searchArticles(newFilter));
      else dispatch(searchOurWork({ keyword: _.trim(keyword) }));
    } else dispatch(clearSearchPayload());
  };

  const onKeydownInput = async (e: any) => {
    const keyword = _.get(filter, "keyword");
    const type = _.get(filter, "type");
    if (e.keyCode === 13 && _.trim(keyword)) {
      await history.push(
        `${ROUTES.USER.SEARCH}?type=${type}&keyword=${_.trim(keyword)}`
      );
      if (type === "articles")
        dispatch(searchArticles({ type, keyword: _.trim(keyword) }));
      else dispatch(searchOurWork({ keyword: _.trim(keyword) }));
    }
  };

  // Render sub heading of Search
  const renderFilterBar = () => {
    return (
      <FilterBar>
        {_.map(FILTERS, (item, index) => {
          const currentFilter = _.get(filter, "type");
          const activeClass =
            currentFilter === _.get(item, "value") ? "active" : "";
          return (
            <div
              className={activeClass}
              key={`filter-${index}`}
              onClick={() => handleChangeType(_.get(item, "value"))}
            >
              {t(`label.${_.get(item, "label")}`)}
            </div>
          );
        })}
      </FilterBar>
    );
  };

  // Render skeleton loading
  const renderLoading = (type: string) => {
    const layerLoading = [0, 1, 2, 3, 4];
    return (
      <FlexVertical>
        {_.map(layerLoading, (item) => {
          return (
            <LoadingLayer key={`layer-loading-${item}`}>
              {type === CONSTANTS.SEARCH_TYPE.ARTICLES && (
                <FlexHorizontal width="unset">
                  <div className="image" />
                </FlexHorizontal>
              )}
              <FlexVertical flex="1">
                {type === CONSTANTS.SEARCH_TYPE.CAREER && (
                  <div className="time" />
                )}
                <div className="title" />
                {type === CONSTANTS.SEARCH_TYPE.ARTICLES && (
                  <div className="time" />
                )}
                <div className="description" />
                <div className="button" />
              </FlexVertical>
            </LoadingLayer>
          );
        })}
      </FlexVertical>
    );
  };

  // Render articles list
  const renderArticlesContent = () => {
    const render = _.map(payload, (item, index) => {
      return (
        <NewsCard
          key={`news-${index}`}
          data={item}
          onClick={(location: string) => {
            document.documentElement.style.scrollBehavior = "unset";
            history.push({
              pathname: `${ROUTES.USER.NEWS}/${location}`,
              state: { from: ROUTES.USER.SEARCH, query: "" },
            });
          }}
          pathname={ROUTES.USER.SEARCH}
          searchQuery=""
        />
      );
    });
    return <FlexVertical>{render}</FlexVertical>;
  };

  const onViewOurWork = (item: any) => {
    const id = _.get(item, "id");
    const developmentTopic = [
      "Economic Development",
      "Agriculture & Environment",
      "Governance & Rule of Law",
      "Monitoring & Evaluation",
      "Social Development",
      "Urban Planning & GIS",
    ];
    const solutions = _.get(item, "solutions");
    let page = "research-work";
    _.forEach(solutions, (solution) => {
      const title = _.get(solution, "title");
      if (_.includes(developmentTopic, title)) page = "development-work";
    });
    const query = `?type=${_.get(filter, "type")}&keyword=${_.get(
      filter,
      "keyword"
    )}`;
    history.push({
      pathname: `${page}/${id}`,
      state: { from: ROUTES.USER.SEARCH, query },
    });
  };

  // Render job list
  const renderOurWork = () => {
    const render = _.map(payload, (item, index) => {
      return (
        <WorkCard
          key={`our-work-${index}`}
          data={item}
          onClick={() => onViewOurWork(item)}
          searchQuery={location.search}
          pathname={ROUTES.USER.RESEARCH_WORK}
        />
      );
    });
    return render;
  };

  // Render content
  const renderContent = () => {
    const type = _.get(filter, "type");
    // Handle loading to render skeleton
    if (requestIsLoading || isLoading) return renderLoading(type);

    // Handle empty data to return illustrators
    if (_.isEmpty(payload))
      return (
        <SVGImage
          src={Images.noResultFound.default}
          alt="no result found illustrators"
        />
      );

    // Handle type to render content
    const isArticles = type === CONSTANTS.SEARCH_TYPE.ARTICLES;
    if (isArticles) return renderArticlesContent();
    return renderOurWork();
  };

  const renderMain = () => {
    return (
      <SearchContainer className="pb-4">
        <SearchContent>
          <SearchInput>
            <div className="content">
              <span
                className="search-icon"
                onClick={() => inputRef && inputRef.current?.focus()}
              >
                <img src={Icons.search.default} alt="" />
              </span>
              <input
                ref={inputRef}
                type="text"
                placeholder={t("placeholder.typeKeywordToSearch")}
                value={_.get(filter, "keyword")}
                onChange={(e: any) => {
                  dispatch(
                    setSearchFilter({ ...filter, keyword: e.target.value })
                  );
                }}
                onKeyDown={(e: any) => onKeydownInput(e)}
              />
              {_.trim(_.get(filter, "keyword")).length > 0 && (
                <span
                  className="remove-icon"
                  onClick={() =>
                    dispatch(setSearchFilter({ ...filter, keyword: "" }))
                  }
                >
                  <i className="fas fa-times" />
                </span>
              )}
            </div>
          </SearchInput>
          {renderFilterBar()}
          {renderContent()}
        </SearchContent>
      </SearchContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.SEARCH}
      headerTitle="Search | IDRA"
    />
  );
};

export default Search;
