import React from 'react';
import { RadioContainer, RadioCircleBorder, RadioCircleSolid, RadioLabel } from './Radio.styles';

interface SectionProps {
  isLeftLabel?: boolean;
  label: string;
  value: string;
  onClick(value: string): unknown;
  flex?: string;
  className?: string;
  defaultSelected?: string;
}

const Radio = (props: SectionProps) => {
  const { isLeftLabel, label, value, onClick, flex, className } = props;
  return (
    <RadioContainer onClick={() => onClick && onClick(value)} className={className} flex={flex}>
      {isLeftLabel && <RadioLabel isLeftLabel={isLeftLabel}>{label}</RadioLabel>}
      <RadioCircleBorder>
        <RadioCircleSolid />
      </RadioCircleBorder>
      {!isLeftLabel && <RadioLabel isLeftLabel={isLeftLabel}>{label}</RadioLabel>}
    </RadioContainer>
  );
};

export default Radio;
