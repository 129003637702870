/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import { statusAlert } from '../libs';

// SINGLE ACTIONS
const setAccountDetails = (payload?: any) => {
  return {
    type: ActionTypes.SET_ACCOUNT_DETAILS,
    payload,
  };
};

const clearAccountDetails = () => {
  return {
    type: ActionTypes.CLEAR_ACCOUNT_DETAILS,
  };
};

// ASYNC ACTIONS
const changePasswordFailure = () => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_FAILURE,
  };
};

const changPasswordRequest = () => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_REQUEST,
  };
};

const changePasswordSuccess = () => {
  return {
    type: ActionTypes.UPDATE_PASSWORD_SUCCESS,
  };
};

const changePassword = (data: any) => {
  return async (dispatch: any) => {
    dispatch(changPasswordRequest());
    await API.changePassword(data)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(changePasswordSuccess());
          statusAlert('success', message);
        } else {
          dispatch(changePasswordFailure());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(changePasswordFailure()));
  };
};

export default { setAccountDetails, changePassword, clearAccountDetails };
