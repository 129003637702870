/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  ShowMoreContentContainer,
  SolutionCategory,
  SolutionSubCategory,
  SolutionContent,
  ShowButton,
  GifImage,
  HeadingWrapper,
} from "./ShowMoreContent.styles";
import { Content } from "../CommonLayout";
import { Icons } from "../../../themes";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import sanitizeHtml from "sanitize-html";

interface DataStructure {
  title: {
    en: string;
    al: string;
  };
  subtitle: {
    en: string;
    al: string;
  };
  description: {
    en: string;
    al: string;
  };
  details: {
    en: string;
    al: string;
  };
}

const animationContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const animationItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

interface SectionProps {
  data: DataStructure;
  onChange?(value: string): unknown;
  type?: string;
  className?: string;
  active?: string;
}

const ShowMoreContent: React.FC<SectionProps> = (props: SectionProps) => {
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));

  const { onChange, data, type, className, active } = props;
  const [maxHeight, setMaxHeight] = useState<string>("0px");
  const [showIndex, setShowIndex] = useState<number | null>(null);
  // Parse string to HTML

  const handleShowContent = (key: string, id: string, index: number) => {
    setShowIndex(index);
    if (onChange) onChange(key);
    const el = document.getElementById(id);
    const serviceDescription = document.querySelector(
      `.services-description-${index}`
    );
    const serviceDetails = document.querySelector(`.services-details-${index}`);

    if (serviceDescription || serviceDetails) {
      let resolveMaxHeight = 0;
      if (serviceDescription)
        resolveMaxHeight = resolveMaxHeight + serviceDescription.clientHeight;
      if (serviceDetails)
        resolveMaxHeight = resolveMaxHeight + serviceDetails.clientHeight;
      setMaxHeight(`${resolveMaxHeight + 30}px`);
    }

    if (el)
      setTimeout(() => {
        const topPosition = el.offsetTop;
        window.scrollTo({ top: topPosition - 132 });
      }, 550);
  };

  const resizeWindow = () => {
    if (_.isNumber(showIndex)) {
      const id = `showmore-content-${showIndex}`;
      const el = document.getElementById(id);
      const serviceDescription = document.querySelector(
        `.services-description-${showIndex}`
      );
      const serviceDetails = document.querySelector(
        `.services-details-${showIndex}`
      );

      if (serviceDescription || serviceDetails) {
        let resolveMaxHeight = 0;
        if (serviceDescription)
          resolveMaxHeight = resolveMaxHeight + serviceDescription.clientHeight;
        if (serviceDetails)
          resolveMaxHeight = resolveMaxHeight + serviceDetails.clientHeight;
        setMaxHeight(`${resolveMaxHeight + 30}px`);
      }
      if (el)
        setTimeout(() => {
          const topPosition = el.offsetTop;
          window.scrollTo({ top: topPosition - 132 });
        }, 550);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    window.addEventListener("load", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
      window.removeEventListener("load", resizeWindow);
    };
  }, [showIndex]);

  return (
    <ShowMoreContentContainer className={`${className} pb-4`}>
      <motion.div
        variants={animationContainer}
        initial="hidden"
        animate="visible"
      >
        {_.isArray(data) &&
          _.map(data, (item, index) => {
            const { title, subtitle, description, details, isDescription } =
              item;
            const currentIdx = _.toNumber(index);
            const key = `${type}-${index + 1}`;
            const showClass = active === key ? "show" : "";

            const paddingClass = title ? "15px 0" : "30px 0";
            if (isDescription)
              return (
                <motion.div key={`${key}-${index}`} variants={animationItem}>
                  <Content direction="column" key={key}>
                    <p
                      className="services-description"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(_.get(description, lang)),
                      }}
                    />
                  </Content>
                </motion.div>
              );

            return (
              <motion.div key={`${key}-${index}`} variants={animationItem}>
                <Content
                  direction="column"
                  key={key}
                  id={`showmore-content-${currentIdx}`}
                >
                  <HeadingWrapper
                    className="solutions-content"
                    style={{ padding: paddingClass }}
                  >
                    <SolutionCategory>{_.get(title, lang)}</SolutionCategory>
                    <SolutionSubCategory>
                      {_.get(subtitle, lang)}
                    </SolutionSubCategory>
                    <ShowButton
                      onClick={() =>
                        handleShowContent(
                          key,
                          `showmore-content-${currentIdx}`,
                          index
                        )
                      }
                      className={showClass}
                    >
                      <img
                        src={Icons.showButton.default}
                        alt="hide-show-icon"
                      />
                    </ShowButton>
                  </HeadingWrapper>

                  <SolutionContent
                    className={showClass}
                    style={{ maxHeight: showClass ? maxHeight : "0px" }}
                  >
                    <p
                      className={`services-description services-description-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(_.get(description, lang)),
                      }}
                    />
                    {!_.isEmpty(_.get(details, lang)) && (
                      <div className="flex-row mt-3">
                        <GifImage>
                          <img
                            src={Icons.doubleRight.default}
                            alt="double right gif"
                          />
                        </GifImage>
                        <div className="flow-column">
                          <p
                            className={`services-description services-details-${index}`}
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(_.get(details, lang)),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </SolutionContent>
                </Content>
              </motion.div>
            );
          })}
      </motion.div>
    </ShowMoreContentContainer>
  );
};

export default ShowMoreContent;
