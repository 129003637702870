import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import languageEN from './locale/translate.en.json';
import languageAL from './locale/translate.al.json';
import Utils from './libs/Utils';

const resources = {
  en: languageEN,
  al: languageAL,
};

i18next
  .use(Backend)
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: Utils.getCurrentLocale() || 'en',
    fallbackLng: 'en',
    debug: true,
    ns: ['languageEN', 'languageAL'],
    defaultNS: ['languageEN'],
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      useSuspense: false,
      // useSuspense: true,
      // wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18next;
