import Service from './Base';
import API from '../constants/API';

const { SITE_SETTINGS } = API;

export function getSiteSettings() {
  return Service.get(SITE_SETTINGS.BASIC);
}

export function editSiteSettings(data: any) {
  return Service.putMany(SITE_SETTINGS.BASIC, data);
}

export function deleteSiteSetting(id: string) {
  return Service.del(`${SITE_SETTINGS.BASIC}/${id}`);
}
