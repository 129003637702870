import React from 'react';
import { UserDatePickerContainer } from './UserDatePicker.styles';
import DatePicker from 'react-datepicker';

interface ISectionProps {
  value: string;
  onChange(date: string): void;
}

const UserDatePicker: React.FC<ISectionProps> = ({ value, onChange }) => {
  const datePickerRef = React.useRef<DatePicker>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(null);

  React.useEffect(() => {
    if (value) {
      const newDate = new Date(`${value}`);
      if (newDate instanceof Date) setStartDate(newDate);
    } else setStartDate(null);
  }, [value]);

  const onFocusDatePicker = () => {
    if (datePickerRef && datePickerRef.current) datePickerRef.current.setFocus();
  };

  const onChangeDate = (date: Date) => {
    const resolveDate = date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
    onChange(resolveDate);
  };

  return (
    <UserDatePickerContainer>
      <DatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={(date: Date) => onChangeDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText='dd/mm/yyyy'
      />
      <i className="fas fa-calendar-alt" onClick={() => onFocusDatePicker()}></i>
    </UserDatePickerContainer>
  );
};

export default UserDatePicker;
