/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';

// SINGLE ACTIONS
const setPayloadCount = (payload: any) => {
  return {
    type: ActionTypes.SET_PAYLOAD_COUNT,
    payload,
  };
};

const setPayloadLogs = (payload: any) => {
  return {
    type: ActionTypes.SET_PAYLOAD_LOGS,
    payload,
  };
};

const clearPayloadLogs = (payload: any) => {
  return {
    type: ActionTypes.CLEAR_PAYLOAD_LOGS,
    payload,
  };
};

const setLogsMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_LOGS_META,
    payload,
  };
};

const setLogsPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_LOGS_PAGINATION,
    payload,
  };
};

const setDefaultLogsMetaPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_DEFAULT_LOGS_META_PAGINATION,
    payload,
  };
};

// ASYNC ACTIONS
const getCountDashboardError = () => {
  return {
    type: ActionTypes.GET_COUNT_DASHBOARD_FAILURE,
  };
};

const getCountDashboardIsLoading = () => {
  return {
    type: ActionTypes.GET_COUNT_DASHBOARD_REQUEST,
  };
};

const getCountDashboardIsSuccess = () => {
  return {
    type: ActionTypes.GET_COUNT_DASHBOARD_SUCCESS,
  };
};

const getCountDashboard = () => {
  return async (dispatch: any) => {
    dispatch(getCountDashboardIsLoading());
    await API.getCountDashboard()
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          dispatch(setPayloadCount(payload))
          dispatch(getCountDashboardIsSuccess());
        }
        else dispatch(getCountDashboardError());
      })
      .catch(() => dispatch(getCountDashboardError()));
  };
};

const getLogsActivityError = () => {
  return {
    type: ActionTypes.GET_LOGS_ACTIVITY_FAILURE,
  };
};

const getLogsActivityIsLoading = () => {
  return {
    type: ActionTypes.GET_LOGS_ACTIVITY_REQUEST,
  };
};

const getLogsActivityIsSuccess = () => {
  return {
    type: ActionTypes.GET_LOGS_ACTIVITY_SUCCESS,
  };
};

const getLogsActivity = (pagination: any) => {
  return async (dispatch: any) => {
    dispatch(getLogsActivityIsLoading());
    await API.getLogsActivity(pagination)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          const { items, meta } = payload;
          dispatch(setLogsMeta(meta));
          dispatch(setPayloadLogs(items));
          dispatch(getLogsActivityIsSuccess());
        } else dispatch(getLogsActivityError());
      })
      .catch(() => dispatch(getLogsActivityError()));
  };
};

const singleActions = {
  setPayloadCount,
  setPayloadLogs,
  clearPayloadLogs,
  setLogsMeta,
  setLogsPagination,
  setDefaultLogsMetaPagination,
};

const asyncActions = {
  getCountDashboard,
  getLogsActivity,
};

export default { ...singleActions, ...asyncActions };
