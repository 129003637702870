import styled from "styled-components";
import {
  VerticalContainer,
  HorizontalContainer,
} from "../../../components/Common";
import { ColorName } from "../../../components/Variables";

interface SectionProps {
  background?: string;
  flex?: string;
  maxWidth?: string;
}

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  & .illustrator-login {
    width: 100%;
    max-width: 500px;
  }

  & .illustrator-col {
    @media screen and (max-width: 836px) {
      display: none;
    }
  }
`;

const ContentCol = styled(VerticalContainer)`
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 50vw;
  height: 100%;
  background: ${(props: SectionProps) =>
    props.background || ColorName.aquaHaze};
  box-sizing: border-box;
  padding: 40px 80px;

  & .medium-logo {
    display: none;
  }

  @media screen and (max-width: 836px) {
    max-width: 100vw;

    & .medium-logo {
      display: flex;
      width: 200px;
      margin-bottom: 100px;
    }
  }
`;

const InputField = styled(HorizontalContainer)`
  width: 100%;
  height: 45px;
  padding: 18px 21px;
  background: ${ColorName.aquaHaze};
  border-radius: 2px;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid transparent;

  &.focus {
    border: 1px solid ${ColorName.darkElectricBlue};
    img {
      opacity: 1;
    }
  }
  img {
    width: 20px;
    object-fit: contain;
    opacity: 0.7;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    color: ${ColorName.darkElectricBlue};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    &::placeholder {
      color: ${ColorName.darkElectricBlue};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      opacity: 0.6;
    }
  }
  & .pwd_icon {
    position: relative;
    width: 22px;
    height: 22px;

    i {
      font-size: 14px;
      position: absolute;
      top: 6px;
      left: 10px;
      color: ${ColorName.darkElectricBlue};
      transition: all ease 0.25s;

      &.fa-eye {
        left: 11px;
      }
    }

    &:hover {
      cursor: pointer;
    }
    &.hide {
      & .fa-eye {
        opacity: 0;
      }
    }
    &.show {
      & .fa-eye-slash {
        opacity: 0;
      }
    }
  }
`;

const LoginForm = styled(VerticalContainer)`
  width: 370px;
  h3 {
    font-size: 26px;
    line-height: 38px;
    font-weight: 700;
    color: ${ColorName.darkElectricBlue};
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${ColorName.darkElectricBlue};
  &:hover {
    cursor: pointer;
  }
`;

const CheckboxSquare = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 5px;

  & .tick-icon {
    position: absolute;
    width: 9px;
    height: 9px;
    top: 3px;
    left: 3px;
    opacity: 0;
    transition: all ease 0.25s;

    &.checked {
      opacity: 1;
    }
  }
  & .square-icon {
    position: absolute;
    width: 9px;
    height: 9px;
    top: 3px;
    left: 3px;
    opacity: 0;
    transition: all ease 0.25s;
  }

  &:hover {
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    ${CheckboxSquare} {
      & .square-icon {
        opacity: 0.8;
      }
      & .tick-icon {
        opacity: 0;
      }
    }
  }
`;

const LoginContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  display: flex;

  &::before,
  &:after {
    position: absolute;
    content: "";
    width: 100vw;
    height: 100vh;
    top: 0;
  }

  &:before {
    right: 100%;
    background: ${ColorName.aquaHaze};
  }

  &:after {
    left: 100%;
    background: white;
  }
`;

export {
  LoginContainer,
  ContentCol,
  InputField,
  LoginForm,
  CheckboxSquare,
  CheckboxContainer,
  Label,
  LoginContent,
};
