/* eslint import/no-anonymous-default-export: off */
import _ from 'lodash';
import ActionTypes from '../constants/ActionTypes';
import API from '../api';

// SINGLE ACTIONS
const setOurWorkMetaPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_OUR_WORK_META_PAGINATION,
    payload,
  };
};

const setDefaultOurWorkMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_OUR_WORK_META_PAGINATION,
  };
};

const setOurWorkCountry = (payload: any) => {
  return {
    type: ActionTypes.SET_OUR_WORK_COUNTRY,
    payload,
  };
};

const setOurWorkDetails = (payload: any) => {
  return {
    type: ActionTypes.SET_OUR_WORK_DETAILS,
    payload,
  };
};

const clearOurWorkDetails = () => {
  return {
    type: ActionTypes.CLEAR_OUR_WORK_DETAILS,
  };
};

const setOurWorkFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_OUR_WORK_FILTER,
    payload,
  };
};

const clearOurWorkFilter = () => {
  return {
    type: ActionTypes.CLEAR_OUR_WORK_FILTER,
  };
};

const setOurWorkPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_OUR_WORK_PAYLOAD,
    payload,
  };
};

const clearOurWorkPayload = () => {
  return {
    type: ActionTypes.CLEAR_OUR_WORK_PAYLOAD,
  };
};

// ASYNC FUNCTION
const getProjectByIdRequests = () => {
  return {
    type: ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_REQUEST,
  };
};

const getProjectByIdFail = () => {
  return {
    type: ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_FAILURE,
  };
};

const getProjectByIdSuccess = () => {
  return {
    type: ActionTypes.GET_OUR_WORK_PROJECT_BY_ID_SUCCESS,
  };
};

const getProjectById = (id: string, page: string) => {
  return async (dispatch: any) => {
    dispatch(getProjectByIdRequests());
    await API.getPEA()
      .then(async (response: any) => {
        const { data } = response;
        const details = _.find(data, { id: _.toNumber(id) });
        if (details) {
          dispatch(setOurWorkDetails(details));
          dispatch(getProjectByIdSuccess());
        } else dispatch(getProjectByIdFail());
      })
      .catch(async (error: any) => await dispatch(getProjectByIdFail()));
  };
};

const getAllWorkProjectRequests = () => {
  return {
    type: ActionTypes.GET_ALL_OUR_WORK_PROJECT_REQUEST,
  };
};

const getAllWorkProjectFail = () => {
  return {
    type: ActionTypes.GET_ALL_OUR_WORK_PROJECT_FAILURE,
  };
};

const getAllWorkProjectSuccess = () => {
  return {
    type: ActionTypes.GET_ALL_OUR_WORK_PROJECT_SUCCESS,
  };
};

const getAllWorkProject = (params: any) => {
  const { filter, meta } = params;
  return async (dispatch: any) => {
    dispatch(getAllWorkProjectRequests());
    await API.getPEA(filter)
      .then(async (response: any) => {
        const payload = _.values(response.data);
        const country: any[] = [];
        _.map(payload, item => {
          const getCountry = _.get(item, 'country');
          const findCountry = _.find(country, { value: getCountry });
          if (!findCountry && getCountry) country.push({ label: getCountry, value: getCountry });
        });
        const filterByCountry: any[] = [];
        if (_.get(filter, 'country'))
          _.map(payload, item => {
            const itemCountry = _.get(item, 'country');
            if (itemCountry === _.get(filter, 'country')) filterByCountry.push(item);
          });
        else filterByCountry.push(...payload);
        filterByCountry.sort((a, b) => {
          const dateOfA = new Date(_.get(a, 'start_date'));
          const dateOfB = new Date(_.get(b, 'start_date'));
          if (dateOfA.getMonth() === dateOfB.getMonth()) return _.get(b, 'title') - _.get(a, 'title');
          return dateOfB.valueOf() - dateOfA.valueOf();
        });
        const chunkPayload = _.chunk(filterByCountry, 5);
        const totalPages = _.ceil(filterByCountry.length / 5);
        const currentPage: number = _.get(meta, 'currentPage');
        const resolvePayload = chunkPayload[currentPage - 1];
        await dispatch(setOurWorkMetaPagination({ currentPage, totalPages }));
        await dispatch(setOurWorkCountry(_.sortBy(country, ['label'])));
        await dispatch(setOurWorkPayload(resolvePayload));
        dispatch(getAllWorkProjectSuccess());
      })
      .catch(async (error: any) => dispatch(getAllWorkProjectFail()));
  };
};

const singleActions = {
  setOurWorkMetaPagination,
  setDefaultOurWorkMetaPagination,
  setOurWorkCountry,
  setOurWorkDetails,
  clearOurWorkDetails,
  setOurWorkFilter,
  clearOurWorkFilter,
  setOurWorkPayload,
  clearOurWorkPayload,
};

const asyncActions = {
  getAllWorkProject,
  getProjectById,
};

export default {
  ...singleActions,
  ...asyncActions,
};
