/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const defaultDetails = {
  languageDisplay: 'both',
  status: 'active',
  title: {
    al: '',
    en: '',
  },
  description: {
    al: '',
    en: '',
  },
  content: {
    al: '',
    en: '',
  },
};

const defaultPagination = {
  currentPage: 1,
  limit: 10,
};

const defaultMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

const defaultFilter = {
  status: '',
  keyword: '',
  sortBy: '',
  orderBy: 'ASC',
  language: 'en',
  type: 'admin',
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  applying: false,
  payload: [],
  details: defaultDetails,
  filter: defaultFilter,
  pagination: defaultPagination,
  meta: defaultMeta,
  removeIds: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_CAREERS_PAYLOAD:
      return {
        ...state,
        payload,
      };
    case ActionTypes.CLEAR_CAREERS_PAYLOAD:
      return {
        ...state,
        payload: [],
      };

    case ActionTypes.SET_CAREERS_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ActionTypes.CLEAR_CAREERS_DETAILS:
      return {
        ...state,
        details: defaultDetails,
      };

    case ActionTypes.SET_CAREERS_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_CAREERS_FILTER:
      return {
        ...state,
        filter: defaultFilter,
      };

    case ActionTypes.SET_CAREERS_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_CAREERS_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_DEFAULT_CAREERS_META_PAGINATION:
      return {
        ...state,
        meta: defaultMeta,
        pagination: defaultPagination,
      };

    case ActionTypes.SET_REMOVE_CAREERS_IDS:
      return {
        ...state,
        removeIds: payload,
      };
    case ActionTypes.CLEAR_REMOVE_CAREERS_IDS:
      return {
        ...state,
        removeIds: [],
      };

    case ActionTypes.GET_ALL_CAREERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_ALL_CAREERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.GET_ALL_CAREERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.FILTER_CAREERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.FILTER_CAREERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
        payload: [],
      };
    case ActionTypes.FILTER_CAREERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.CREATE_CAREERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.CREATE_CAREERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.CREATE_CAREERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.UPDATE_CAREER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CAREER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.UPDATE_CAREER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_CAREER_BY_ID_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_CAREER_BY_ID_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_CAREER_BY_ID_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_SINGLE_CAREER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_CAREER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_SINGLE_CAREER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.REMOVE_MULTIPLE_CAREERS_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_CAREERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.REMOVE_MULTIPLE_CAREERS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.APPLY_CAREER_FAILURE:
      return {
        ...state,
        hasError: true,
        applying: false,
        isSuccess: false,
      };
    case ActionTypes.APPLY_CAREER_REQUEST:
      return {
        ...state,
        hasError: false,
        applying: true,
        isSuccess: false,
      };
    case ActionTypes.APPLY_CAREER_SUCCESS:
      return {
        ...state,
        hasError: false,
        applying: false,
        isSuccess: true,
      };

    default:
      return state;
  }
};
