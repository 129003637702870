import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentInput, Icon } from './UserPagination.styles';

interface SectionProps {
  currentPage?: number;
  totalPages?: number;
  className?: string;
  onClick?(page: number): unknown;
}

const UserPagination: React.FC<SectionProps> = (props: SectionProps) => {
  const { currentPage, totalPages, className, onClick } = props;
  const { t } = useTranslation('user');

  const handleClickPrevious = () => {
    if (currentPage && currentPage > 1 && onClick) onClick(currentPage - 1);
  };

  const handleClickNext = () => {
    if (currentPage && totalPages && currentPage < totalPages && onClick) onClick(currentPage + 1);
  };

  return (
    <ComponentInput className={className}>
      <Icon onClick={() => handleClickPrevious()} className={`mr-1 ${currentPage === 1 ? 'disabled' : ''}`}>
        <i className="fas fa-angle-left" />
      </Icon>
      <span>{t('label.page')}</span>
      <p>{currentPage}</p>
      <span>{t('label.of')}</span>
      <p>{totalPages}</p>
      <Icon onClick={() => handleClickNext()} className={`ml-1 ${currentPage === totalPages ? 'disabled' : ''}`}>
        <i className="fas fa-angle-right" />
      </Icon>
    </ComponentInput>
  );
};

export default UserPagination;
