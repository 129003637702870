import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterContainer,
  Title,
  FilterGroup,
  ResetButton,
  FilterChildOptions,
} from "./FilterNews.styles";
import { GenerateValue } from "../../../libs";
import { FlexHorizontal } from "../FlexLayout";
import { FilterDropdown } from "../Dropdown";

interface ItemsStructure {
  value: string;
  label: string;
  icon?: string;
}

interface DataStructure {
  title: string;
  key: string;
  items: ItemsStructure[] | any[];
}

interface ActiveFilterStructure {
  [key: string]: string;
}

const { GenerateYear, GenerateYearFrom } = GenerateValue;

interface SectionProps {
  data: DataStructure[];
  onChange(value: ActiveFilterStructure): unknown;
  onClick?(): unknown;
  activeFilter: ActiveFilterStructure;
  isWorkPage?: boolean;
  className?: string;
}

const FilterNews: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare props
  const { onChange, activeFilter, onClick, className } = props;
  const [isDisabledResetFilter, setIsDisabledResetFilter] =
    useState<boolean>(true);
  const [collapse, setCollapse] = useState<any>({
    status: false,
    categories: false,
    topic: false,
    implement: false,
    year: false,
  });
  useEffect(() => {
    let flag = true;
    const activeFilterKeys = _.keys(activeFilter);
    _.map(activeFilterKeys, (key) => {
      if (key !== "page") {
        const value = _.get(activeFilter, key);
        if (!_.isEmpty(value)) flag = false;
      }
    });
    setIsDisabledResetFilter(flag);
  }, [activeFilter]);

  // Declare translation
  const { t } = useTranslation("user");

  return (
    <FilterContainer
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        marginBottom: 20,
      }}
    >
      <FilterChildOptions className="show">
        <div>
          <Title
            className={`mt-3 ${
              _.get(collapse, "year") ? "uncollapse" : "collapse"
            }`}
            onClick={() =>
              setCollapse({ ...collapse, year: !_.get(collapse, "year") })
            }
          >
            {t(`label.filterByYear`)}
            {_.get(collapse, "year") ? (
              <i className="fa fa-window-minimize" />
            ) : (
              <i className="fas fa-filter" />
            )}
          </Title>
          <FilterGroup
            className={`${_.get(collapse, "year") ? "uncollapse" : "collapse"}`}
          >
            <FlexHorizontal width="100%">
              <FilterDropdown
                options={GenerateYearFrom(_.get(activeFilter, "yearTo"))}
                onChange={(value: any) =>
                  onChange &&
                  onChange({ ...activeFilter, yearFrom: _.toString(value) })
                }
                selectedValue={_.toNumber(_.get(activeFilter, "yearFrom"))}
                placeholder={t("label.from")}
              />

              <FilterDropdown
                options={GenerateYear(_.get(activeFilter, "yearFrom"))}
                onChange={(value: any) =>
                  onChange &&
                  onChange({ ...activeFilter, yearTo: _.toString(value) })
                }
                selectedValue={_.toNumber(_.get(activeFilter, "yearTo"))}
                placeholder={t("label.to")}
              />
            </FlexHorizontal>
          </FilterGroup>
        </div>
      </FilterChildOptions>
      <ResetButton
        className={`${isDisabledResetFilter ? "disabled" : ""}`}
        onClick={() => !isDisabledResetFilter && onClick && onClick()}
      >
        {t("label.resetFilters")}
      </ResetButton>
    </FilterContainer>
  );
};

export default FilterNews;
