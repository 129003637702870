/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import history from '../history';
import { ROUTES } from '../constants';
import { statusAlert } from '../libs';

// SINGLE ACTIONS
const setCareersPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_CAREERS_PAYLOAD,
    payload,
  };
};

const clearCareersPayload = () => {
  return {
    type: ActionTypes.CLEAR_CAREERS_PAYLOAD,
  };
};

const setCareersDetails = (payload: any) => {
  return {
    type: ActionTypes.SET_CAREERS_DETAILS,
    payload,
  };
};

const clearCareersDetails = () => {
  return {
    type: ActionTypes.CLEAR_CAREERS_DETAILS,
  };
};

const setCareersFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_CAREERS_FILTER,
    payload,
  };
};

const clearCareersFilter = () => {
  return {
    type: ActionTypes.CLEAR_CAREERS_FILTER,
  };
};

const setCareersPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_CAREERS_PAGINATION,
    payload,
  };
};

const setCareersMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_CAREERS_META,
    payload,
  };
};

const setDefaultCareersMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_CAREERS_META_PAGINATION,
  };
};

const setRemoveCareersIds = (payload: any) => {
  return {
    type: ActionTypes.SET_REMOVE_CAREERS_IDS,
    payload,
  };
};

const clearRemoveCareersIds = () => {
  return {
    type: ActionTypes.CLEAR_REMOVE_CAREERS_IDS,
  };
};

// ASYNC ACTIONS
const getAllCareersError = () => {
  return {
    type: ActionTypes.GET_ALL_CAREERS_FAILURE,
  };
};

const getAllCareersLoading = () => {
  return {
    type: ActionTypes.GET_ALL_CAREERS_REQUEST,
  };
};

const getAllCareersSuccess = () => {
  return {
    type: ActionTypes.GET_ALL_CAREERS_SUCCESS,
  };
};

const getAllCareers = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(getAllCareersLoading());
    await API.getAllCareers(data)
      .then(async (res: any) => {
        const { status, payload } = res;
        if (status) {
          dispatch(setCareersPayload(payload));
          dispatch(getAllCareersSuccess());
        } else dispatch(getAllCareersError());
      })
      .catch(() => dispatch(getAllCareersError()));
  };
};

const filterCareersError = () => {
  return {
    type: ActionTypes.FILTER_CAREERS_FAILURE,
  };
};

const filterCareersLoading = () => {
  return {
    type: ActionTypes.FILTER_CAREERS_REQUEST,
  };
};

const filterCareersSuccess = () => {
  return {
    type: ActionTypes.FILTER_CAREERS_SUCCESS,
  };
};

const filterCareers = (filterParams: any) => {
  return async (dispatch: any) => {
    dispatch(filterCareersLoading());
    await API.filterCareers(filterParams)
      .then(async (res: any) => {
        const { status, payload } = res;
        if (status) {
          const { items, meta } = payload;
          dispatch(setCareersMeta(meta));
          dispatch(setCareersPayload(items));
          dispatch(filterCareersSuccess());
        } else dispatch(filterCareersError());
      })
      .catch(() => dispatch(filterCareersError()));
  };
};

const createCareerError = () => {
  return {
    type: ActionTypes.CREATE_CAREER_FAILURE,
  };
};

const createCareerLoading = () => {
  return {
    type: ActionTypes.CREATE_CAREER_REQUEST,
  };
};

const createCareerSuccess = (payload: any) => {
  return {
    type: ActionTypes.CREATE_CAREER_SUCCESS,
    payload,
  };
};

const createCareer = (data: any) => {
  return async (dispatch: any) => {
    dispatch(createCareerLoading());
    await API.createCareer(data)
      .then(async (res: any) => {
        const { message, status } = res;
        if (status) {
          history.push(ROUTES.ADMIN.CAREER);
          dispatch(createCareerSuccess(res.payload));
          statusAlert('success', message);
        } else {
          dispatch(createCareerError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(createCareerError()));
  };
};

const updateCareerError = () => {
  return {
    type: ActionTypes.UPDATE_CAREER_FAILURE,
  };
};

const updateCareerLoading = () => {
  return {
    type: ActionTypes.UPDATE_CAREER_REQUEST,
  };
};

const updateCareerSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CAREER_SUCCESS,
    payload,
  };
};

const updateCareer = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateCareerLoading());
    await API.updateCareer(data)
      .then(async (res: any) => {
        const { message, payload, status } = res;
        if (status) {
          dispatch(updateCareerSuccess(payload));
          statusAlert('success', message);
          history.push(ROUTES.ADMIN.CAREER);
        } else {
          dispatch(updateCareerError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(updateCareerError()));
  };
};

const getCareerByIdError = () => {
  return {
    type: ActionTypes.GET_CAREER_BY_ID_FAILURE,
  };
};

const getCareerByIdLoading = () => {
  return {
    type: ActionTypes.GET_CAREER_BY_ID_REQUEST,
  };
};

const getCareerByIdSuccess = () => {
  return {
    type: ActionTypes.GET_CAREER_BY_ID_SUCCESS,
  };
};

const getCareerById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getCareerByIdLoading());
    await API.getCareerById(id)
      .then(async (res: any) => {
        const { status, payload } = res;
        if (status) {
          dispatch(setCareersDetails(payload));
          dispatch(getCareerByIdSuccess());
        } else dispatch(getCareerByIdError());
      })
      .catch(() => dispatch(getCareerByIdError()));
  };
};

const removeSingleCareerError = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_FAILURE,
  };
};

const removeSingleCareerLoading = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_REQUEST,
  };
};

const removeSingleCareerSuccess = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_SUCCESS,
  };
};

const removeSingleCareer = (id: string) => {
  return async (dispatch: any) => {
    dispatch(removeSingleCareerLoading());
    await API.removeSingleCareer(id)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(removeSingleCareerSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeSingleCareerError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(removeSingleCareerError()));
  };
};

const removeMultipleCareersError = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CAREERS_FAILURE,
  };
};

const removeMultipleCareersLoading = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CAREERS_REQUEST,
  };
};

const removeMultipleCareersSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_CAREERS_SUCCESS,
  };
};

const removeMultipleCareers = (ids: string[]) => {
  return async (dispatch: any) => {
    dispatch(removeMultipleCareersLoading());
    await API.removeMultipleCareers(ids)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(removeMultipleCareersSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeMultipleCareersError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(removeMultipleCareersError()));
  };
};

const applyCareerError = () => {
  return {
    type: ActionTypes.APPLY_CAREER_FAILURE,
  };
};

const applyCareerLoading = () => {
  return {
    type: ActionTypes.APPLY_CAREER_REQUEST,
  };
};

const applyCareerSuccess = () => {
  return {
    type: ActionTypes.APPLY_CAREER_SUCCESS,
  };
};

const applyCareer = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(applyCareerLoading());
    await API.applyCareer(payload)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(applyCareerSuccess());
          statusAlert('success', message);
        } else {
          dispatch(applyCareerError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(applyCareerError()));
  };
};

const singleActions = {
  setCareersPayload,
  clearCareersPayload,
  setCareersDetails,
  clearCareersDetails,
  setCareersFilter,
  clearCareersFilter,
  setCareersPagination,
  setCareersMeta,
  setDefaultCareersMetaPagination,
  setRemoveCareersIds,
  clearRemoveCareersIds,
};

const asyncActions = {
  getAllCareers,
  filterCareers,
  createCareer,
  updateCareer,
  getCareerById,
  removeSingleCareer,
  removeMultipleCareers,
  applyCareer,
};

export default {
  ...singleActions,
  ...asyncActions,
};
