import _ from 'lodash';
import React from 'react';
import { GridImageLayoutContainer, GridContent, ImageBox, MoreLayer } from './GridImageLayout.styles';

interface DataStructure {
  type: string;
  images: string[];
}

interface SectionProps {
  data: DataStructure;
  onClick?(url: string): void;
  style?: any;
}

const GridImageLayout = (props: SectionProps) => {
  const { data, onClick, style } = props;
  const { type, images } = data;

  const generateGridStyle = () => {
    const resolveStyle: any = {};

    if (images.length === 1) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "gr0 gr0 gr0 gr0 gr0 gr0"
          "gr0 gr0 gr0 gr0 gr0 gr0"
          "gr0 gr0 gr0 gr0 gr0 gr0"
          "gr0 gr0 gr0 gr0 gr0 gr0"`,
      });
    }

    if (images.length === 2) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "gr0 gr0 gr1 gr1"
          "gr0 gr0 gr1 gr1"
          "gr0 gr0 gr1 gr1"
          "gr0 gr0 gr1 gr1"`,
      });
    }

    if (images.length === 3) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "gr0 gr0 gr0 gr1 gr1"
          "gr0 gr0 gr0 gr1 gr1"
          "gr0 gr0 gr0 gr2 gr2"
          "gr0 gr0 gr0 gr2 gr2"`,
      });
    }

    if (images.length >= 4) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "gr0 gr0 gr1 gr1"
          "gr0 gr0 gr1 gr1"
          "gr2 gr2 gr3 gr3"
          "gr2 gr2 gr3 gr3"`,
      });
    }

    return resolveStyle;
  };

  const generateThumbnailStyle = () => {
    const resolveStyle: any = {};

    if (images.length === 1) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"`,
      });
    }

    if (images.length === 2) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn1 tn1 tn1 tn1 tn1 tn1"
          "tn1 tn1 tn1 tn1 tn1 tn1"`,
      });
    }

    if (images.length === 3) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn1 tn1 tn1 tn2 tn2 tn2"
          "tn1 tn1 tn1 tn2 tn2 tn2"`,
      });
    }

    if (images.length >= 4) {
      _.assign(resolveStyle, {
        gridTemplateAreas: `
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn0 tn0 tn0 tn0 tn0 tn0"
          "tn1 tn1 tn2 tn2 tn3 tn3"
          "tn1 tn1 tn2 tn2 tn3 tn3"`,
      });
    }

    return resolveStyle;
  };

  const renderMain = () => {
    if (type === 'grid')
      return (
        <GridContent style={generateGridStyle()}>
          {images[0] && (
            <ImageBox area="gr0" onClick={() => onClick && onClick(images[0])}>
              <img src={images[0]} alt="" />
            </ImageBox>
          )}
          {images[1] && (
            <ImageBox area="gr1" onClick={() => onClick && onClick(images[1])}>
              <img src={images[1]} alt="" />
            </ImageBox>
          )}
          {images[2] && (
            <ImageBox area="gr2" onClick={() => onClick && onClick(images[2])}>
              <img src={images[2]} alt="" />
            </ImageBox>
          )}
          {images[3] && (
            <ImageBox area="gr3" onClick={() => onClick && onClick(images[3])}>
              <img src={images[3]} alt="" />
              {images.length > 4 && <MoreLayer>+{images.length - 3}</MoreLayer>}
            </ImageBox>
          )}
        </GridContent>
      );
    if (type === 'thumbnails')
      return (
        <GridContent style={generateThumbnailStyle()}>
          {' '}
          {images[0] && (
            <ImageBox area="tn0" onClick={() => onClick && onClick(images[0])}>
              <img src={images[0]} alt="" />
            </ImageBox>
          )}
          {images[1] && (
            <ImageBox area="tn1" onClick={() => onClick && onClick(images[1])}>
              <img src={images[1]} alt="" />
            </ImageBox>
          )}
          {images[2] && (
            <ImageBox area="tn2" onClick={() => onClick && onClick(images[2])}>
              <img src={images[2]} alt="" />
            </ImageBox>
          )}
          {images[3] && (
            <ImageBox area="tn3" onClick={() => onClick && onClick(images[3])}>
              <img src={images[3]} alt="" />
              {images.length > 4 && <MoreLayer>+{images.length - 3}</MoreLayer>}
            </ImageBox>
          )}
        </GridContent>
      );
  };

  return <GridImageLayoutContainer style={style}>{renderMain()}</GridImageLayoutContainer>;
};

export default GridImageLayout;
