/* eslint import/no-anonymous-default-export: off */
import { BREAK_POINTS } from '../../constants';

export default {
  CONTENT: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        padding: '0 15px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        padding: '0 30px',
      },
    },
    {
      breakpoint: BREAK_POINTS.SMALL_SCREEN,
      style: {
        padding: '0 40px',
      },
    },
    {
      breakpoint: BREAK_POINTS.LARGE_SCREEN,
      style: {
        padding: '0 40px',
      },
    },
    {
      breakpoint: BREAK_POINTS.EXTRA_LARGE_SCREEN,
      style: {
        padding: '0 80px',
      },
    },
  ],
  NEWS_CONTENT: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        padding: '0 15px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        padding: '0 30px',
      },
    },
    {
      breakpoint: BREAK_POINTS.SMALL_SCREEN,
      style: {
        padding: '0 40px',
      },
    },
    {
      breakpoint: BREAK_POINTS.LARGE_SCREEN,
      style: {
        padding: '0 40px',
      },
    },
    {
      breakpoint: BREAK_POINTS.EXTRA_LARGE_SCREEN,
      style: {
        padding: '0 0',
      },
    },
  ],
  NEWS_DETAILS_CONTENT: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        padding: '15px 0',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        padding: '30px 0',
      },
    },
    {
      breakpoint: BREAK_POINTS.SMALL_SCREEN,
      style: {
        padding: '40px 0',
      },
    },
    {
      breakpoint: BREAK_POINTS.LARGE_SCREEN,
      style: {
        padding: '40px 0',
      },
    },
    {
      breakpoint: BREAK_POINTS.EXTRA_LARGE_SCREEN,
      style: {
        padding: '80px 0',
      },
    },
  ],
  SECTION_TITLE: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        'font-size': '24px',
        'line-height': '28px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        'font-size': '28px',
        'line-height': '34px',
        'margin-bottom': '5px',
      },
    },
    {
      breakpoint: BREAK_POINTS.LARGE_SCREEN,
      style: {
        'font-size': '34px',
        'line-height': '40px',
        'margin-bottom': '5px',
      },
    },
  ],
  HEADER_IMAGE_LABEL: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        'font-size': '18px',
        'line-height': '26px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        'font-size': '26px',
        'line-height': '31px',
      },
    },
    {
      breakpoint: BREAK_POINTS.SMALL_SCREEN,
      style: {
        'font-size': '29px',
        'line-height': '35px',
      },
    },
  ],
  SLIDER_TITLE: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        'font-size': '24px',
        'line-height': '30px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        'font-size': '34px',
        'line-height': '47px',
        'margin-bottom': '5px',
      },
    },
  ],
  SLIDER_CONTENT: [
    {
      breakpoint: BREAK_POINTS.MOBILE,
      style: {
        'margin-top': '35px',
      },
    },
    {
      breakpoint: BREAK_POINTS.TABLET,
      style: {
        'margin-top': '60px',
      },
    },
    {
      breakpoint: BREAK_POINTS.SMALL_SCREEN,
      style: {
        'margin-top': '60px',
      },
    },
    {
      breakpoint: BREAK_POINTS.LARGE_SCREEN,
      style: {
        'margin-top': '80px',
      },
    },
    {
      breakpoint: BREAK_POINTS.EXTRA_LARGE_SCREEN,
      style: {
        'margin-top': '80px',
      },
    },
  ],
};
