import styled from 'styled-components';
import { PageContainer, FlexVertical, FlexHorizontal } from '../../../components/Common';
import generateResponsive from '../../../components/Common/responsive';
import responsiveValue from '../../../components/Common/responsive.value';
import { ColorName } from '../../../components/Variables';

const Container = styled(PageContainer)``;

const DetailsWorkGroup = styled(FlexVertical)`
  width: 100%;
  max-width: 400px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: auto;
  margin-left: 100px;

  ${generateResponsive(responsiveValue.NEWS_CONTENT)}

  @media screen and (max-width: 1439px) {
    margin: 40px auto 0;
    max-width: 720px;
  }

  @media screen and (max-width: 426px) {
    margin: 0 auto 0;
    padding: 20px;
  }
`;

const DetailsWorkItem = styled(FlexHorizontal)`
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding: 10px 24px;
  background: ${ColorName.pampas};
  margin-bottom: 10px;
  box-sizing: border-box;

  p {
    line-height: 20px;
  }
  b {
    margin-right: 5px;
  }

  @media screen and (max-width: 426px) {
    padding: 10px 14px;
    b {
      font-size: 13px;
    }
  }
`;

const DetailsContentColumn = styled.div`
  display: flex;
  justify-content: center;

  ${generateResponsive(responsiveValue.NEWS_DETAILS_CONTENT)}

  @media screen and (max-width: 1439px) {
    display: block;
  }
`;

export { Container, DetailsWorkGroup, DetailsWorkItem, DetailsContentColumn };
