/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import {
  Title,
  DetailsContent,
  SkeletonNewsDetails,
  DetailsBanner,
  PageContainer,
  Description,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES } from "../../../constants/";
import { CareersActions } from "../../../actions";
import { Images } from "../../../themes";
import ApplyCareer from "./ApplyCareer";

interface ParamsType {
  id: string;
  type: string;
}

// Declare actions
const { getCareerById } = CareersActions;

const CareersDetails = () => {
  // Declare params from url
  const { id } = useParams<ParamsType>();
  // Declare reducer and dispatch
  const dispatch = useDispatch();
  const details = useSelector((state) => get(state, "Careers.details"));
  const isLoading = useSelector((state) => get(state, "Careers.isLoading"));
  const lang = useSelector((state) => get(state, "PageSettings.userLang"));

  useEffect(() => {
    if (id) dispatch(getCareerById(id));
  }, []);

  const renderMain = () => {
    return (
      <PageContainer>
        <DetailsBanner
          isEmptyRecents
          isLoading={isLoading}
          banner={Images.careerBanner.default}
        />
        <DetailsContent className="ml-auto mr-auto pt-3 pb-6">
          {isLoading ? (
            <SkeletonNewsDetails className="mt-4 mb-2" />
          ) : (
            <>
              <Title className="mt-4 mb-2">
                {get(details, `title.${lang}`)}
              </Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: get(details, `content.${lang}`) || '',
                }}
              />
              <ApplyCareer specific={get(details, "specific") || false} />
            </>
          )}
        </DetailsContent>
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.CAREER_DETAILS}
      titlePage={get(details, `title.${lang}`)}
      headerTitle={`${
        isLoading ? "Loading..." : get(details, `title.${lang}`)
      } | IDRA`}
    />
  );
};

export default CareersDetails;
