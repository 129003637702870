import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'unset';
    window.scrollTo({ top: 0, left: 0 });
    setTimeout(() => {
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 1000)
  }, [pathname]);

  return null;
}
