/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  OurlineGrowButton,
  UserCarrotButton,
  Content,
  NewsCard,
  Slider,
  Carousel,
  RegularParagraph,
  QuoteWhoWeAre,
  GridLayout,
  FlexVertical,
  FlexHorizontal,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import {
  SectionTitle,
  HomepageContainer,
  ContentScrollVertical,
  HideDescription,
} from "./Homepage.styles";
import { Icons, Images } from "../../../themes";
import history from "../../../history";
import { ROUTES } from "../../../constants";
import { ArticlesActions } from "../../../actions";
import { Header } from "../../../components/Layout/CommonLayoutPart";

// Declare section ids
const SECTION_IDS = {
  [ROUTES.USER.WHO_WE_ARE]: "who-we-are",
  [ROUTES.USER.SERVICES_SOLUTIONS]: "solutions",
  [ROUTES.USER.NEWS]: "news",
  [ROUTES.USER.CAREER]: "careers",
  [ROUTES.USER.CONTACT]: "contact",
};

// Declare actions
const { getArticlesLatest } = ArticlesActions;

const Homepage = () => {
  // Declare dispatch, reducers;
  const dispatch = useDispatch();
  const { t } = useTranslation("user");
  const articlesPayload: any = useSelector((state) =>
    _.get(state, "Articles.payloadLatest")
  );

  const content: any = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.HOMEPAGE}`)
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));

  // Handle clicked item in navbar
  const onClickNavbarItem = (value: string) => {
    const id = _.get(SECTION_IDS, value);
    const currentSection = document.getElementById(id);
    if (currentSection)
      currentSection.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(getArticlesLatest({ locale: lang }));
  }, [lang]);

  useEffect(() => {
    const svgElement = document.querySelector<SVGSVGElement>("svg");
    const maskedElement = document.querySelector("#mask-circle");
    const circleFeedback = document.querySelector("#circle-shadow");
    const svgPoint = svgElement && svgElement.createSVGPoint();

    const cursorPoint = (e: any, svg: any) => {
      if (svgPoint) {
        svgPoint.x = e.clientX;
        svgPoint.y = e.clientY;
        return svgPoint.matrixTransform(svg.getScreenCTM().inverse());
      }
    };

    const update = (svgCoords: any) => {
      if (maskedElement && circleFeedback) {
        maskedElement.setAttribute("cx", svgCoords.x);
        maskedElement.setAttribute("cy", svgCoords.y);
        circleFeedback.setAttribute("cx", svgCoords.x);
        circleFeedback.setAttribute("cy", svgCoords.y);
      }
    };

    const handleMouseMove = (e: any) => {
      update(cursorPoint(e, svgElement));
    };

    window.addEventListener("mousemove", handleMouseMove, false);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const renderDescription = () => {
    return (
      <HideDescription>
        <h1>IDRA</h1>
        <h2>IDRA · Insights for foresight</h2>
      </HideDescription>
    );
  };

  // Render Who we are section
  const renderAboutUs = () => {
    const aboutUsQuote = _.get(content, "whoWeAre");
    return (
      <GridLayout
        id="who-we-are"
        col="2"
        className="mt-12 mb-8 pl-4 pr-4 custom-ourpartners"
      >
        <FlexHorizontal alignFlexEnd>
          <LazyLoadImage
            effect="blur"
            width="100%"
            height="100%"
            alt={`illustrators-who-we-are`}
            src={Images.whoWeAre.default}
          />
        </FlexHorizontal>
        <FlexVertical width="unset" className="desktop-ml-4">
          <SectionTitle>{_.get(aboutUsQuote, `title.${lang}`)}</SectionTitle>
          <QuoteWhoWeAre className="mt-1 mb-2">
            {_.get(aboutUsQuote, `description.${lang}`)}
          </QuoteWhoWeAre>
          <UserCarrotButton
            width="25rem"
            isUppercase
            onClick={() => {
              document.documentElement.style.scrollBehavior = "unset";
              history.push(`${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`);
            }}
          >
            {t("button.continueReading")}
          </UserCarrotButton>
        </FlexVertical>
      </GridLayout>
    );
  };

  // Render News section
  const renderNews = () => {
    return (
      <Content id="news" direction="column">
        <FlexHorizontal alignCenter justifySpaceBetween className="mb-4">
          <SectionTitle fontSize="3.6rem" className="center mb-1">
            {_.get(content, `news.${lang}`) || t("label.news")}
          </SectionTitle>
          <span
            className="show-all d-flex flex-align-center"
            onClick={() => {
              document.documentElement.style.scrollBehavior = "unset";
              history.push(ROUTES.USER.NEWS);
            }}
          >
            {t("button.seeAll")}{" "}
            <img src={Icons.rightArrow.default} alt="right arrow" />
          </span>
        </FlexHorizontal>
        <ContentScrollVertical>
          <GridLayout
            col="4"
            colGap="34px"
            rowGap="34px"
            className="none-responsive"
          >
            {_.map(articlesPayload, (news) => {
              return (
                <NewsCard
                  isHomepage
                  key={`news-${_.get(news, "id")}`}
                  data={news}
                />
              );
            })}
          </GridLayout>
        </ContentScrollVertical>
      </Content>
    );
  };

  // Render Trusted by section
  const renderClients = () => {
    return (
      <Content direction="column">
        <FlexHorizontal alignCenter justifySpaceBetween className="mt-4 mb-4">
          <SectionTitle fontSize="3.6rem" className="center">
            {_.get(content, `trustedBy.${lang}`)}
          </SectionTitle>
          <span
            className="show-all d-flex flex-align-center"
            onClick={() => {
              document.documentElement.style.scrollBehavior = "unset";
              history.push(`${ROUTES.USER.WHO_WE_ARE}?category=ourClients`);
            }}
          >
            {t("button.seeAll")}{" "}
            <img src={Icons.rightArrow.default} alt="right arrow" />
          </span>
        </FlexHorizontal>
        <Carousel />
      </Content>
    );
  };

  // Render Contact section
  const renderContact = () => {
    const contactContent = _.get(content, "contact");
    return (
      <Content
        id="contact"
        direction="column"
        className="flex-align-center mt-5 mb-8"
      >
        <SectionTitle className="center">
          {_.get(contactContent, `title.${lang}`)}
        </SectionTitle>
        <RegularParagraph textAlignCenter className="mt-1 mb-2">
          {_.get(contactContent, `subtitle.${lang}`)}
        </RegularParagraph>
        <OurlineGrowButton
          label="contactUs"
          onClick={() => {
            document.documentElement.style.scrollBehavior = "unset";
            history.push(ROUTES.USER.CONTACT);
          }}
        />
      </Content>
    );
  };

  const renderMain = () => {
    return (
      <>
        <Header
          onChange={(value: string) => onClickNavbarItem(value)}
          currentPage={ROUTES.USER.HOMEPAGE}
        />
        <HomepageContainer>
          <Slider />
          {renderDescription()}
          {renderAboutUs()}
          {renderNews()}
          {renderClients()}
          {renderContact()}
        </HomepageContainer>
      </>
    );
  };
  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.HOMEPAGE}
      headerTitle="IDRA"
      metaDescription="IDRA · Insights for foresight"
    />
  );
};

export default Homepage;
