import _ from 'lodash';
import React, { useRef } from 'react';
import {
  SectionCardContainer,
  HiddenLink,
  ArrowIcon,
  SectionIcon,
  CardTitle,
  CardContent,
  CardDescription,
} from './SectionCard.styles';
import { Icons } from '../../../themes';
import history from '../../../history';
import { useSelector } from 'react-redux';

interface DataStructure {
  title: string;
  description: string;
  icon?: string;
  link?: string;
}

interface SectionProps {
  data: DataStructure;
  className?: string;
  pathname?: string;
}

const SectionCard: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare props
  const { data, className, pathname } = props;
  const { title, description, icon, link } = data;
  // Declare reducer
  const locale = useSelector(state => _.get(state, 'PageSettings.userLang'));
  const linkRef = useRef<HTMLAnchorElement>(null);

  // Handle event click card to redirect
  const handleClickCard = (link: string) => {
    if (!_.isEmpty(link)) {
      const isHttpLink = _.includes(link, 'http');
      if (isHttpLink && linkRef && linkRef.current) {
        linkRef.current.href = link;
        linkRef.current.click();
      } else {
        document.documentElement.style.scrollBehavior = 'unset';
        history.push({
          pathname: link,
          state: { from: pathname, query: "" },
        });
      }
    }
  };

  // Render title if title is a object
  const renderTitle = () => {
    if (_.isObject(title)) return _.get(title, locale);
    return title;
  };

  // Render description if description is a object
  const renderDescription = () => {
    if (_.isObject(description)) return _.get(description, locale);
    return description;
  };

  return (
    <>
      <SectionCardContainer className={!icon ? 'none_icon' : ''} onClick={() => link && handleClickCard(link)}>
        <HiddenLink className={className} target="_blank" ref={linkRef} />
        <CardContent>
          {icon && (
            <SectionIcon>
              <img src={icon} alt="section-icon" />
            </SectionIcon>
          )}
          <CardTitle>{renderTitle()}</CardTitle>
          <CardDescription>{renderDescription()}</CardDescription>
          <ArrowIcon src={Icons.bigWhiteRightArrow.default} alt="arrow icon" />
        </CardContent>
      </SectionCardContainer>
    </>
  );
};

export default SectionCard;
