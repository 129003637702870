/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import { statusAlert } from '../libs';

// SINGLE ACTIONS
const setSiteSettingsPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_SITE_SETTINGS_PAYLOAD,
    payload,
  };
};

const clearSiteSettingsPayload = () => {
  return {
    type: ActionTypes.CLEAR_SITE_SETTINGS_PAYLOAD,
  };
};

// ASYNC ACTIONS
const getSiteSettingsError = () => {
  return {
    type: ActionTypes.GET_SITE_SETTINGS_FAILURE,
  };
};

const getSiteSettingsIsLoading = () => {
  return {
    type: ActionTypes.GET_SITE_SETTINGS_REQUEST,
  };
};

const getSiteSettingsIsSuccess = () => {
  return {
    type: ActionTypes.GET_SITE_SETTINGS_SUCCESS,
  };
};

const getSiteSettings = () => {
  return async (dispatch: any) => {
    dispatch(getSiteSettingsIsLoading());
    await API.getSiteSettings().then(async (res: any) => {
      const { payload, status } = res;
      if (status) {
        dispatch(setSiteSettingsPayload(payload));
        dispatch(getSiteSettingsIsSuccess());
      } else dispatch(getSiteSettingsError());
    });
  };
};

const editSiteSettingsError = () => {
  return {
    type: ActionTypes.UPDATE_SITE_SETTINGS_FAILURE,
  };
};

const editSiteSettingsIsLoading = () => {
  return {
    type: ActionTypes.UPDATE_SITE_SETTINGS_REQUEST,
  };
};

const editSiteSettingsIsSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_SITE_SETTINGS_SUCCESS,
    payload,
  };
};

const editSiteSettings = (data: any) => {
  return async (dispatch: any) => {
    dispatch(editSiteSettingsIsLoading());
    await API.editSiteSettings(data)
      .then(async (res: any) => {
        const { payload, message, status } = res;
        if (status) {
          dispatch(editSiteSettingsIsSuccess(payload));
          statusAlert('success', message);
        } else {
          dispatch(editSiteSettingsError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(editSiteSettingsError()));
  };
};

const deleteSiteSettingError = () => {
  return {
    type: ActionTypes.REMOVE_SITE_SETTINGS_FAILURE,
  };
};

const deleteSiteSettingIsLoading = () => {
  return {
    type: ActionTypes.REMOVE_SITE_SETTINGS_REQUEST,
  };
};

const deleteSiteSettingIsSuccess = () => {
  return {
    type: ActionTypes.REMOVE_SITE_SETTINGS_SUCCESS,
  };
};

const deleteSiteSetting = (id: string) => {
  return async (dispatch: any) => {
    dispatch(deleteSiteSettingIsLoading());
    await API.deleteSiteSetting(id)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(deleteSiteSettingIsSuccess());
          statusAlert('success', message);
        } else {
          dispatch(deleteSiteSettingError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(deleteSiteSettingError()));
  };
};

export default {
  setSiteSettingsPayload,
  clearSiteSettingsPayload,
  getSiteSettings,
  editSiteSettings,
  deleteSiteSetting,
};
