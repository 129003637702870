/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import { SearchQuery } from '../interfaces/Search.interfaces';
import _ from 'lodash';

// SINGLE ACTIONS
const setSearchFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_SEARCH_FILTER,
    payload,
  };
};

const clearSearchFilter = () => {
  return {
    type: ActionTypes.CLEAR_SEARCH_FILTER,
  };
};

const setSearchPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_SEARCH_PAYLOAD,
    payload,
  };
};

const clearSearchPayload = () => {
  return {
    type: ActionTypes.CLEAR_SEARCH_PAYLOAD,
  };
};

// ASYNC ACTIONS
const searchError = () => {
  return {
    type: ActionTypes.SEARCH_FAILURE,
  };
};

const searchLoading = () => {
  return {
    type: ActionTypes.SEARCH_REQUEST,
  };
};

const searchSuccess = () => {
  return {
    type: ActionTypes.SEARCH_SUCCESS,
  };
};

const searchArticles = (query: SearchQuery) => {
  return async (dispatch: any) => {
    dispatch(searchLoading());
    await API.search(query).then(async (res: any) => {
      const { status, payload } = res;
      if (status) {
        dispatch(setSearchPayload(payload));
        dispatch(searchSuccess());
      } else dispatch(searchError());
    });
  };
};

const searchOurWork = (params: any) => {
  const { keyword } = params;
  return async (dispatch: any) => {
    dispatch(searchLoading());
    await API.getPEA()
      .then(async (response: any) => {
        const data = _.get(response, 'data');
        if (data) {
          const resolveSolutions: string[] = [];
          const filterPayload = _.filter(data, item => {
            const title = _.lowerCase(_.get(item, 'title'));
            const solutions = _.get(item, 'solutions');
            _.forEach(solutions, solution => resolveSolutions.push(_.get(solution, 'title')));
            if (_.includes(_.lowerCase(title), _.lowerCase(_.trim(keyword)))) return item;
          });
          dispatch(setSearchPayload(filterPayload));
          dispatch(searchSuccess());
        }
      })
      .catch(async (error: any) => dispatch(searchError()));
  };
};

export default {
  setSearchFilter,
  clearSearchFilter,
  setSearchPayload,
  clearSearchPayload,
  searchArticles,
  searchOurWork,
};
