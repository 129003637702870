import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ColorName } from "../../Variables";

const PaginationContainer = styled.div`
  display: flex;
  margin-top: 15px;
  @media screen and (max-width: 436px) {
    place-content: center;
  }
`;

const PaginationButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${ColorName.black};
  span,
  i {
    transition: all ease 0.25s;
    padding-bottom: 3px;
    opacity: 0.4;
  }
  i {
    opacity: 1;
    font-size: 14px;
    transform: translateY(2px);
  }
  &.active {
    span {
      opacity: 1;
      border-bottom: 1px solid ${ColorName.black};
    }
  }
  &:hover {
    cursor: pointer;
    span {
      opacity: 1;
    }
  }
`;

interface SectionProps {
  active: number;
  onChange(page: number): void;
  totalItems: number;
  itemsPerPage: number;
}

const Pagination: React.FC<SectionProps> = (props: SectionProps) => {
  const { active, onChange, totalItems, itemsPerPage } = props;
  const [index, setIndex] = useState<number>(0);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChangeType = (value?: string | number) => {
    let newIndex = index;
    if (value === "left" && index === 0) newIndex = totalPages - 1;
    if (value === "left" && index !== 0) newIndex = index - 1;
    if (value === "right" && index === totalPages - 1) newIndex = 0;
    if (value === "right" && index !== totalPages - 1) newIndex = index + 1;
    if (_.isNumber(value)) newIndex = value;

    setIndex(newIndex);
    if (onChange) onChange(newIndex);
  };

  useEffect(() => {
    if (active) {
      const findType = _.findIndex(
        _.range(totalPages),
        (val) => val === active
      );
      setIndex(findType >= 0 ? findType : 0);
    }
  }, [active, totalPages]);

  const renderMain = () => {
    return (
      <PaginationContainer>
        <PaginationButton
          className="arrow"
          onClick={() => handleChangeType("left")}
        >
          <i className="fas fa-arrow-left" />
        </PaginationButton>
        {_.map(_.range(totalPages), (value) => {
          const active = index === value ? "active" : "";
          return (
            <PaginationButton
              className={`${active}`}
              onClick={() => handleChangeType(value)}
              key={`client-type-page-${value}`}
            >
              <span>{value + 1}</span>
            </PaginationButton>
          );
        })}
        <PaginationButton
          className="arrow"
          onClick={() => handleChangeType("right")}
        >
          <i className="fas fa-arrow-right" />
        </PaginationButton>
      </PaginationContainer>
    );
  };

  return renderMain();
};

export default Pagination;
