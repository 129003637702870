/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import history from "../../../history";
import {
  DarkElectriSolidcButton,
  CarrotSolidButton,
  FlexHorizontal,
  AdminPagination,
  ContentContainer,
} from "../../../components/Common";
import { NormalTable } from "../../../components/Layout/CommonLayoutPart/Table";
import { ROUTES, ColumnsTable } from "../../../constants";
import { MembersActions } from "../../../actions";
import { statusAlert } from "../../../libs";

const {
  getAllMembers,
  removeSingleMember,
  removeMultipleMembers,
  setMembersFilter,
  setMembersPagination,
  setRemoveMembersIds,
  clearRemoveMembersIds,
  updateMemberStatus,
} = MembersActions;

const MembersManagement = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const lang: any = useSelector((state) =>
    _.get(state, "PageSettings.adminLang")
  );
  const isRequest = useSelector((state) => _.get(state, "Members.isLoading"));
  const filter: any = useSelector((state) => _.get(state, "Members.filter"));
  const payload: any = useSelector((state) => _.get(state, "Members.payload"));
  const removeIds: any = useSelector((state) =>
    _.get(state, "Members.removeIds")
  );
  const pagination: any = useSelector((state) =>
    _.get(state, "Members.pagination")
  );
  const meta: any = useSelector((state) => _.get(state, "Members.meta"));
  const isExpand = useSelector((state) =>
    _.get(state, "PageSettings.isExpandMenu")
  );

  useEffect(() => {
    dispatch(getAllMembers({ ...filter, ...pagination }));
  }, []);

  const handleSort = async (key: string) => {
    const sortBy = _.get(filter, "sortBy");
    const resolveFilter = {
      ...filter,
      sortBy: key,
      orderBy: "DESC",
    };
    if (sortBy === key) _.assign(resolveFilter, { sortBy: "", orderBy: "ASC" });
    await dispatch(setMembersFilter(resolveFilter));
    await dispatch(
      getAllMembers({ ...resolveFilter, ...pagination, sortBy: key })
    );
  };

  const handleViewDetails = async (value: any, key: string) => {
    if (key === "edit" && value)
      history.push(`${ROUTES.ADMIN.TEAM_MEMBERS}/details?id=${value}`);
    if (key === "delete" && value) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningRemoveMember")
      );
      if (isAgree) {
        let currentPage = _.get(pagination, "currentPage");
        if (payload.length === 1 && currentPage !== 1)
          currentPage = currentPage - 1;
        if (currentPage !== _.get(pagination, "currentPage"))
          dispatch(setMembersPagination({ ...pagination, currentPage }));
        await dispatch(removeSingleMember(value));
        await dispatch(
          getAllMembers({ ...filter, ...pagination, currentPage })
        );
        const findRemoveIds = _.includes(removeIds, value);
        if (findRemoveIds) {
          const resolveRemoveIds = _.filter(removeIds, (id) => id !== value);
          dispatch(setRemoveMembersIds(resolveRemoveIds));
        }
      }
    }
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(setMembersPagination({ ...pagination, currentPage }));
    await dispatch(getAllMembers({ ...filter, ...pagination, currentPage }));
    await dispatch(setRemoveMembersIds([]));
  };

  const handleSelectAll = () => {
    if (_.isArray(removeIds)) {
      const isMaxLength = removeIds.length === payload.length;
      if (isMaxLength) dispatch(setRemoveMembersIds([]));
      else {
        const ids: string[] = [];
        _.map(payload, (item) => {
          const id = _.get(item, "id");
          ids.push(id);
        });
        dispatch(setRemoveMembersIds(ids));
      }
    }
  };

  const handleDelete = async () => {
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningDeleteAllMembers")
    );
    if (isAgree && !_.isEmpty(removeIds)) {
      let currentPage = _.get(pagination, "currentPage");
      if (payload.length === removeIds.length && currentPage !== 1)
        currentPage = currentPage - 1;
      else currentPage = _.get(pagination, "currentPage");
      if (currentPage !== _.get(pagination, "currentPage"))
        dispatch(setMembersPagination({ ...pagination, currentPage }));
      await dispatch(removeMultipleMembers(removeIds));
      await dispatch(clearRemoveMembersIds());
      await dispatch(getAllMembers({ ...filter, ...pagination, currentPage }));
    }
  };

  const changeStatusForMember = async (member: any) => {
    let data = { ...member };
    if (data.status === "active") _.set(data, "status", "inactive");
    else _.set(data, "status", "active");
    await dispatch(updateMemberStatus(data));
    await dispatch(getAllMembers({ ...filter, ...pagination }));
  };

  const renderMain = () => {
    return (
      <ContentContainer isExpand={isExpand}>
        <FlexHorizontal>
          <CarrotSolidButton
            onClick={() => history.push(ROUTES.ADMIN.TEAM_MEMBERS_DETAILS)}
          >
            {t("button.create")}
          </CarrotSolidButton>
        </FlexHorizontal>
        <NormalTable
          className="mt-3"
          column={ColumnsTable.TEAM_MEMBERS}
          data={payload}
          onClick={(value: any, key: string) => handleViewDetails(value, key)}
          onSort={(key: string) => handleSort(key)}
          sortBy={_.get(filter, "sortBy")}
          fragment="0.3fr 1.5fr 1.5fr 1.5fr 1fr 0.4fr 0.4fr 0.4fr"
          checked={removeIds}
          handleChecked={(ids: string[]) => dispatch(setRemoveMembersIds(ids))}
          //Todo
          handleCheckedChangeStatus={(member: any) =>
            changeStatusForMember(member)
          }
          lang={lang}
        />
        {!_.isEmpty(payload) && (
          <FlexHorizontal
            minWidth="100%"
            className="flex-justify-between mt-3 pb-3"
          >
            <FlexHorizontal>
              <DarkElectriSolidcButton
                onClick={() => handleSelectAll()}
                className="mr-1"
              >
                {removeIds.length === payload.length
                  ? t("button.deselectAll")
                  : t("button.selectAll")}
              </DarkElectriSolidcButton>
              <CarrotSolidButton
                onClick={() => !_.isEmpty(removeIds) && handleDelete()}
                className={`${_.isEmpty(removeIds) ? "disabled" : ""}`}
              >
                {t("button.delete")} ({_.isArray(removeIds) && removeIds.length}
                )
              </CarrotSolidButton>
            </FlexHorizontal>
            <AdminPagination
              maxPage={_.get(meta, "totalPages")}
              currentPage={_.get(meta, "currentPage")}
              numberPageDisplay={2}
              totalCount={_.get(meta, "totalItems")}
              clickLinkPagination={(page: number) =>
                handleClickPagination(page)
              }
              clickLinkNext={(page: number) => handleClickPagination(page)}
              clickLinkPrevious={(page: number) => handleClickPagination(page)}
              clickLinkLast={() =>
                handleClickPagination(_.get(meta, "totalPages"))
              }
              clickLinkFirst={() => handleClickPagination(1)}
            />
          </FlexHorizontal>
        )}
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.TEAM_MEMBERS}
      loading={isRequest}
    />
  );
};

export default MembersManagement;
