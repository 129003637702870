import { useEffect } from "react";
import _ from "lodash";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import history from "../history";
import { LOCAL_STORAGE_KEYS, CONSTANTS, ROUTES } from "../constants/Constants";
import dayjs from "dayjs";

const {
  SECURE_TOKEN,
  NAME_KEY,
  BASIC_USER_DATA_KEY,
  USER_DATA_KEY,
  USER_EXTRA_DATA_KEY,
  USER_LOCALE,
  ADMIN_LOCALE,
  REMEMBER,
  CURRENT_LOCALE,
  FIRST_LOAD,
  ALUMNI_POPUP,
} = LOCAL_STORAGE_KEYS;

const cookies = new Cookies();
const saveToken = (token: string) => {
  cookies.set(SECURE_TOKEN, token, { path: "/" });
};

const getSavedToken = () => {
  const token = cookies.get(SECURE_TOKEN);
  return token;
};

const clearToken = () => {
  cookies.remove(SECURE_TOKEN);
};

const authenticationValidate = (params: any) => {
  const accessToken = getSavedToken();
  const token = _.get(accessToken, "refresh_token");
  if (token) {
    const decodedToken: any = jwt_decode(token);
    const dateNow = new Date();
    if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
      clearAllSavedData();
      return history.push(ROUTES.ADMIN.LOGIN);
    }
    params.found(token);
  } else {
    params.notFound();
  }
};

const saveFirstLoad = () => {
  localStorage.setItem(FIRST_LOAD, JSON.stringify(false));
};

const getSavedFirstLoad = () => {
  const firstLoad = localStorage.getItem(FIRST_LOAD);
  if (firstLoad) return JSON.parse(firstLoad);
  return true;
};

const saveUserName = (name: string) => {
  localStorage.setItem(NAME_KEY, JSON.stringify(name));
};

const getSavedUserName = () => {
  const username = localStorage.get(NAME_KEY);
  return JSON.parse(username);
};

const saveUserData = (userData: any) => {
  if (userData) {
    const basicUserData: any = {};
    cookies.set(BASIC_USER_DATA_KEY, basicUserData, { path: "/" });
    const resolveUserData = JSON.stringify(userData);
    localStorage.setItem(USER_EXTRA_DATA_KEY, resolveUserData);
  }
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(USER_EXTRA_DATA_KEY);
  if (!_.isEmpty(userData) && userData) {
    const resolveUserData = JSON.parse(userData);
    return resolveUserData;
  }
  return null;
};

const clearUserData = () => {
  localStorage.removeItem(USER_EXTRA_DATA_KEY);
};

const clearAllSavedData = async () => {
  cookies.remove(SECURE_TOKEN, { path: "/" });
  cookies.remove(NAME_KEY, { path: "/" });
  cookies.remove(USER_DATA_KEY, { path: "/" });
  cookies.remove(BASIC_USER_DATA_KEY, { path: "/" });
  // Fixed over memory for cookie, changed to localstorage instead - 20210501 - BaoNguyen
  localStorage.removeItem(USER_EXTRA_DATA_KEY);
  cookies.remove(USER_EXTRA_DATA_KEY, { path: "/" });
};

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const getUserLocale = () => {
  const locale = localStorage.getItem(USER_LOCALE);
  if (locale) return JSON.parse(locale);
  return CONSTANTS.LOCALE.EN;
};

const savedUserLocale = (locale: string) => {
  if (locale) localStorage.setItem(USER_LOCALE, JSON.stringify(locale));
};

const getAdminLocale = () => {
  const locale = localStorage.getItem(ADMIN_LOCALE);
  if (locale) return JSON.parse(locale);
  return CONSTANTS.LOCALE.EN;
};

const savedAdminLocale = (locale: string) => {
  if (locale) localStorage.setItem(ADMIN_LOCALE, JSON.stringify(locale));
};

const getCurrentLocale = () => {
  const locale = localStorage.getItem(CURRENT_LOCALE);
  if (locale) return JSON.parse(locale);
  return CONSTANTS.LOCALE.EN;
};

const savedCurrentLocale = (locale: string) => {
  if (locale) localStorage.setItem(CURRENT_LOCALE, JSON.stringify(locale));
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

const setTypeUser = (type: string) => {
  localStorage.setItem("type", JSON.stringify(type));
};

const getTypeUser = () => {
  const type = localStorage.getItem("type");
  if (type) return JSON.parse(type);
  return null;
};

const setRemember = (isRemember: boolean) => {
  localStorage.setItem(REMEMBER, JSON.stringify(isRemember));
};

const getRemember = () => {
  const isRemember = localStorage.getItem(REMEMBER);
  if (isRemember) return JSON.parse(isRemember);
  return false;
};

const scrollToBottomOfBanner = () => {
  const topicImage = document.querySelector(".topic_image");
  if (topicImage) {
    setTimeout(() => {
      const scrollHeight = topicImage.clientHeight;
      window.scroll({
        top: scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }
};

const setClosePopupTime = (time: number) => {
  localStorage.setItem(ALUMNI_POPUP, JSON.stringify(time));
};

const getClosePopupTime = () => {
  const closeTime = localStorage.getItem(ALUMNI_POPUP);
  if (closeTime) return JSON.parse(closeTime);
  return 0;
};

const convertDateInNews = (locale?: string, date?: string): string => {
  // Define LOCALE_DATE with type annotations
  const LOCALE_DATE: {
    en: { [index: number]: string };
    al: { [index: number]: string };
  } = {
    en: {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    },
    al: {
      0: "Janar",
      1: "Shkurt",
      2: "Mars",
      3: "Prill",
      4: "Maj",
      5: "Qershor",
      6: "Korrik",
      7: "Gusht",
      8: "Shtator",
      9: "Tetor",
      10: "Nëntor",
      11: "Dhjetor",
    },
  };

  // Early return for missing arguments
  if (!locale || !date) return "";

  // Check date validity using dayjs
  if (dayjs(date).isValid()) {
    const day = dayjs(date).date();
    const formattedDay = `${day}`.padStart(2, "0");
    const monthIndex = dayjs(date).month();
    const year = dayjs(date).year();

    // Use template literals for cleaner string formatting
    return `${formattedDay} ${
      LOCALE_DATE[locale as "en" | "al"][monthIndex]
    } ${year}`;
  }

  // Return empty string for invalid dates
  return "";
};

const Utils = {
  saveToken,
  getSavedToken,
  authenticationValidate,
  saveUserName,
  getSavedUserName,
  saveUserData,
  getSavedUserData,
  clearAllSavedData,
  useOutsideClick,
  getUserLocale,
  savedUserLocale,
  getAdminLocale,
  savedAdminLocale,
  getCurrentLocale,
  savedCurrentLocale,
  getDeviceType,
  setTypeUser,
  getTypeUser,
  clearUserData,
  setRemember,
  getRemember,
  clearToken,
  scrollToBottomOfBanner,
  getSavedFirstLoad,
  saveFirstLoad,
  setClosePopupTime,
  getClosePopupTime,
  convertDateInNews,
};

export default Utils;
