/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';

const defaultPagination = {
  currentPage: 1,
  limit: 10,
};

const defaultMeta = {
  totalItems: 0,
  itemCount: 0,
  itemsPerPage: 10,
  totalPages: 0,
  currentPage: 1,
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  payloadCount: {},
  payloadLogs: [],
  logsMeta: defaultMeta,
  logsPagination: defaultPagination,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_PAYLOAD_COUNT:
      return {
        ...state,
        payloadCount: payload,
      };

    case ActionTypes.SET_PAYLOAD_LOGS:
      return {
        ...state,
        payloadLogs: payload,
      };
    case ActionTypes.CLEAR_PAYLOAD_LOGS:
      return {
        ...state,
        payloadLogs: [],
      };

    case ActionTypes.SET_LOGS_META:
      return {
        ...state,
        logsMeta: payload,
      };
    case ActionTypes.SET_LOGS_PAGINATION:
      return {
        ...state,
        logsPagination: payload,
      };
    case ActionTypes.SET_DEFAULT_LOGS_META_PAGINATION:
      return {
        ...state,
        logsPagination: defaultPagination,
        logsMeta: defaultMeta,
      };

    case ActionTypes.GET_COUNT_DASHBOARD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.GET_COUNT_DASHBOARD_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };
    case ActionTypes.GET_COUNT_DASHBOARD_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };

    case ActionTypes.GET_LOGS_ACTIVITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isSuccess: false,
        logsActivity: [],
      };

    case ActionTypes.GET_LOGS_ACTIVITY_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isSuccess: false,
      };

    case ActionTypes.GET_LOGS_ACTIVITY_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
        logsActivity: payload,
      };

    default:
      return state;
  }
};
