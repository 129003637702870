import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

interface SectionProps {
  margin?: string;
  width?: string;
  isRequired?: boolean;
}

const Options = styled(VerticalContainer)`
  position: absolute;
  top: calc(100% + 0px);
  right: -1px;
  width: 110px;
  background: white;
  opacity: 0;
  transition: all ease 0.15s;
  z-index: -1;

  @media screen and (max-width: 426px) {
    width: 80px;
    right: unset;
    left: 0;
  }
`;

const OptionItem = styled(HorizontalContainer)`
  width: 100%;
  height: 0px;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
  box-sizing: border-box;
  transition: all ease 0.15s;
  &:hover {
    background: ${ColorName.shark};
    color: ${ColorName.bianca};
  }
  p {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 836px) {
    height: 25px;
    p {
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 426px) {
    p {
      font-size: 1.4rem;
    }
  }
`;

const DropdownContainer = styled(HorizontalContainer)`
  width: ${(props: SectionProps) => props.width || '100px'};
  margin: ${(props: SectionProps) => props.margin || 'unset'};
  position: relative;
  color: ${ColorName.shark};
  font-weight: 400;
  padding: 0.3em 0.5em;
  transition: all ease 0.15s;
  align-items: center;

  &:hover {
    cursor: pointer;
    ${Options} {
      z-index: 7;
      opacity: 1;
    }
    ${OptionItem} {
      height: 34px;
    }
  }
  @media screen and (max-width: 426px) {
    width: 80px;
    &:hover {
      ${OptionItem} {
        height: 26px;
      }
    }
  }
`;

const LabelDropdown = styled(HorizontalContainer)`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  p {
    font-size: 1.8rem;
  }
  i {
    font-size: 2.2rem;
    transform: translateY(-3px);
  }

  @media screen and (max-width: 796px) {
    height: 25px;
    p {
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 426px) {
    height: 25px;
    p {
      font-size: 1.4rem;
    }
  }
`;

export { DropdownContainer, Options, OptionItem, LabelDropdown };
