import React from "react";
import _ from "lodash";
import { TableContainer, TableContent, OrderDropdown } from "./Table.styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface SectionProps {
  onClick(id: string, key: string): unknown;
  className?: string;
  data?: any[];
  column?: any[];
  fragment?: string;
  handleChangeOrder?(data: any): unknown;
  lang: string;
}

const DATA_TOPIC = [
  {
    label: "socioEconomicDevelopment",
    value: "socioEconomicDevelopment",
  },
  {
    label: "governanceAndRuleofLaw",
    value: "governanceAndRuleOfLaw",
  },
  {
    label: "urbanPlanningAndGis",
    value: "urbanPlanningAndGis",
  },
  {
    label: "environment",
    value: "environment",
  },
  {
    label: "monitoringAndEvaluationServices",
    value: "monitoringAndEvaluationServices",
  },
  {
    label: "marketResearch",
    value: "marketResearch",
  },
  {
    label: "opinionPolls",
    value: "opinionPolls",
  },
  {
    label: "economicResearch",
    value: "economicResearch",
  },
  {
    label: "mediaResearch",
    value: "mediaResearch",
  },
  {
    label: "advertisingResearch",
    value: "advertisingResearch",
  },
  {
    label: "omnibus",
    value: "omnibus",
  },
];

const ORDER_OPTIONS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
];

const Table: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  // const lang = useSelector(state => _.get(state, 'PageSettings.adminLang'));
  const timezone = useSelector((state) =>
    _.get(state, "PageSettings.ip.timezone")
  );
  const {
    onClick,
    className,
    data,
    column,
    fragment,
    handleChangeOrder,
    lang,
  } = props;
  // Declare dispatch, reducers

  const handleClick = (value: any, key: string) => {
    if (onClick) onClick(value, key);
  };

  const handleChangeOrderNumber = (orderNumber: number, id: string) => {
    const findItem = _.find(data, { id });
    if (findItem) {
      const newPayload = {
        ...findItem,
        orderNumber,
      };
      if (handleChangeOrder) handleChangeOrder(newPayload);
    }
  };

  const renderHeader = () => {
    const render = _.map(column, (item, index) => {
      const { isCenter, isSort } = item;
      const centerClass = isCenter
        ? "flex-justify-center"
        : "flex-justify-between";
      return (
        <span key={`header-${index}`} className={`header ${centerClass}`}>
          {item.label ? t(`table.${item.label}`) : ""}{" "}
          {isSort ? (
            <i
              className="fas fa-caret-down ml-2"
              onClick={() => handleClick(item.value, "sort")}
            />
          ) : null}
        </span>
      );
    });
    return render;
  };

  const renderData = () => {
    const render = _.map(data, (item, index) => {
      const innerRender = _.map(column, (cell, innerIndex) => {
        const { isCenter } = cell;
        const centerClass = isCenter
          ? "flex-justify-center"
          : "flex-justify-between";
        const backgroundClass = index % 2 === 0 ? "background" : "";
        if (_.isObject(_.get(item, cell.value))) {
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span>{_.get(item, `${cell.value}.${lang}`)}</span>
            </div>
          );
        }
        if (cell.value === "topic") {
          const findLabel = _.find(DATA_TOPIC, {
            value: _.get(item, cell.value),
          });
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span>
                {_.get(findLabel, "label")
                  ? t(`table.${_.get(findLabel, "label")}`)
                  : "N/I"}
              </span>
            </div>
          );
        }
        if (cell.value === "updatedAt") {
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span>
                {new Date(_.get(item, cell.value)).toLocaleString("en-US", {
                  timeZone: timezone ? timezone : "Europe/Tirane",
                })}
              </span>
            </div>
          );
        }
        if (cell.value === "status") {
          const value = _.get(item, cell.value);
          const labelStatus =
            value === "active" || value === 1
              ? t("table.active")
              : t("table.inactive");
          const statusClass =
            value === "active" || value === 1 ? "active" : "inactive";
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span className={`${statusClass}`}>{labelStatus}</span>
            </div>
          );
        }
        if (cell.value === "edit") {
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span onClick={() => handleClick(item.id, "edit")}>
                <i className="fas fa-pen" />
              </span>
            </div>
          );
        }
        if (cell.value === "orderNumber") {
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <OrderDropdown>
                <span className="label">
                  <span>{_.get(item, "orderNumber") || "Pos "}</span>{" "}
                  <i className="fas fa-sort-down" />
                </span>
                <div className="options">
                  {_.map(ORDER_OPTIONS, (option, index) => {
                    return (
                      <div
                        className={`option ${
                          _.get(item, "orderNumber") === option.value
                            ? "selected"
                            : ""
                        }`}
                        key={`option-${cell.value}-${index}`}
                        onClick={() =>
                          handleChangeOrderNumber(option.value, item.id)
                        }
                      >
                        {_.get(option, "label")}
                      </div>
                    );
                  })}
                </div>
              </OrderDropdown>
            </div>
          );
        }

        if (cell.value === "remove") {
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${innerIndex}`}
            >
              <span onClick={() => handleClick(item, "delete")}>
                <i className="fas fa-trash-alt" />
              </span>
            </div>
          );
        }
        return (
          <div
            className={`${centerClass} content ${backgroundClass}`}
            key={`row-${index}-${innerIndex}`}
          >
            <span key={`row-${index}-${innerIndex}`}>
              {_.get(item, cell.value)}
            </span>
          </div>
        );
      });
      return innerRender;
    });
    return render;
  };

  return (
    <TableContainer className={className} minWidth="100%">
      <TableContent fragment={data ? fragment : "1fr"}>
        {renderHeader()}
        {renderData()}
      </TableContent>
    </TableContainer>
  );
};

export default Table;
