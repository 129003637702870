import React from 'react';
import { LoadingLayer } from './Skeleton.styles';

interface SectionProps {
  className?: string;
}

const SkeletonNewsDetails = (props: SectionProps) => {
  const { className } = props;
  return (
    <LoadingLayer className={className}>
      <div className="timestamp" />
      <div className="title" />
      <div className="description" />
    </LoadingLayer>
  );
};

export default SkeletonNewsDetails;
