import styled from "styled-components";
import { RegularParagraph } from "../../../components/Common";

const AlumniParagraph = styled(RegularParagraph)`
  ul {
    margin: 0;
  }
`;

const AlumniIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: cover;
`;

export { AlumniParagraph, AlumniIcon };
