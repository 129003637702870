/* eslint import/no-anonymous-default-export: off */
import * as BaseAPI from "./Base";
import * as AccountAPI from "./Account.api";
import * as ArticlesAPI from "./Articles.api";
import * as AuthenticationAPI from "./Authentication.api";
import * as CareersAPI from "./Careers.api";
import * as ClientsAPI from "./Clients.api";
import * as ContactAPI from "./Contact.api";
import * as DashboardAPI from "./Dashboard.api";
import * as EditContent from "./EditContent.api";
import * as MediaAPI from "./Media.api";
import * as MembersAPI from "./Members.api";
import * as OurWorkAPI from "./OurWork.api";
import * as SiteSettingsAPI from "./SiteSettings.api";
import * as SearchAPI from "./Search.api";
import * as AlumniNetworkAPI from "./AlumniNetwork.api";

export default {
  ...BaseAPI,
  ...AccountAPI,
  ...ArticlesAPI,
  ...AuthenticationAPI,
  ...CareersAPI,
  ...ContactAPI,
  ...ClientsAPI,
  ...DashboardAPI,
  ...EditContent,
  ...MediaAPI,
  ...MembersAPI,
  ...OurWorkAPI,
  ...SearchAPI,
  ...SiteSettingsAPI,
  ...AlumniNetworkAPI,
};
