const icons = {
  search: require("../assets/icons/search.svg"),
  bigWhiteRightArrow: require("../assets/icons/big_white_right_arrow.svg"),
  rightArrow: require("../assets/icons/right__arrow.png"),
  whiteRightArrow: require("../assets/icons/whiteRightArrow.svg"),
  union: require("../assets/icons/union.svg"),
  password: require("../assets/icons/password.svg"),
  hide: require("../assets/icons/hide.svg"),
  showButton: require("../assets/icons/show_button.svg"),
  defaultUser: require("../assets/icons/default_user.svg"),
  downArrowAboutUs: require("../assets/icons/down_arrow.svg"),
  leftArrow: require("../assets/icons/left_arrow.svg"),
  articles: require("../assets/icons/articles.svg"),
  career: require("../assets/icons/career.svg"),
  dashboard: require("../assets/icons/dashboard.svg"),
  reOrderArticles: require("../assets/icons/re_order_articles.svg"),
  siteSettings: require("../assets/icons/site_settings.svg"),
  trustedBy: require("../assets/icons/trusted_by.svg"),
  expandIcon: require("../assets/icons/expand_icon.svg"),
  english: require("../assets/icons/english.png"),
  albanian: require("../assets/icons/albanian.png"),
  key: require("../assets/icons/key.svg"),
  mail: require("../assets/icons/mail.svg"),
  checkboxSquare: require("../assets/icons/checkbox_square.svg"),
  tick: require("../assets/icons/tick.svg"),
  userTick: require("../assets/icons/tick_user.svg"),
  members: require("../assets/icons/members.svg"),
  doubleRight: require("../assets/icons/double_right.gif"),
  solidSquare: require("../assets/icons/solid_square.svg"),
  emptyImage: require("../assets/icons/empty_image.png"),
  idraAdvance: require("../assets/icons/idra_advance.svg"),
  idraResearch: require("../assets/icons/idra_research.svg"),
  idraDiGIS: require("../assets/icons/idra_digis.svg"),
  solutions: require("../assets/icons/solutions.svg"),
  services: require("../assets/icons/services.svg"),
  ourWork: require("../assets/icons/our_work.svg"),
  clients: require("../assets/icons/clients.svg"),
  news: require("../assets/icons/news.svg"),
  idraPoll: require("../assets/icons/idra_poll.svg"),
  mailContact: require("../assets/icons/email.svg"),
  phoneContact: require("../assets/icons/phone_number.svg"),
  addressContact: require("../assets/icons/location.svg"),
  zipContact: require("../assets/icons/zip_code.svg"),
  directionContact: require("../assets/icons/direction.svg"),
  line: require("../assets/icons/line.svg"),
  plus: require("../assets/icons/plus.svg"),
  grid: require("../assets/icons/grid.svg"),
  masonry: require("../assets/icons/masonry.svg"),
  slider: require("../assets/icons/slider.svg"),
  slideshow: require("../assets/icons/slideshow.svg"),
  thumbnails: require("../assets/icons/thumbnails.svg"),
  alumiNetwork: require("../assets/icons/alumni_network.svg"),
  newsAndInformation: require("../assets/icons/Access to company news and information.svg"),
  alumniStories: require("../assets/icons/Alumni stories.svg"),
  careerAdvancement: require("../assets/icons/Career advancement.svg"),
  consultingServices: require("../assets/icons/Consulting services.svg"),
  expertiseEngagement: require("../assets/icons/Expertise engagement.svg"),
  informationExchange: require("../assets/icons/Information and experience exchange.svg"),
  networkingOpportunities: require("../assets/icons/Networking opportunities.svg"),
  virtualEvents: require("../assets/icons/Virtual events webinars.svg"),
};

export default icons;
