import styled from 'styled-components';
import { ColorName } from '../../../Variables';
import { FlexVertical, FlexHorizontal } from '../../../Common';

const OrderPopupContainer = styled(FlexVertical)`
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 200px;
  background: ${ColorName.white};
  border-radius: 5px;
  overflow: hidden;

  h3 {
    font-size: 1.8rem;
    color: ${ColorName.darkElectricBlue};
  }
`;

const FilterBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  background: ${ColorName.aquaHaze};
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
`;

const FilterLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${ColorName.darkElectricBlue};
`;

const TableContent = styled.div`
  width: 100%;
  height: 420px;
  overflow-y: auto;
  background: ${ColorName.white};
  padding: 34px 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
`;

const ButtonBar = styled(FlexHorizontal)`
  width: 100%;
  height: 60px;
  padding: 0 24px;
  align-items: center;
  box-sizing: border-box;
  background: ${ColorName.aquaHaze};
`;

const TabBar = styled(FlexHorizontal)``;
const TabBarItem = styled(FlexHorizontal)`
  height: 44px;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: ${ColorName.geyser};
  color: ${ColorName.darkElectricBlue};
  /* transition: all ease 0.15s; */
  font-size: 14px;
  &:hover,
  &.active {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

const MediaItem = styled(FlexVertical)`
  width: 100%;
  overflow: hidden;
  height: 150px;
  img,
  video,
  canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #eee;
    box-sizing: border-box;
  }
  /* video {
    display: none;
  } */

  p {
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
    color: ${ColorName.darkElectricBlue};
    margin-top: 10px;
  }
`;

const FunctionBox = styled.div`
  position: relative;

  & .image-box {
    display: flex;
    align-items: center;
    flex: 1;
    height: 110px;
  }

  & .layer {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    opacity: 0;
    /* transition: all ease 0.15s; */

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    & .select,
    & .remove {
      margin: 5px 0;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    & .select {
      background: ${ColorName.burntSienna};
      color: ${ColorName.white};
    }
    & .remove {
      background: ${ColorName.geyser};
      color: ${ColorName.darkElectricBlue};
    }
  }
`;

export {
  OrderPopupContainer,
  FilterBar,
  FilterLabel,
  TableContent,
  ButtonBar,
  TabBar,
  TabBarItem,
  MediaItem,
  FunctionBox,
};
