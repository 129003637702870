import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { AlumniNetworkTable } from "../../../components/Layout/CommonLayoutPart/Table";
import {
  AdminDropdown,
  FlexVertical,
  FlexHorizontal,
  GeyserSolidButton,
  InputSearchField,
  AdminPagination,
  ContentContainer,
  AdminLabel,
  // AdminAutocomplete,
} from "../../../components/Common";
import { ROUTES, ColumnsTable /* COUNTRY_OPTIONS */ } from "../../../constants";
import history from "../../../history";
import { AlumniNetworkActions } from "../../../actions";
import { Utils } from "../../../libs";

const {
  fetchAlumniNetworks,
  setAlumniNetworkOrder,
  resetAlumniNetworkReducer,
} = AlumniNetworkActions;

const DEFAULT_FILTER = {
  page: 1,
  limit: 10,
  status: "",
  keyword: "",
  country: "",
};

// interface IOptionStructure {
//   label: string;
//   value: string;
// }

const AlumniaNetworkManagement = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  const locale = Utils.getUserLocale();
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const requestIsLoading = useSelector((state) =>
    _.get(state, "AlumniNetwork.requestIsLoading")
  );
  const payload = useSelector((state) => _.get(state, "AlumniNetwork.payload"));
  const pagination = useSelector((state) =>
    _.get(state, "AlumniNetwork.pagination")
  );
  const sortOrder = useSelector((state) =>
    _.get(state, "AlumniNetwork.sortOrder")
  );

  const meta = useSelector((state) => _.get(state, "AlumniNetwork.meta"));

  const [filter, setFilter] = useState(DEFAULT_FILTER);
  // const [countryOptions, setCountryOptions] = useState<IOptionStructure[]>([]);

  useEffect(() => {
    dispatch(
      fetchAlumniNetworks({
        page: 1,
        limit: 10,
      })
    );
    return () => {
      dispatch(resetAlumniNetworkReducer());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (COUNTRY_OPTIONS) {
  //     const resolveOptions = _.map(COUNTRY_OPTIONS, (option) => {
  //       return {
  //         label: option.name,
  //         value: option.code,
  //       };
  //     });
  //     setCountryOptions(resolveOptions);
  //   }
  // }, []);

  const handleSearch = () =>
    dispatch(
      fetchAlumniNetworks({
        ...filter,
        keyword: _.trim(filter.keyword),
      })
    );

  const handleSort = (key: string) => {
    for (const _key in sortOrder) {
      if (_key === key) {
        const newOrder = sortOrder;
        newOrder[_key] = newOrder[_key] === "ASC" ? "DESC" : "ASC";
        dispatch(
          fetchAlumniNetworks({
            ...pagination,
            sortBy: key,
            orderBy: newOrder[_key],
          })
        );
        dispatch(setAlumniNetworkOrder(newOrder));
      }
    }
  };

  const handleChangeFilter = (value: any, key: string) => {
    setFilter({ ...filter, [key]: value });
    const newFilter = {
      ...pagination,
      [key]: value,
    };
    if (key !== "keyword") dispatch(fetchAlumniNetworks(newFilter));
  };

  const handleClearFilter = () => {
    setFilter(DEFAULT_FILTER);
    dispatch(fetchAlumniNetworks(DEFAULT_FILTER));
  };

  const handleViewDetails = (value: string, key: string) => {
    if (key === "edit" && value)
      history.push(`${ROUTES.ADMIN.ALUMNI_NETWORK_DETAILS}?id=${value}`);
  };

  const handleClickPagination = async (currentPage: number) => {
    dispatch(fetchAlumniNetworks({ ...filter, ...pagination, page: currentPage }));
  };

  const _renderFilterBar = () => {
    return (
      <FlexHorizontal>
        <FlexHorizontal flex="1" alignCenter>
          <FlexVertical className="mr-1">
            <InputSearchField
              width="30rem"
              placeholder={t("placeholder.searchByKeyword")}
              value={_.get(filter, "keyword")}
              onChange={(e: any) =>
                handleChangeFilter(e.target.value, "keyword")
              }
              onKeydown={() => handleSearch()}
              onClick={() => handleSearch()}
            />
          </FlexVertical>
          {/* <FlexVertical className="mr-1">
            <AdminAutocomplete
              options={countryOptions}
              value={_.get(filter, "country")}
              onChange={(value: string) => handleChangeFilter(value, "country")}
              placeholder={t("placeholder.searchByCountry")}
            />
          </FlexVertical> */}
        </FlexHorizontal>

        <FlexHorizontal flex="1" alignCenter className="mr-1">
          <AdminLabel>{t("label.status")}</AdminLabel>
          <AdminDropdown
            width="12rem"
            data={[
              { label: "open", value: "open" },
              {
                label: "denied",
                value: "denied",
              },
              {
                label: "approved",
                value: "approved",
              },
            ]}
            selectedValue={_.get(filter, "status")}
            onChange={(value: any) => handleChangeFilter(value, "status")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignCenter>
          <GeyserSolidButton onClick={() => handleClearFilter()}>
            {t("button.reset")}
          </GeyserSolidButton>
        </FlexHorizontal>
      </FlexHorizontal>
    );
  };

  const _renderTable = () => (
    <>
      <AlumniNetworkTable
        className="mt-3"
        column={ColumnsTable.ALUMNI_NETWORK}
        data={payload}
        onClick={(value: any, key: string) => handleViewDetails(value, key)}
        fragment="1.5fr 1fr 0.5fr 1fr 0.5fr 0.5fr"
        onSort={(value: string) => handleSort(value)}
        orderBy={_.get(pagination, "orderBy")}
        sortBy={_.get(pagination, "sortBy")}
        lang={locale}
      />
      {!_.isEmpty(payload) && (
        <FlexHorizontal className="mt-3" justifyFlexEnd>
          <AdminPagination
            maxPage={_.get(meta, "totalPages")}
            currentPage={_.get(meta, "currentPage")}
            numberPageDisplay={2}
            totalCount={_.get(meta, "totalItems")}
            clickLinkPagination={(page: number) => handleClickPagination(page)}
            clickLinkNext={(page: number) => handleClickPagination(page)}
            clickLinkPrevious={(page: number) => handleClickPagination(page)}
            clickLinkLast={() =>
              handleClickPagination(_.get(meta, "totalPages"))
            }
            clickLinkFirst={() => handleClickPagination(1)}
          />
        </FlexHorizontal>
      )}
    </>
  );

  const renderMain = () => {
    return (
      <ContentContainer>
        {_renderFilterBar()}
        {_renderTable()}
      </ContentContainer>
    );
  };
  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.ALUMNI_NETWORK}
      loading={requestIsLoading}
    />
  );
};

export default AlumniaNetworkManagement;
