/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Content,
  SectionCard,
  SectionTitle,
  SkeletonCareerCard,
  PageContainer,
  GridIDRAPage,
  RegularParagraph,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES } from "../../../constants/";
import { CareersActions } from "../../../actions";

// Declare actions
const { getAllCareers } = CareersActions;

const Careers = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare dispatch and reducers
  const dispatch = useDispatch();
  const payload = useSelector((state) => _.get(state, "Careers.payload"));
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Careers.isLoading")
  );
  const content = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.CAREER}.content`)
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    dispatch(getAllCareers());
  }, [lang]);

  useEffect(() => {
    if (descriptionRef && descriptionRef.current)
      descriptionRef.current.innerHTML = _.get(content, lang);
  }, [lang, content]);

  // Render description of Career section
  const renderQuote = () => {
    return (
      <>
        <RegularParagraph textAlignLeft fontSize="15px" ref={descriptionRef} />
        <SectionTitle
          fontSize="2.6rem"
          className={`mt-5 ${
            !isRequestLoading && _.isEmpty(payload) ? "mb-0" : "mb-3"
          }`}
        >
          {t("label.currentJobOpenings")}
        </SectionTitle>
        {!isRequestLoading && _.isEmpty(payload) && (
          <RegularParagraph textAlignLeft fontSize="16px">
            {t("label.noCareersFound")}
          </RegularParagraph>
        )}
      </>
    );
  };

  // Render job list
  const renderJobList = () => {
    if (!_.isEmpty(payload)) {
      const render = _.map(payload, (item, index) => {
        const careerId = _.get(item, "id");
        const slug = _.get(item, "slug");
        return (
          <SectionCard
            pathname={ROUTES.USER.CAREER}
            data={{
              ...item,
              link: `${ROUTES.USER.CAREER}/${slug ? slug : careerId}`,
            }}
            key={`job-${index}`}
          />
        );
      });
      return render;
    }
    return null;
  };

  // Render skeleton when change language
  const renderSkeletonLoading = () => {
    if (_.isEmpty(payload)) {
      const render = _.map([0, 1, 2, 3], (item, index) => {
        return <SkeletonCareerCard key={`career-skeleton-${index}`} />;
      });
      return render;
    }
    return null;
  };

  const renderMain = () => {
    return (
      <PageContainer>
        <Content className="pb-5 pt-5" direction="column">
          {renderQuote()}
          <GridIDRAPage colGap="35px" rowGap="15px">
            {isRequestLoading ? renderSkeletonLoading() : renderJobList()}
          </GridIDRAPage>
        </Content>
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.CAREER}
      headerTitle="Careers"
      metaDescription="Joining IDRA Research & Consulting gives you an opportunity to work on challenging projects that can make a difference and allow you to grow professionally."
    />
  );
};

export default Careers;
