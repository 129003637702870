/* eslint import/no-anonymous-default-export: off */
import { Icons } from "../themes";

export default {
  NEWS_TOPIC: [
    {
      label: "socioEconomicDevelopment",
      value: "socioEconomicDevelopment",
    },
    {
      label: "governanceAndRuleofLaw",
      value: "governanceAndRuleOfLaw",
    },
    {
      label: "urbanPlanningAndGis",
      value: "urbanPlanningAndGis",
    },
    {
      label: "environment",
      value: "environment",
    },
    {
      label: "monitoringAndEvaluationServices",
      value: "monitoringAndEvaluationServices",
    },
    {
      label: "marketResearch",
      value: "marketResearch",
    },
    {
      label: "opinionPolls",
      value: "opinionPolls",
    },
    {
      label: "economicResearch",
      value: "economicResearch",
    },
    {
      label: "mediaResearch",
      value: "mediaResearch",
    },
    {
      label: "advertisingResearch",
      value: "advertisingResearch",
    },
    {
      label: "omnibus",
      value: "omnibus",
    },
  ],
  MEMBERS_TYPE: [
    {
      label: "management",
      value: "management",
    },
    {
      label: "IDRAResearch",
      value: "idraResearch",
    },
    {
      label: "IDRAAdvance",
      value: "idraAdvance",
    },
    {
      label: "IDRADiGIS",
      value: "idraDiGIS",
    },
    {
      label: "administration",
      value: "administration",
    },
  ],
  ADMIN_LANGUAGE_OPTIONS: [
    {
      icon: Icons.english.default,
      lang: "en",
    },
    {
      icon: Icons.albanian.default,
      lang: "al",
    },
  ],
  STATUS: [
    {
      label: "active",
      value: 1,
    },
    {
      label: "inactive",
      value: 0,
    },
  ],
  LANGUAGE: [
    {
      label: "english",
      value: "en",
    },
    {
      label: "albanian",
      value: "al",
    },
  ],
  FEATURES: [
    {
      label: "news",
      value: "news",
    },
    {
      label: "publications",
      value: "publications",
    },
    {
      label: "projects",
      value: "projects",
    },
    {
      label: "successStories",
      value: "successStories",
    },
  ],
  CATEGORY: [
    {
      label: "all",
      value: "all",
    },
    {
      label: "socioEconomicDevelopment",
      value: "socioEconomicDevelopment",
    },
    {
      label: "governanceAndRuleofLaw",
      value: "governanceAndRuleOfLaw",
    },
    {
      label: "urbanPlanningAndGis",
      value: "urbanPlanningAndGis",
    },
    {
      label: "environment",
      value: "environment",
    },
  ],
  ORDER_ARTICLES: [
    {
      label: "news",
      value: "news",
    },
    {
      label: "work",
      value: "work",
    },
  ],
  CLIENTS: [
    {
      label: "homepage",
      value: "homepage",
    },
    {
      label: "whoWeAre",
      value: "whoWeAre",
    },
    {
      label: "researchClients",
      value: "researchClients",
    },
    {
      label: "developmentClients",
      value: "developmentClients",
    },
  ],
  CATEGORY_CLIENTS: {
    "fc7a3d0f-4e27-4c33-bd56-a58ea7f74002": [
      {
        label: "internationalOrganizationsOrInstitutions",
        value: "internationalOrganizationsOrInstitutions",
      },
      {
        label: "publicInstitutions",
        value: "publicInstitutions",
      },
      {
        label: "developmentProgramsAndNGOs",
        value: "developmentProgramsAndNGOs",
      },
      {
        label: "consumerProducts",
        value: "consumerProducts",
      },
      {
        label: "retail",
        value: "retail",
      },
      {
        label: "banking",
        value: "banking",
      },
      {
        label: "universities",
        value: "universities",
      },
      {
        label: "telecommunication",
        value: "telecommunication",
      },
      {
        label: "consultancy",
        value: "consultancy",
      },
      {
        label: "automotive",
        value: "automotive",
      },
      {
        label: "aviation",
        value: "aviation",
      },
      {
        label: "construction",
        value: "construction",
      },
      {
        label: "mediaAndAdvertising",
        value: "mediaAndAdvertising",
      },
      {
        label: "tobacco",
        value: "tobacco",
      },
      {
        label: "oil",
        value: "oil",
      },
      {
        label: "entertainment",
        value: "entertainment",
      },
    ],
    "33b3e87e-5814-4a47-b323-ecf99680dfb2": [
      {
        label: "internationalOrganizationsOrInstitutions",
        value: "internationalOrganizationsOrInstitutions",
      },
      {
        label: "publicInstitutions",
        value: "publicInstitutions",
      },
      {
        label: "developmentProgramsAndNGOs",
        value: "developmentProgramsAndNGOs",
      },
      {
        label: "consumerProducts",
        value: "consumerProducts",
      },
      {
        label: "retail",
        value: "retail",
      },
      {
        label: "banking",
        value: "banking",
      },
      {
        label: "universities",
        value: "universities",
      },
      {
        label: "telecommunication",
        value: "telecommunication",
      },
      {
        label: "consultancy",
        value: "consultancy",
      },
      {
        label: "automotive",
        value: "automotive",
      },
      {
        label: "aviation",
        value: "aviation",
      },
      {
        label: "construction",
        value: "construction",
      },
      {
        label: "mediaAndAdvertising",
        value: "mediaAndAdvertising",
      },
      {
        label: "tobacco",
        value: "tobacco",
      },
      {
        label: "oil",
        value: "oil",
      },
      {
        label: "entertainment",
        value: "entertainment",
      },
    ],
    "07550c9b-9cad-476d-90de-a20650be28e7": [
      {
        label: "internationalOrganizationsOrInstitutions",
        value: "internationalOrganizationsOrInstitutions",
      },
      {
        label: "publicInstitutions",
        value: "publicInstitutions",
      },
      {
        label: "developmentProgramsAndNGOs",
        value: "developmentProgramsAndNGOs",
      },
      {
        label: "consultancy",
        value: "consultancy",
      },
    ],
  },
  CLIENT_PAGES: [
    {
      label: "homepage",
      value: "98f31c11-7bbb-4dfc-bd24-8df159b294f2",
    },
    {
      label: "ourClients",
      value: "fc7a3d0f-4e27-4c33-bd56-a58ea7f74002",
    },
    {
      label: "researchClients",
      value: "33b3e87e-5814-4a47-b323-ecf99680dfb2",
    },
    {
      label: "developmentClients",
      value: "07550c9b-9cad-476d-90de-a20650be28e7",
    },
  ],
  TOPIC: {
    NEWS: [
      {
        label: "socioEconomicDevelopment",
        value: "socioEconomicDevelopment",
      },
      {
        label: "governanceAndRuleofLaw",
        value: "governanceAndRuleOfLaw",
      },
      {
        label: "urbanPlanningAndGis",
        value: "urbanPlanningAndGis",
      },
      {
        label: "environment",
        value: "environment",
      },
      {
        label: "monitoringAndEvaluationServices",
        value: "monitoringAndEvaluationServices",
      },
      {
        label: "marketResearch",
        value: "marketResearch",
      },
      {
        label: "opinionPolls",
        value: "opinionPolls",
      },
      {
        label: "economicResearch",
        value: "economicResearch",
      },
      {
        label: "mediaResearch",
        value: "mediaResearch",
      },
      {
        label: "advertisingResearch",
        value: "advertisingResearch",
      },
      {
        label: "omnibus",
        value: "omnibus",
      },
    ],
    RESEARCH_NEWS: [
      {
        label: "marketResearch",
        value: "marketResearch",
      },
      {
        label: "opinionPolls",
        value: "opinionPolls",
      },
      {
        label: "economicResearch",
        value: "economicResearch",
      },
      {
        label: "mediaResearch",
        value: "mediaResearch",
      },
      {
        label: "advertisingResearch",
        value: "advertisingResearch",
      },
      {
        label: "omnibus",
        value: "omnibus",
      },
    ],
    DEVELOPMENT_NEWS: [
      {
        label: "socioEconomicDevelopment",
        value: "socioEconomicDevelopment",
      },
      {
        label: "governanceAndRuleofLaw",
        value: "governanceAndRuleOfLaw",
      },
      {
        label: "urbanPlanningAndGis",
        value: "urbanPlanningAndGis",
      },
      {
        label: "environment",
        value: "environment",
      },
      {
        label: "monitoringAndEvaluationServices",
        value: "monitoringAndEvaluationServices",
      },
    ],
  },
  SOLUTION: [
    {
      label: "general",
      value: "general",
    },
    {
      label: "IDRAResearch",
      value: "researchNews",
    },
    {
      label: "IDRAAdvance",
      value: "developmentNews",
    },
    {
      label: "IDRADiGIS",
      value: "DiGISNews",
    },
  ],
  FILTER_SOLUTION: [
    {
      label: "all",
      value: "",
    },
    {
      label: "general",
      value: "general",
    },
    {
      label: "IDRAResearch",
      value: "researchNews",
    },
    {
      label: "IDRAAdvance",
      value: "developmentNews",
    },
    {
      label: "IDRADiGIS",
      value: "DiGISNews",
    },
  ],
  EDITABLE_PAGES: [
    {
      label: "researchSolutions",
      value: "researchSolutions",
    },
    {
      label: "developmentSolutions",
      value: "developmentSolutions",
    },
  ],
  SOLUTIONS_TYPE: {
    researchSolutions: [
      {
        label: "marketResearch",
        value: "marketResearch",
      },
      {
        label: "opinionPolls",
        value: "opinionPolls",
      },
      {
        label: "economicResearch",
        value: "economicResearch",
      },
      {
        label: "mediaResearch",
        value: "mediaResearch",
      },
      {
        label: "advertisingResearch",
        value: "advertisingResearch",
      },
      {
        label: "omnibus",
        value: "omnibus",
      },
    ],
    developmentSolutions: [
      {
        label: "socioEconomicDevelopment",
        value: "socioEconomicDevelopment",
      },
      {
        label: "governanceAndRuleofLaw",
        value: "governanceAndRuleOfLaw",
      },
      {
        label: "urbanPlanningAndGis",
        value: "urbanPlanningAndGis",
      },
      {
        label: "environment",
        value: "environment",
      },
      {
        label: "monitoringAndEvaluationServices",
        value: "monitoringAndEvaluationServices",
      },
    ],
  },
  ALUMNI_NETWORK: {
    expertise: [
      {
        label: "socioEconomicDevelopment",
        value: "socioEconomicDevelopment",
      },
      {
        label: "governanceAndRuleofLaw",
        value: "governanceAndRuleofLaw",
      },
      {
        label: "urbanPlanningAndGis",
        value: "urbanPlanningAndGis",
      },
      {
        label: "environment",
        value: "environment",
      },
      {
        label: "monitoringAndEvaluationServices",
        value: "monitoringAndEvaluationServices",
      },
      {
        label: "marketResearch",
        value: "marketResearch",
      },
      {
        label: "opinionPolls",
        value: "opinionPolls",
      },
      {
        label: "economicResearch",
        value: "economicResearch",
      },
      {
        label: "mediaResearch",
        value: "mediaResearch",
      },
      {
        label: "advertisingResearch",
        value: "advertisingResearch",
      },
      {
        label: "softwareDeveloper",
        value: "softwareDeveloper",
      },
      {
        label: "webDevelopment",
        value: "webDevelopment",
      },
      {
        label: "other",
        value: "other",
      },
    ],
    status: [
      { label: "approved", value: "approved" },
      { label: "open", value: "open" },
      { label: "denied", value: "denied" },
    ],
    social: [
      {
        label: "facebook",
        value: "facebook",
      },
      {
        label: "youtube",
        value: "youtube",
      },
      {
        label: "linkedIn",
        value: "linkedIn",
      },
      {
        label: "instagram",
        value: "instagram",
      },
    ],
  },
};
