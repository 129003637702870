/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { EditIDRAPageContainer } from "./EditIDRAPage.styles";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../constants";
import {
  IDRACardEditable,
  GridLayout,
  Richtextbox,
} from "../../../../components/Common";
import { useTranslation } from "react-i18next";

interface SectionProps {
  className?: string;
}

const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditIDRAPage = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const payload: any = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const editingPage: any = useSelector((state) =>
    _.get(state, "EditContent.pageEditing")
  );
  const editingLang: any = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );

  useEffect(() => {
    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getAllContentForUser());
  }, [editingPage]);

  const handleChange = (value: string, key: string) => {
    const isEdited = !_.isEqual(value, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const currentPayload = _.get(payload, editingPage);
    const newContent = {
      ...currentPayload,
      [key]: {
        ...currentPayload[key],
        [editingLang]: value,
      },
    };
    dispatch(setEditContentPayload({ ...payload, [editingPage]: newContent }));
  };

  const handleChangeIDRACard = (value: any, order: number) => {
    const currentPayload = _.get(payload, editingPage);
    const sections = _.get(currentPayload, "sections");
    const index = _.findIndex(sections, { order });
    sections[index] = value;
    const resolvePayload = {
      ...currentPayload,
      sections,
    };
    dispatch(
      setEditContentPayload({ ...payload, [editingPage]: resolvePayload })
    );
  };

  return (
    <EditIDRAPageContainer className={className}>
      <div className="flex-row flex-justify-center">
        <img
          src={_.get(payload, `${editingPage}.icon`)}
          alt="section-icon"
          className="section-icon"
        />
      </div>
      <h3
        spellCheck={false}
        suppressContentEditableWarning
        contentEditable={true}
        onFocus={(e: any) =>
          dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
        }
        onBlur={(e: any) =>
          handleChange(e.currentTarget.innerText, "pageTitle")
        }
        data-placeholder={t("placeholder.typeTheTitleHere")}
        className="flex-row flex-justify-center mt-1 mb-3 editable"
      >
        {_.get(payload, `${editingPage}.pageTitle.${editingLang}`)}
      </h3>
      <Richtextbox
        onChange={(value: any) =>
          handleChange(_.trim(value), "pageDescription")
        }
        onFocus={(value: any) => dispatch(setContentEdited(_.trim(value)))}
        data={
          _.get(payload, `${editingPage}.pageDescription.${editingLang}`) || ""
        }
        className="idra-description"
        fontSize="16px"
        toolbar={[
          "bold",
          "link",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "undo",
          "redo",
        ]}
      />
      <GridLayout
        className="mt-5 mb-3"
        col={editingPage === ROUTES.USER.IDRA_ADVANCE ? "4" : "3"}
        colGap="35px"
        rowGap="30px"
      >
        {_.map(_.get(payload, `${editingPage}.sections`), (item, index) => {
          const order = _.get(item, "order");
          return (
            <IDRACardEditable
              onChange={(value: any) => handleChangeIDRACard(value, order)}
              keyCard={`idra-card-${index}`}
              data={item}
              key={`card-${index}`}
            />
          );
        })}
      </GridLayout>
    </EditIDRAPageContainer>
  );
};

export default EditIDRAPage;
