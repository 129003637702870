import Service from "./Base";
import API from "../constants/API";

const { NEWS } = API;

export function getAllArticles(data: any) {
  return Service.get(NEWS.BASIC, data);
}

export function getArticlesById(id: string, type?: string) {
  return Service.get(`${NEWS.GET_BY_ID}/${id}`, type);
}

export function filterArticles(data: any) {
  return Service.get(NEWS.FILTER, data);
}

export function createArticles(data: any) {
  return Service.post(NEWS.BASIC, data);
}

export function updateArticles(data: any) {
  const { id } = data;
  return Service.put(`${NEWS.BASIC}/${id}`, data);
}

export function removeSingleArticles(id: string) {
  return Service.del(`${NEWS.BASIC}/${id}`);
}

export function removeMultipleArticles(data: any) {
  return Service.del(NEWS.REMOVE, { ids: data });
}

export function getArticlesOrdered(data?: any) {
  return Service.get(NEWS.ORDERED, data);
}

export function getArticlesUnordered(data?: any) {
  return Service.get(NEWS.UNORDERED, data);
}

export function getArticlesLatest(data?: any) {
  return Service.get(NEWS.LATEST, data);
}
