import styled from 'styled-components';
import { HorizontalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

interface SectionProps {
  margin?: string;
  width?: string;
  isRequired?: boolean;
}

const ComponentInput = styled(HorizontalContainer)`
  width: ${(props: SectionProps) => props.width || '100%'};
  margin: ${(props: SectionProps) => props.margin || 'unset'};
  color: ${ColorName.black};
  align-items: center;

  justify-content: flex-end;
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0.25rem;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0.25rem;
  }
`;

const Icon = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  background: ${ColorName.cararra};
  transition: all ease 0.25s;
  &:hover {
    cursor: pointer;
    background: ${ColorName.black};
    color: ${ColorName.cararra};
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.45;
    &:hover {
      background: ${ColorName.cararra};
      color: ${ColorName.black};
    }
  }
`;

export { ComponentInput, Icon };
