/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { Icons } from "../../../themes";
import { ColorName } from "../../Variables";
interface SectionProps {
  label: string;
  onChange(value: string | number): unknown;
  checked: boolean;
  className?: string;
  isLeftLabel?: boolean;
  disabled?: boolean;
  value: string | number;
}

const Label = styled.p`
  font-size: 14px;
  color: ${ColorName.darkElectricBlue};
`;

const CheckboxSquare = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 5px;

  & .tick-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 4px;
    left: 3px;
    opacity: 0;
    /* transition: all ease 0.25s; */

    &.checked {
      opacity: 1;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    ${CheckboxSquare} {
      & .tick-icon {
        opacity: 0.8;
      }
    }
  }
  &.disabled {
    opacity: 0.8;
    &:hover {
      cursor: not-allowed;
      ${CheckboxSquare} {
        & .tick-icon {
          opacity: 0;
        }
      }
    }
  }
`;

const Checkbox: React.FC<SectionProps> = (props: SectionProps) => {
  const { onChange, checked, className, label, isLeftLabel, disabled, value } =
    props;

  const handleCheck = () => {
    if (disabled) return;
    if (onChange) onChange(value);
  };

  return (
    <CheckboxContainer
      className={`${className} ${disabled ? "disabled" : ""}`}
      onClick={() => handleCheck()}
    >
      {isLeftLabel && <Label>{label}</Label>}
      <CheckboxSquare>
        <img src={Icons.checkboxSquare.default} alt="square" />
        <img
          src={Icons.tick.default}
          alt="tick"
          className={`tick-icon ${checked ? "checked" : ""}`}
        />
      </CheckboxSquare>
      {!isLeftLabel && <Label>{label}</Label>}
    </CheckboxContainer>
  );
};

export default Checkbox;
