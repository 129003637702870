/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import Service from '../api/Base';

// SINGLE ACTIONS
const setUserLang = (payload: any) => {
  return {
    type: ActionTypes.SET_USER_LANG,
    payload,
  };
};

const setAdminLang = (payload: any) => {
  return {
    type: ActionTypes.SET_ADMIN_LANG,
    payload,
  };
};

const setExpandSideMenu = (payload: any) => {
  return {
    type: ActionTypes.SET_EXPAND_SIDE_MENU,
    payload,
  };
};

const setFirstLoad = (payload: any) => {
  return {
    type: ActionTypes.SET_FIRST_LOAD_FOR_USER,
    payload,
  };
};

const setStatusEdited = (payload: any) => {
  return {
    type: ActionTypes.SET_STATUS_EDITED,
    payload,
  };
};

const setContentEdited = (payload: any) => {
  return {
    type: ActionTypes.SET_CONTENT_EDITED,
    payload,
  };
};

const setPageRouter = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGE_ROUTER,
    payload,
  };
};

const getIpLocationFail = () => {
  return {
    type: ActionTypes.GET_IP_LOCATION_FAILURE,
  };
};

const getIpLocationRequest = () => {
  return {
    type: ActionTypes.GET_IP_LOCATION_REQUEST,
  };
};

const getIpLocationSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_IP_LOCATION_SUCCESS,
    payload,
  };
};

const getIpLocation = () => {
  return async (dispatch: any) => {
    dispatch(getIpLocationRequest());
    await Service.getIp().then(async (response: any) => {
      const { data } = response;
      if (data) return dispatch(getIpLocationSuccess(data));
      dispatch(getIpLocationFail());
    });
  };
};

export default {
  setUserLang,
  setAdminLang,
  setExpandSideMenu,
  setFirstLoad,
  setStatusEdited,
  setContentEdited,
  setPageRouter,
  getIpLocation,
};
