/* eslint import/no-anonymous-default-export: off */
import ActionTypes from "../constants/ActionTypes";

const initialState = {
  requestIsLoading: false,
  pagination: { page: 1, limit: 10 },
  sortOrder: { name: "", email: "", country: "", createdAt: "", status: "" },
  meta: null,
  payload: [],
  details: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_ALUMNI_NETWORK_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ActionTypes.SET_ALUMNI_NETWORK_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case ActionTypes.SET_ALUMNI_NETWORK_META:
      return {
        ...state,
        meta: payload,
      };
    case ActionTypes.SET_ALUMNI_NETWORK_IS_LOADING:
      return {
        ...state,
        requestIsLoading: payload,
      };

    case ActionTypes.RESET_ALUMNI_NETWORK_REDUCER:
      return initialState;

    case ActionTypes.FETCH_ALUMNI_NETWORK_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        requestIsLoading: false,
        payload,
      };
    case ActionTypes.FETCH_ALUMNI_NETWORK_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    case ActionTypes.GET_ALUMNI_NETWORK_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        requestIsLoading: false,
        details: payload,
      };
    case ActionTypes.GET_ALUMNI_NETWORK_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };
    case ActionTypes.CREATE_ALUMNI_NETWORK_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        requestIsLoading: false,
        payload,
      };
    case ActionTypes.CREATE_ALUMNI_NETWORK_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };
    case ActionTypes.APPROVE_ALUMNI_NETWORK_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        requestIsLoading: false,
      };
    case ActionTypes.DENY_ALUMNI_NETWORK_SUCCESS:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        requestIsLoading: false,
      };

    default:
      return state;
  }
};
