import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Content, ClientsIndustries, PageContainer } from '../../../components/Common';
import { DefaultUserLayout } from '../../../components/Layout/DefaultLayout';
import { ROUTES, CONSTANTS } from '../../../constants';

const ResearchClients = () => {
  // Declare translation
  const { t } = useTranslation('user');
  // Declare reducers
  const clientPayload = useSelector(state => get(state, `Clients.payload.${ROUTES.USER.RESEARCH_CLIENTS}`));
  const isRequestLoading = useSelector(state => get(state, 'Clients.isLoading'));
  // Declare states
  const [currentType, setCurrentType] = useState<string>('internationalOrganizationsOrInstitutions');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderMain = () => {
    return (
      <PageContainer padding="80px 0 100px" minHeight="600px">
        <Content>
          <ClientsIndustries
            header={CONSTANTS.CLIENTS_INDUSTRY_HEADER.IDRA_RESEARCH}
            data={get(clientPayload, currentType) || []}
            currentType={currentType}
            onChange={(value: string) => setCurrentType(value)}
            currentPage={ROUTES.USER.RESEARCH_CLIENTS}
            onLoaded={() => setIsLoading(false)}
          />
        </Content>
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_CLIENTS}
      titlePage={t('titlePage.researchClients')}
      headerTitle="Research Clients"
      metaDescription="All the Institutions, Companies and Organizations we have worked with"
      loading={isRequestLoading || isLoading}
    />
  );
};

export default ResearchClients;
