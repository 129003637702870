/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { GalleryContainer } from "./NewsDetails.styles";
import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES, DropdownOptions, CONSTANTS } from "../../../constants";
import { ColorName } from "../../../components/Variables";
import { ArticlesActions, PageSettingsActions } from "../../../actions";
import {
  AdminDropdown,
  CropImage,
  FlexHorizontal,
  FlexVertical,
  GeyserSolidButton,
  CarrotSolidButton,
  GrannyRegularParagraph,
  TabBar,
  PreviewImage,
  Radio,
  ContentForm,
  ContentContainer,
  AdminLabel,
  UpdatedLabel,
  ReactQuill,
  InputCalendar,
} from "../../../components/Common";
import {
  GalleryImages,
  PreviewNews,
  SelectMedia,
} from "../../../components/Layout/Popups";
import { GenerateValue, statusAlert } from "../../../libs";
import history from "../../../history";
import { TAB_BAR } from "../../../constants/Constants";
import GridImageLayout from "../../UserPage/NewsDetails/GridImageLayout";

const {
  getArticlesById,
  updateArticles,
  clearArticlesDetails,
  createArticles,
} = ArticlesActions;
const { generateDropdown } = GenerateValue;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const ArticlesDetails = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const newsDetails = useSelector((state) => _.get(state, "Articles.details"));
  // const filter = useSelector(state => _.get(state, 'Articles.filter.admin'));
  const currentType = useSelector((state) => _.get(state, "Media.filter.type"));
  const requestIsLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const requestMediaIsLoading = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const isExpand = useSelector((state) =>
    _.get(state, "PageSettings.isExpandMenu")
  );
  // Declare states, ref
  const [selectedTab, setSelectedTab] = useState<string>("options");
  const [currentEditor, setCurrentEditor] = useState<string>("content");
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowPreviewPopup, setIsShowPreviewPopup] = useState<boolean>(false);
  const [isShowGalleryPopup, setIsShowGalleryPopup] = useState<boolean>(false);
  const [galleryData, setGalleryData] = useState<any>({
    images: [],
    type: "grid",
  });

  const [details, setDetails] = useState<any>({});

  const detailsRef = React.useRef<any>(null);
  detailsRef.current = details;

  useEffect(() => {
    const id = _.get(queryStringValue, "id");
    if (_.isString(id) && _.includes(id, "-")) dispatch(getArticlesById(id));
    return () => {
      dispatch(setStatusEdited(false));
      dispatch(clearArticlesDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newsDetails)) {
      setDetails(newsDetails);
    }
  }, [newsDetails]);

  const onChangeHandler = (value: any, key: string, defaultValue?: any) => {
    const resolveDetails = {
      ...detailsRef.current,
      [key]: value,
    };
    if (key === "page" && value !== "news")
      _.assign(resolveDetails, { categories: "" });
    if (key === "features" && _.includes(["insights", "successStories"], value))
      _.assign(resolveDetails, { topic: "" });
    if (key === "mediaURL") setIsShowPopup(false);
    setDetails(resolveDetails);
  };

  const checkValidateForm = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    const validateData = [
      {
        value: _.get(details, "categories"),
        label: t("alert.solutions"),
      },
      {
        value: _.get(details, "thumbnail"),
        label: t("alert.thumbnail"),
      },
      {
        value: _.get(details, "features"),
        label: t("alert.features"),
      },
    ];
    if (languageDisplay === "both")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.en"),
            label: t("alert.titleInEnglish"),
          },
          {
            value: _.get(details, "description.en"),
            label: t("alert.descriptionInEnglish"),
          },
          {
            value: _.trim(_.get(details, "content.en")),
            label: t("alert.contentInEnglish"),
          },
          {
            value: _.get(details, "title.al"),
            label: t("alert.titleInShqip"),
          },
          {
            value: _.get(details, "description.al"),
            label: t("alert.descriptionInShqip"),
          },
          {
            value: _.trim(_.get(details, "content.al")),
            label: t("alert.contentInShqip"),
          },
        ]
      );
    if (languageDisplay === "en")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.en"),
            label: t("alert.titleInEnglish"),
          },
          {
            value: _.get(details, "description.en"),
            label: t("alert.descriptionInEnglish"),
          },
          {
            value: _.trim(_.get(details, "content.en")),
            label: t("alert.contentInEnglish"),
          },
        ]
      );
    if (languageDisplay === "al")
      validateData.push(
        ...[
          {
            value: _.get(details, "title.al"),
            label: t("alert.titleInShqip"),
          },
          {
            value: _.get(details, "description.al"),
            label: t("alert.descriptionInShqip"),
          },
          {
            value: _.trim(_.get(details, "content.al")),
            label: t("alert.contentInShqip"),
          },
        ]
      );
    let flag = true;
    let emptyFields = "";
    _.map(validateData, (item) => {
      if (!_.trim(item.value)) {
        emptyFields = emptyFields + `\n ${item.label}`;
        flag = false;
      }
    });
    return { flag, emptyFields };
  };

  const saveNews = async (isDraft?: boolean) => {
    const isHasId = _.get(details, "id");
    const trimValue = {
      content: {
        en: _.get(details, "content.en").replace(/\s+/g, " "),
        al: _.get(details, "content.al").replace(/\s+/g, " "),
      },
      title: {
        en: _.get(details, "title.en").replace(/\s+/g, " "),
        al: _.get(details, "title.al").replace(/\s+/g, " "),
      },
      description: {
        en: _.get(details, "description.en").replace(/\s+/g, " "),
        al: _.get(details, "description.al").replace(/\s+/g, " "),
      },
    };

    // const resolvedGalleryImages = _.map(
    //   details.galleryImages,
    //   (galleryImage) => ({
    //     ...galleryImage,
    //     imagesNew: JSON.stringify(galleryImage?.images),
    //   })
    // );

    const resolvePayload = {
      ...details,
      ...trimValue,
      publishedAt: details?.publishedAt
        ? new Date(details?.publishedAt)
        : new Date(),
      isDraft: 0,
      //handle here
      // galleryImages: resolvedGalleryImages,
    };

    if (isDraft)
      _.assign(resolvePayload, {
        isDraft: 1,
        orderNumber: null,
        isShowOnHomepage: 0,
      });

    if (isHasId) await dispatch(updateArticles(resolvePayload));
    else await dispatch(createArticles(resolvePayload));
    // await dispatch(filterArticles(filter));
  };

  const handleSubmit = async (isDraft?: boolean) => {
    if (isDraft) saveNews(isDraft);
    else {
      const { flag, emptyFields } = await checkValidateForm();
      if (flag) saveNews(isDraft);
      else
        return await statusAlert(
          "warning",
          `${t("alert.foundEmptyFields")} : ${emptyFields}`
        );
    }
  };

  const handleHidePopup = (value: string) => {
    if (value) {
      let resolveValue;
      if (currentType === "pdf")
        resolveValue = `<a href=\"${value}\" target="_blank" title=\"Here\">${
          selectedTab === "en" ? "HERE" : "KËTU"
        }</a>`;
      else if (currentType === "image") {
        const altImage = _.replace(
          _.lowerCase(_.get(details, "title.en")),
          /\s/g,
          "-"
        );
        resolveValue = `<img src=\"${value}\" alt=\"${altImage}">`;
      } else {
        resolveValue = `<iframe data-proportion="true" frameborder="0" allowfullscreen="true" scrolling="no" loading="eager" src="${value}" controls></iframe>`;
      }
      if (selectedTab === "options") onChangeHandler(value, "thumbnail");
      if (selectedTab === "en")
        onChangeHandler(
          {
            ...details[currentEditor],
            en: `${details[currentEditor].en} ${resolveValue}`,
          },
          currentEditor
        );
      if (selectedTab === "al")
        onChangeHandler(
          {
            ...details[currentEditor],
            al: `${details[currentEditor].al} ${resolveValue}`,
          },
          currentEditor
        );
    }
    setIsShowPopup(false);
  };

  const onBack = async () => {
    if (isEditing) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningConfirmLeaveWhenEditing")
      );
      if (isAgree) history.goBack();
    } else history.goBack();
  };

  const checkDisabledTopic = () => {
    const features = _.get(details, "features");
    const page = _.get(details, "categories");
    const checkFeatures = _.includes(["insights", "successStories"], features);
    if (checkFeatures || page === "DiGISNews" || page === "") return true;
    return false;
  };

  const onCloseGalleryImages = (payload: any) => {
    if (payload) {
      const variable = _.get(payload, "variable");
      const galleryImages = _.get(details, "galleryImages");
      if (variable) {
        const updatedGalleryImages = _.map(galleryImages, (item) =>
          item.variable === variable
            ? { ...payload, imagesNew: JSON.stringify([...payload?.images]) }
            : item
        );

        const variableExists = _.some(
          galleryImages,
          (item) => item.variable === variable
        );

        setDetails({
          ...details,
          galleryImages: variableExists
            ? updatedGalleryImages
            : [
                ...galleryImages,
                {
                  ...payload,
                  imagesNew: JSON.stringify([...payload?.images]),
                },
              ],
        });
      } else {
        setDetails({
          ...details,
          galleryImages: [
            ...galleryImages,
            {
              ...payload,
              variable: `##GALLERY${galleryImages.length + 1}##`,
              imagesNew: JSON.stringify([...payload?.images]),
            },
          ],
        });
      }
    }
    setIsShowGalleryPopup(false);
  };

  const onRemoveGallery = async (index: number) => {
    const galleryImages = _.get(details, "galleryImages");
    const removeGallery: any[] = [];
    _.forEach(galleryImages, (gallery, idx) => {
      if (_.toNumber(idx) !== index)
        removeGallery.push({
          ...gallery,
          variable: `##GALLERY${removeGallery.length + 1}##`,
        });
    });
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningBeforeRemoveGallery")
    );
    if (isAgree) setDetails({ ...details, galleryImages: removeGallery });
  };

  const generateTopicDropdown = () => {
    const page = _.get(details, "categories");
    if (page === "general") return DropdownOptions.TOPIC.NEWS;
    if (page === "developmentNews")
      return DropdownOptions.TOPIC.DEVELOPMENT_NEWS;
    if (page === "researchNews") return DropdownOptions.TOPIC.RESEARCH_NEWS;
    return [];
  };

  const renderTabBar = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    const tabBarData = _.get(
      CONSTANTS.TAB_BAR.ARTICLES_DETAILS,
      languageDisplay
    );
    const lastUpdated = _.get(details, "updatedAt");
    return (
      <div className="content mb-2">
        <FlexHorizontal>
          <TabBar
            selectedTab={selectedTab}
            data={tabBarData}
            onChange={(value: string) => setSelectedTab(value)}
            className=""
          />
        </FlexHorizontal>
        {lastUpdated && (
          <UpdatedLabel className="mt-1">
            {t("label.lastUpdated")}: {lastUpdated}
          </UpdatedLabel>
        )}
      </div>
    );
  };

  const renderGalleryField = () => {
    const galleries = _.get(details, "galleryImages");
    return (
      <>
        {_.map(galleries, (gallery, index) => {
          const resolvedGallery = {
            ...gallery,
            images: !_.isEmpty(gallery.imagesNew)
              ? JSON.parse(gallery.imagesNew)
              : gallery.images,
          };
          return (
            <GalleryContainer className="mt-1" key={index}>
              <div
                className="remove-btn"
                onClick={() => onRemoveGallery(_.toNumber(index))}
              >
                <i className="fas fa-times"></i>
              </div>
              <div
                className="edit-btn"
                onClick={() => {
                  setGalleryData(gallery);
                  setIsShowGalleryPopup(true);
                }}
              >
                <i className="fas fa-pen" />
              </div>
              <label>{_.get(gallery, "variable")}</label>
              <GridImageLayout data={resolvedGallery} />
            </GalleryContainer>
          );
        })}
        <CarrotSolidButton
          onClick={() => {
            setGalleryData({
              type: "grid",
              images: [],
            });
            setIsShowGalleryPopup(true);
          }}
          className="mt-1"
        >
          {t("button.addGallery")}
        </CarrotSolidButton>
      </>
    );
  };

  const renderArticlesOptions = () => {
    const languageDisplay = _.get(details, "languageDisplay");
    if (selectedTab === "options")
      return (
        <FlexVertical>
          {
            <FlexHorizontal>
              <FlexHorizontal flex="1" className="flex-align-center mr-2">
                <AdminLabel width="100px">{t("label.publishAs")}</AdminLabel>
                <InputCalendar
                  value={
                    _.get(details, "publishedAt") || new Date().toISOString()
                  }
                  onChange={(value: any) =>
                    onChangeHandler(value, "publishedAt")
                  }
                  isMaxDate={new Date().toISOString()}
                  className="publish_as"
                />
              </FlexHorizontal>
              <FlexHorizontal flex="0.7" />
            </FlexHorizontal>
          }
          <FlexHorizontal flex="1" className="flex-align-center mt-1">
            <AdminLabel width="100px">{t("label.language")}</AdminLabel>
            {_.map(
              [
                { label: "bothLanguages", value: "both" },
                { label: "onlyEnglish", value: "en" },
                { label: "onlyShqip", value: "al" },
              ],
              (item, index) => {
                const className =
                  item.value === languageDisplay ? "active" : "";
                return (
                  <Radio
                    className={className}
                    flex="1"
                    key={`radio-${index}`}
                    onClick={(value: string) =>
                      onChangeHandler(value, "languageDisplay")
                    }
                    value={item.value}
                    label={t(`label.${item.label}`)}
                    defaultSelected={languageDisplay}
                  />
                );
              }
            )}
          </FlexHorizontal>
          <FlexHorizontal className="mt-1">
            <FlexHorizontal flex="1" className="flex-align-center mr-2">
              <AdminLabel width="100px">{t("label.solution")}*</AdminLabel>
              <AdminDropdown
                data={DropdownOptions.SOLUTION}
                flex="1"
                width="100%"
                selectedValue={_.get(details, "categories")}
                onChange={(value: any) => onChangeHandler(value, "categories")}
              />
            </FlexHorizontal>
            <FlexHorizontal flex="0.7" className="flex-align-center ">
              <AdminLabel width="100px">{t("label.features")}*</AdminLabel>
              <AdminDropdown
                data={generateDropdown(DropdownOptions.FEATURES)}
                flex="1"
                selectedValue={_.get(details, "features")}
                onChange={(value: any) => onChangeHandler(value, "features")}
              />
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal className="mt-1 ">
            <FlexHorizontal flex="1" className="flex-align-center mr-2">
              <AdminLabel width="100px">{t("label.topic")}</AdminLabel>
              <AdminDropdown
                disabled={checkDisabledTopic()}
                data={generateTopicDropdown()}
                flex="1"
                width="100%"
                selectedValue={_.get(details, "topic")}
                onChange={(value: any) => onChangeHandler(value, "topic")}
              />
            </FlexHorizontal>
            <FlexHorizontal flex="0.7">
              <AdminLabel width="100px"></AdminLabel>
              <CarrotSolidButton onClick={() => setIsShowPopup(true)}>
                {t("button.selectMedia")}
              </CarrotSolidButton>
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal className="flex-align-center mt-1">
            <AdminLabel width="100px">{t("label.preview")}</AdminLabel>
            <PreviewImage>
              <CropImage
                imageUrl={_.get(details, "thumbnail")}
                handleChange={(cropData: any) =>
                  onChangeHandler(cropData, "croppedData")
                }
                data={_.get(details, "croppedData")}
              />
            </PreviewImage>
          </FlexHorizontal>
        </FlexVertical>
      );
  };

  const renderContentDetails = () => {
    const isSelectedContent = selectedTab === "en" || selectedTab === "al";
    if (isSelectedContent)
      return (
        <React.Fragment key={`content-${selectedTab}`}>
          <h2
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            onFocus={(event: any) =>
              dispatch(setContentEdited(_.trim(event.currentTarget.innerText)))
            }
            onBlur={(event: any) =>
              onChangeHandler(
                {
                  ...details.title,
                  [selectedTab]: _.trim(event.currentTarget.innerText),
                },
                "title",
                _.trim(event.currentTarget.innerText)
              )
            }
            data-placeholder={t("placeholder.typeTheTitleHere")}
            className="editable"
          >
            {_.get(details, `title.${selectedTab}`)}
          </h2>
          <GrannyRegularParagraph
            contentEditable
            suppressContentEditableWarning
            spellCheck={false}
            onFocus={(event: any) =>
              dispatch(setContentEdited(_.trim(event.currentTarget.innerText)))
            }
            onBlur={(event: any) =>
              onChangeHandler(
                {
                  ...details.description,
                  [selectedTab]: _.trim(event.currentTarget.innerText),
                },
                "description",
                _.trim(event.currentTarget.innerText)
              )
            }
            data-placeholder={t("placeholder.typeTheDescriptionHere")}
            className="editable"
          >
            {_.get(details, `description.${selectedTab}`)}
          </GrannyRegularParagraph>
          <ReactQuill
            key="content"
            onAddMedia={() => {
              setIsShowPopup(true);
              setCurrentEditor("content");
            }}
            value={_.get(details, `content.${selectedTab}`)}
            defaultValue={_.get(details, `content.${selectedTab}`)}
            onChange={(value: any) =>
              onChangeHandler(
                {
                  ...details.content,
                  [selectedTab]: value,
                },
                "content",
                _.trim(value)
              )
            }
            placeholder={t("placeholder.typeTheContentHere")}
          />
          <ReactQuill
            className="mt-2"
            key="download-content"
            onAddMedia={() => {
              setIsShowPopup(true);
              setCurrentEditor("downloadContent");
            }}
            value={_.get(details, `downloadContent.${selectedTab}`)}
            defaultValue={_.get(details, `downloadContent.${selectedTab}`)}
            onChange={(value: any) =>
              onChangeHandler(
                {
                  ...details.downloadContent,
                  [selectedTab]: value,
                },
                "downloadContent",
                _.trim(value)
              )
            }
            placeholder={t("placeholder.typeTheDownloadContentHere")}
          />
          {renderGalleryField()}
        </React.Fragment>
      );
  };

  const renderButtonField = () => {
    return (
      <FlexHorizontal justifySpaceBetween className="mt-3">
        <GeyserSolidButton onClick={() => onBack()}>
          {t("button.back")}
        </GeyserSolidButton>
        <FlexHorizontal justifyFlexEnd>
          <GeyserSolidButton
            className="mr-1"
            onClick={() => handleSubmit(true)}
          >
            {t("button.saveAsDraft")}
          </GeyserSolidButton>
          <CarrotSolidButton
            className="mr-1"
            color={ColorName.burntSienna}
            onClick={() => setIsShowPreviewPopup(true)}
          >
            {t("button.preview")}
          </CarrotSolidButton>
          <CarrotSolidButton
            color={ColorName.burntSienna}
            onClick={() => handleSubmit()}
          >
            {_.get(details, "isDraft")
              ? t("button.publish")
              : _.has(details, "id")
              ? t("button.update")
              : t("button.publish")}
          </CarrotSolidButton>
        </FlexHorizontal>
      </FlexHorizontal>
    );
  };

  const renderPopup = () => {
    const popupTab =
      selectedTab !== "options"
        ? TAB_BAR.ARTICLES_POPUP
        : TAB_BAR.ARTICLES_IMAGE_POPUP;
    return (
      <>
        {isShowPopup && (
          <SelectMedia
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(value: string) => handleHidePopup(value)}
            tabBar={popupTab}
            selectedTab="image"
            onClose={() => setIsShowPopup(false)}
          />
        )}
        {isShowPreviewPopup && (
          <PreviewNews
            details={details}
            className={`${isShowPreviewPopup ? "show" : ""}`}
            onClose={() => setIsShowPreviewPopup(false)}
          />
        )}
        {isShowGalleryPopup && (
          <GalleryImages
            className={`${isShowGalleryPopup ? "show" : ""}`}
            onClose={(payload: any) => onCloseGalleryImages(payload)}
            data={galleryData}
          />
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer isExpand={isExpand}>
        <ContentForm>
          {renderPopup()}
          {renderTabBar()}
          {renderArticlesOptions()}
          {renderContentDetails()}
          {renderButtonField()}
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.ARTICLES_DETAILS}
      loading={requestIsLoading || requestMediaIsLoading}
    />
  );
};

export default ArticlesDetails;
