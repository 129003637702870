/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { PreviewImageLayer, PreviewButton, PaginationImageList } from './PreviewImage.styles';

interface SectionProps {
  payload: string[];
  selected: string;
  onClose(): void;
}

const PreviewImage = (props: SectionProps) => {
  const { payload, selected, onClose } = props;
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (selected) {
      const findIndex = _.findIndex(payload, item => item === selected);
      if (findIndex >= 0) setIndex(findIndex);
    }
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onPrev = () => {
    if (index === 0) setIndex(payload.length - 1);
    else setIndex(index - 1);
  };

  const onNext = () => {
    if (index === payload.length - 1) setIndex(0);
    else setIndex(index + 1);
  };

  return (
    <PreviewImageLayer>
      <PreviewButton className="prev-btn" onClick={() => onPrev()}>
        <i className="fas fa-chevron-left"></i>
      </PreviewButton>
      <img src={payload[index]} alt="" />
      <PreviewButton className="next-btn" onClick={() => onNext()}>
        <i className="fas fa-chevron-right"></i>
      </PreviewButton>
      <PreviewButton className="close-btn" onClick={() => onClose()}>
        <i className="fas fa-times"></i>
      </PreviewButton>
      <PaginationImageList>
        <div>
          {_.map(payload, (item, idx) => (
            <img
              key={`image-${idx}`}
              src={item}
              alt="media-preview"
              className={index === idx ? 'active' : ''}
              onClick={() => setIndex(idx)}
            />
          ))}
        </div>
      </PaginationImageList>
    </PreviewImageLayer>
  );
};

export default PreviewImage;
