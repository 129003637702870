import styled from "styled-components";

const Container = styled.div`
  width: calc(100% + 10px);
  font-size: 12px;

  &.alumni-dropdown {
    flex: 1;
    width: unset;
    &.max-height {
      .select-prefix__menu-list {
        max-height: 100%;
      }
    }
  }

  .select-prefix__control {
    border-color: black;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-radius: 0px;
  }

  .select-prefix__control--is-focused {
    box-shadow: rgb(232, 120, 68, 0.3) 0px 1px 2px 0px,
      rgb(232, 120, 68, 0.15) 0px 1px 3px 1px;
  }

  .select-prefix__control:hover {
    border-color: black;
  }

  .select-prefix__value-container {
    min-height: 40px;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 400;
  }

  .select-prefix__option {
    height: 40px;
    color: black;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
  }

  .select-prefix__menu {
    border-radius: 0px;
    border: 1px solid black;
  }
`;

export { Container };
